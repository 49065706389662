/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { baseApi } from '.';

export const diagnosticsApi = baseApi.injectEndpoints({
  endpoints: builder => {
    const token = localStorage.getItem('token') ?? '';

    return {
      getDiagnostics: builder.query<any, number | undefined | void>({
        query: brandId => ({
          url: `/api/brand_monitoring/diagnostics/${brandId ? `?brand_id=${brandId}` : ''}`,
          method: 'get',
          headers: {
            Authorization: `Token ${token}`,
          },
        }),
      }),
    };
  },
});

export const { useGetDiagnosticsQuery } = diagnosticsApi;
