import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import Mcgill from '../../../assets/images/login/Mcgilll.png';
import Caudalie from '../../../assets/images/login/Caudalie.png';
import Thatsnotabag from '../../../assets/images/login/Thatsnotabag.png';
import CircleK from '../../../assets/images/login/Circle-K.png';
import Promptify from '../../../assets/images/login/Promptify.png';
import Soligny from '../../../assets/images/login/Soligny.png';

export const BrandGrid = () => {
  return (
    <Box
      sx={{
        width: '100%',
        gap: '16px',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        padding: '16px',
        margin: '0 auto',
      }}
    >
      <Typography
        sx={{
          color: 'rgba(66, 72, 93, 0.60)',
          textAlign: 'center',
          fontFeatureSettings: 'liga off, clig off',
          fontFamily: 'Poppins',
          fontSize: 16,
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '110%',
        }}
      >
        Join leading brands already using Focal Studio
      </Typography>

      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={6} sm={4} md={2}>
          <img src={Mcgill} alt="McGill" style={{ width: '109.25px', height: '30.25' }} />
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <img src={Caudalie} alt="Caudalie" style={{ width: '111.5px', height: '36px' }} />
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <img
            src={Thatsnotabag}
            alt="Thatsnotabag"
            style={{ width: '163.5px', height: '18.75px' }}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <img src={CircleK} alt="Circle K" style={{ width: '114.74px', height: '31.5px' }} />
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <img src={Promptify} alt="Promptify" style={{ width: '129.75px', height: '26.5px' }} />
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <img src={Soligny} alt="Soligny" style={{ width: '111.75px', height: '31.25px' }} />
        </Grid>
      </Grid>
    </Box>
  );
};
