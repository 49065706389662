import { useEffect, useState } from 'react';

const useCountUp = (end: number | null, duration: number = 300): number => {
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    if (end === null) return;

    let startTimestamp: number | null = null;
    const step = (timestamp: number) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      setCount(Math.floor(progress * end));
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }, [end, duration]);

  return count;
};

export default useCountUp;
