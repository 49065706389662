import React from 'react';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import ProductCardPlaceholder from '../../Common/Placeholders/ProductCardPlaceholder';

const CardsPlaceholder = () => (
  <Grid container columnSpacing={2} rowSpacing={1} justifyContent={'center'}>
    {Array.from({ length: 12 }).map((_, i) => (
      <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
        <ProductCardPlaceholder />
      </Grid>
    ))}
  </Grid>
);

function ImagesSectionPlaceholder() {
  return (
    <Stack>
      <Stack gap={'4px'} mt={'30px'}>
        <Skeleton variant="text" width={200} height={50} />
        <Skeleton variant="text" width={400} height={30} />
      </Stack>
      <Stack direction={'row'} gap={'24px'} py={'8px'} alignItems={'flex-start'}>
        <Stack flex={1} gap={'8px'}>
          <Skeleton variant="rectangular" width="100%" height={56} />
          <Stack p={'8px 0px'}>
            <Skeleton variant="text" width={150} height={24} />
          </Stack>
          <CardsPlaceholder />
        </Stack>
        <Skeleton variant="rectangular" width={300} height={500} />
      </Stack>
    </Stack>
  );
}

export default ImagesSectionPlaceholder;
