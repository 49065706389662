import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { type FormikProps } from 'formik';
import { type IEditProfile } from '../types';

interface IProps {
  formik: FormikProps<IEditProfile>;
}

export const About: React.FC<IProps> = ({ formik }) => {
  return (
    <Box>
      <Typography fontWeight={500} fontSize="1rem" textAlign={{ xs: 'center', sm: 'start' }}>
        About you
      </Typography>
      <Box mt="0.5rem" display="flex" justifyContent={{ xs: 'center', sm: 'flex-start' }}>
        <TextField
          id="outlined-multiline-static"
          label="Short"
          name="bio"
          value={formik.values.bio}
          onChange={formik.handleChange}
          error={!!formik.touched.bio && !!formik.errors.bio}
          helperText={formik.touched.bio && formik.errors.bio}
          multiline
          rows={10}
          sx={{ width: { xs: '90%', sm: '100%' } }}
        />
      </Box>
    </Box>
  );
};
