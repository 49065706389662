import React from 'react';
import { Box, Typography } from '@mui/material';
import { LogoDark } from '../../../../../../assets/icons/LogoDark';

interface IChatAvatar {
  showAvatar: boolean;
}

export const ChatAvatar = ({ showAvatar }: IChatAvatar) => {
  return (
    <Box display="flex" justifyContent="flex-start" alignItems="center" width="90%">
      {showAvatar ? (
        <>
          <LogoDark width="24px" height="24px" />
          <Typography
            sx={{
              fontSize: 16,
              fontFamily: 'Figtree',
              ml: '15px',
            }}
          >
            Focal AI
          </Typography>
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};
