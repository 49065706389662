import React from 'react';

export const ProductsBestImage: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      fill="#FFF7F4"
    />
    <path
      d="M12 15.3334L8.08137 17.7267L9.1467 13.26L5.66003 10.2734L10.2367 9.90669L12 5.66669L13.7634 9.90669L18.3407 10.2734L14.8534 13.26L15.9187 17.7267L12 15.3334Z"
      fill="#FF7B43"
    />
  </svg>
);
