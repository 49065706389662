import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Grid, Modal } from '@mui/material';
import { postUploadMaskImage } from '../../../../hooks/api/maskImage';
import { postUploadImage } from '../../../../hooks/api/image';

import Sidebar from './components/Sidebar';
import Content from './components/Content';
import { ImagsGeneratedContext } from '../../../../contexts';

interface IImagesArray {
  url: string;
  id: string;
}

interface IModal {
  open: boolean;
  setOpen: (val: boolean) => void;
  modalImage: string;
  conversation: any;
  handleCancelRequest: () => void;
  setModalImage: (value: string) => void;
  assets: IImagesArray[];
  setAssets: (value: IImagesArray[]) => void;
}

const ImageEditModal = ({
  open,
  setOpen,
  modalImage,
  handleCancelRequest,
  setModalImage,
  assets,
  setAssets,
}: IModal) => {
  const [shape, setShape] = useState([]);
  const [nextShapes, setNextShapes] = useState([]);
  const [query, setQuery] = useState('');
  const [lineWidth, setLineWidth] = useState(40);
  const [undo, setUndo] = useState(false);
  const [redo, setRedo] = useState(false);
  const [maskResponse, setMaskResponse] = useState('');
  const [initialImage, setInitialImage] = useState(null);
  const [maskImage, setMaskImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [textError, setTextError] = useState(false);
  const [maskError, setMaskError] = useState(false);
  const [isRedraw, setIsRedraw] = useState(false);
  const [refineMode, setRefineMode] = useState<'none' | 'restore' | 'removal'>('none');
  const [selectedAsset, setSelectedAsset] = useState('');

  const { imageGenerated, handleEditImageRequestID, isLoading } = useContext(ImagsGeneratedContext);

  const canvasRef = useRef(null);
  const initImageRef = useRef(null);
  const modalRef = useRef(null);
  const shapeRef = useRef(null);

  const [isGeneratedImageLoading, setIsGeneratedImageLoading] = useState(false);

  useEffect(() => {
    if (shape.length) {
      setUndo(true);
    } else {
      setUndo(false);
    }
  }, [shape]);

  useEffect(() => {
    if (nextShapes.length) {
      setRedo(true);
    } else {
      setRedo(false);
    }
  }, [nextShapes]);

  const handleUndo = () => {
    if (shape.length) {
      const shapeCopy = [...shape];
      const lastShape = shapeCopy[shapeCopy.length - 1];
      shapeCopy.pop();
      setShape(shapeCopy);
      if (lastShape) {
        setNextShapes(prev => [...prev, lastShape]);
      }
    } else {
      setShape([]);
      setUndo(false);
    }

    setIsRedraw(true);
  };

  const handleRedo = () => {
    if (redo && nextShapes.length) {
      const shapeCopy = [...shape];
      const nextShapesCopy = [...nextShapes];
      const lastShape = nextShapesCopy[nextShapesCopy.length - 1];
      nextShapesCopy.pop();
      setShape([...shapeCopy, lastShape]);
      if (nextShapesCopy.length) {
        setNextShapes(nextShapesCopy);
      } else {
        setNextShapes([]);
      }
      setIsRedraw(true);
    }
  };

  const handleClose = () => {
    if (isLoading) {
      handleCancelRequest();
    }
    setOpen(false);
    setShape([]);
    setQuery('');
  };

  const handleSend = async () => {
    if (!query) {
      setTextError(true);
    }
    if (!maskImage) {
      setMaskError(true);
    }

    if (query.trim() !== '' && initialImage && maskImage) {
      setIsGeneratedImageLoading(true);
      setLoading(true);

      const initialImageS3 = await postUploadImage(initialImage);

      const maskImageS3 = await postUploadImage(maskImage);

      if (initialImageS3 && maskImageS3) {
        postUploadMaskImage({
          image: initialImageS3,
          mask: maskImageS3,
          prompt: query,
        }).then(res => {
          setLoading(false);
          setShape([]);
          setMaskResponse(res.data.request_id);
          setQuery('');
        });
      }
    }
  };

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setLineWidth(newValue as number);
  };

  useEffect(() => {
    if (maskImage) {
      setMaskError(false);
    }
  }, [maskImage]);

  useEffect(() => {
    if (query) {
      setTextError(false);
    }
  }, [query]);

  useEffect(() => {
    if (maskResponse) {
      handleEditImageRequestID(maskResponse);
    }
  }, [maskResponse]);

  useEffect(() => {
    if (imageGenerated.url) {
      const isImageExist = assets.find(asset => asset.url === imageGenerated.url);
      if (isImageExist === undefined) {
        setAssets([imageGenerated, ...assets]);
      }
      setModalImage(imageGenerated.url);
      setQuery('');
      setIsGeneratedImageLoading(false);
    }
  }, [imageGenerated]);

  useEffect(() => {
    setShape([]);
  }, [assets, modalImage]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '85vw',
          height: '95vh',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          borderRadius: '10px',
          boxShadow: 24,
          overflow: 'auto',
        }}
      >
        <Grid container display="flex" flexDirection={{ xs: 'row', md: 'row-reverse' }}>
          <Content
            handleClose={handleClose}
            handleSliderChange={handleSliderChange}
            undo={undo}
            handleUndo={handleUndo}
            redo={redo}
            handleRedo={handleRedo}
            modalRef={modalRef}
            image={modalImage}
            canvasRef={canvasRef}
            shapeRef={shapeRef}
            initImageRef={initImageRef}
            lineWidth={lineWidth}
            shape={shape}
            isRedraw={isRedraw}
            setShape={setShape}
            setInitialImage={setInitialImage}
            setMaskImage={setMaskImage}
            setIsRedraw={setIsRedraw}
            mode={refineMode}
            setRefineMode={setRefineMode}
            selectedAsset={selectedAsset}
            modalImage={modalImage}
            isGeneratedImageLoading={isGeneratedImageLoading}
            setModalImage={setModalImage}
          />
          <Sidebar
            setQuery={setQuery}
            query={query}
            handleSend={handleSend}
            loading={loading}
            textError={textError}
            maskError={maskError}
            assets={assets}
            isLoading={isLoading}
            setSelectedAsset={setSelectedAsset}
            selectedAsset={selectedAsset}
            isShapesExist={!!shape.length}
            setModalImage={setModalImage}
          />
        </Grid>
      </Box>
    </Modal>
  );
};

export default ImageEditModal;
