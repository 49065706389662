import React from 'react';

export const ProductsSwitchRight: React.FC = () => (
  <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="26" rx="4" fill="#F3F3F7" />
    <rect x="2" y="2" width="20" height="20" rx="2" fill="white" />
    <path
      d="M14.7814 11.3331L11.2054 7.75712L12.1481 6.81445L17.3334 11.9998L12.1481 17.1851L11.2054 16.2425L14.7814 12.6665H6.66675V11.3331H14.7814Z"
      fill="#171D30"
      fillOpacity="0.25"
    />
  </svg>
);
