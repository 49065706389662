import React from 'react';

export const ArchiveLine: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M2.49984 8.33334H1.6665V3.33583C1.6665 2.87417 2.04567 2.5 2.49317 2.5H17.5065C17.6159 2.49977 17.7243 2.5213 17.8253 2.56334C17.9263 2.60538 18.0179 2.66708 18.0948 2.74486C18.1717 2.82264 18.2324 2.91495 18.2734 3.01641C18.3143 3.11786 18.3346 3.22645 18.3332 3.33583V8.33334H17.4998V16.6675C17.5002 16.7765 17.479 16.8845 17.4376 16.9853C17.3962 17.0861 17.3353 17.1778 17.2585 17.2551C17.1817 17.3324 17.0903 17.3939 16.9898 17.4359C16.8892 17.4779 16.7813 17.4997 16.6723 17.5H3.32734C3.21834 17.4997 3.11047 17.4779 3.0099 17.4359C2.90932 17.3939 2.81801 17.3324 2.74117 17.2551C2.66433 17.1778 2.60347 17.0861 2.56206 16.9853C2.52065 16.8845 2.49951 16.7765 2.49984 16.6675V8.33334ZM15.8332 8.33334H4.1665V15.8333H15.8332V8.33334ZM3.33317 4.16667V6.66667H16.6665V4.16667H3.33317ZM7.49984 10H12.4998V11.6667H7.49984V10Z"
      fill="white"
    />
  </svg>
);
