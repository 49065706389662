import React from 'react';

export const ChipIcon: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_506_1088)">
      <path
        d="M8 0C7.57197 4.22832 4.22832 7.57197 0 8C4.22832 8.42803 7.57197 11.7717 8 16C8.42803 11.7717 11.7717 8.42803 16 8C11.7717 7.57197 8.42803 4.22832 8 0Z"
        fill="#49199E"
      />
    </g>
    <defs>
      <clipPath id="clip0_506_1088">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
