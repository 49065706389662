import React from 'react';

interface IProps {
  color?: string;
}

export const ArrowRightIcon = ({ color = '#28205B' }: IProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_714_298" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" transform="matrix(-1 0 0 1 24 0)" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_714_298)">
      <path
        d="M9.99998 17.6538L15.6538 12L9.99998 6.34619L8.94616 7.40002L13.5462 12L8.94616 16.6L9.99998 17.6538Z"
        fill={color}
      />
    </g>
  </svg>
);
