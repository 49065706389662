import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, ImageList, ImageListItem, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import Card from './components/Card';
import { ThreeDots } from 'react-loader-spinner';
import useUser from '../../../../hooks/useUser';
import { ThemeBox } from './components/ThemeBox';
import { ChatAvatar } from './components/ChatAvatar';
import { UserAvatar } from './components/UserAvatar';
import ImageEditModal from '../ImageEditModal';
import { FileContentContext, ImagsGeneratedContext } from '../../../../contexts';

enum SPEAKER {
  user,
  bot,
}

interface IImagesArray {
  url: string;
  id: string;
}

interface ICONVERSATION {
  speaker_type: SPEAKER;
  query: string;
  isLoading?: boolean;
  images?: IImagesArray[];
  isSuccess?: boolean;
  loadingItems?: string[];
  ids?: string[];
  type?: string;
  editedPrompt?: string;
}

interface ConversationProps {
  conversation: ICONVERSATION[];
  requestError: boolean;
  setModalImage: (value: string) => void;
  modalImage: string;
  handleCancelRequest: () => void;
  assets: IImagesArray[];
  setAssets: (value: IImagesArray[]) => void;
  setEditAsset: (value: boolean) => void;
}

const Conversation: React.FC<ConversationProps> = ({
  conversation,
  requestError,
  setModalImage,
  modalImage,
  handleCancelRequest,
  assets,
  setAssets,
  setEditAsset,
}) => {
  const { fileContentVal, handleContentVal } = useContext(FileContentContext);

  const { imagesGenerated } = useContext(ImagsGeneratedContext);
  const [openEditModal, setOpenEditModal] = useState(false);

  const [isClicked, setIsClicked] = useState<number | null>(null);
  const [clickedImage, setClickedImage] = useState('');

  const user = useUser();

  const lastSpeakerIsUser = useMemo(() => {
    if (conversation.length > 0) {
      const lastUser = conversation[conversation.length - 1];
      if (lastUser?.speaker_type === SPEAKER.user) return true;
      else return false;
    } else return false;
  }, [conversation]);

  const botReply = conversation.find(item => item.speaker_type === SPEAKER.bot)?.query ?? '';
  const botFinalReply = `Sure, here's my idea: "${botReply}". Please hold while I create the visuals. `;

  useEffect(() => {
    let typingInterval: NodeJS.Timeout | null = null;
    if (lastSpeakerIsUser) {
      const words = botFinalReply ? botFinalReply.split(' ') : [];
      let currentWordIndex = 0;

      typingInterval = setInterval(() => {
        if (currentWordIndex < words.length) {
          currentWordIndex++;
        }
      }, 100);
    }
    return () => {
      if (typingInterval) {
        clearInterval(typingInterval);
      }
    };
  }, [botReply, lastSpeakerIsUser, botFinalReply]);

  useEffect(() => {
    const elem = document.getElementById('stack');
    if (elem) {
      elem.scrollTop = elem.scrollHeight;
    }
  }, [conversation.length]);

  useEffect(() => {
    if (imagesGenerated[0]?.url && imagesGenerated.length === 1) {
      setClickedImage(imagesGenerated[0].url);
      handleContentVal(imagesGenerated[0].url);
    }
  }, [imagesGenerated]);

  const formatTextOutputs = (text: string) => {
    const pattern = /\*\*(.*?)\*\*/g;

    const parts = text.split(pattern);

    const formattedText = parts.map((part, index) => {
      if (index % 2 === 1) {
        return `<b>${part}</b>`;
      }
      return part;
    });

    return formattedText.join(' ');
  };

  return (
    <Wrapper id="stack">
      {modalImage && (
        <ImageEditModal
          open={openEditModal}
          setOpen={setOpenEditModal}
          modalImage={modalImage}
          conversation={conversation}
          handleCancelRequest={handleCancelRequest}
          setModalImage={setModalImage}
          assets={assets}
          setAssets={setAssets}
        />
      )}
      <StackStyled>
        {fileContentVal !== '' && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              alignItems: 'center',
              mt: '20px',
              pb: '10px',
              boxSizing: 'border-box',
              borderBottom: '1px solid #EFEFF0',
            }}
          >
            <ChatAvatar showAvatar />
            <BotChat>
              <Typography
                sx={{
                  fontSize: 16,
                  fontFamily: 'Figtree',
                }}
              >
                What do you want to create today?
              </Typography>
            </BotChat>
          </Box>
        )}
        {conversation.map((value, index) => {
          if (value.speaker_type === SPEAKER.user) {
            return (
              <React.Fragment key={`user-${index}`}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pb: '10px',
                    boxSizing: 'border-box',
                    borderBottom: '1px solid #EFEFF0',
                  }}
                >
                  <UserAvatar avatar={user?.avatar} username={user?.username} />
                  <UserChat>
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontFamily: 'Figtree',
                        color: '#61646B',
                      }}
                    >
                      {value.query}
                    </Typography>
                  </UserChat>
                </Box>
                {lastSpeakerIsUser && index === conversation.length - 1 && !requestError && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                      alignItems: 'center',
                      pb: '10px',
                      boxSizing: 'border-box',
                      borderBottom: '1px solid #EFEFF0',
                    }}
                  >
                    <ChatAvatar
                      showAvatar={value.speaker_type !== conversation[index - 1]?.speaker_type}
                    />
                    <BotChat key={index}>
                      <Stack direction="column" spacing={2} width={1}>
                        <ThreeDots
                          height={30}
                          width={50}
                          radius={9}
                          color="#5A58CB"
                          ariaLabel="three-dots-loading"
                          visible={true}
                        />
                      </Stack>
                    </BotChat>
                  </Box>
                )}
              </React.Fragment>
            );
          } else {
            return (
              <Box
                key={`bot-${index}`}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                  alignItems: 'center',
                  pb: '10px',
                  boxSizing: 'border-box',
                  borderBottom: '1px solid #EFEFF0',
                }}
              >
                <ChatAvatar
                  showAvatar={value.speaker_type !== conversation[index - 1]?.speaker_type}
                />

                <BotChat>
                  {value.isLoading && value?.type === 'edited' ? (
                    <Stack direction="column" spacing={2} width={1}>
                      <ThreeDots
                        height={30}
                        width={50}
                        radius={9}
                        color="#5A58CB"
                        ariaLabel="three-dots-loading"
                        visible={true}
                      />
                    </Stack>
                  ) : (
                    <Stack direction="column" width={1}>
                      {value.ids ? (
                        ''
                      ) : (
                        <Typography
                          sx={{
                            fontSize: 16,
                            fontFamily: 'Figtree',
                          }}
                          dangerouslySetInnerHTML={{
                            __html: formatTextOutputs(value.query.replace(/(\n)/g, '<br />')),
                          }}
                        ></Typography>
                      )}
                      {value.isSuccess && (
                        <Stack
                          direction="column"
                          width={1}
                          sx={{ opacity: isClicked === index ? 1 : 0.5, '&:hover': { opacity: 1 } }}
                          onClick={() => {
                            setIsClicked(index);
                          }}
                        >
                          {value?.type === 'edited' ? (
                            <Typography
                              sx={{
                                fontSize: 16,
                                fontFamily: 'Figtree',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {value.editedPrompt && `Your prompt: "${value.editedPrompt}"`}
                            </Typography>
                          ) : (
                            <ThemeBox
                              query={value.query}
                              opacity={isClicked === index ? 1 : 0.5}
                              onClick={() => {
                                if (value?.loadingItems?.length && value?.images?.length) {
                                  const firstImage = value?.loadingItems[0];
                                  const image = value.images?.find(img => {
                                    return img.id === firstImage;
                                  });

                                  if (image) {
                                    setClickedImage(image.url);
                                    if (fileContentVal !== image.url) {
                                      handleContentVal(image.url);
                                    }
                                  }
                                }
                              }}
                            />
                          )}
                          <ImageList
                            sx={{
                              marginTop: '0px !important',
                              overflow: 'hidden',
                            }}
                            cols={3}
                            rowHeight={'auto'}
                          >
                            {value.loadingItems ? (
                              value.loadingItems.map((item, imgIndex) => {
                                const image = value.images?.find(img => img.id === item);
                                return (
                                  <ImageListItemS key={`img-${imgIndex}`}>
                                    {image ? (
                                      <Card
                                        image={image.url}
                                        clickedImage={clickedImage}
                                        setClickedImage={setClickedImage}
                                        setEditAsset={setEditAsset}
                                        setIsClicked={() => {
                                          setIsClicked(index);
                                        }}
                                      />
                                    ) : (
                                      <Stack
                                        direction="column"
                                        spacing={2}
                                        width={1}
                                        sx={{
                                          display: 'flex',
                                          minHeight: '100%',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                        }}
                                      >
                                        <ThreeDots
                                          height={30}
                                          width={50}
                                          radius={9}
                                          color="#5A58CB"
                                          ariaLabel="three-dots-loading"
                                          visible={true}
                                        />
                                      </Stack>
                                    )}
                                  </ImageListItemS>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </ImageList>
                        </Stack>
                      )}
                    </Stack>
                  )}
                </BotChat>
              </Box>
            );
          }
        })}
      </StackStyled>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  max-height: 95%;
  overflow-y: auto;
  /* Hide scrollbar for Firefox */
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const StackStyled = styled(Stack)`
  gap: 1rem;
  padding-bottom: 2rem;
`;

const UserChat = styled('div')`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  padding: 0.5rem;
  width: calc(90% - 80px);
  animation: fadeIn 0.2s ease-in-out;
  // background: #efeff0;
  // box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 10px;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const BotChat = styled('div')`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  padding: 0.5rem;
  width: calc(90% - 80px);
  animation: fadeIn 0.5s ease-in-out;
  // background: #f7f5ff;
  // box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 10px;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const ImageListItemS = styled(ImageListItem)`
  animation: fadeIn 0.5s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
export default Conversation;
