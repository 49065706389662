import React, { useContext, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  ClickAwayListener,
  Grow,
  List,
  ListItem,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from '@mui/material';
import { NavLink as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import useLogout from '../../../../hooks/useLogout';
import useUser from '../../../../hooks/useUser';
import { PhotoIcon, SupportIcon, FolderIcon, BrandIcon } from '../../../../assets/icons';
import { AssetContentContext, FileContentContext } from '../../../../contexts';

interface ISidebar {
  isMobile?: boolean;
}

function SidebarMenu({ isMobile = false }: ISidebar) {
  const location = useLocation();
  const activeUrl = location.pathname;

  const isActive = (url: string) => {
    return url === activeUrl;
  };

  const { handleAssetVal } = useContext(AssetContentContext);
  const { fileContentVal } = useContext(FileContentContext);

  const menuAnchorRef = useRef<HTMLDivElement | null>(null);
  const [isMenuShown, setIsMenuShown] = useState(false);

  const user = useUser();
  const navigate = useNavigate();
  const logout = useLogout();

  const handleLogout = () => {
    logout();
  };

  return (
    <>
      <List
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'row' : 'column',
          padding: 0,
          width: '100%',
        }}
      >
        <ListItem
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingY: 2,
          }}
        >
          <Tooltip placement="right" title="Assets Library" arrow>
            <Box
              sx={{
                backgroundColor: isActive('/assets') ? '#F6F3FC' : 'transparent',
                borderRadius: '10px',
                '&:hover': { bgcolor: '#F6F6F6' },
              }}
            >
              <RouterLink
                onClick={() => {
                  handleAssetVal('');
                }}
                to="/assets"
                style={routerStyle}
              >
                <FolderIcon color={isActive('/assets') ? '#5930A5' : '#808080'} />
              </RouterLink>
            </Box>
          </Tooltip>
        </ListItem>
        <ListItem
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingY: 2,
          }}
        >
          <Tooltip placement="right" title="Image Creator" arrow sx={{ width: '100%' }}>
            <Box
              sx={{
                backgroundColor: isActive('/') ? '#F6F3FC' : 'transparent',
                borderRadius: '10px',
                '&:hover': { bgcolor: '#F6F6F6' },
              }}
            >
              <RouterLink
                onClick={() => {
                  handleAssetVal(fileContentVal);
                }}
                to="/"
                style={routerStyle}
              >
                <PhotoIcon color={isActive('/') ? '#5930A5' : '#808080'} />
              </RouterLink>
            </Box>
          </Tooltip>
        </ListItem>
        <ListItem
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingY: 2,
          }}
        >
          <Tooltip placement="right" title="Brand Monitoring" arrow sx={{ width: '100%' }}>
            <Box
              sx={{
                backgroundColor: isActive('/brands') ? '#F6F3FC' : 'transparent',
                borderRadius: '10px',
                '&:hover': { bgcolor: '#F6F6F6' },
              }}
            >
              <RouterLink
                onClick={() => {
                  handleAssetVal(fileContentVal);
                }}
                to="/brands"
                style={routerStyle}
              >
                <BrandIcon color={isActive('/brands') ? '#5930A5' : '#808080'} />
              </RouterLink>
            </Box>
          </Tooltip>
        </ListItem>
        <ListItem
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingY: 2,
            position: { xs: 'relative', md: 'absolute' },
            bottom: { xs: '0px', md: '25px' },
          }}
        >
          <Tooltip placement="right" title="Support" arrow>
            <Box
              sx={{
                backgroundColor: isActive('/support') ? '#F6F3FC' : 'transparent',
                borderRadius: '10px',
                '&:hover': { bgcolor: '#F6F6F6' },
              }}
            >
              <RouterLink to={'https://discord.gg/xCZC3uMn89'} target="_blank" style={routerStyle}>
                <SupportIcon color={isActive('/support') ? '#5930A5' : '#808080'} />
              </RouterLink>
            </Box>
          </Tooltip>
        </ListItem>
        {isMobile && (
          <ListItem
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 0,
            }}
          >
            <Avatar
              alt={'user'}
              src={user?.avatar ?? 'https://placehold.it/50x50'}
              ref={menuAnchorRef}
              onClick={() => {
                setIsMenuShown(true);
              }}
              sx={{
                cursor: 'pointer',
              }}
            />
          </ListItem>
        )}
      </List>
      <Popper
        open={isMenuShown}
        anchorEl={menuAnchorRef.current}
        role={undefined}
        placement={isMobile ? 'bottom-end' : 'left-end'}
        transition
        sx={{ zIndex: 2 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'left-end' ? 'left top' : 'left top',
            }}
          >
            <Paper sx={{ border: '1px solid #E3E3E3', borderRadius: '10px' }} elevation={0}>
              <ClickAwayListener
                onClickAway={() => {
                  setIsMenuShown(false);
                }}
              >
                <MenuList autoFocusItem={isMenuShown} sx={{ width: '100%' }}>
                  <MenuItem
                    onClick={() => {
                      navigate('/profile');
                    }}
                  >
                    <Typography fontFamily="Figtree" fontSize={16} sx={{ paddingX: '20px' }}>
                      Profile
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>
                    <Typography fontFamily="Figtree" fontSize={16} sx={{ paddingX: '20px' }}>
                      Log out
                    </Typography>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default SidebarMenu;

const routerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '50px',
  height: '50px',
};
