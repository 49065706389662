import React from 'react';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { NameInfo } from './components/NameInfo';
import { About } from './components/About';
import { Gender } from './components/Gender';
import { ProfileImage } from './components/ProfileImage';
import { useNavigate } from 'react-router-dom';
import useUser from '../../hooks/useUser';
import { useFormik } from 'formik';
import { useUpdateUser } from '../../hooks/api/user';
import useSetUser from '../../hooks/useSetUser';
import type { IEditProfile } from './types';
import { Header } from '../../components/blocks';

export const Profile = () => {
  const navigate = useNavigate();
  const user = useUser();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateUser, _, isLoading] = useUpdateUser();
  const setUser = useSetUser();

  const onSubmit = async (values: IEditProfile) => {
    await updateUser(values).then(user => {
      setUser(user);
    });
  };

  const formik = useFormik<IEditProfile>({
    initialValues: {
      first_name: user?.first_name ?? '',
      last_name: user?.last_name ?? '',
      username: user?.username ?? '',
      gender: user?.gender ?? '',
      bio: user?.bio ?? '',
      avatar: null, // file expected
    },
    enableReinitialize: true,
    onSubmit,
  });

  return (
    <>
      <Header />
      <Grid container>
        <Grid item xs={0} sm={3}></Grid>
        <Grid
          item
          xs={12}
          sm={6}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="calc(100vh - 50px)"
        >
          <Box
            display="flex"
            mt="10px"
            justifyContent={{ xs: 'center', sm: 'flex-start' }}
            onClick={() => {
              navigate('/dashboard');
            }}
            sx={{ cursor: 'pointer' }}
          >
            <Typography
              fontWeight={500}
              fontSize={{ xs: '1.5rem', sm: '2rem' }}
              textAlign={{ xs: 'center', sm: 'start' }}
              color="#0000007A"
            >
              User Hub &gt;
            </Typography>
            <Typography
              fontWeight={500}
              fontSize={{ xs: '1.5rem', sm: '2rem' }}
              textAlign={{ xs: 'center', sm: 'start' }}
            >
              &nbsp;Account Info
            </Typography>
          </Box>

          <NameInfo formik={formik} />
          <ProfileImage formik={formik} />
          <Gender formik={formik} />
          <About formik={formik} />

          <Box display="flex" justifyContent="center" alignItems="center">
            <Button
              sx={{
                mb: '50px',
                width: { xs: '90%', sm: '100%' },
                bgcolor: '#D6D6D6',
                height: '50px',
                borderRadius: '15px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                color: 'common.black',
              }}
              onClick={formik.submitForm}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress /> : <Typography>Save</Typography>}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={0} sm={3}></Grid>
      </Grid>
    </>
  );
};
