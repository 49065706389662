import React from 'react';

export const BackArrowIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M6.52398 9.1664H16.6673V10.8331H6.52398L10.994 15.3031L9.81565 16.4814L3.33398 9.99973L9.81565 3.51807L10.994 4.6964L6.52398 9.1664Z"
      fill="#42485D"
      fillOpacity="0.6"
    />
  </svg>
);
