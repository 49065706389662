import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import { EraserIcon } from '../../assets/icons';
import Collapsible from './Collapsible';
import AverageScoreChart from './AverageScoreChart';
import { useAppDispatch, useAppSelector } from '../../hooks/useStore';
import {
  resetFilters,
  setFilterDiagnostics,
  setFilterReseller,
  setFilterScoreGte,
  setFilterScoreLte,
} from '../../core/store/filterSlice';
import { useGetStatsQuery } from '../../core/api/product';
import { useFilteredItems } from '../../hooks/useFiltredItems';
import { useGetDiagnosticsQuery } from '../../core/api/images';
import ProductFilterPlaceholder from '../Dashboard/Placeholders/ProductFilterPlaceholder';
import useOnboardingSearchParam from '../../hooks/useOnboardingSearchParam';
import { roundGrade } from '../../common/helpers';

interface Props {
  type: 'images' | 'products';
  hasDiagnostics?: boolean;
}

function Filter({ hasDiagnostics = false, type }: Props) {
  const { isOnboarding, brandId } = useOnboardingSearchParam();

  const dispatch = useAppDispatch();
  const { reseller: filterReseller, diagnostics: filterDiagnostics = [] } = useAppSelector(
    state => state.filter
  );

  const { data: stats, isLoading: isLoadingStats } = useGetStatsQuery(Number(brandId));
  const { data: diagnostics } = useGetDiagnosticsQuery(Number(brandId));
  const { allowResetFilter } = useFilteredItems(type === 'images' ? 'images' : 'products');

  const [resetScoreRange, setResetScoreRange] = useState(false);

  const handleSelectReseller = (reseller: string) => {
    if (filterReseller === reseller) {
      dispatch(setFilterReseller(undefined));
    } else {
      dispatch(setFilterReseller(reseller));
    }
  };

  const handleSelectDiagnostic = (diagnostic: { name: string }) => {
    if (filterDiagnostics.includes(diagnostic.name)) {
      dispatch(setFilterDiagnostics(filterDiagnostics.filter(d => d !== diagnostic.name)));
    } else {
      dispatch(setFilterDiagnostics([...filterDiagnostics, diagnostic.name]));
    }
  };

  const handleAverageScoreChange = (ranges: number[]) => {
    const scoreGte = ranges[0] === 0 ? undefined : roundGrade(ranges[0]);
    const scoreLte = ranges[1] === 10 ? undefined : roundGrade(ranges[1]);
    dispatch(setFilterScoreGte(scoreGte));
    dispatch(setFilterScoreLte(scoreLte));
  };

  const handleResetFilter = () => {
    setResetScoreRange(true);
    dispatch(resetFilters());
    setTimeout(() => {
      setResetScoreRange(false);
    }, 2000);
  };

  const sortedResellers = [...(stats?.resellers ?? [])].sort((a, b) => b.count - a.count);

  const sortedDiagnostics = [...(diagnostics ?? [])].sort(
    (a, b) => b.images_count - a.images_count
  );

  return (
    <Box sx={{ width: 400, my: '16px', borderRadius: '16px', bgcolor: '#FFFFFF' }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={1}
        p="16px 24px"
      >
        <Typography fontSize={20} fontWeight={400} color="#171D30">
          Filter
        </Typography>
        <IconButton
          onClick={handleResetFilter}
          disabled={!allowResetFilter || Boolean(isOnboarding)}
          sx={{ border: 'none', color: '#171D30', ':disabled': { opacity: 0.6 } }}
        >
          <EraserIcon size={14} />
        </IconButton>
      </Stack>
      {isLoadingStats ? (
        <ProductFilterPlaceholder />
      ) : (
        <>
          <Stack p="8px 12px">
            <Collapsible title={type === 'products' ? 'Average score' : 'Image quality score'}>
              <AverageScoreChart
                scores={Object.values(stats?.score_distribution ?? {})}
                onSlide={handleAverageScoreChange}
                resetRange={resetScoreRange}
                disabled={Boolean(isOnboarding)}
              />
            </Collapsible>
          </Stack>
          {sortedResellers.length > 0 && (
            <Stack p="8px 12px">
              <Collapsible
                title="Source"
                items={sortedResellers.map(res => ({
                  name: res.reseller,
                  icon: res.reseller_logo_s3_url,
                  count: res.count,
                }))}
                selected={filterReseller ? [filterReseller] : []}
                onSelect={item => {
                  handleSelectReseller(item.name);
                }}
              />
            </Stack>
          )}

          {hasDiagnostics && diagnostics && diagnostics.length > 0 && (
            <Stack p="8px 12px">
              <Collapsible
                title="Issues"
                items={sortedDiagnostics.map(diagnostic => ({
                  name: diagnostic.name,
                  icon: diagnostic.icon_url,
                  count: diagnostic.images_count,
                }))}
                selected={filterDiagnostics}
                onSelect={handleSelectDiagnostic}
              />
            </Stack>
          )}
        </>
      )}
    </Box>
  );
}

export default Filter;
