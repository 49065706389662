import React from 'react';

interface IIconProps {
  active: boolean;
}

export const AssetsGrid: React.FC<IIconProps> = ({ active = true }) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    {active && <rect width="40" height="40" rx="8" fill="#F6F3FC" />}
    <path
      d="M12.5 19.1667H19.1667V12.5H12.5V19.1667ZM12.5 27.5H19.1667V20.8333H12.5V27.5ZM20.8333 27.5H27.5V20.8333H20.8333V27.5ZM20.8333 12.5V19.1667H27.5V12.5"
      fill={active ? '#5930A5' : '#1B1B1E'}
    />
  </svg>
);
