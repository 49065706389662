import React from 'react';

export const LoadingIcon1: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="red" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.79238 6.04167C9.79238 6.39167 9.98405 6.70833 10.2965 6.86667L11.5507 7.50417C11.959 7.7125 12.2882 8.04167 12.4965 8.45L13.134 9.70417C13.2924 10.0167 13.609 10.2083 13.959 10.2083C14.309 10.2083 14.6257 10.0167 14.784 9.70417L15.4215 8.45C15.6299 8.04167 15.959 7.7125 16.3674 7.50417L17.6215 6.86667C17.934 6.70833 18.1257 6.39167 18.1257 6.04167C18.1257 5.69167 17.934 5.375 17.6215 5.21667L16.3674 4.57917C15.959 4.37083 15.6299 4.04167 15.4215 3.63333L14.784 2.37917C14.6257 2.06667 14.309 1.875 13.959 1.875C13.609 1.875 13.2924 2.06667 13.134 2.37917L12.4965 3.63333C12.2882 4.04167 11.959 4.37083 11.5507 4.57917L10.2965 5.21667C9.98405 5.375 9.79238 5.69167 9.79238 6.04167ZM12.1174 5.69167C12.7632 5.3625 13.2799 4.84583 13.609 4.2L13.959 3.5125L14.309 4.2C14.6382 4.84583 15.1549 5.3625 15.8007 5.69167L16.4882 6.04167L15.8007 6.39167C15.1549 6.72083 14.6382 7.2375 14.309 7.88333L13.959 8.57083L13.609 7.88333C13.2799 7.2375 12.7632 6.72083 12.1174 6.39167L11.4299 6.04167L12.1174 5.69167Z"
      fill="#3B1E8E"
    />
    <path
      d="M6.66738 7.91667C7.35774 7.91667 7.91738 7.35702 7.91738 6.66667C7.91738 5.97631 7.35774 5.41667 6.66738 5.41667C5.97703 5.41667 5.41738 5.97631 5.41738 6.66667C5.41738 7.35702 5.97703 7.91667 6.66738 7.91667Z"
      fill="#3B1E8E"
    />
    <path
      d="M17.5007 8.54167C17.1549 8.54167 16.8757 8.82083 16.8757 9.16667V13.3333C16.8757 13.3833 16.8715 13.4333 16.8674 13.4833L15.0382 11.9125C14.1715 11.1667 12.9132 11.1667 12.0465 11.9125L11.0799 12.7458L8.18405 10.2583C7.31738 9.5125 6.05905 9.5125 5.19238 10.2583L3.12988 12.0333V6.67083C3.12988 4.71667 4.71738 3.12917 6.67155 3.12917H10.8382C11.184 3.12917 11.4632 2.85 11.4632 2.50417C11.4632 2.15833 11.184 1.87917 10.8382 1.87917H6.67155C4.02988 1.87917 1.87988 4.02917 1.87988 6.67083V13.3375C1.87988 15.9792 4.02988 18.1292 6.67155 18.1292H13.3382C15.9799 18.1292 18.1299 15.9792 18.1299 13.3375V9.17083C18.1299 8.825 17.8507 8.54583 17.5049 8.54583L17.5007 8.54167ZM3.14238 13.6625L6.00488 11.2042C6.40072 10.8625 6.97572 10.8667 7.36738 11.2042L10.1174 13.5667L6.29238 16.8542C4.62155 16.6792 3.29655 15.3375 3.14238 13.6625ZM13.334 16.875H8.18822L12.8632 12.8583C13.259 12.5167 13.834 12.5167 14.2257 12.8583L16.534 14.8417C15.9674 16.0417 14.7465 16.875 13.334 16.875Z"
      fill="#3B1E8E"
    />
  </svg>
);
