import React, { createContext, type FC, type ReactNode, useState } from 'react';

interface FileContentType {
  fileContentVal: string;
  handleContentVal: (value: string) => void;
  chatID: string;
  handleChatID: (value: string) => void;
  setLoadedFile: (value: any) => void;
  loadedFile: {
    url: string;
    name: string;
  };
  isLoadingError: boolean;
  setIsLoadingError: (value: boolean) => void;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
}

// Create a new context for file content value
export const FileContentContext = createContext<FileContentType>(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as FileContentType
);

interface FileProviderProps {
  children?: ReactNode;
}

// Component that provides the context value
export const FileContentProvider: FC<FileProviderProps> = ({ children }) => {
  const [loadedFile, setLoadedFile] = useState({ url: '', name: '' });
  const [fileContentVal, setFileContentVal] = useState('');
  const [chatID, setChatID] = useState('');
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleContentVal = (value: string) => {
    setFileContentVal(value);
  };

  const handleChatID = (value: string) => {
    setChatID(value);
  };

  return (
    <FileContentContext.Provider
      value={{
        fileContentVal,
        handleContentVal,
        chatID,
        handleChatID,
        setLoadedFile,
        loadedFile,
        isLoadingError,
        setIsLoadingError,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </FileContentContext.Provider>
  );
};
