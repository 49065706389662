import React from 'react';
import Chip from '@mui/material/Chip';
import { type SxProps } from '@mui/material/styles';

interface ColorChipProps {
  color: string;
  title: string;
  onClick?: () => void;
  sx?: SxProps;
  disabled?: boolean;
}

function ColorChip({ color, title, onClick, sx, disabled }: ColorChipProps) {
  return (
    <Chip
      onClick={() => onClick?.()}
      label={title}
      disabled={disabled}
      sx={{
        border: '1px solid #E9E9F0',
        backgroundColor: '#FFF',
        borderRadius: '10px',
        padding: '6px 10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '6px',
        boxShadow: '0px 1px 2px 0px rgba(20, 21, 26, 0.05)',
        '.MuiChip-label': {
          fontSize: 14,
          fontWeight: 500,
          color: '#171D30',
          padding: '0 4px',
        },
        '.MuiChip-icon': {
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          backgroundColor: color,
          margin: '0',
          display: 'inline-block',
        },
        ...sx,
      }}
    />
  );
}

export default ColorChip;
