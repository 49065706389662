import React from 'react';

interface IProps {
  active: boolean;
}

export const AssetsRemove = ({ active }: IProps) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5332 2.96675L17.6582 7.08342C18.3082 7.74175 18.3082 8.79175 17.6582 9.45008L9.99984 17.1084C8.69984 18.4084 6.5915 18.4084 5.28317 17.1084L2.3415 14.1667C1.6915 13.5084 1.6915 12.4584 2.3415 11.8001L11.1748 2.96675C11.8332 2.31675 12.8832 2.31675 13.5332 2.96675ZM3.5165 12.9834L6.4665 15.9251C7.1165 16.5834 8.1665 16.5834 8.82484 15.9251L11.7665 12.9834L7.6415 8.85842L3.5165 12.9834Z"
      fill={active ? '#FFF' : '#3B4050'}
    />
  </svg>
);
