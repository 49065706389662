import React from 'react';
import { MenuItem, Select, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { type IOptions } from '../../../../types';

interface ICustomSelect {
  isLoading: boolean;
  selectedValue: string;
  setSelectedValue: (value: string) => void;
  options: IOptions[];
}

export const CustomSelect = ({
  isLoading,
  selectedValue,
  setSelectedValue,
  options,
}: ICustomSelect) => {
  const handleSelectChange = (event: any) => {
    setSelectedValue(event.target.value);
  };

  return (
    <Select
      value={selectedValue}
      onChange={handleSelectChange}
      inputProps={{
        sx: {
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        },
      }}
      sx={{
        height: '40px',
        bgcolor: '#F6F6F6',
        borderRadius: '10px',
        boxShadow: 'none',
        '.MuiOutlinedInput-notchedOutline': { border: 0 },
        '&:hover': {
          bgcolor: '#E4E4E4',
        },
      }}
      IconComponent={KeyboardArrowDownIcon}
      variant="outlined"
      disabled={isLoading}
      fullWidth
    >
      {options.map(option => {
        return (
          <MenuItem
            key={option.value}
            value={option.value}
            onClick={option.onClick}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              borderLeft: option.value === selectedValue ? '2px solid #3B1E8E' : '#61646B',
            }}
          >
            {option.icon}
            <Typography
              fontFamily="Figtree"
              color="#61646B"
              fontSize={14}
              sx={{ ml: '10px', color: '#6E6E6E' }}
            >
              {option.label}
            </Typography>
          </MenuItem>
        );
      })}
    </Select>
  );
};
