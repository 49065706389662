import React, { useEffect, useState } from 'react';
import { Box, Typography, Link, Stack } from '@mui/material';
import { SocialButtons } from './SocialButtons';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { type IContinueWithSocialMediaResponse } from '../../../common/types';
import { type AxiosResponse } from 'axios';
import { BrandGrid } from './BrandGrid';

interface IProps {
  preLogin: () => void;
  postLogin: (data: AxiosResponse<IContinueWithSocialMediaResponse> | null) => void;
}

export const LoginLayout: React.FC<IProps> = ({ postLogin, preLogin }) => {
  const [isOnboarding, setIsOnboarding] = useState(false);

  useEffect(() => {
    const onboarding = localStorage.getItem('isOnboarding');
    setIsOnboarding(!!onboarding);
  }, []);

  return (
    <Stack
      sx={{
        display: 'flex',
        padding: '16px',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100dvh',
        overflow: 'hidden',
      }}
    >
      <Stack gap="64px" alignItems="center" flexGrow={1} justifyContent="center">
        <Stack
          gap="64px"
          width={{ sm: 'auto', md: '50%', xl: '40%' }}
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            sx={{
              color: '#171D30',
              textAlign: 'center',
              fontFamily: 'Poppins',
              fontSize: '36px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '38px',
              letterSpacing: -0.5,
            }}
          >
            {isOnboarding
              ? `Sign In to Get Your Free Report – We're Fetching Your Data Now!`
              : 'Welcome to Focal Studio'}
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontWeight: 400,
              fontSize: '16px',
              display: 'flex',
              alignItems: 'center',
              color: '#1D2028',
              textAlign: 'center',
            }}
          >
            You are one step closer to monitoring and optimizing your omnichannel e-commerce content
            week-over-week.
          </Typography>

          <Stack width={'100%'} gap={'12px'}>
            {' '}
            <Typography
              sx={{
                color: '#171D30',
                textAlign: 'center',
                fontFeatureSettings: 'clig off, liga off',
                fontFamily: 'Poppins',
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '110%',
              }}
            >
              Please use the following ways to sign-up
            </Typography>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
              <SocialButtons
                preLogin={preLogin}
                postLogin={postLogin}
                sx={{
                  width: '100%',
                  '.login-button': {
                    transition: 'background 0.3s ease, color 0.3s ease',
                    ':hover': {
                      bgcolor: '#171D30',
                      '.button-label': {
                        color: '#fff',
                      },
                    },
                  },
                }}
              />
            </GoogleOAuthProvider>
          </Stack>
        </Stack>

        <BrandGrid />
      </Stack>

      <Box
        sx={{
          width: '100%',
          textAlign: 'center',
          paddingBottom: '32px',
          flexShrink: 0,
          gap: '8px',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontSize: '12px',
            color: 'rgba(66, 72, 93, 0.60)',
            textAlign: 'center',
          }}
        >
          Your privacy is our priority:{' '}
          <span
            style={{
              color: 'rgba(66, 72, 93, 0.60)',
              fontFamily: 'Poppins',
              fontSize: 12,
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal',
            }}
          >
            We never compromise on data security
          </span>
        </Typography>
        <Typography
          sx={{
            color: '#81889E',
            textAlign: 'center',
            fontFeatureSettings: 'liga off, clig off',
            fontFamily: 'Poppins',
            fontSize: 12,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '110%',
          }}
        >
          By creating an account, you agree to our&nbsp;
          <Link href="https://www.focalstudio.ai/terms" target="_blank">
            <Typography
              component="span"
              sx={{
                color: '#81889E',
                fontFeatureSettings: 'liga off, clig off',
                fontFamily: 'Poppins',
                fontSize: 12,
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '110%',
                textDecoration: 'underline',
              }}
            >
              Terms & Conditions
            </Typography>
          </Link>
          &nbsp;and&nbsp;
          <Link href="https://www.focalstudio.ai/privacy" target="_blank">
            <Typography
              component="span"
              sx={{
                color: '#81889E',
                fontFeatureSettings: 'liga off, clig off',
                fontFamily: 'Poppins',
                fontSize: 12,
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '110%',
                textDecoration: 'underline',
              }}
            >
              Privacy Policy
            </Typography>
          </Link>
        </Typography>
      </Box>
    </Stack>
  );
};
