import React from 'react';

export const ArrowDownLineIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      d="M6.49837 8.086L9.18038 5.404L9.88737 6.111L5.99837 10L2.10938 6.111L2.81638 5.404L5.49837 8.086V2H6.49837V8.086Z"
      fill="white"
    />
  </svg>
);
