import { Avatar } from '@mui/material';
import React from 'react';

export const BrandLogo: React.FC<{ name?: string; logo?: string | null; color?: string }> = ({
  name,
  logo,
  color,
}) => {
  return (
    <Avatar
      src={logo ?? ''}
      sx={{
        bgcolor: logo ? 'transparent' : color,
        width: 16,
        height: 16,
        fontSize: 12,
        fontWeight: 700,
      }}
    >
      {name?.slice(0, 1) ?? 'B'}
    </Avatar>
  );
};
