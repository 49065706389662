import React from 'react';

interface IProps {
  active: boolean;
}

export const AssetsKeep = ({ active }: IProps) => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.7582 3.85822L16.6415 2.74155C16.3332 2.41655 15.7915 2.41655 15.4665 2.74155L7.99984 10.2082L10.2915 12.4999L17.7582 5.03322C18.0832 4.70822 18.0832 4.16655 17.7582 3.85822ZM6.33317 11.6666C5.67013 11.6666 5.03424 11.9299 4.5654 12.3988C4.09656 12.8676 3.83317 13.5035 3.83317 14.1666C3.83317 15.2582 2.8665 15.8332 2.1665 15.8332C2.93317 16.8499 4.24984 17.4999 5.49984 17.4999C6.38389 17.4999 7.23174 17.1487 7.85686 16.5236C8.48198 15.8985 8.83317 15.0506 8.83317 14.1666C8.83317 13.5035 8.56978 12.8676 8.10094 12.3988C7.6321 11.9299 6.99621 11.6666 6.33317 11.6666Z"
      fill={active ? '#FFF' : '#3B4050'}
    />
  </svg>
);
