export enum Steps {
  addBrand,
  identifying,
  evaluation,
  review,
  finalizing,
}

export interface IImagesArray {
  link: string;
  title: string;
  url: string;
  src: string;
  score: number;
  reason: string[];
  user_score: number;
  reviewed?: boolean;
  description: string;
  descriptionId: string;
  popularity: number;
  source_link: string;
  diagnostics: string[];
  size: string;
  width: number;
  height: number;
  position: number;
  origin_url: string;
}

export interface ISegmentImages {
  url: string;
  score: number;
  user_score: number;
  popularity: number;
  source_link: string;
  diagnostics: string[];
  size: string;
}

export interface IDescriptionImagesResponse {
  url: string;
  description: {
    text: string;
    description_id: string;
  };
}

export interface ICreateBrandRequest {
  ai_good_images_description_ids: string[];
  ai_bad_images_description_ids: string[];
  final_good_images_description_ids: string[];
  final_bad_images_description_ids: string[];
  brand_name: string;
  products: string[];
  logo_url?: string;
}

export interface IAutocompleteCompanyMatch {
  name: string;
  domain: string;
  logo: string;
}
