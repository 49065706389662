import React, { useEffect, useRef, useState } from 'react';
import Shape from './Shape';
import useDebounce from '../../../../../hooks/useDebounce';

const CanvasRefine = ({
  image,
  imageWithoutBackground,
  canvasRef,
  modalRef,
  lineWidth,
  shape,
  setShape,
  setMaskImage,
  correctedImage,
  mode,
  brushRef,
  setNextShapes,
  scale,
  img,
  correctImage,
}) => {
  const [isDrawing, setIsDrawing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const shapeRef = useRef(null);

  useEffect(() => {
    const canvasImage = canvasRef.current;

    const rect = canvasImage.getBoundingClientRect();
    const scale = canvasImage.width / rect.width;

    const penStyle = {
      color: mode === 'removal' ? '#3EC934' : 'red',
      radius: Math.round(lineWidth / scale),
      canvas: document.createElement('canvas'),
      cursor_url: '',
    };

    const canvas = brushRef.current;

    const rad = penStyle.radius;
    const color = penStyle.color;

    const cursorCanvas = penStyle.canvas;
    const cursorCtx = cursorCanvas.getContext('2d', { willReadFrequently: true });

    cursorCanvas.width = cursorCanvas.height = rad;
    cursorCtx.fillStyle = color;

    cursorCtx.arc(rad / 2, rad / 2, rad / 2, 0, Math.PI * 2);
    cursorCtx.fill();

    cursorCanvas.toBlob(blob => {
      if (blob) {
        URL.revokeObjectURL(penStyle.cursor_url);
        penStyle.cursor_url = URL.createObjectURL(blob);
        canvas.style.cursor = `url(${penStyle.cursor_url}) ${rad / 2} ${rad / 2}, auto`;
      }
    });
  }, [lineWidth, mode, scale, canvasRef]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const brushCanvas = brushRef.current;

    const context = canvas.getContext('2d', { willReadFrequently: true });
    const brushContext = brushCanvas.getContext('2d', { willReadFrequently: true });

    canvas.width = img.width;
    canvas.height = img.height;

    setIsLoading(true);

    context.drawImage(img, 0, 0, img.width, img.height);

    brushCanvas.width = correctImage.width;
    brushCanvas.height = correctImage.height;

    brushContext.fillStyle = 'red';

    brushContext.fillRect(0, 0, correctImage.width, correctImage.height);

    brushContext.globalCompositeOperation = 'destination-out';
    brushContext.drawImage(correctImage, 0, 0, correctImage.width, correctImage.height);
    brushContext.globalCompositeOperation = 'source-over';

    if (shape.length) {
      drawShape(shape);
    }

    setIsLoading(false);
  }, [image, correctedImage, imageWithoutBackground, canvasRef, brushRef]);

  const debouncedShape = useDebounce(shape, 50);

  // Now we draw shapes in handleMouseMove method
  useEffect(() => {
    if (shape.length) {
      drawShape(shape);
    }

    setIsLoading(false);
  }, [image, correctedImage, imageWithoutBackground, canvasRef, brushRef]);

  // Now we draw shapes in handleMouseMove method
  // useEffect(() => {
  //   if (shape.length) {
  //     drawShape(shape);
  //   }
  // }, [shape]);

  useEffect(() => {
    if (shape.length) {
      drawMask();
    }
  }, [debouncedShape]);

  const handleMouseDown = event => {
    setIsDrawing(true);
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    const clientX = (event.clientX - rect.left) * scaleX;
    const clientY = (event.clientY - rect.top) * scaleY;

    if (mode === 'restore') {
      setShape(prev => [...prev, new Shape(clientX, clientY, [], lineWidth, 'restore')]);
    } else if (mode === 'removal') {
      setShape(prev => [...prev, new Shape(clientX, clientY, [], lineWidth, 'removal')]);
    }
  };

  const handleMouseMove = event => {
    // const ctx = canvas.getContext('2d', { willReadFrequently: true });

    // canvas.width = correctImage.width;
    // canvas.height = correctImage.height;

    // ctx.fillStyle = 'red';

    // ctx.fillRect(0, 0, correctImage.width, correctImage.height);

    // ctx.globalCompositeOperation = 'destination-out';
    // ctx.drawImage(correctImage, 0, 0, correctImage.width, correctImage.height);
    // ctx.globalCompositeOperation = 'source-over';

    // if (shape.length) {
    //   drawShape(shape);
    // }

    // ctx.beginPath();
    // ctx.arc(clientX, clientY, lineWidth / 2 + 1, 0, 2 * Math.PI);
    // ctx.fillStyle = 'black';
    // ctx.fill();
    // ctx.closePath();

    // ctx.beginPath();
    // ctx.arc(clientX, clientY, lineWidth / 2, 0, 2 * Math.PI);
    // ctx.fillStyle = mode === 'removal' ? '#00fc04' : '#fc0000';
    // ctx.fill();
    // ctx.closePath();

    if (!isDrawing) return;

    const canvas = brushRef.current;
    const rect = canvas.getBoundingClientRect();

    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    const clientX = (event.clientX - rect.left) * scaleX;
    const clientY = (event.clientY - rect.top) * scaleY;

    canvas.width = correctImage.width;
    canvas.height = correctImage.height;

    ctx.fillStyle = 'red';

    ctx.fillRect(0, 0, correctImage.width, correctImage.height);

    ctx.globalCompositeOperation = 'destination-out';
    ctx.drawImage(correctImage, 0, 0, correctImage.width, correctImage.height);
    ctx.globalCompositeOperation = 'source-over';

    if (shape.length) {
      drawShape(shape);
    }

    ctx.beginPath();
    ctx.arc(clientX, clientY, lineWidth / 2 + 1, 0, 2 * Math.PI);
    ctx.fillStyle = 'black';
    ctx.fill();
    ctx.closePath();

    ctx.beginPath();
    ctx.arc(clientX, clientY, lineWidth / 2, 0, 2 * Math.PI);
    ctx.fillStyle = mode === 'removal' ? '#00fc04' : '#fc0000';
    ctx.fill();
    ctx.closePath();

    if (!isDrawing) return;

    const shapeCopy = [...shape];
    const lastShape = shapeCopy[shapeCopy.length - 1];
    shapeCopy.pop();

    if (mode === 'restore') {
      setShape([...shapeCopy, new Shape(clientX, clientY, lastShape, lineWidth, 'restore')]);
    } else if (mode === 'removal') {
      setShape([...shapeCopy, new Shape(clientX, clientY, lastShape, lineWidth, 'removal')]);
    }

    setNextShapes([]);
  };

  const handleMouseUp = event => {
    setIsDrawing(false);
    // const canvas = canvasRef.current;
    // const rect = canvas.getBoundingClientRect();
    // const scaleX = canvas.width / rect.width;
    // const scaleY = canvas.height / rect.height;
    // const clientX = (event.clientX - rect.left) * scaleX;
    // const clientY = (event.clientY - rect.top) * scaleY;

    // if (mode === 'restore') {
    //   setShape(prev => [...prev, new Shape(clientX, clientY, [], lineWidth, 'restore')]);
    // } else if (mode === 'removal') {
    //   setShape(prev => [...prev, new Shape(clientX, clientY, [], lineWidth, 'removal')]);
    // }
  };

  const drawShape = shape => {
    const brushCanvas = brushRef.current;

    const brushContext = brushCanvas.getContext('2d', { willReadFrequently: true });

    for (let i = 0; i < shape.length; i++) {
      const offset = shape[i];

      if (offset.mode === 'restore') {
        brushContext.strokeStyle = 'rgb(255, 0, 0)';
        brushContext.lineWidth = offset.lineWidth;
        brushContext.lineJoin = brushContext.lineCap = 'round';
        brushContext.beginPath();
        brushContext.moveTo(offset.startX, offset.startY);

        let currentShape = offset;

        while (currentShape.next) {
          currentShape = currentShape.next;
          brushContext.lineTo(currentShape.startX, currentShape.startY);
        }

        brushContext.stroke();
      } else {
        brushContext.strokeStyle = 'rgb(255, 0, 0)';
        brushContext.lineWidth = offset.lineWidth;
        brushContext.lineJoin = brushContext.lineCap = 'round';
        brushContext.globalCompositeOperation = 'destination-out';
        brushContext.beginPath();
        brushContext.moveTo(offset.startX, offset.startY);

        let currentShape = offset;

        while (currentShape.next) {
          currentShape = currentShape.next;
          brushContext.lineTo(currentShape.startX, currentShape.startY);
        }

        brushContext.stroke();

        brushContext.globalCompositeOperation = 'source-over';
      }
    }
  };

  const drawMask = () => {
    const brushCanvas = brushRef.current;
    const shapeCanvas = shapeRef.current;

    const shapeContext = shapeCanvas.getContext('2d', { willReadFrequently: true });

    shapeCanvas.width = img.width;
    shapeCanvas.height = img.height;

    shapeContext.drawImage(img, 0, 0, img.width, img.height);

    shapeContext.globalCompositeOperation = 'destination-out';
    shapeContext.drawImage(brushCanvas, 0, 0, img.width, img.height);
    shapeContext.globalCompositeOperation = 'source-over';

    shapeCanvas.toBlob(img => setMaskImage(img));
  };

  return (
    <>
      <canvas ref={canvasRef} style={{ ...style, filter: isLoading ? 'blur(4px)' : 'blur(0px)' }} />
      <canvas
        ref={brushRef}
        style={{ ...style1, filter: isLoading ? 'blur(4px)' : 'blur(0px)' }}
        onPointerDown={handleMouseDown}
        onPointerMove={handleMouseMove}
        onPointerUp={handleMouseUp}
        onPointerLeave={handleMouseUp}
      />
      <canvas ref={shapeRef} style={style2} />
    </>
  );
};

export default CanvasRefine;

const style = {
  borderRadius: '16px',
  bgcolor: '#EFEFF0',
  border: '1px solid #EFEFF0',
  cursor: 'none',
  position: 'absolute',
  top: 0,
  left: 'auto',
  zIndex: 1,
  height: '100%',
  width: '100%',
};

const style1 = {
  borderRadius: '16px',
  bgcolor: '#EFEFF0',
  border: '1px solid #EFEFF0',
  cursor: 'none',
  position: 'absolute',
  top: 0,
  left: 'auto',
  zIndex: 2,
  opacity: 0.5,
  height: '100%',
  width: '100%',
};

const style2 = {
  display: 'none',
  cursor: 'none',
};
