import React, { useEffect, useRef, useState, type MouseEvent } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import SearchHeader from '../../Common/SearchHeader';
import ImageCard from '../../Images/ImageCard';
import Filter from '../../Common/Filter';
import ProductCardPlaceholder from '../../Common/Placeholders/ProductCardPlaceholder';
import { useFilteredItems } from '../../../hooks/useFiltredItems';
import InfiniteScrollContainer from '../../Common/InfiniteScrollContainer';
import { useAppSelector } from '../../../hooks/useStore';
import Box from '@mui/material/Box';
import { FilterHdr } from '../../../assets/icons/FilterHdr';
import useOnboardingSearchParam from '../../../hooks/useOnboardingSearchParam';
import FreeTrialCard from '../../Common/FreeTrialCard';
import { useSubscriptionStatus } from '../../../hooks/useSubscriptionStatus';
import UpgradePlanModal from '../Landing/UpgradePlanModal';
import { usePostLimitClickMutation } from '../../../core/api/user';

const CardsPlaceholder = () => (
  <Grid container columnSpacing={2} rowSpacing={1} justifyContent={'center'}>
    {Array.from({ length: 12 }).map((_, i) => (
      <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
        <ProductCardPlaceholder />
      </Grid>
    ))}
  </Grid>
);

const OnboardingPlaceholder = ({ onBoardingImages }: { onBoardingImages: string[] }) => (
  <Grid container columnSpacing={2} rowSpacing={1} justifyContent={'center'} pb={'16px'}>
    {Array.from({ length: 8 }).map((_, i) => {
      const imageIndex = i % onBoardingImages.length;
      const onBoardingImage = onBoardingImages[imageIndex];
      return (
        <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '-99px',
              alignSelf: 'stretch',
              borderRadius: '8px',
              background: '#FFF',
              minHeight: '213px',
            }}
          >
            {onBoardingImages.length > 0 ? (
              <img
                src={onBoardingImage}
                style={{
                  width: '100%',
                  aspectRatio: '6 / 5',
                  filter: 'blur(12px)',
                }}
              />
            ) : (
              <FilterHdr />
            )}
          </Box>
        </Grid>
      );
    })}
  </Grid>
);

function Images() {
  const { isOnboarding } = useOnboardingSearchParam();
  const { freeTrial, user } = useSubscriptionStatus();
  const onBoardingImage = useAppSelector(state => state.onboarding.images);

  const containerRef = useRef<HTMLDivElement>(null);
  const [isAtTop, setIsAtTop] = useState(false);

  const [clickCount, setClickCount] = useState(
    () => Number(sessionStorage.getItem('clickCount')) || 0
  );
  const [upgradeModal, setUpgradeModal] = useState(false);

  const { view: selectedView, gridColumns } = useAppSelector(state => state.filter);

  const {
    items: images,
    isLoading,
    isFetching,
    fetchingType,
    handleNextPage,
    hasMore,
    count,
  } = useFilteredItems('images');

  const [postLimitClick] = usePostLimitClickMutation();

  const isFilterFetching = isFetching && fetchingType === 'filter';

  useEffect(() => {
    if (isOnboarding) {
      return;
    }
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsAtTop(!entry.isIntersecting);
      },
      { threshold: [1], rootMargin: '0px' }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [isOnboarding]);

  useEffect(() => {
    sessionStorage.setItem('clickCount', clickCount.toString());
  }, [clickCount]);

  const handleImageClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (freeTrial && user) {
      setClickCount(prev => {
        if (prev >= 3 || user?.click_tracker?.image_limit) {
          event.preventDefault();
          event.stopPropagation();
          setUpgradeModal(true);
          return prev;
        } else {
          const newCount = prev + 1;
          if (newCount === 3) {
            postLimitClick(undefined);
          }
          sessionStorage.setItem('clickCount', newCount.toString());
          return newCount;
        }
      });
    }
  };

  const imagesToShow = freeTrial ? 12 : images.length;

  return (
    <Box
      ref={containerRef}
      sx={{
        height: isAtTop ? '100vh' : 'auto',
        overflowY: isAtTop ? 'auto' : 'visible',
        scrollBehavior: 'smooth',
        '::-webkit-scrollbar': { display: 'none' },
      }}
    >
      <Stack gap={'4px'} mt={'30px'}>
        <Typography fontSize={36} fontWeight={400} fontFamily={'Poppins'} letterSpacing={'-0.96px'}>
          Images
        </Typography>
        <Typography
          fontSize={18}
          fontWeight={400}
          fontFamily={'Poppins'}
          letterSpacing={'-0.216px'}
          color={'#42485D99'}
        >
          Overview results of image audit over all vendors sites
        </Typography>
      </Stack>
      <Stack direction={'row'} gap={'24px'} py={'8px'} alignItems={'flex-start'}>
        <Stack flex={1} gap={'8px'}>
          <SearchHeader maxGridColumn={4} />
          <Stack p={'16px 0px'}>
            {isOnboarding ? (
              <Typography color={'text.secondary'}>
                {onBoardingImage.length} images found
              </Typography>
            ) : (
              !!count &&
              !isFilterFetching && (
                <Typography color={'text.secondary'}>{count} images found</Typography>
              )
            )}
          </Stack>
          {isLoading ? (
            <CardsPlaceholder />
          ) : isOnboarding ? (
            <OnboardingPlaceholder onBoardingImages={onBoardingImage} />
          ) : images.length ? (
            <InfiniteScrollContainer
              isLoading={isLoading || isFilterFetching}
              isFetching={isFetching && !isFilterFetching}
              hasMore={hasMore && !isOnboarding && !freeTrial}
              onLoadMore={handleNextPage}
              placeholder={<CardsPlaceholder />}
              scrollRef={containerRef}
            >
              {selectedView === 'grid' && gridColumns ? (
                <Grid container columnSpacing={1} rowSpacing={1}>
                  {images.slice(0, imagesToShow).map(image => {
                    if (image.type === 'image') {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={12 / Math.min(gridColumns, 4)}
                          lg={12 / Math.min(gridColumns, 4)}
                          key={image.id}
                          sx={{
                            padding: '8px',
                          }}
                        >
                          <ImageCard
                            variant="grid"
                            key={image.id}
                            product={{
                              id: image.id,
                              name: image.title,
                              grade: Math.round(image.score),
                              image: image.image_url,
                              url: image.original_reseller_product_url,
                              brand: {
                                name: image.reseller,
                                image: image.reseller_logo_s3_url,
                              },
                            }}
                            onImageClick={handleImageClick}
                          />
                        </Grid>
                      );
                    }
                    return null;
                  })}
                </Grid>
              ) : (
                <Box>
                  <Grid container sx={{ p: '8px 16px' }}>
                    <Grid item xs={1}>
                      <Typography
                        color={'text.secondary'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={'20px'}
                        letterSpacing={'-0.07px'}
                      >
                        Score
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        color={'text.secondary'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={'20px'}
                        letterSpacing={'-0.07px'}
                      >
                        Image
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        color={'text.secondary'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={'20px'}
                        letterSpacing={'-0.07px'}
                      >
                        Name
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        color={'text.secondary'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={'20px'}
                        letterSpacing={'-0.07px'}
                      >
                        Vendor
                      </Typography>
                    </Grid>
                  </Grid>
                  <Stack gap={1} pt={'16px'}>
                    {images.map(image => {
                      if (image.type === 'image') {
                        return (
                          <ImageCard
                            variant="list"
                            key={image.id}
                            product={{
                              id: image.id,
                              name: image.title,
                              grade: Math.round(image.score),
                              image: image.image_url,
                              url: image.original_reseller_product_url,
                              brand: {
                                name: image.reseller,
                                image: image.reseller_logo_s3_url,
                              },
                            }}
                            onImageClick={handleImageClick}
                          />
                        );
                      }
                      return null;
                    })}
                  </Stack>
                </Box>
              )}
            </InfiniteScrollContainer>
          ) : (
            <Typography
              fontSize={14}
              fontWeight={400}
              fontFamily={'Poppins'}
              color={'#42485D99'}
              m={'32px auto'}
            >
              No image found
            </Typography>
          )}

          {freeTrial && <FreeTrialCard title="Explore unlimited images with Enterprise Plan" />}
        </Stack>
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            maxHeight: '100svh',
            overflowY: 'auto',
            '::-webkit-scrollbar': { display: 'none' },
          }}
        >
          <Filter type="images" hasDiagnostics />
        </Box>
      </Stack>

      <UpgradePlanModal
        open={upgradeModal}
        handleClose={() => {
          setUpgradeModal(false);
        }}
      />
    </Box>
  );
}

export default Images;
