import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  Slider,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {
  AssetsKeep,
  AssetsRemove,
  CloseSquareIcon,
  PackageIcon,
} from '../../../../../assets/icons';
import { AssetContentContext, ImagsGeneratedContext } from '../../../../../contexts';
import { LoadingButton } from '@mui/lab';

interface INewAsset {
  assetNameError: boolean;
  setAssetName: (value: string) => void;
  lineWidth: number;
  setLineWidth: (value: number) => void;
  setIsAddNewAsset: (value: boolean) => void;
  setRefineMode: (value: 'none' | 'restore' | 'removal') => void;
  refineMode: 'none' | 'restore' | 'removal';
  handleClickNext: () => void;
  nextButtonText: string;
}

const NewAsset = ({
  assetNameError,
  setAssetName,
  lineWidth,
  setLineWidth,
  setIsAddNewAsset,
  setRefineMode,
  refineMode,
  handleClickNext,
  nextButtonText,
}: INewAsset) => {
  const [buttonHovered, setButtonHovered] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  useEffect(() => {
    if (assetNameError) {
      setIsLoadingSave(false);
    }

    return () => {
      setIsLoadingSave(false);
    };
  }, [assetNameError]);

  const { setIsFileLoading } = useContext(AssetContentContext);
  const { isLoading } = useContext(ImagsGeneratedContext);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: 'none' | 'restore' | 'removal'
  ) => {
    if (newAlignment) {
      setRefineMode(newAlignment);
    } else {
      setRefineMode('none');
    }
  };

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setLineWidth(newValue as number);
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
      <Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          paddingLeft="20px"
          paddingRight="10px"
          paddingY="20px"
        >
          <Typography
            display="flex"
            alignItems="center"
            fontSize={26}
            color="#313234"
            fontFamily={'Poppins'}
            fontWeight={500}
          >
            New Asset
          </Typography>
          <Box
            sx={{
              '&: hover': {
                cursor: 'pointer',
              },
            }}
            onClick={() => {
              setIsAddNewAsset(false);
              setIsFileLoading(false);
            }}
          >
            <CloseSquareIcon />
          </Box>
        </Box>
        <Box paddingX="20px">
          <Typography fontSize={20} color="#313234" fontFamily={'Poppins'} fontWeight={400}>
            Refine image
          </Typography>
          <Typography
            color="#1b1b1b73"
            fontFamily={'Poppins'}
            fontWeight={400}
            fontSize={14}
            mt="10px"
          >
            If you want to update the detouring, you can add back part of the image to the asset or
            remove it using the brush below:
          </Typography>
        </Box>
        <ToggleButtonGroup
          value={refineMode}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
          sx={{ display: 'flex', justifyContent: 'center', mt: '30px', opacity: 1 }}
        >
          <ToggleButton
            value="removal"
            sx={{
              borderRadius: '50px',
              width: '45%',
              bgcolor: refineMode === 'removal' ? '#5930A5 !important' : '#FFF',
            }}
            onClick={() => {
              setRefineMode('removal');
            }}
          >
            <AssetsKeep active={refineMode === 'removal'} />
            <Typography color={refineMode === 'removal' ? '#FFF' : '#313234'} ml="5px">
              Keep
            </Typography>
          </ToggleButton>
          <ToggleButton
            value="restore"
            sx={{
              borderRadius: '50px',
              width: '45%',
              bgcolor: refineMode === 'restore' ? '#5930A5 !important' : '#FFF',
            }}
            onClick={() => {
              setRefineMode('restore');
            }}
          >
            <AssetsRemove active={refineMode === 'restore'} />
            <Typography color={refineMode === 'restore' ? '#FFF' : '#313234'} ml="5px">
              Remove
            </Typography>
          </ToggleButton>
        </ToggleButtonGroup>
        <Box display="flex" alignItems="center" mt="20px" flexDirection="column">
          <Box display="flex" justifyContent="space-between" width="90%">
            <Typography color="#313234" fontFamily={'Poppins'} fontWeight={500} fontSize={16}>
              Brush size:
            </Typography>
            <Typography
              color="#313234"
              ml="5px"
              fontFamily={'Poppins'}
              fontWeight={400}
              fontSize={16}
            >
              {Math.round(lineWidth)}px
            </Typography>
          </Box>
          <Slider
            defaultValue={lineWidth}
            value={lineWidth}
            aria-label="Small"
            min={1}
            max={100}
            sx={{ width: '90%', color: '#5930A5' }}
            onChange={handleSliderChange}
          />
        </Box>
        <Box display="flex" justifyContent="center" mt="20px">
          <FormControl sx={{ width: '90%' }}>
            <Typography
              color={assetNameError ? 'red' : '#313234'}
              fontFamily={'Poppins'}
              fontWeight={400}
              fontSize={20}
            >
              {assetNameError ? 'This field required' : 'Name Asset'}
            </Typography>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              placeholder={'e.g. “bottle”, “can”, “sneakers”, etc.'}
              sx={{
                mt: '10px',
                width: '100%',
                border: assetNameError ? '2px solid red' : '1px solid #AFB1B6',
                borderRadius: '8px',
                '& fieldset': {
                  border: 0,
                  borderRadius: '8px',
                },
                '& input': {
                  py: '14px',
                  fontSize: '14px',
                },
                '& svg': {
                  color: 'common.black',
                },

                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    border: assetNameError ? '1px solid transparent' : '1px solid #3B1E8E',
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PackageIcon />
                  </InputAdornment>
                ),
              }}
              onChange={e => {
                setAssetName(e.target.value);
              }}
            />
          </FormControl>
        </Box>
      </Box>
      <Box display="flex" mb="20px" justifyContent="space-around">
        <Button
          variant="outlined"
          sx={{ width: '45%', borderRadius: '5px' }}
          onClick={() => {
            setIsAddNewAsset(false);
          }}
        >
          <Box color={'#313234'} fontFamily={'Poppins'} fontWeight={500} fontSize={14}>
            Cancel
          </Box>
        </Button>
        <LoadingButton
          variant="contained"
          sx={{ width: '45%', bgcolor: '#5930A5', borderRadius: '5px' }}
          onClick={() => {
            handleClickNext();
            if (nextButtonText === 'Save') {
              setIsLoadingSave(true);
            }
          }}
          loading={(isLoading && !assetNameError) || isLoadingSave}
          onMouseEnter={() => {
            setButtonHovered(true);
          }}
          onMouseLeave={() => {
            setButtonHovered(false);
          }}
        >
          <Typography
            color={buttonHovered ? '#28205B' : '#FFF'}
            fontFamily="Poppins"
            fontWeight={500}
            fontSize={14}
          >
            {nextButtonText}
          </Typography>
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default NewAsset;
