import React from 'react';

export const PlusIcon: React.FC = () => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="100" height="100" rx="4" fill="#F7F5FF" />
    <path
      d="M50.0005 32.5V67.5M32.5005 50H67.5005"
      stroke="#3B1E8E"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
