import React from 'react';

export const EraserIcon = ({ size = 20, color = '#61646B' }: { size?: number; color?: string }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6118 11.0596L9.23131 5.6791M16.9178 17.2088H6.92537M9.18309 16.4884L15.8462 9.82526C16.7594 8.91207 17.216 8.45547 17.3871 7.92896C17.5375 7.46583 17.5375 6.96695 17.3871 6.50381C17.216 5.9773 16.7594 5.52071 15.8462 4.60752L15.6834 4.44474C14.7702 3.53155 14.3136 3.07495 13.7871 2.90388C13.324 2.7534 12.8251 2.7534 12.362 2.90388C11.8355 3.07495 11.3789 3.53155 10.4657 4.44474L4.15372 10.7567C3.24053 11.6699 2.78393 12.1265 2.61286 12.653C2.46238 13.1161 2.46238 13.615 2.61286 14.0781C2.78393 14.6046 3.24053 15.0612 4.15372 15.9744L4.66766 16.4884C4.93354 16.7542 5.06648 16.8872 5.22161 16.9823C5.35916 17.0665 5.50911 17.1287 5.66597 17.1663C5.8429 17.2088 6.0309 17.2088 6.40691 17.2088H7.44384C7.81984 17.2088 8.00785 17.2088 8.18477 17.1663C8.34163 17.1287 8.49159 17.0665 8.62913 16.9823C8.78427 16.8872 8.91721 16.7542 9.18309 16.4884Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
