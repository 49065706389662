import { useCallback, useMemo, useState } from 'react';

const useDeferredAction = (action: (...args: any[]) => Promise<any>, deps: any[] = []) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fn = useCallback(async (...args: any[]) => {
    setIsLoading(true);
    return await action(...args)
      .then(({ data }) => {
        setIsLoading(false);
        return data;
      })
      .catch(error => {
        setIsLoading(false);
        setError(error);
      });
  }, deps);

  return useMemo(() => {
    return [fn, error, isLoading];
  }, [fn, error, isLoading]) as [(...args: any[]) => Promise<any>, unknown, boolean];
};

export default useDeferredAction;
