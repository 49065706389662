import React from 'react';
import { Box, Typography } from '@mui/material';
import { BrandArrow, BrandContainer } from '../../../assets/icons';
import sidebarbg from '../../../assets/images/sidebarbg.webp';
import { theme } from '../../../theme';

export const Sidebar = () => {
  return (
    <Box
      display="flex"
      height="100vh"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
    >
      <Box
        position="absolute"
        overflow="hidden"
        height="100vh"
        width="100%"
        zIndex={1}
        display="flex"
        flexDirection="row"
      >
        <Box
          position="relative"
          height="100vh"
          zIndex={1}
          component="img"
          src={sidebarbg}
          sx={{
            animation: 'cycleAnimation 20s linear infinite',
            '@keyframes cycleAnimation': {
              '0%': {
                transform: 'translateX(0)',
              },
              '100%': {
                transform: 'translateX(-100%)',
              },
            },
          }}
          mr="0px"
        />
        <Box
          position="relative"
          height="100vh"
          zIndex={1}
          component="img"
          src={sidebarbg}
          sx={{
            animation: 'cycleAnimation1 20s linear infinite',
            '@keyframes cycleAnimation1': {
              '0%': {
                transform: 'translateX(0%)',
              },
              '100%': {
                transform: 'translateX(-100%)',
              },
            },
          }}
          pr="2px"
        />
      </Box>
      <Box display="flex" mt="50px" width="100%" justifyContent="center" zIndex={2}>
        <BrandContainer />
      </Box>
      <Box
        display="flex"
        mb="20px"
        zIndex={2}
        width="90%"
        height="170px"
        borderRadius="10px"
        bgcolor="#eeeaf7"
      >
        <Box display="flex" flexDirection="column" justifyContent="space-between" p="20px">
          <Box display="flex" flexDirection="column">
            <Typography
              fontFamily="Poppins"
              fontSize="12px"
              fontWeight={500}
              color={theme.palette.text.secondary}
              textTransform="uppercase"
              letterSpacing={1.3}
            >
              Did you know?
            </Typography>
            <Typography
              fontFamily="Poppins"
              fontSize="14px"
              fontWeight={400}
              color={theme.palette.text.primary}
              mt="10px"
            >
              75% of online shoppers rely on product photos to make purchasing decisions
            </Typography>
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ textDecoration: 'none' }}
            component={'a'}
            href="https://focalstudio.ai/"
            target="_blank"
          >
            <Typography
              fontFamily="Poppins"
              fontSize="14px"
              fontWeight={600}
              color={theme.palette.primary.main}
              width="70%"
            >
              How Focal helps brand sell more
            </Typography>
            <BrandArrow />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
