import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { ImageIcon } from '../../../assets/icons';
import { BrandLoader } from '../../../components/blocks';
import { theme } from '../../../theme';
import { Filter } from '../types';

interface ISidebar {
  filterItem: number;
  setFilterItem: (value: number) => void;
  assetsLength: number;
}

export const PageSidebar = ({ filterItem, setFilterItem, assetsLength }: ISidebar) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      p="20px"
      borderRight="1px solid #EFEFF0"
      minWidth="220px"
    >
      <Box display="flex">
        <BrandLoader />
        <Box ml="10px">
          <Typography fontFamily="Poppins" fontSize={18} fontWeight={500} color="#151729">
            Brand Monitoring
          </Typography>
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            color={theme.palette.text.secondary}
            fontWeight={400}
          >
            {assetsLength} Images found
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ mt: '10px' }} />

      <Box display="flex" mt="10px">
        <ImageIcon />
        <Typography
          fontFamily="Poppins"
          fontSize={16}
          fontWeight={500}
          color={theme.palette.text.primary}
          ml="5px"
        >
          Images
        </Typography>
      </Box>

      <Box display="flex" mt="10px" flexDirection="column">
        <Box
          p="10px"
          bgcolor={filterItem === Filter.risk ? '#F5F4F8' : '#FFF'}
          borderRadius="10px"
          onClick={() => {
            setFilterItem(Filter.risk);
          }}
          sx={{ cursor: 'pointer' }}
        >
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            fontWeight={500}
            color={theme.palette.text.primary}
            ml="5px"
          >
            High risk
          </Typography>
        </Box>
        <Box
          p="10px"
          mt="5px"
          bgcolor={filterItem === Filter.popular ? '#F5F4F8' : '#FFF'}
          borderRadius="10px"
          onClick={() => {
            setFilterItem(Filter.popular);
          }}
          sx={{ cursor: 'pointer' }}
        >
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            fontWeight={500}
            color={theme.palette.text.primary}
            ml="5px"
          >
            Most popular
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
