import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { ImageStats } from '../api/types/Product';
import { ensureLightColor, getRandomColor, rgbToHex } from '../../common/helpers';

export interface IDashboardState {
  stats?: ImageStats;
  brandsCount: number;
  brandsLoading: boolean;
  statsLoading: boolean;
  logoDominantColor: string;
  vendorsWithColor: Record<string, { color: string }>;
}

export const initialState: IDashboardState = {
  stats: undefined,
  brandsCount: 0,
  brandsLoading: true,
  statsLoading: true,
  logoDominantColor: '#6439AF',
  vendorsWithColor: {},
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboardStats: (state, action: PayloadAction<ImageStats | undefined>) => {
      state.stats = action.payload;
    },
    setBrandsCount: (state, action: PayloadAction<number>) => {
      state.brandsCount = action.payload;
    },

    setBrandsLoading: (state, action: PayloadAction<boolean>) => {
      state.brandsLoading = action.payload;
    },

    setStatsLoading: (state, action: PayloadAction<boolean>) => {
      state.statsLoading = action.payload;
    },

    setLogoDominantColor: (state, action: PayloadAction<[number, number, number] | string>) => {
      if (typeof action.payload === 'string') {
        state.logoDominantColor = action.payload;
      } else {
        const dominantColor = ensureLightColor(action.payload);
        state.logoDominantColor = rgbToHex(dominantColor[0], dominantColor[1], dominantColor[2]);
      }
    },

    setVendorsWithColor: (
      state: IDashboardState,
      action: PayloadAction<Record<string, { color: string }>>
    ) => {
      const newVendorsWithColor: Record<string, { color: string }> = {};

      Object.keys(action.payload).forEach(vendorName => {
        newVendorsWithColor[vendorName] = {
          color: getRandomColor(),
        };
      });

      state.vendorsWithColor = newVendorsWithColor;
    },

    resetDashboardStates: () => {
      return initialState;
    },
  },
});

export const {
  setDashboardStats,
  setBrandsCount,
  resetDashboardStates,
  setBrandsLoading,
  setStatsLoading,
  setLogoDominantColor,
  setVendorsWithColor,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
