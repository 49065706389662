import React from 'react';
import { Box } from '@mui/material';
import { CustomSelect } from '../CustomSelect/CustomSelect';
import { type IOptions } from '../../../../types';

interface IRatio {
  isLoading: boolean;
  selectedValue: string;
  setSelectedValue: (value: string) => void;
  options: IOptions[];
}

export const Ratio = ({ isLoading, selectedValue, setSelectedValue, options }: IRatio) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        paddingX: { xs: '10px', md: '0px' },
        boxSizing: 'border-box',
        borderBottom: { xs: '1px solid #EFEFF0', md: '0px' },
        borderRight: { xs: '1px solid #EFEFF0', md: '0px' },
        height: { xs: '50px', md: '65px' },
      }}
    >
      <CustomSelect
        isLoading={isLoading}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        options={options}
      />
    </Box>
  );
};
