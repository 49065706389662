class Shape {
  constructor(startX, startY, next = [], lineWidth = 10, mode) {
    this.startX = startX;
    this.startY = startY;
    this.next = next;
    this.lineWidth = lineWidth;
    this.mode = mode;
  }
}

export default Shape;
