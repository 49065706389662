import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import Stack from '@mui/material/Stack';
import React, { type ReactNode, useCallback, useRef, useState, useEffect } from 'react';

interface Props {
  isLoading: boolean;
  isFetching: boolean;
  onLoadMore: () => void;
  children: ReactNode;
  hasMore?: boolean;
  placeholder?: ReactNode;
  scrollRef?: React.RefObject<HTMLDivElement>;
}

const InfiniteScrollContainer = ({
  isLoading,
  isFetching,
  onLoadMore,
  children,
  hasMore,
  placeholder,
  scrollRef,
}: Props) => {
  const observer = useRef<IntersectionObserver | null>(null);
  const topRef = useRef<HTMLDivElement>(null);
  const [showScrollTop, setShowScrollTop] = useState(false);

  const lastTemplateElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          onLoadMore();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore]
  );

  useEffect(() => {
    if (scrollRef) {
      const handleScroll = () => {
        if (topRef.current) {
          const rect = topRef.current.getBoundingClientRect();
          setShowScrollTop(rect.top <= 0);
        }
      };

      scrollRef.current?.addEventListener('scroll', handleScroll);
      return () => {
        scrollRef.current?.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const scrollToTop = () => {
    if (scrollRef?.current) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Stack id="data_listing">
      <Box ref={topRef} />
      {isLoading ? placeholder : children}
      {isFetching && (
        <Box sx={{ my: '20px', textAlign: 'center' }}>
          <CircularProgress size={40} sx={{ color: '#42485D99', m: 'auto' }} />
        </Box>
      )}
      <div ref={lastTemplateElementRef}></div>
      {showScrollTop && (
        <Fab
          color="primary"
          size="small"
          onClick={scrollToTop}
          sx={{
            position: 'fixed',
            bottom: 60,
            left: 'calc(100% - 470px)',
          }}
        >
          <ArrowUpwardIcon />
        </Fab>
      )}
    </Stack>
  );
};

export default InfiniteScrollContainer;
