import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { OverviewStart } from '../../../assets/icons/OverviewStart';
import useOnboardingSearchParam from '../../../hooks/useOnboardingSearchParam';
import CustomCircularProgress from '../../Common/CustomCircularProgress';
import DefaultResellerLogo from '../../Common/DefaultResellerLogo';
import { useSubscriptionStatus } from '../../../hooks/useSubscriptionStatus';
import UpgradePlanModal from '../Landing/UpgradePlanModal';
import Avatar from '@mui/material/Avatar';
import { useAppSelector } from '../../../hooks/useStore';

interface Reseller {
  reseller: string;
  reseller_logo_s3_url: string;
  count: number;
}
interface TopVendorsProps {
  resellers: Reseller[];
}

const TopVendors: React.FC<TopVendorsProps> = ({ resellers }) => {
  const navigate = useNavigate();
  const { freeTrial } = useSubscriptionStatus();
  const { logoDominantColor, vendorsWithColor } = useAppSelector(state => state.dashboard);
  const { isOnboarding, brandId, loadingOnboarding } = useOnboardingSearchParam();
  const [upgradeModal, setUpgradeModal] = useState(false);

  const { sortedResellers, othersCount } = useMemo(() => {
    const top5Resellers = [...resellers]
      .sort((resellerA, resellerB) => resellerB.count - resellerA.count)
      .slice(0, 5);

    const top5Count = top5Resellers.reduce((acc, reseller) => acc + reseller.count, 0);
    const totalCount = resellers.reduce((acc, reseller) => acc + reseller.count, 0);
    const othersCount = totalCount - top5Count;

    return { sortedResellers: top5Resellers, othersCount };
  }, [resellers, brandId]);

  const totalCount = useMemo(
    () => resellers.reduce((acc, reseller: Reseller) => acc + reseller.count, 0),
    [resellers, brandId]
  );
  const othersPercentage = useMemo(() => {
    return (othersCount / totalCount) * 100;
  }, [othersCount, totalCount, brandId]);

  const handleViewAllClick = () => {
    const currentSearchParams = new URLSearchParams(location.search);

    currentSearchParams.set('brand_id', brandId as string);

    navigate(`/dashboard/vendors?${currentSearchParams.toString()}`);
  };

  const colorPalette = ['#EE3939', '#EEB039', '#62B91D', '#21CCB7', '#1BC2E7'];

  // const assignUniqueColors = (resellers: Reseller[]) => {
  //   const colorMap = new Map<string, string>();
  //   const usedColors = new Set<string>();

  //   resellers.forEach((reseller, index) => {
  //     if (!colorMap.has(reseller.reseller)) {
  //       let color: string;
  //       if (index < colorPalette.length) {
  //         // Use colors from the palette first
  //         color = colorPalette[index];
  //       } else {
  //         // Generate a random color if we've exhausted the palette
  //         do {
  //           color =
  //             '#' +
  //             Math.floor(Math.random() * 16777215)
  //               .toString(16)
  //               .padStart(6, '0');
  //         } while (usedColors.has(color));
  //       }
  //       colorMap.set(reseller.reseller, color);
  //       usedColors.add(color);
  //     }
  //   });

  //   return colorMap;
  // };

  return (
    <Stack bgcolor={'#fff'} borderRadius={'16px'} width={'30%'} minWidth={'300px'}>
      <Stack p={'16px 24px'} gap={'4px'}>
        <Typography fontWeight={400} fontSize={20} lineHeight={'28px'} letterSpacing={'-0.24px'}>
          Top vendors
        </Typography>
        <Typography
          fontWeight={400}
          fontSize={14}
          lineHeight={'20px'}
          letterSpacing={'-0.07px'}
          color={'text.secondary'}
        >
          Your images across different vendors websites
        </Typography>
      </Stack>

      {!isOnboarding ? (
        <Stack gap={'32px'} pb={'16px'}>
          <Box
            display="flex"
            height="32px"
            borderRadius="4px"
            overflow="hidden"
            bgcolor="#F3F3F7"
            mx={'24px'}
          >
            {sortedResellers.map((item, index) => (
              <Box
                key={index}
                flex={`${(item.count / totalCount) * 100}%`}
                bgcolor={vendorsWithColor[item.reseller]?.color || colorPalette[index]}
                borderRight={'2px solid #fff'}
              />
            ))}
            {othersPercentage > 0 && <Box flex={`${othersPercentage}%`} bgcolor="#F3F3F7" />}
          </Box>
          <Stack px={'16px'}>
            {sortedResellers?.map((item, index) => (
              <Stack
                direction={'row'}
                alignItems={'center'}
                key={index}
                justifyContent={'space-between'}
                p={'8px'}
              >
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  gap={'8px'}
                  sx={{
                    color: '#171D30',
                    fontSize: 16,
                    fontWeight: 400,
                    lineHeight: '28px',
                    img: { width: 24, height: 24, borderRadius: '50%' },
                  }}
                >
                  {item.reseller_logo_s3_url ? (
                    <img
                      src={item.reseller_logo_s3_url}
                      alt=""
                      style={{ width: '36px', height: '36px', borderRadius: '50%' }}
                    />
                  ) : (
                    <Avatar
                      sx={{
                        width: '36px',
                        height: '36px',
                        backgroundColor:
                          vendorsWithColor[item.reseller]?.color || logoDominantColor,
                        fontSize: '18px',
                      }}
                    >
                      {item.reseller.charAt(0).toUpperCase()}
                    </Avatar>
                  )}

                  {item.reseller}
                </Stack>
                <Stack direction="row" alignItems="center" gap="8px">
                  <Typography fontSize={20} fontWeight={400} color={'text.primary'}>
                    {item.count}
                  </Typography>
                  <Stack bgcolor={'#F3F3F7'} p={'2px 8px'} borderRadius={'8px'}>
                    <Typography fontSize={16} fontWeight={400} color={'text.secondary'}>
                      {((item.count / totalCount) * 100).toFixed(0)}%
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            ))}

            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              p={'8px'}
            >
              <Stack
                direction={'row'}
                alignItems={'center'}
                gap={'8px'}
                sx={{
                  color: '#171D30',
                  fontSize: 16,
                  fontWeight: 400,
                  lineHeight: '28px',
                  img: { width: 24, height: 24, borderRadius: '50%' },
                }}
              >
                <DefaultResellerLogo />
                Others
              </Stack>
              <Stack direction="row" alignItems="center" gap="8px">
                <Typography fontSize={20} fontWeight={400} color={'text.primary'}>
                  {othersCount}
                </Typography>
                <Stack bgcolor={'#F3F3F7'} p={'2px 8px'} borderRadius={'8px'}>
                  <Typography fontSize={16} fontWeight={400} color={'text.secondary'}>
                    {othersPercentage.toFixed(0)}%
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          {freeTrial ? (
            <Stack
              bgcolor={'#F8F8FB'}
              p={'16px'}
              borderRadius={'16px'}
              direction={'row'}
              justifyContent={'space-between'}
              mx={'24px'}
            >
              <Typography>Upgrade your plan to unlock all vendors</Typography>
              <Button
                onClick={() => {
                  setUpgradeModal(true);
                }}
                variant="contained"
                sx={{
                  borderRadius: '12px',
                  bgcolor: 'text.primary',
                  borderColor: 'text.primary',
                  fontSize: '14px',
                  fontWeight: 500,
                  letterSpacing: '-0.07px',
                  lineHeight: '20px',
                  ':hover': {
                    bgcolor: '#575C70',
                    color: '#FFFFFF',
                  },
                }}
              >
                Pricing
              </Button>
            </Stack>
          ) : (
            <Button
              variant="outlined"
              onClick={handleViewAllClick}
              sx={{
                borderRadius: '12px',
                mx: '24px',
                p: '10px 12px',
                height: '40px',
                fontSize: 14,
                lineHeight: '20px',
                letterSpacing: '-0.07px',
                fontWeight: 500,
              }}
            >
              All vendors
            </Button>
          )}
        </Stack>
      ) : (
        <Stack
          sx={{
            display: 'flex',
            width: '324px',
            height: '240px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '24px',
            m: 'auto',
          }}
        >
          {loadingOnboarding ? <CustomCircularProgress size={124} /> : <OverviewStart />}

          <Typography
            sx={{
              color: 'rgba(66, 72, 93, 0.60)',
              textAlign: 'center',
              fontFamily: 'Poppins',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '20px',
              letterSpacing: '-0.07px',
            }}
          >
            Add new brand to build visualization
          </Typography>
        </Stack>
      )}

      <UpgradePlanModal
        open={upgradeModal}
        handleClose={() => {
          setUpgradeModal(false);
        }}
      />
    </Stack>
  );
};

export default TopVendors;
