import React, { useEffect, useRef, useState } from 'react';
import Shape from './Shape';
import useDebounce from '../../../../../../hooks/useDebounce';
import { SpinnerLoader } from '../../../../../../components/blocks';

const Canvas = ({
  image,
  canvasRef,
  modalRef,
  shapeRef,
  lineWidth,
  shape,
  setShape,
  setInitialImage,
  setMaskImage,
  isRedraw,
  setIsRedraw,
  initImageRef,
  isGeneratedImageLoading,
}) => {
  const [isDrawing, setIsDrawing] = useState(false);
  const [imageHeight, setImageHeight] = useState(0);
  const [imageWidth, setImageWidth] = useState(0);
  const [imageLoading, setImageLoading] = useState(true);

  const brushRef = useRef(null);

  useEffect(() => {
    const penStyle = {
      color: 'rgba(3, 138, 255, 0.4)',
      cap: 'round',
      radius: lineWidth / 3,
      canvas: document.createElement('canvas'),
      cursor_url: null,
    };

    const canvas = brushRef.current;

    const rad = penStyle.radius;
    const color = penStyle.color;
    const cap = penStyle.cap;

    const cursorCanvas = penStyle.canvas;
    const cursorCtx = cursorCanvas.getContext('2d', { willReadFrequently: true });

    cursorCanvas.width = cursorCanvas.height = rad;
    cursorCtx.fillStyle = color;
    if (cap === 'round') {
      cursorCtx.arc(rad / 2, rad / 2, rad / 2, 0, Math.PI * 2);
    } else {
      cursorCtx.rect(0, 0, rad, rad);
    }
    cursorCtx.fill();
    cursorCanvas.toBlob(function (blob) {
      URL.revokeObjectURL(penStyle.cursor_url);
      penStyle.cursor_url = URL.createObjectURL(blob);
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      canvas.style.cursor = `url(${penStyle.cursor_url}) ${rad / 2} ${rad / 2}, auto`;
    });
  }, [lineWidth, imageLoading]);

  useEffect(() => {
    if (isRedraw) {
      const canvas2 = shapeRef.current;

      const context2 = canvas2.getContext('2d', { willReadFrequently: true });

      context2.clearRect(0, 0, imageWidth, imageHeight);

      drawShape(shape);
    }
    setIsRedraw(false);
  }, [isRedraw]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const initImageCanvas = initImageRef.current;
    const modal = modalRef.current;
    const modalHeight = modal.offsetHeight;
    const modalWidth = modal.offsetWidth;
    const brushCanvas = brushRef.current;

    const context = canvas.getContext('2d', { willReadFrequently: true });
    const initImageContext = initImageCanvas.getContext('2d', { willReadFrequently: true });

    const img = new Image();
    img.src = image;
    img.crossOrigin = 'anonymous';
    img.onload = e => {
      const ratio = e.target.width / e.target.height;
      let height = modalHeight - 150;
      let width = height * ratio;

      while (width > modalWidth) {
        height = height - 10;
        width = height * ratio;
      }

      canvas.width = width;
      canvas.height = height;

      initImageCanvas.width = e.target.width;
      initImageCanvas.height = e.target.height;

      brushCanvas.width = width;
      brushCanvas.height = height;

      context.drawImage(img, 0, 0, width, height);
      initImageContext.drawImage(img, 0, 0, e.target.width, e.target.height);

      initImageCanvas.toBlob(img => setInitialImage(img));

      setImageHeight(e.target.height);
      setImageWidth(e.target.width);
      setImageLoading(false);
    };
  }, [image, canvasRef, modalRef, isGeneratedImageLoading]);

  const handleMouseDown = event => {
    setIsDrawing(true);
    const { offsetX, offsetY } = event.nativeEvent;
    setShape(prev => [...prev, new Shape(offsetX, offsetY, lineWidth / 3)]);
  };

  const handleMouseMove = event => {
    if (!isDrawing) return;
    const { offsetX, offsetY } = event.nativeEvent;
    const shapeCopy = [...shape];
    const lastShape = shapeCopy[shapeCopy.length - 1];
    shapeCopy.pop();
    setShape([...shapeCopy, new Shape(offsetX, offsetY, lastShape, lineWidth / 3)]);
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
  };

  const drawShape = shape => {
    const brushCanvas = brushRef.current;
    const brushContext = brushCanvas.getContext('2d', { willReadFrequently: true });

    brushContext.clearRect(0, 0, imageWidth, imageHeight);

    for (let i = 0; i < shape.length; i++) {
      const offset = shape[i];

      brushContext.strokeStyle = 'rgb(3, 138, 255)';
      brushContext.lineWidth = offset.lineWidth;
      brushContext.lineJoin = brushContext.lineCap = 'round';

      brushContext.beginPath();
      brushContext.moveTo(offset.startX, offset.startY);

      let currentShape = offset;

      while (currentShape.next) {
        currentShape = currentShape.next;
        brushContext.lineTo(currentShape.startX, currentShape.startY);
      }

      brushContext.stroke();
    }
  };

  const drawMask = shape => {
    const canvas2 = shapeRef.current;
    const brushCanvas = brushRef.current;

    const context = canvas2.getContext('2d', { willReadFrequently: true });

    canvas2.width = imageWidth;
    canvas2.height = imageHeight;

    context.clearRect(0, 0, imageWidth, imageHeight);
    context.fillStyle = '#FFF';
    context.fillRect(0, 0, imageWidth, imageHeight);

    context.globalCompositeOperation = 'destination-out';
    context.drawImage(brushCanvas, 0, 0, imageWidth, imageHeight);
    context.globalCompositeOperation = 'source-over';

    const imageData = context.getImageData(0, 0, imageWidth, imageHeight);
    context.putImageData(imageData, 0, 0);

    canvas2.toBlob(img => setMaskImage(img));
  };

  const debouncedShape = useDebounce(shape, 100);

  useEffect(() => {
    if (shape.length) {
      drawShape(shape);
    }
  }, [shape]);

  useEffect(() => {
    if (shape.length) {
      drawMask(shape);
    }
  }, [debouncedShape]);

  return (
    <>
      {(imageLoading || isGeneratedImageLoading) && <SpinnerLoader />}
      <canvas
        ref={canvasRef}
        style={imageLoading || isGeneratedImageLoading ? { display: 'none' } : style}
      />
      <canvas ref={shapeRef} style={{ display: 'none' }} />
      <canvas
        ref={brushRef}
        style={imageLoading || isGeneratedImageLoading ? { display: 'none' } : style1}
        onPointerDown={handleMouseDown}
        onPointerMove={handleMouseMove}
        onPointerUp={handleMouseUp}
        onPointerLeave={handleMouseUp}
      />
      <canvas ref={initImageRef} style={{ display: 'none' }} />
    </>
  );
};

export default Canvas;

const style = {
  borderRadius: '16px',
  bgcolor: '#EFEFF0',
  border: '1px solid #EFEFF0',
  cursor: 'none',
  touchAction: 'none',
  position: 'absolute',
  top: 0,
  left: 'auto',
  zIndex: 1,
};

const style1 = {
  borderRadius: '16px',
  bgcolor: '#EFEFF0',
  border: '1px solid #EFEFF0',
  cursor: 'none',
  touchAction: 'none',
  position: 'absolute',
  top: 0,
  left: 'auto',
  zIndex: 2,
  opacity: 0.5,
};
