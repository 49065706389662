import React from 'react';

export const FolderIcon: React.FC = () => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="100" height="100" rx="4" fill="#F7F5FF" />
    <path
      d="M51.7505 41.2499L49.7983 37.3456C49.2364 36.2219 48.9555 35.66 48.5364 35.2495C48.1658 34.8865 47.7191 34.6105 47.2286 34.4413C46.674 34.25 46.0459 34.25 44.7895 34.25H38.1005C36.1403 34.25 35.1602 34.25 34.4115 34.6315C33.753 34.967 33.2175 35.5025 32.882 36.161C32.5005 36.9097 32.5005 37.8898 32.5005 39.85V41.2499M32.5005 41.2499H59.1005C62.0408 41.2499 63.5109 41.2499 64.6339 41.8222C65.6218 42.3255 66.4249 43.1286 66.9283 44.1165C67.5005 45.2395 67.5005 46.7096 67.5005 49.6499V57.3498C67.5005 60.2901 67.5005 61.7602 66.9283 62.8832C66.4249 63.8711 65.6218 64.6742 64.6339 65.1775C63.5109 65.7498 62.0408 65.7498 59.1005 65.7498H40.9005C37.9602 65.7498 36.4901 65.7498 35.367 65.1775C34.3792 64.6742 33.576 63.8711 33.0727 62.8832C32.5005 61.7602 32.5005 60.2901 32.5005 57.3498V41.2499Z"
      stroke="#3B1E8E"
      strokeWidth="3.49999"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
