import React, { useEffect, useState } from 'react';
import { Box, Slider, Stack } from '@mui/material';
import { sliderStyle } from '../../common/constants/Constants';
import ColorChip from './ColorChip';
import { getColorAndLabel } from '../Dashboard/Helpers';
import useDebounce from '../../hooks/useDebounce';
import { useAppSelector } from '../../hooks/useStore';

interface Props {
  scores: number[];
  onSlide?: (ranges: number[]) => void;
  resetRange?: boolean;
  disabled?: boolean;
}

function AverageScoreChart({ scores, onSlide, resetRange, disabled }: Props) {
  const [range, setRange] = useState([0, 100]);
  const filterRange = useDebounce(range, 500);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { score_gte, score_lte } = useAppSelector(state => state.filter);

  useEffect(() => {
    if (resetRange) {
      setRange([0, 100]);
    }
  }, [resetRange]);

  useEffect(() => {
    if (score_gte !== undefined && score_lte !== undefined) {
      const newRange = [score_gte * 10, score_lte * 10];
      setRange(newRange);
    }
  }, [score_gte, score_lte]);

  useEffect(() => {
    const scoreGte = filterRange[0] / 10;
    const scoreLte = filterRange[1] / 10;
    onSlide?.([
      [4, 7].includes(scoreGte) ? scoreGte + 0.5 : scoreGte,
      [4, 7].includes(scoreLte) ? scoreLte + 0.4 : scoreLte,
    ]);
  }, [filterRange]);

  return (
    <Box>
      <Stack
        direction={'row'}
        justifyContent="space-between"
        alignItems={'flex-end'}
        gap={'2px'}
        sx={{
          height: 65,
          px: '8px',
        }}
      >
        {scores.map((count, index) => {
          const inSliderRange = range[0] <= index * 10 && range[1] >= (index + 1) * 10;
          const gradeHeight = (count / Math.max(...scores)) * 100;

          return (
            <Stack key={index} flex={1} justifyContent={'flex-end'} height={'100%'}>
              <Box
                sx={{
                  height: `${gradeHeight}%`,
                  bgcolor: '#E9E9F0',
                  borderRadius: '2px',
                  display: 'flex',
                  alignItems: 'flex-end',
                }}
              />
              <Box
                sx={{
                  height: 8,
                  bgcolor: inSliderRange ? getColorAndLabel(index + 1).color : '#E9E9F0',
                  mt: '2px',
                  borderRadius: '2px',
                }}
              />
            </Stack>
          );
        })}
      </Stack>
      <Slider
        value={range}
        onChange={(_, val) => {
          setRange(val as number[]);
        }}
        step={10}
        disabled={disabled}
        sx={{
          ...sliderStyle,
          mx: '14px',
          width: 'calc(100% - 28px)',
          p: 0,
          position: 'relative',
          top: '-18px',
          '.MuiSlider-rail, .MuiSlider-track': {
            display: 'none',
          },
        }}
      />
      <Stack direction="row" alignItems={'center'} gap={'8px'}>
        <ColorChip
          color="#EE3939"
          disabled={disabled}
          title="Low"
          sx={chipStyle}
          onClick={() => {
            setRange([0, 40]);
          }}
        />
        <ColorChip
          color="#FFB31A"
          title="Medium"
          sx={chipStyle}
          disabled={disabled}
          onClick={() => {
            setRange([40, 70]);
          }}
        />
        <ColorChip
          color="#40C6A7"
          disabled={disabled}
          title="High"
          sx={chipStyle}
          onClick={() => {
            setRange([70, 100]);
          }}
        />
      </Stack>
    </Box>
  );
}

export default AverageScoreChart;

const chipStyle = {
  ':hover': {
    backgroundColor: '#E9E9F0',
  },
};
