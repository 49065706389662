import React from 'react';
import { Grid } from '@mui/material';
import { Asset } from './components/Asset';
import { Variations } from './components/Variations';
import { type IOptions } from '../../../../types';

interface IRightSidebar {
  handleSend: (value: string) => void;
  handleSaveImage: () => void;
  setOpenEditModal: (value: boolean) => void;
  isLoading: boolean;
  setEditAsset: (value: boolean) => void;
  selectedValue: string;
  setSelectedValue: (value: string) => void;
  options: IOptions[];
  isChatHasOutputImg: boolean;
}

function DesktopSidebar({
  handleSend,
  handleSaveImage,
  setOpenEditModal,
  isLoading,
  setEditAsset,
  selectedValue,
  setSelectedValue,
  options,
  isChatHasOutputImg,
}: IRightSidebar) {
  return (
    <Grid
      container
      sx={{
        width: '100%',
        padding: 0,
        margin: 0,
      }}
    >
      <Grid item xs={4}>
        <Asset setEditAsset={setEditAsset} />
      </Grid>
      <Grid item xs={8}>
        {isChatHasOutputImg && (
          <Variations
            setOpenEditModal={setOpenEditModal}
            handleSend={handleSend}
            handleSaveImage={handleSaveImage}
            isLoading={isLoading}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            options={options}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default DesktopSidebar;
