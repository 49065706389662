import axios from 'axios';
import { getToken } from './utils';

const apiUrl = process.env.REACT_APP_API_URL;
const focalUrl = process.env.REACT_APP_FOCAL_API_URL;

if (!apiUrl) {
  throw new Error('Must set REACT_APP_API_URL environment variable');
}

export const client = axios.create({
  baseURL: apiUrl,
});

export const authClient = axios.create({
  baseURL: apiUrl,
});

export const focalClient = axios.create({
  baseURL: focalUrl,
});

authClient.interceptors.request.use(config => {
  const token = getToken();

  if (token) {
    config.headers.Authorization = `Token ${token}`;
  }

  return config;
});
