import React from 'react';

export const AssetNotSelected: React.FC = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.9998 26.6667C18.2317 26.6667 16.536 25.9644 15.2858 24.7141C14.0355 23.4639 13.3332 21.7682 13.3332 20.0001C13.3332 18.232 14.0355 16.5363 15.2858 15.286C16.536 14.0358 18.2317 13.3334 19.9998 13.3334C21.7679 13.3334 23.4636 14.0358 24.7139 15.286C25.9641 16.5363 26.6665 18.232 26.6665 20.0001C26.6665 21.7682 25.9641 23.4639 24.7139 24.7141C23.4636 25.9644 21.7679 26.6667 19.9998 26.6667ZM19.9998 11.6667C18.9055 11.6667 17.8219 11.8823 16.8108 12.3011C15.7998 12.7199 14.8811 13.3337 14.1073 14.1075C12.5445 15.6703 11.6665 17.7899 11.6665 20.0001C11.6665 22.2102 12.5445 24.3298 14.1073 25.8926C14.8811 26.6665 15.7998 27.2803 16.8108 27.6991C17.8219 28.1179 18.9055 28.3334 19.9998 28.3334C22.21 28.3334 24.3296 27.4554 25.8924 25.8926C27.4552 24.3298 28.3332 22.2102 28.3332 20.0001C28.3332 18.9057 28.1176 17.8221 27.6988 16.8111C27.28 15.8 26.6662 14.8813 25.8924 14.1075C25.1186 13.3337 24.1999 12.7199 23.1889 12.3011C22.1778 11.8823 21.0942 11.6667 19.9998 11.6667Z"
      fill="#1B1B1E"
    />
  </svg>
);
