import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import {
  Steps,
  type IImagesArray,
  type ISegmentImages,
  type IDescriptionImagesResponse,
  type ICreateBrandRequest,
} from './types';
import { CloseIconBrand, StepIcon } from '../../assets/icons';
import { useNavigate } from 'react-router-dom';
import { createBrand, describeImages, segmentImages } from '../../repositories/brands.service';
import { Sidebar, AddBrand, Identifying, Evaluation, Review, Finalizing } from './components';
import { setBrand as setUserCreatedBrand } from '../../common/utils';
import { BrandsContext } from '../../contexts';

const stepsArray = ['addBrand', 'identifying', 'evaluation', 'review', 'finalizing'];

export const Brand = () => {
  const navigate = useNavigate();

  const { updateUserBrands } = useContext(BrandsContext);

  const [step, setStep] = useState(Steps.addBrand);
  const [brand, setBrand] = useState<string>('');
  const [chips, setChips] = useState<string[]>([]);
  const [selectedChips, setSelectedChips] = useState<string[]>([]);
  const [brandImages, setBrandImages] = useState<IImagesArray[]>([]);
  const [imagesToDescribe, setImagesToDescribe] = useState<IImagesArray[]>([]);
  const [describedImages, setDescribedImages] = useState<IImagesArray[]>([]);
  const [stylePreference, setStylePreference] = useState([]);
  const [isFinishLoading, setIsFinishLoading] = useState(false);
  const [isImagesDescribed, setIsImagesDescribed] = useState(false);
  const [requestQuery, setRequestQuery] = useState('');

  useEffect(() => {
    if (brand && selectedChips.length) {
      setRequestQuery(`${brand} ${selectedChips.join(', ')} photography`);
    }
  }, [brand, selectedChips]);

  useEffect(() => {
    setBrandImages([]);
  }, [requestQuery]);

  useEffect(() => {
    if (describedImages.length) {
      const tempDescribedImagesArray = brandImages;

      describedImages.forEach(image => {
        const index = tempDescribedImagesArray.findIndex(
          brandImage => brandImage.src === image.url
        );
        if (index >= 0) {
          tempDescribedImagesArray[index].description = image.description;
          tempDescribedImagesArray[index].descriptionId = image.descriptionId;
        }
      });

      setBrandImages(tempDescribedImagesArray);
      setIsImagesDescribed(true);
    }
  }, [describedImages]);

  const finishBrandsWorkflow = async () => {
    setStep(Steps.finalizing);
    try {
      const tempArray: ISegmentImages[] = [];

      brandImages.forEach(image => {
        tempArray.push({
          url: image.src,
          score: image.score,
          user_score: image.user_score,
          popularity: image.position,
          source_link: image.link,
          diagnostics: image.reason,
          size: `${image.height}x${image.width}`,
        });
      });

      const brandId = await handleCreateBrand();

      await segmentImages(brandId, tempArray);

      setUserCreatedBrand(brandId);
      updateUserBrands();
    } catch (error) {
      console.log(error);
      setStep(Steps.review);
    } finally {
      setIsFinishLoading(false);
      setStep(Steps.finalizing);
    }
  };

  useEffect(() => {
    if (imagesToDescribe.length) {
      const imagesSrc: string[] = [];
      const tempBrandImages = brandImages;

      brandImages.forEach(image => imagesSrc.push(image.src));

      try {
        describeImages(imagesSrc)
          .then(res => {
            res.forEach((image: IDescriptionImagesResponse) => {
              const index = brandImages.findIndex(brandImage => brandImage.src === image.url);
              tempBrandImages[index].description = image.description.text;
              tempBrandImages[index].descriptionId = image.description.description_id;
            });
          })
          .then(() => {
            setDescribedImages(tempBrandImages);
          });
      } catch (error) {
        setStep(Steps.addBrand);
        console.log('Error describing images: ', error);
      }
    }
  }, [imagesToDescribe.length]);

  const handleCreateBrand = async () => {
    const goodImagesAI = brandImages
      .filter(
        image =>
          image.score >= 0.5 &&
          image.descriptionId !== undefined &&
          image.descriptionId !== '00000000-0000-0000-0000-000000000000'
      )
      .map(image => image.descriptionId);
    const badImagesAI = brandImages
      .filter(
        image =>
          image.score < 0.5 &&
          image.descriptionId !== undefined &&
          image.descriptionId !== '00000000-0000-0000-0000-000000000000'
      )
      .map(image => image.descriptionId);

    const goodImagesUser: string[] = [];

    brandImages.forEach(image => {
      if (
        image.descriptionId === undefined ||
        image.descriptionId === '00000000-0000-0000-0000-000000000000'
      )
        return;
      if (image.reviewed && image.user_score === 1) {
        goodImagesUser.push(image.descriptionId);
      } else if (!image.reviewed && image.score >= 0.5) {
        goodImagesUser.push(image.descriptionId);
      }
    });

    const badImagesUser: string[] = [];

    brandImages.forEach(image => {
      if (
        image.descriptionId === undefined ||
        image.descriptionId === '00000000-0000-0000-0000-000000000000'
      )
        return;
      if (image.reviewed && image.user_score === 0) {
        badImagesUser.push(image.descriptionId);
      } else if (!image.reviewed && image.score < 0.5) {
        badImagesUser.push(image.descriptionId);
      }
    });

    const data: ICreateBrandRequest = {
      ai_good_images_description_ids: goodImagesAI,
      ai_bad_images_description_ids: badImagesAI,
      final_good_images_description_ids: goodImagesUser,
      final_bad_images_description_ids: badImagesUser,
      brand_name: brand,
      products: selectedChips,
    };

    const response = await createBrand(data);

    setStylePreference(response.takeaways);

    return response.id;
  };

  return (
    <Grid container>
      <Grid item xs={0} md={3} display={{ xs: 'none', md: 'block' }}>
        <Sidebar />
      </Grid>
      <Grid
        item
        xs={12}
        md={9}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        overflow="auto"
        height="100vh"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          height="50px"
          paddingX="20px"
          borderBottom="1px solid #F5F4F8"
        >
          <Box display="flex" justifyContent="center" alignItems="baseline">
            <Typography
              fontFamily="Poppins"
              fontSize="12px"
              textTransform="uppercase"
              color="#9D9AB4"
              letterSpacing={1.3}
            >
              Create your branded AI
            </Typography>
            <Box display="flex" pl="10px">
              {stepsArray.map((item, index) => {
                return (
                  <Box key={item} pl="8px">
                    <StepIcon active={index <= step} />
                  </Box>
                );
              })}
            </Box>
          </Box>

          <Box
            sx={{ '&: hover': { cursor: 'pointer' } }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <CloseIconBrand />
          </Box>
        </Box>
        <Box
          display="flex"
          position="relative"
          justifyContent="center"
          alignItems="center"
          flex={1}
        >
          {step === Steps.addBrand && (
            <AddBrand
              setStep={setStep}
              brand={brand}
              setBrand={setBrand}
              chips={chips}
              setChips={setChips}
              selectedChips={selectedChips}
              setSelectedChips={setSelectedChips}
              setRequestQuery={setRequestQuery}
            />
          )}
          {step === Steps.identifying && (
            <Identifying
              setBrand={setBrand}
              setStep={setStep}
              requestQuery={requestQuery}
              setSelectedChips={setSelectedChips}
              setChips={setChips}
              brandImages={brandImages}
              setBrandImages={setBrandImages}
              setRequestQuery={setRequestQuery}
            />
          )}
          {step === Steps.evaluation && (
            <Evaluation
              setStep={setStep}
              brandImages={brandImages}
              setImagesToDescribe={setImagesToDescribe}
              setBrandImages={setBrandImages}
              isFinishLoading={isFinishLoading}
            />
          )}
          {step === Steps.review && (
            <Review
              setStep={setStep}
              brandImages={brandImages}
              setBrandImages={setBrandImages}
              isFinishLoading={isFinishLoading}
            />
          )}
          {step === Steps.finalizing && (
            <Finalizing
              setStep={setStep}
              brand={brand}
              brandImages={brandImages}
              setBrandImages={setBrandImages}
              stylePreference={stylePreference}
              isFinishLoading={isFinishLoading}
              finishBrandsWorkflow={finishBrandsWorkflow}
              isImagesDescribed={isImagesDescribed}
              setIsFinishLoading={setIsFinishLoading}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
