import { Avatar, AvatarGroup, Box, Card, Grid, Typography } from '@mui/material';
import React from 'react';
import { theme } from '../../theme';
import { type IVendor } from '../../pages/Dashboard/Products/Types';
import { roundGrade } from '../../common/helpers';
import { useAppSelector } from '../../hooks/useStore';

interface IProps {
  vendors: IVendor[];
}

export const RankingScore = ({ vendors }: IProps) => {
  const items = [
    { number: 1, color: '#EE3939' },
    { number: 2, color: '#EE3939' },
    { number: 3, color: '#EE3939' },
    { number: 4, color: '#EE3939' },
    { number: 5, color: '#FFB31A' },
    { number: 6, color: '#FFB31A' },
    { number: 7, color: '#FFB31A' },
    { number: 8, color: '#40C6A7' },
    { number: 9, color: '#40C6A7' },
    { number: 10, color: '#40C6A7' },
  ];

  const { logoDominantColor, vendorsWithColor } = useAppSelector(state => state.dashboard);

  const groupedByName = vendors.reduce(
    (acc: Record<string, { total: number; count: number }>, reseller) => {
      const { name } = reseller;
      const grade = roundGrade(reseller.score);

      if (!acc[name]) {
        acc[name] = { total: 0, count: 0 };
      }

      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      acc[name].total += grade;
      acc[name].count++;

      return acc;
    },
    {}
  );

  const transformedData = Object.entries(groupedByName).map(([name, { total, count }]) => {
    return {
      name,
      averageGrade: Math.round(total / count),
      logoUrl: vendors.find(reseller => reseller.name === name)?.icon_url,
    };
  });

  return (
    <Grid container spacing={1} width="100%">
      {items.map((item, index) => (
        <Grid item xs={1.2} key={index} mt="30px">
          <Card elevation={0}>
            <Box
              bgcolor={item.color}
              height="10px"
              display="flex"
              borderRadius="5px"
              justifyContent="center"
              alignItems="center"
              mt="20px"
            >
              <AvatarGroup spacing="small" sx={groupAvatarStyles}>
                {transformedData.map(reseller => {
                  if (reseller.averageGrade === item.number) {
                    const backgroundColor =
                      vendorsWithColor[reseller.name]?.color || logoDominantColor;
                    const firstLetter = reseller.name.charAt(0).toUpperCase();

                    return (
                      <Avatar
                        key={reseller.name}
                        src={reseller.logoUrl}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          width: '35px',
                          height: '35px',
                          backgroundColor,
                          fontSize: '14px',
                        }}
                        style={{
                          border: '2px solid white',
                          boxSizing: 'border-box',
                        }}
                      >
                        {firstLetter}
                      </Avatar>
                    );
                  } else {
                    return null;
                  }
                })}
              </AvatarGroup>
            </Box>
            <Box display="flex" justifyContent="center">
              <Typography
                fontWeight="400"
                fontSize="18px"
                color={theme.palette.text.secondary}
                mt="30px"
              >
                {item.number}
              </Typography>
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

const groupAvatarStyles = {
  '& .MuiAvatar-root': {
    width: '35px',
    height: '35px',
    border: '2px solid white',
    boxSizing: 'border-box',
    zIndex: 1,
  },
  '& .MuiAvatarGroup-avatar': {
    width: '35px',
    height: '35px',
  },
  '& .MuiAvatarGroup-avatar:first-child': {
    width: '35px',
    height: '35px',
    color: 'white',
    fontSize: '14px',
    zIndex: 4,
  },
  '& .MuiAvatarGroup-avatar:nth-child(2)': {
    zIndex: 3,
  },
  '& .MuiAvatarGroup-avatar:nth-child(3)': {
    zIndex: 2,
  },
  '& .MuiAvatarGroup-avatar:nth-child(4)': {
    zIndex: 1,
  },
  '& .MuiAvatarGroup-avatar:nth-child(5)': {
    zIndex: 0,
  },
};
