import React from 'react';
import Stack from '@mui/material/Stack';
import { roundGrade } from '../../common/helpers';
import { getColorAndLabel } from '../Dashboard/Helpers';
import type { ViewType } from '../../core/api/types/Product';

const ScoreIndicator = ({ variant, score }: { variant?: ViewType; score: number }) => (
  <Stack
    justifyContent={'center'}
    alignItems={'center'}
    component={'span'}
    sx={{
      height: variant === 'list' ? 24 : 28,
      width: variant === 'list' ? 24 : 28,
      bgcolor: getColorAndLabel(roundGrade(score)).color,
      borderRadius: '50%',
      ...(variant === 'grid' && {
        position: 'absolute',
        left: 12,
        top: 12,
        zIndex: 1,
      }),
      fontSize: variant === 'list' ? 14 : 16,
      fontWeight: 500,
      color: '#FFFFFF',
    }}
  >
    {roundGrade(score)}
  </Stack>
);

export default ScoreIndicator;
