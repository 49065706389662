import React from 'react';

export const TrashIconGrey: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.2002 5.2V4.56C13.2002 3.66392 13.2002 3.21587 13.0258 2.87362C12.8724 2.57256 12.6276 2.32779 12.3266 2.17439C11.9843 2 11.5362 2 10.6401 2H9.36006C8.46394 2 8.01588 2 7.67361 2.17439C7.37254 2.32779 7.12776 2.57256 6.97436 2.87362C6.79996 3.21587 6.79996 3.66392 6.79996 4.56V5.2M8.40002 9.6V13.6M11.6001 9.6V13.6M2.7998 5.2H17.2004M15.6003 5.2V14.16C15.6003 15.5041 15.6003 16.1762 15.3387 16.6896C15.1086 17.1412 14.7414 17.5083 14.2898 17.7384C13.7764 18 13.1043 18 11.7602 18H8.24002C6.89584 18 6.22375 18 5.71034 17.7384C5.25873 17.5083 4.89157 17.1412 4.66146 16.6896C4.39987 16.1762 4.39987 15.5041 4.39987 14.16V5.2"
      stroke="#313234"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
