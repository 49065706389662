import axios from 'axios';
import {
  type IAutocompleteCompanyMatch,
  type ICreateBrandRequest,
  type ISegmentImages,
} from '../pages/Brand/types';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';

const url = process.env.REACT_APP_FOCAL_API_URL ?? 'https://focalstudio-science.com';
const newUrl = process.env.REACT_APP_API_URL ?? 'https://focal.adtitan.io';

export const getBrandChips = async (brand: string) => {
  return await axios
    .get(`${url}/suggestion/brand_products`, {
      params: {
        brand_name: brand,
      },
      headers: {
        'api-key': 'focal-test-key-temp-2023',
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token') ?? ''}`,
      },
      timeout: 30000,
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error(error);
      Sentry.setTag('api_url', `${url}/suggestion/brand_products`);
      Sentry.captureException(error);
      toast.error('Internal server error. Please try again', {
        position: 'bottom-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    });
};

export const searchImages = async (brand: string) => {
  return await axios
    .post(
      `${newUrl}/api/meta/assets/search_images/`,
      {
        brand_products: brand,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token') ?? ''}`,
        },
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error(error);
      Sentry.captureException(error);
      Sentry.setTag('api_url', `${newUrl}/api/meta/assets/search_images/`);
      toast.error('An error has occurred during the image search. Please try again.', {
        position: 'bottom-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    });
};

export const evaluateImage = async (image: string) => {
  return await axios
    .post(
      `${newUrl}/api/meta/assets/evaluate_image/`,
      {
        image_url: image,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token') ?? ''}`,
        },
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error(error);
      Sentry.captureException(error);
      Sentry.setTag('api_url', `${newUrl}/api/meta/assets/evaluate_image/`);
      toast.error(error.message, {
        position: 'bottom-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    });
};

export const segmentImages = async (brandId: number, images: ISegmentImages[]) => {
  return await axios
    .post(
      `${newUrl}/api/meta/assets/bulk_segment/`,
      {
        brand_id: brandId,
        images,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token') ?? ''}`,
        },
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error(error);
      Sentry.setTag('api_url', `${newUrl}/api/meta/assets/bulk_segment/`);
      Sentry.captureException(error);
      toast.error(error.message, {
        position: 'bottom-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    });
};

export const describeImages = async (images: string[]) => {
  return await axios
    .post(
      `${newUrl}/api/meta/assets/describe_images/`,
      {
        image_urls: images,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token') ?? ''}`,
        },
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error(error);
      Sentry.setTag('api_url', `${newUrl}/api/meta/assets/describe_images/`);
      Sentry.captureException(error);
      toast.error(error.message, {
        position: 'bottom-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    });
};

export const createBrand = async (data: ICreateBrandRequest) => {
  return await axios
    .post(
      `${newUrl}/api/meta/assets/generate_brand/`,
      {
        ...data,
      },
      {
        headers: {
          'api-key': 'focal-test-key-temp-2023',
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token') ?? ''}`,
        },
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error(error);
      Sentry.setTag('api_url', `${newUrl}/api/meta/assets/generate_brand/`);
      Sentry.captureException(error);
      toast.error(error.message, {
        position: 'bottom-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    });
};

export const getUserBrands = async () => {
  return await axios
    .get(`${newUrl}/api/meta/brands`, {
      headers: {
        'api-key': 'focal-test-key-temp-2023',
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token') ?? ''}`,
      },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error(error);
      Sentry.setTag('api_url', `${newUrl}/api/meta/brands`);
      Sentry.captureException(error);
      toast.error('Internal server error. Please try again', {
        position: 'bottom-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    });
};

export const getBrandLogo = async (brandName: string) => {
  return await axios
    .get(`https://autocomplete.clearbit.com/v1/companies/suggest?query=${brandName}`)
    .then(response => {
      const matches: IAutocompleteCompanyMatch[] = response.data;
      const match = matches.find(match => match.name.toLowerCase() === brandName.toLowerCase());
      return match?.logo ?? null;
    })
    .catch(error => {
      console.error(error);
      return null;
    });
};
