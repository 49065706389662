import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { getColorAndLabel } from '../Helpers';
import WallpaperIcon from '../../../assets/icons/Dashboard/WallpaperIcon';
import CustomCircularProgress from '../../Common/CustomCircularProgress';
import { FilterHdr } from '../../../assets/icons/FilterHdr';
import useOnboardingSearchParam from '../../../hooks/useOnboardingSearchParam';

interface Props {
  featuredImage: string;
  count: number;
  scoreLabel: string;
  minScore: number;
  maxScore: number;
  totalImages: number;
  onboardingImage?: string | null;
}

function ProductItem({
  featuredImage,
  count,
  totalImages,
  scoreLabel,
  minScore,
  maxScore,
  onboardingImage,
}: Props) {
  const imagePercentage: number = Math.round((count / totalImages) * 100);
  const { isOnboarding, loadingOnboarding } = useOnboardingSearchParam();

  return (
    <Stack bgcolor={'#FFF'} borderRadius={'16px'} width={'100%'} gap={'16px'} position={'relative'}>
      <Stack
        px={'6px'}
        position={'absolute'}
        top={'10px'}
        left={'14px'}
        bgcolor={getColorAndLabel(minScore).color}
        borderRadius={'16px'}
        zIndex={43}
      >
        <Typography
          color={'#fff'}
          fontSize={16}
          fontWeight={500}
          lineHeight={'24px'}
          letterSpacing={'-0.16px'}
        >
          {`${minScore}-${maxScore}`}
        </Typography>
      </Stack>

      {isOnboarding || count === 0 ? (
        <Stack
          sx={{
            width: '100%',
            aspectRatio: '9 / 7',
            margin: '0px auto',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {onboardingImage ? (
            <img
              src={onboardingImage}
              style={{
                width: '100%',
                aspectRatio: '6 / 5',
                filter: 'blur(12px)',
              }}
            />
          ) : (
            <FilterHdr />
          )}
        </Stack>
      ) : (
        <img
          src={featuredImage}
          style={{
            width: '100%',
            aspectRatio: '9 / 7',
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px',
            objectFit: 'cover',
          }}
        />
      )}

      <Stack p={'16px 24px 0px 24px'}>
        <Typography
          fontSize={20}
          fontWeight={400}
          lineHeight={'28px'}
          letterSpacing={'-0.24px'}
          color={'text.primary'}
        >
          {scoreLabel}
        </Typography>
      </Stack>
      <Stack p={'12px 16px 16px 24px'} direction={'row'} justifyContent={'space-between'}>
        <Stack direction={'row'} gap={'6px'} alignItems={'center'}>
          <WallpaperIcon />
          {!isOnboarding ? (
            <Typography
              fontSize={30}
              fontWeight={400}
              lineHeight={'36px'}
              letterSpacing={'-0.48px'}
              color={'text.primary'}
            >
              {count}
            </Typography>
          ) : (
            loadingOnboarding && <CustomCircularProgress size={24} thickness={7} />
          )}
        </Stack>

        {!isOnboarding && (
          <Stack
            p={'2px 8px'}
            bgcolor={'neutralSubtleMore'}
            borderRadius={'8px'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Typography
              fontSize={16}
              fontWeight={500}
              lineHeight={'24px'}
              letterSpacing={'-0.16px'}
              color={'text.secondary'}
            >
              {imagePercentage || 0}%
            </Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

export default ProductItem;
