import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Cell,
  LabelList,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import { styled } from '@mui/system';

import { getColorAndLabel } from '../Helpers';
import { Button, Stack, Typography } from '@mui/material';
import { useAppDispatch } from '../../../hooks/useStore';
import { setFilterScoreGte, setFilterScoreLte } from '../../../core/store/filterSlice';

interface ScoreData {
  score: number;
  count: number;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: any;
  onMount?: (score: number) => void;
  onUnmount?: () => void;
}
interface CustomLabelProps {
  x?: number;
  y?: number;
  width?: number;
  value?: string | number;
  isHovered?: boolean;
}

interface ImageScoreChartProps {
  scoreDistribution: Record<string, number>;
  totalImages: number;
}

const COLORS = [
  '#EE3939',
  '#EE3939',
  '#EE3939',
  '#EE3939',
  '#FFB31A',
  '#FFB31A',
  '#FFB31A',
  '#40C6A7',
  '#40C6A7',
  '#40C6A7',
];

const CustomLabel: React.FC<CustomLabelProps> = ({ x, y, width, value, isHovered }) => {
  if (x === undefined || y === undefined || width === undefined || value === undefined) {
    return null;
  }

  const [hover, setHover] = useState(false);

  useEffect(() => {
    setHover(!!isHovered);
  }, [isHovered]);

  return (
    <g
      transform={`translate(${x + width / 2}, ${y - 10 + (hover ? -5 : 0)})`}
      style={{ transition: 'transform .3s ease' }}
    >
      <rect
        x={-value.toString().length * 4 - 4}
        y={-14 - 4}
        width={value.toString().length * 8 + 6 * 2}
        height={14 + 4 * 2}
        rx="12"
        ry="12"
        fill="white"
      />
      <text x={2} y={-2} fill={hover ? '#42485D' : '#42485D99'} textAnchor="middle" fontSize={14}>
        {value}
      </text>
    </g>
  );
};

const TooltipContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  padding: '16px 24px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  textAlign: 'center',
  marginLeft: '-90px',
  zIndex: 4444,
}));

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, onMount, onUnmount }) => {
  const dispatch = useAppDispatch();
  if (active && payload && payload.length) {
    const { score } = payload[0].payload;
    const label = getColorAndLabel(score).label;

    useEffect(() => {
      onMount?.(score);

      const activeColor = COLORS[score - (1 % COLORS.length)];
      const cursorEl = document.querySelector('.images-score-chart .recharts-tooltip-cursor');
      if (cursorEl) {
        cursorEl.setAttribute('fill', activeColor);
        cursorEl.setAttribute('opacity', '.1');
      }

      return () => {
        onUnmount?.();
      };
    }, [score]);
    useEffect(() => {
      return () => {
        onUnmount?.();
      };
    }, []);

    const handleSelectScore = () => {
      dispatch(setFilterScoreGte(score));
      dispatch(setFilterScoreLte(score));

      const dataListingSection = document.getElementById('data_listing');
      if (dataListingSection) {
        dataListingSection.scrollIntoView({ behavior: 'smooth' });
      }
    };

    return (
      <TooltipContainer>
        <Stack gap={1} sx={{ zIndex: 2222 }}>
          <Typography fontSize={21} fontWeight={400} lineHeight={'28px'} letterSpacing={'-0.26px'}>
            {label}
          </Typography>
          <Button variant="outlined" sx={{ borderRadius: '12px' }} onClick={handleSelectScore}>
            Browse
          </Button>
        </Stack>
      </TooltipContainer>
    );
  }

  return null;
};

const ImageScoreChart: React.FC<ImageScoreChartProps> = ({ scoreDistribution, totalImages }) => {
  const [hoveredCell, setHoveredCell] = useState<number | null>(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const axisTickStyle = { fontSize: 18, fontWeight: 400, fill: '#171D30' };
  const yAxisTickStyle = { fontSize: 14, fill: '#42485D99' };

  const data: ScoreData[] = Object.entries(scoreDistribution)
    .map(([score, count]) => ({
      score: parseInt(score),
      count,
    }))
    .sort((a, b) => a.score - b.score);

  const maxCount = data.reduce((max, item) => Math.max(max, item.count), 0);
  const maxYValue = Math.max(totalImages, Math.ceil(maxCount / 50) * 50);
  const ticks = Array.from({ length: Math.ceil(maxYValue / 50) + 1 }, (_, i) => i * 50);

  return (
    <Box
      sx={{
        width: '100%',
        height: 300,
        margin: '0 auto',
        position: 'relative',
        zIndex: 333,
        pb: '16px',
        '.recharts-xAxis': { transform: 'translateY(5px)', line: { display: 'none' } },
        '.recharts-tooltip-wrapper': {
          pointerEvents: 'auto !important',
        },
      }}
    >
      <ResponsiveContainer width="100%" height="100%" className="images-score-chart">
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 0, bottom: 5 }}
          onMouseLeave={() => {
            setHoveredCell(null);
            setTooltipVisible(false);
          }}
          onMouseMove={() => {
            setTooltipVisible(true);
          }}
        >
          <XAxis dataKey="score" tick={axisTickStyle} tickLine={false} />
          <YAxis
            tick={yAxisTickStyle}
            ticks={ticks}
            axisLine={false}
            tickLine={false}
            domain={[0, maxYValue]}
          />
          <CartesianGrid stroke="#E9E9F0" horizontal vertical={false} />
          <Tooltip
            active={tooltipVisible}
            content={
              <CustomTooltip
                onMount={score => {
                  setHoveredCell(score - 1);
                }}
                onUnmount={() => {
                  setHoveredCell(null);
                }}
              />
            }
            position={{ y: 270 }}
          />
          <Bar dataKey="count" radius={[12, 12, 0, 0]}>
            <LabelList
              dataKey="count"
              position="top"
              content={(props: any) => (
                <CustomLabel isHovered={hoveredCell === props.index} {...props} />
              )}
            />
            {data.map((_, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default ImageScoreChart;
