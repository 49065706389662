import { Box } from '@mui/material';
import React from 'react';
import { DownloadIcon } from '../../../../../../assets/icons';

interface IDownload {
  handleSaveImage: () => void;
}

export const Download = ({ handleSaveImage }: IDownload) => {
  return (
    <Box
      sx={{
        borderRight: '1px solid #EFEFF0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        borderBottom: { xs: '1px solid #EFEFF0', md: '0px' },
        height: '50px',
      }}
    >
      <Box onClick={handleSaveImage} display="flex" justifyContent="center" alignItems="center">
        <DownloadIcon />
      </Box>
    </Box>
  );
};
