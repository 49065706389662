import React from 'react';
import Stack from '@mui/material/Stack';
import GloablLine from '../../assets/icons/GloablLine';

function DefaultResellerLogo() {
  return (
    <Stack
      sx={{
        display: 'flex',
        width: '32px',
        height: '32px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        borderRadius: '999px',
        background: '#F3F3F7',
      }}
    >
      <GloablLine />
    </Stack>
  );
}

export default DefaultResellerLogo;
