import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import OnboardingAverageScore from '../../Common/Placeholders/OnboardingAverageScore';
import DoubleDollarIcon from '../../../assets/icons/Dashboard/DoubleDollarIcon';
import CustomCircularProgress from '../../Common/CustomCircularProgress';

function AverageScorePlaceholder({
  isOnboarding,
  loading,
}: {
  isOnboarding: boolean;
  loading: boolean;
}) {
  const segments = 10;
  const segmentAngle = 360 / (segments + 2);
  const segmentPath =
    'M2.78976 35C0.876591 33.8954 0.213747 31.4436 1.40042 29.5803C8.62169 18.2412 18.2412 8.62169 29.5803 1.40042C31.4436 0.213743 33.8954 0.876589 35 2.78976L36.52 5.42248C37.6246 7.33565 36.963 9.77364 35.1067 10.9713C25.4381 17.2096 17.2096 25.4381 10.9713 35.1067C9.77364 36.9629 7.33565 37.6246 5.42248 36.52L2.78976 35Z';

  return (
    <Stack width={'29%'} minWidth={'350px'} bgcolor={'#fff'} borderRadius={'16px'} gap={'24px'}>
      {!isOnboarding ? (
        <>
          <Stack p={'16px 24px 12px 24px'} mx={'auto'}>
            <Skeleton variant="text" width={160} height={40} />
          </Stack>

          <Box display="flex" flexDirection="column" alignItems="center">
            <Box position="relative" width={150} height={150}>
              {Array.from({ length: segments }).map((_, index) => {
                const rotation = index * segmentAngle - 90;
                return (
                  <svg
                    key={index}
                    width="150"
                    height="150"
                    viewBox="0 0 150 150"
                    style={{
                      position: 'absolute',
                      transform: `rotate(${rotation}deg)`,
                      transformOrigin: '75px 75px',
                      zIndex: 1,
                    }}
                  >
                    <mask id={`mask-${index}`} fill="white">
                      <path d={segmentPath} />
                    </mask>
                    <path
                      d={segmentPath}
                      fill={'#F3F3F7'}
                      stroke="white"
                      strokeWidth="2"
                      mask={`url(#mask-${index})`}
                    />
                  </svg>
                );
              })}
              <Box
                position="absolute"
                top="50%"
                left="50%"
                width="94px"
                height="94px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                bgcolor="white"
                borderRadius="50%"
                boxShadow={3}
                style={{ transform: 'translate(-50%, -50%)' }}
              >
                <Typography
                  fontSize={40}
                  fontWeight={400}
                  lineHeight={'56px'}
                  letterSpacing={'-0.96px'}
                >
                  0
                </Typography>
              </Box>
            </Box>
            <Typography
              fontSize={20}
              fontWeight={400}
              lineHeight={'28px'}
              letterSpacing={'0.24px'}
              mt={3}
            >
              <Skeleton variant="text" width={120} height={40} />
            </Typography>
          </Box>
          <Stack p={'0px 24px 12px 24px'} textAlign={'center'} mt={-1}>
            <Skeleton variant="text" width={'100%'} height={40} />
          </Stack>
          <Stack p={'16px 24px'} gap={'16px'}>
            <Stack
              border={'1px solid'}
              borderColor={'borderActionsNormal'}
              borderRadius={'12px'}
              p={'10px 12px'}
              direction={'row'}
              gap={'16px'}
            >
              <Skeleton variant="text" width={40} height={40} />
              <Skeleton variant="text" width={40} height={40} />
            </Stack>

            <Stack height={'100px'}></Stack>

            <Stack p={'58px 0px 16px 0px'} gap={'16px'}>
              <Skeleton variant="text" width={160} height={40} />
              <Skeleton variant="text" width={'100%'} height={40} />
            </Stack>
          </Stack>
        </>
      ) : (
        <>
          <Stack p={'16px 24px 12px 24px'} mx={'auto'}>
            <Typography
              fontSize={20}
              fontWeight={400}
              lineHeight={'28px'}
              letterSpacing={'-0.24px'}
              color={'text.primary'}
            >
              Average score
            </Typography>
          </Stack>

          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '16px',
              pt: '24px',
            }}
          >
            <OnboardingAverageScore />
          </Stack>
          <Stack p={'8px 16px 16px 16px'} textAlign={'center'}>
            <Typography
              fontSize={14}
              fontWeight={400}
              lineHeight={'20px'}
              letterSpacing={'-0.07px'}
              color={'text.secondary'}
            >
              Average score of images quality over all <br />
              vendors sites
            </Typography>
          </Stack>
          <Stack p={'16px 24px 0px 24px'} gap={'16px'}>
            <Stack
              border={'1px solid'}
              borderColor={'borderActionsNormal'}
              borderRadius={'12px'}
              p={'10px 12px'}
              direction={'row'}
              gap={'16px'}
              sx={{
                ...(isOnboarding && {
                  alignItems: 'center',
                }),
              }}
            >
              <Stack
                width={'40px'}
                height={'40px'}
                bgcolor={!isOnboarding ? 'button.destructiveTertiary' : '#F8F8FB'}
                borderRadius={'12px'}
                direction={'row'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                {loading ? (
                  <CustomCircularProgress size={40} />
                ) : (
                  <DoubleDollarIcon bgcolor={!isOnboarding ? '#EE3939' : '#E9E9F0'} />
                )}
              </Stack>

              <Stack>
                <Typography
                  fontSize={14}
                  fontWeight={400}
                  lineHeight={'20px'}
                  letterSpacing={'-0.07px'}
                >
                  Unrealized Revenue
                </Typography>
              </Stack>
            </Stack>

            <Stack p={'0px 0px 16px 0px'} gap={'16px'}>
              <Typography
                fontSize={16}
                fontWeight={400}
                lineHeight={'24px'}
                letterSpacing={'-0.16px'}
              >
                What does score mean?
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={400}
                lineHeight={'20px'}
                letterSpacing={'-0.07px'}
                color={'text.secondary'}
              >
                Average score of images quality over all vendors sites and all products. Score
                calculated for each based on 12 parameters
              </Typography>
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
}

export default AverageScorePlaceholder;
