import React, {
  type ReactNode,
  type Dispatch,
  type SetStateAction,
  createContext,
  useState,
  useContext,
  useEffect,
} from 'react';
import { updateTheme } from '../repositories/theme.service';
import { AssetContentContext } from './assetsContext';
import { FileContentContext } from './fileContext';

interface IConversationData {
  conversation: ICONVERSATION[];
  setConversation: Dispatch<SetStateAction<ICONVERSATION[]>>;
  handleDeleteConversation: () => void;
  setThemeId: (value: number) => void;
  themeId: number;
}

interface IProps {
  children: ReactNode;
}

interface ICONVERSATION {
  speaker_type: SPEAKER;
  query: string;
  isLoading?: boolean;
  images?: Array<{ url: string; id: string }>;
  isSuccess?: boolean;
  loadingItems?: string[];
  ids?: string[];
}

enum SPEAKER {
  user,
  bot,
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ConversationContext = createContext<IConversationData>({} as IConversationData);

export const ConversationProvider: React.FC<IProps> = ({ children }) => {
  const { handleContentVal, handleChatID, setLoadedFile } = useContext(FileContentContext);
  const { handleAssetVal } = useContext(AssetContentContext);

  const [conversation, setConversation] = useState<ICONVERSATION[]>([]);
  const [themeId, setThemeId] = useState(0);

  useEffect(() => {
    if (conversation.length > 0 && themeId) {
      updateTheme(themeId, conversation);
    }
  }, [conversation]);

  const handleDeleteConversation = () => {
    handleChatID('');
    handleContentVal('');
    handleAssetVal('');
    setConversation([]);
    setLoadedFile({ url: '', name: '' });
  };

  const contextValue: IConversationData = {
    conversation,
    setConversation,
    handleDeleteConversation,
    setThemeId,
    themeId,
  };

  return (
    <ConversationContext.Provider value={contextValue}>{children}</ConversationContext.Provider>
  );
};
