import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { FolderIcon, MainIcon, PlusIcon } from '../../../../assets/icons/createTheme';
import { useNavigate } from 'react-router-dom';
import { postUploadImage } from '../../../../hooks/api/image';
import * as loadImage from 'blueimp-load-image';
import { postChatInitialize } from '../../../../repositories/prompte.service';
import { AssetContentContext, FileContentContext } from '../../../../contexts';
import { SpinnerLoader } from '../../../../components/blocks';

export const ChatWelcome = () => {
  const navigate = useNavigate();

  const { handleChatID, setLoadedFile, setImageName, setIsLoadingError } =
    useContext(AssetContentContext);

  const { fileContentVal, isLoading, setIsLoading } = useContext(FileContentContext);

  const getUrlImage = async (image: File) => {
    setIsLoadingError(false);
    setIsLoading(true);
    try {
      await loadImage(
        image,
        async (canvas: any) => {
          canvas.toBlob(async (img: any) => {
            const imageS3 = await postUploadImage(img as unknown as File);

            setLoadedFile({ url: imageS3 as unknown as string, name: image.name });
            const chatId = await postChatInitialize({ image: imageS3 });
            handleChatID(chatId);
          });
        },
        { orientation: true, canvas: true, maxWidth: 1024, maxHeight: 1024 }
      );

      navigate('/assets', { state: { addNewAsset: true } });
    } catch (error) {
      throw new Error('Get url image error');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePicture = async (e: any) => {
    try {
      setImageName(e.target.files[0].name);
      getUrlImage(e.target.files[0] as unknown as File);
    } catch (error) {
      throw new Error('Handle picture error');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: {
          xs: 'calc(100vh - 124px)',
          sm: `${fileContentVal ? 'calc(100vh - 130px)' : 'calc(100vh - 64px)'}`,
        },
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: '#F5F5F5',
        flexDirection: 'column',
        padding: {
          xs: '10px',
          sm: '0px',
        },
      }}
    >
      {fileContentVal ? (
        <Box display="flex" flexDirection="column" alignItems="center" width="100%">
          <Box
            display="flex"
            flexDirection="column"
            width="95%"
            height={{ xs: '250px', sm: 'calc(100vh - 200px)' }}
            component="img"
            src={fileContentVal}
            alt={'sting'}
            sx={{
              objectFit: 'contain',
            }}
          />
        </Box>
      ) : (
        <>
          {isLoading ? (
            <SpinnerLoader />
          ) : (
            <>
              <MainIcon />
              <Typography
                fontFamily="Figtree"
                fontSize={34}
                mt={{ xs: '50px', lg: '25px' }}
                lineHeight="38px"
                fontWeight={600}
                color="#313234"
              >
                Welcome to the
              </Typography>
              <Typography
                fontFamily="Figtree"
                fontSize={34}
                lineHeight="38px"
                fontWeight={600}
                color="#313234"
              >
                Theme Creator Page!
              </Typography>
              <Typography
                fontFamily="Figtree"
                fontSize={{ xs: 14, lg: 16 }}
                pt={{ xs: '25px', lg: '10px' }}
                color="#61646B"
                textAlign="center"
              >
                In order to start creating you need to upload an asset first.
              </Typography>
              <Typography
                fontFamily="Figtree"
                fontSize={{ xs: 14, lg: 16 }}
                color="#61646B"
                textAlign="center"
              >
                Please select an asset from your library or upload a new one.
              </Typography>
              <Box display="flex" gap="80px" mt={{ xs: '50px', lg: '25px' }}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  sx={{
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                  component={'label'}
                >
                  <input
                    onChange={e => {
                      handlePicture(e);
                      e.target.value = '';
                    }}
                    type="file"
                    id="fileInput"
                    alt="fileInput"
                    accept="image/png, image/jpeg"
                    hidden
                  />
                  <PlusIcon />
                  <Typography fontFamily="Figtree" fontSize={16} mt="5px" color="#313234">
                    Add New Asset
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  onClick={() => {
                    navigate('/assets');
                  }}
                  sx={{
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  <FolderIcon />
                  <Typography fontFamily="Figtree" fontSize={16} mt="5px" color="#313234">
                    Choose from Library
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};
