import React from 'react';

export const DownloadIcon: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.33333 13.3333H12.6667V12H3.33333V13.3333ZM12.6667 6H10V2H6V6H3.33333L8 10.6667L12.6667 6Z"
      fill="#6E6E6E"
    />
  </svg>
);
