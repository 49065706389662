import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { ArchiveLine } from '../../../assets/icons/ArchiveLine';
import { DownloadLine } from '../../../assets/icons/DownloadLine';
import Stack from '@mui/material/Stack';
import { SendPlaneLine } from '../../../assets/icons/SendPlaneLine';
import IconButton from '@mui/material/IconButton';
import { CloseFill } from '../../../assets/icons/CloseFill';
import { type IAction } from '../../../core/api/types/Actions';

interface Props {
  open: boolean;
  handleClose: () => void;
  action: IAction | null;
}

const DraftEmailModal = ({ open, handleClose, action }: Props) => {
  if (!action) return null;
  return (
    <Modal open={open} onClose={handleClose}>
      <Stack sx={{ ...modalStyle }}>
        <Stack
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            alignSelf: 'stretch',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <Typography
            sx={{
              color: '#171D30',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '26px',
              letterSpacing: '-0.216px',
            }}
          >
            {/* Resend it to admin@target.com */}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              startIcon={<SendPlaneLine />}
              sx={{
                display: 'flex',
                padding: '6px 12px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '2px',
                borderRadius: '10px',
                background: '#171D30',
                boxShadow: '0px 1px 2px 0px rgba(20, 21, 26, 0.05)',
                color: '#FFFFFF',
                '&:hover': {
                  background: '#171D30',
                  boxShadow: '0px 1px 2px 0px rgba(20, 21, 26, 0.05)',
                },
              }}
              disabled
            >
              Send
            </Button>
            <IconButton
              onClick={handleClose}
              sx={{
                display: 'flex',
                padding: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '4px',
                border: 'none',
                background: 'rgba(255, 255, 255, 0.00)',
              }}
            >
              <CloseFill />
            </IconButton>
          </Box>
        </Stack>
        <Box
          sx={{
            display: 'flex',
            padding: '24px 32px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '32px',
            borderRadius: '16px',
            background: '#FFF',
            width: 'auto',
          }}
        >
          <Typography
            sx={{
              color: '#171D30',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '26px',
              letterSpacing: '-0.216px',
            }}
          >
            Hello, this is Arthur from Danone!
          </Typography>
          <Typography
            sx={{
              color: '#171D30',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '26px',
              letterSpacing: '-0.216px',
            }}
          >
            Please replace the following images with better quality. Below you can find a link to
            the archive with better images and a table with a full list of changes.
          </Typography>
          <Button
            startIcon={<ArchiveLine />}
            endIcon={<DownloadLine />}
            sx={{
              display: 'flex',
              padding: '10px 14px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '4px',
              borderRadius: '12px',
              background: '#171D30',
              boxShadow: '0px 1px 2px 0px rgba(20, 21, 26, 0.05)',
              color: '#FFFFFF',
              '&:hover': {
                background: '#171D30',
                boxShadow: '0px 1px 2px 0px rgba(20, 21, 26, 0.05)',
              },
            }}
          >
            Download images
          </Button>
          <Typography
            sx={{
              color: '#171D30',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '26px',
              letterSpacing: '-0.216px',
            }}
          >
            Table of changes
          </Typography>

          <Grid
            container
            spacing={2}
            component="div"
            sx={{
              display: 'flex',
              padding: '8px 0px',
              alignItems: 'center',
              gap: '16px',
              alignSelf: 'stretch',
              borderBottom: '1px solid #E9E9F0',
              ml: 0,
              width: '100%',
            }}
          >
            <Grid item xs={1.5}>
              <Typography sx={{ ...headerTitleStyle }}>UPC</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography sx={{ ...headerTitleStyle }}>Image No</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography sx={{ ...headerTitleStyle }}>Preview</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={{ ...headerTitleStyle }}>Original image</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={{ ...headerTitleStyle }}>New image</Typography>
            </Grid>
          </Grid>

          {action.image_data.map((image, index) => (
            <Grid
              container
              spacing={2}
              component="div"
              key={image.id}
              sx={{
                display: 'flex',
                padding: '8px 0px',
                alignItems: 'center',
                gap: '16px',
                alignSelf: 'stretch',
                backgroundColor: index % 2 === 0 ? '#fff' : '#F8F8FB',
                ml: 0,
                width: '100%',
                mt: '-24px',
              }}
            >
              <Grid item xs={1.5} sx={{ ...gridItemStyle }}>
                <Typography
                  sx={{
                    color: '#171D30',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px',
                    letterSpacing: '-0.07px',
                  }}
                >
                  {action.id}
                </Typography>
              </Grid>
              <Grid item xs={1} sx={{ ...gridItemStyle }}>
                <Typography
                  sx={{
                    color: '#171D30',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px',
                    letterSpacing: '-0.07px',
                  }}
                >
                  {Math.floor(Math.random() * 100)}
                </Typography>
              </Grid>
              <Grid item xs={1} sx={{ ...gridItemStyle }}>
                <Box
                  sx={{
                    width: '82px',
                    height: '82px',
                    flexShrink: 0,
                    borderRadius: '4px',
                    background: `url(${image.image_s3_url}) lightgray 50% / contain no-repeat`,
                  }}
                />
              </Grid>
              <Grid item xs={4} sx={{ ...gridItemStyle }}>
                <Typography
                  component={'a'}
                  href={image.image_s3_url}
                  target="_blank"
                  sx={{
                    color: '#171D30',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px',
                    letterSpacing: '-0.07px',
                  }}
                >
                  {image.image_s3_url}
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{ ...gridItemStyle }}>
                <Typography
                  component={'a'}
                  href={image.replacement_image_url}
                  target="_blank"
                  sx={{
                    color: '#171D30',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px',
                    letterSpacing: '-0.07px',
                  }}
                >
                  {image.replacement_image_url}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Box>
      </Stack>
    </Modal>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'inline-flex',
  padding: '16px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
  bgcolor: '#f8f8fb',
  borderRadius: '24px',
  width: '90%',
};

const headerTitleStyle = {
  color: 'rgba(66, 72, 93, 0.60)',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '16px',
};

const gridItemStyle = {
  pt: '0 !important',
  px: '16px',
};

export default DraftEmailModal;
