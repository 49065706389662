import React from 'react';
import { type IAsset } from '../../types';
import AssetsTab from './components/AssetsTab';
import NewAsset from './components/NewAsset';
import EditAsset from './components/EditAsset';

interface IAssetTab {
  assets: IAsset[];
  setSearch: (value: string) => void;
  setSelectedAsset: (value: IAsset | null) => void;
  selectedAsset: IAsset | null;
  handleOpenModal: () => void;
  filterAssetsByNameAsc: () => void;
  filterAssetsByNameDesc: () => void;
  filterAssetsByDateAsc: () => void;
  filterAssetsByDateDesc: () => void;
  sortType: number;
  isAddNewAsset: boolean;
  setIsAddNewAsset: (value: boolean) => void;
  assetContentVal: string;
  assetNameError: boolean;
  setAssetName: (value: string) => void;
  lineWidth: number;
  setLineWidth: (value: number) => void;
  setRefineMode: (value: 'none' | 'restore' | 'removal') => void;
  refineMode: 'none' | 'restore' | 'removal';
  handleClickNext: () => void;
  nextButtonText: string;
  updateAssets: () => void;
  setIsEditAsset: (value: boolean) => void;
  isEditAsset: boolean;
  handleEditAsset: () => void;
  setSelectedId: (value: number) => void;
  selectedId: number;
  assetName: string;
  isAssetsLoading: boolean;
}

export const AssetTab = ({
  assets,
  setSearch,
  setSelectedAsset,
  selectedAsset,
  handleOpenModal,
  filterAssetsByNameAsc,
  filterAssetsByNameDesc,
  filterAssetsByDateAsc,
  filterAssetsByDateDesc,
  sortType,
  isAddNewAsset,
  setIsAddNewAsset,
  assetContentVal,
  assetNameError,
  setAssetName,
  lineWidth,
  setLineWidth,
  setRefineMode,
  refineMode,
  handleClickNext,
  nextButtonText,
  updateAssets,
  setIsEditAsset,
  isEditAsset,
  handleEditAsset,
  setSelectedId,
  selectedId,
  assetName,
  isAssetsLoading,
}: IAssetTab) => {
  return (
    <>
      {isAddNewAsset && (
        <NewAsset
          assetNameError={assetNameError}
          setAssetName={setAssetName}
          lineWidth={lineWidth}
          setLineWidth={setLineWidth}
          setIsAddNewAsset={setIsAddNewAsset}
          setRefineMode={setRefineMode}
          refineMode={refineMode}
          handleClickNext={handleClickNext}
          nextButtonText={nextButtonText}
        />
      )}
      {isEditAsset && (
        <EditAsset
          assetNameError={assetNameError}
          setAssetName={setAssetName}
          setIsEditAsset={setIsEditAsset}
          handleEditAsset={handleEditAsset}
          assetName={assetName}
        />
      )}
      {!isAddNewAsset && !isEditAsset && (
        <AssetsTab
          assets={assets}
          setSearch={setSearch}
          setSelectedAsset={setSelectedAsset}
          selectedAsset={selectedAsset}
          handleOpenModal={handleOpenModal}
          filterAssetsByNameAsc={filterAssetsByNameAsc}
          filterAssetsByNameDesc={filterAssetsByNameDesc}
          filterAssetsByDateAsc={filterAssetsByDateAsc}
          filterAssetsByDateDesc={filterAssetsByDateDesc}
          sortType={sortType}
          updateAssets={updateAssets}
          setIsEditAsset={setIsEditAsset}
          setSelectedId={setSelectedId}
          selectedId={selectedId}
          setAssetName={setAssetName}
          isAssetsLoading={isAssetsLoading}
        />
      )}
    </>
  );
};
