import React, { type PropsWithChildren, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetCurrentUser } from '../../../hooks/api/user';
import useSetUser from '../../../hooks/useSetUser';
import { PageLoading } from '../PageLoading';

interface IProps extends PropsWithChildren {
  showLoadingPage?: boolean;
}

export const Protected: React.FC<IProps> = ({ children, showLoadingPage }) => {
  const navigate = useNavigate();
  const [user, error, isLoading] = useGetCurrentUser();
  const setUser = useSetUser();

  useEffect(() => {
    if (!isLoading && user) {
      setUser(user);
    }

    if (!user && !isLoading) {
      navigate('/signin');
    }
  }, [user, isLoading, error, navigate]);

  return <>{showLoadingPage && isLoading ? <PageLoading /> : children}</>;
};
