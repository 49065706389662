import React, { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Assets, Home, Login, SignUp, Brand, Profile, Brands } from '../../pages';
import { Protected } from '../../components/blocks';
import DashboardPage from '../../pages/Dashboard';
import ActionsPage from '../../pages/Dashboard/Actions';
import ProductsPage from '../../pages/Dashboard/Products';
import VendorsPage from '../../pages/Dashboard/Vendors';
import OnboardingPage from '../../pages/Onboarding';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const Routing = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path="/onboarding" element={<OnboardingPage />} />
        <Route
          path="/"
          element={
            <Protected>
              <DashboardPage />
            </Protected>
          }
        />
        <Route
          path="/asset/:assetID"
          element={
            <Protected>
              <Home />
            </Protected>
          }
        />
        <Route
          path="/assets"
          element={
            <Protected>
              <Assets />
            </Protected>
          }
        />
        <Route
          path="/profile"
          element={
            <Protected>
              <Profile />
            </Protected>
          }
        />
        <Route
          path="/brands"
          element={
            <Protected>
              <Brands />
            </Protected>
          }
        />
        <Route
          path="/dashboard"
          element={
            <Protected>
              <DashboardPage />
            </Protected>
          }
        />
        <Route
          path="/dashboard/actions"
          element={
            <Protected>
              <ActionsPage />
            </Protected>
          }
        />
        <Route
          path="/dashboard/products"
          element={
            <Protected>
              <ProductsPage />
            </Protected>
          }
        />
        <Route
          path="/dashboard/vendors"
          element={
            <Protected>
              <VendorsPage />
            </Protected>
          }
        />
        <Route path="/signin" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/brand" element={<Brand />} />
        <Route path="/brand" element={<Brand />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
