import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { type IContinueWithSocialMediaResponse } from '../../common/types';
import { PageLoading } from '../../components/blocks';
import { useGetCurrentUser } from '../../hooks/api/user';
import useToken from '../../hooks/useToken';
import useSetUser from '../../hooks/useSetUser';
import { LoginLayout } from './components/LoginLayout';
import { getPathURL, saveToken } from '../../common/utils';
import { client } from '../../common/axios';
import { type AxiosResponse } from 'axios';
import { usePostSubscriptionMutation } from '../../core/api/user';

export const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const token = useToken();
  const setUser = useSetUser();
  const [user, error, userIsLoading] = useGetCurrentUser([token]);

  const CODE_TOKEN_ENDPOINT = '/api/login/social/token/';
  const urlParams = new URLSearchParams(location.search);
  const authorizationCode = urlParams.get('code');

  const [updateUserSubscription] = usePostSubscriptionMutation();

  useLayoutEffect(() => {
    const hasTempToken = localStorage.getItem('hasTempToken');
    if (!userIsLoading && user && !hasTempToken) {
      localStorage.setItem('isFirstLaunch', 'true');
      navigate('/dashboard');
    }

    if (error) {
      console.log(error);
    }
  }, [user]);

  const preLogin = () => {
    setIsLoading(true);
  };

  const handleSubscriptionTrial = async () => {
    const payload = {
      plan: 1,
    };
    try {
      await updateUserSubscription({
        data: payload,
      }).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  const postLogin = (response: AxiosResponse<IContinueWithSocialMediaResponse> | null) => {
    setIsLoading(false);

    if (response?.data?.token) {
      setUser(response.data);
      saveToken({ token: response.data.token });
      if (!response.data.subscription) {
        handleSubscriptionTrial();
      }
      localStorage.removeItem('hasTempToken');
      const isOnboardingPage = localStorage.getItem('isOnboarding') === 'true';
      if (isOnboardingPage) {
        navigate('/dashboard?onboarding=true');
      } else {
        navigate('/dashboard');
      }
    } else {
      const path = getPathURL();
      localStorage.removeItem('path');
      if (path) {
        navigate(path);
      } else {
        navigate('/dashboard');
      }
    }
  };

  useEffect(() => {
    if (authorizationCode) {
      client
        .post(CODE_TOKEN_ENDPOINT, {
          provider: 'microsoft',
          code: authorizationCode,
          token: localStorage.getItem('token') ?? '',
        })
        .then((response: AxiosResponse<IContinueWithSocialMediaResponse>) => {
          postLogin(response);
        })
        .catch(reason => {
          console.warn('Could not authenticate via Microsoft:', reason);
        });
    }
  }, []);

  return (
    <Box>
      {isLoading || userIsLoading || authorizationCode ? (
        <PageLoading />
      ) : (
        <LoginLayout preLogin={preLogin} postLogin={postLogin} />
      )}
    </Box>
  );
};
