import React, { lazy } from 'react';

import DashboardLayout from '../../components/Dashboard/DashboardLayout';
import ScrappedImages from '../../components/Dashboard/Overview/ScrappedImages';
import ImagesByScore from '../../components/Dashboard/Overview/ImagesByScore';
import TopVendors from '../../components/Dashboard/Overview/TopVendors';
import { LazyLoad } from '../../components/Common/LazyLoad';
import ActionsBarPlaceholder from '../../components/Common/Placeholders/ActionsBarPlaceholder';
import TopIssuesPlaceholder from '../../components/Common/Placeholders/TopIssuesPlaceholder';
import ImagesSectionPlaceholder from '../../components/Common/Placeholders/ImagesSectionPlaceholder';
import AverageScorePlaceholder from '../../components/Dashboard/Placeholders/AverageScodePlaceholder';

import useOnboardingSearchParam from '../../hooks/useOnboardingSearchParam';
import Stack from '@mui/material/Stack';

const LazyTopIssues = lazy(async () => {
  const module = await import('../../components/Dashboard/Overview/TopIssues');
  return { default: module.default };
});

const LazyActions = lazy(async () => {
  const module = await import('../../components/Dashboard/Overview/Actions');
  return { default: module.default };
});

const LazyImages = lazy(async () => {
  const module = await import('../../components/Dashboard/Overview/Images');
  return { default: module.default };
});

function OnboardingPage() {
  const { isOnboarding, brandId, loadingOnboarding } = useOnboardingSearchParam();

  return (
    <DashboardLayout
      title="Onboarding Dashboard"
      description="Overview results of image audit over all vendors sites"
    >
      <Stack gap={'16px'}>
        <Stack direction={'row'} gap={'16px'} alignItems={'self-start'}>
          <AverageScorePlaceholder isOnboarding={isOnboarding} loading={loadingOnboarding} />

          <Stack gap={'16px'} width={'100%'}>
            <ScrappedImages />

            <ImagesByScore scoreDistribution={null} totalImages={0} />
          </Stack>
        </Stack>
        <Stack direction={'row'} gap={'16px'} alignItems={{ md: 'self-start' }}>
          <LazyLoad fallback={<TopIssuesPlaceholder />}>
            <LazyTopIssues />
          </LazyLoad>
          <TopVendors key={brandId} resellers={[]} />
        </Stack>
        <LazyLoad fallback={<ActionsBarPlaceholder />}>
          <LazyActions />
        </LazyLoad>
        <LazyLoad fallback={<ImagesSectionPlaceholder />}>
          <LazyImages />
        </LazyLoad>
      </Stack>
    </DashboardLayout>
  );
}

export default OnboardingPage;
