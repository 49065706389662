import React from 'react';

interface IIconProps {
  color?: string;
}

export const BrandStarIcon: React.FC<IIconProps> = ({ color = '#FFF' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 0.25C10.5217 5.40327 14.5967 9.47834 19.75 10C14.5967 10.5217 10.5217 14.5967 10 19.75C9.47834 14.5967 5.40327 10.5217 0.25 10C5.40327 9.47834 9.47834 5.40327 10 0.25Z"
      fill={color}
    />
  </svg>
);
