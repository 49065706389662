import React, { useEffect, useState } from 'react';
import { Box, Card, Chip, Stack, Typography } from '@mui/material';
import { roundGrade } from '../../common/helpers';
import { getColorAndLabel } from '../Dashboard/Helpers';
import { type IVendor, type ProductImageInfo } from '../../pages/Dashboard/Products/Types';
import { DotsIcon, ProductsInfo, ProductsAction } from '../../assets/icons';
import { theme } from '../../theme';
import DefaultResellerLogo from '../Common/DefaultResellerLogo';

interface Props {
  product: ProductImageInfo;
  currentVendor: IVendor | null;
  tempVendors: IVendor[];
  diagnostics: Array<{
    name: string;
    image_url: string;
    icon_url: string;
    description: string;
    images_count: number;
  }>;
}

function ProductCard({ product, currentVendor, tempVendors, diagnostics }: Props) {
  const [vendor, setVendor] = useState<IVendor | null>(null);
  const [isShowAllDiagnostics, setIsShowAllDiagnostics] = useState(false);

  useEffect(() => {
    const tempVendor = tempVendors.find(vendor => vendor.id === currentVendor?.id);

    if (tempVendor) {
      setVendor(tempVendor);
    }
  }, [currentVendor]);

  return (
    <Card
      sx={{
        borderRadius: '16px',
        overflow: 'hidden',
        boxShadow: 'none',
        width: '100%',
      }}
    >
      <Box display="flex" flexDirection="column" justifyContent="space-between" height="500px">
        <Box display="flex" alignItems="center" justifyContent="space-between" p="12px">
          <Box display="flex" alignItems="center">
            <Stack
              justifyContent={'center'}
              alignItems={'center'}
              component={'span'}
              sx={{
                height: 24,
                width: 24,
                bgcolor: getColorAndLabel(roundGrade(product.score)).color,
                borderRadius: '50%',
                zIndex: 1,
                fontSize: 12,
                fontWeight: 500,
                color: '#FFFFFF',
                mr: '10px',
              }}
            >
              {product.score}
            </Stack>
            {product?.reseller_logo_s3_url ? (
              <img
                src={product?.reseller_logo_s3_url}
                alt="vendor"
                style={{ height: 24, width: 24, borderRadius: '100%' }}
              />
            ) : (
              <DefaultResellerLogo />
            )}

            <Typography fontSize="14px" fontWeight="400" ml="5px" mr="20px">
              {vendor?.name}
            </Typography>
            <ProductsAction />
            <Typography
              fontSize="12px"
              fontWeight="400"
              ml="5px"
              color={theme.palette.text.secondary}
            >
              No action needed
            </Typography>
          </Box>

          <Box
            display="flex"
            sx={{ transform: 'rotate(90deg)', cursor: 'pointer' }}
            onClick={() => {
              console.log('Dots clicked');
            }}
          >
            <DotsIcon />
          </Box>
        </Box>
        <Box
          display="flex"
          sx={{
            height: 300,
            position: 'relative',
            background: `url(${product.image_s3_url}) no-repeat center/contain`,
          }}
        />
        <Box display="flex" alignItems="center" justifyContent="space-between" p="12px" width="97%">
          <Box display="flex" gap={'10px'} flexWrap={'wrap'}>
            {product.diagnostics?.map((diagnostic, index) => {
              const icon = diagnostics?.find(d => d.name === diagnostic)?.icon_url;

              if (isShowAllDiagnostics) {
                return (
                  <Chip
                    key={index}
                    label={diagnostic}
                    size="small"
                    sx={{ bgcolor: 'white', border: '1px solid #F3F3F7', px: '5px' }}
                    icon={<img src={icon} style={{ height: 16, width: 16 }} />}
                  />
                );
              } else if (!isShowAllDiagnostics && index < 3) {
                return (
                  <Chip
                    key={index}
                    label={diagnostic}
                    size="small"
                    sx={{ bgcolor: 'white', border: '1px solid #F3F3F7', px: '5px' }}
                    icon={<img src={icon} style={{ height: 16, width: 16 }} />}
                  />
                );
              } else if (!isShowAllDiagnostics && index === 4) {
                return (
                  <Chip
                    key={index}
                    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                    label={'+' + (product.diagnostics.length - 3)}
                    size="small"
                    sx={{ bgcolor: 'white', border: '1px solid #F3F3F7', px: '5px' }}
                    onClick={() => {
                      setIsShowAllDiagnostics(true);
                    }}
                  />
                );
              } else {
                return null;
              }
            })}
          </Box>
          <Box display="flex">
            <ProductsInfo />
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

export default ProductCard;
