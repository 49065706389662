import React, { useEffect, useRef, useState } from 'react';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DashboardLayout from '../../../components/Dashboard/DashboardLayout';
import SearchHeader from '../../../components/Common/SearchHeader';
import Filter from '../../../components/Common/Filter';
import ProductCard from '../../../components/Products/ProductCard';
import { ProductView } from '../../../components/Products/ProductView';
import type { IProductImage } from '../../../core/api/types/Product';
import ProductCardPlaceholder from '../../../components/Common/Placeholders/ProductCardPlaceholder';
import { useFilteredItems } from '../../../hooks/useFiltredItems';
import InfiniteScrollContainer from '../../../components/Common/InfiniteScrollContainer';
import Box from '@mui/material/Box';
import { useAppSelector } from '../../../hooks/useStore';
import { useSubscriptionStatus } from '../../../hooks/useSubscriptionStatus';
import UpgradePlanModal from '../../../components/Dashboard/Landing/UpgradePlanModal';

const CardsPlaceholder = () => (
  <Grid container spacing={2}>
    {Array.from({ length: 9 }).map((_, i) => (
      <Grid key={i} item xs={12} sm={6} md={4} lg={4}>
        <ProductCardPlaceholder />
      </Grid>
    ))}
  </Grid>
);

function ProductsPage() {
  const { freeTrial } = useSubscriptionStatus();

  const [productOverview, setProductOverview] = useState<IProductImage | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isAtTop, setIsAtTop] = useState(false);
  const [upgradeModal, setUpgradeModal] = useState(false);

  const { view, gridColumns } = useAppSelector(state => state.filter);

  const {
    items: products,
    hasMore,
    handleNextPage,
    isLoading,
    isFetching,
    fetchingType,
  } = useFilteredItems('products');

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        setIsAtTop(containerRef.current.getBoundingClientRect().top <= 0);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleProductClick = (product: IProductImage, index: number) => {
    if (freeTrial && index >= 3) {
      setUpgradeModal(true);
    } else {
      setProductOverview(product);
    }
  };

  const isFilterFetching = isFetching && fetchingType === 'filter';

  return (
    <>
      {productOverview ? (
        <ProductView product={productOverview} setProductOverview={setProductOverview} />
      ) : (
        <DashboardLayout
          title={'Products'}
          description={'verview results of image audit over all vendors sites'}
        >
          <Stack
            ref={containerRef}
            direction={'row'}
            alignItems={'flex-start'}
            gap={'16px'}
            sx={{
              maxHeight: isAtTop ? '100svh' : 'fit-content',
              overflowY: 'auto',
              '::-webkit-scrollbar': { display: 'none' },
            }}
          >
            <Stack flex={1} gap={'8px'}>
              <Box
                sx={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 999,
                  bgcolor: '#F8F8FB',
                }}
              >
                <SearchHeader maxGridColumn={3} />
              </Box>
              <Stack
                justifyContent={'center'}
                p={view === 'grid' ? '16px 42px 16px 32px' : '0px 8px'}
              >
                {isLoading ? (
                  <CardsPlaceholder />
                ) : products && products?.length > 0 ? (
                  <InfiniteScrollContainer
                    isLoading={isLoading || isFilterFetching}
                    isFetching={isFetching && !isFilterFetching}
                    hasMore={hasMore}
                    onLoadMore={handleNextPage}
                    placeholder={<CardsPlaceholder />}
                    scrollRef={containerRef}
                  >
                    {view === 'grid' && gridColumns ? (
                      <Grid container spacing={1}>
                        {products.map((product, index) => {
                          if (product.type === 'product') {
                            return (
                              <Grid
                                key={product.id}
                                item
                                xs={12}
                                sm={6}
                                md={12 / Math.min(gridColumns, 3)}
                                lg={12 / Math.min(gridColumns, 3)}
                              >
                                <ProductCard
                                  product={product}
                                  onClick={() => {
                                    handleProductClick(product, index);
                                  }}
                                  variant="grid"
                                  sx={{
                                    filter: freeTrial && index >= 3 ? 'blur(5px)' : 'none',
                                  }}
                                />
                              </Grid>
                            );
                          }
                          return null;
                        })}
                      </Grid>
                    ) : (
                      <Box>
                        <Grid container sx={{ p: '8px 16px' }}>
                          <Grid item xs={1}>
                            <Typography
                              color={'text.secondary'}
                              fontSize={14}
                              fontWeight={400}
                              lineHeight={'20px'}
                              letterSpacing={'-0.07px'}
                            >
                              Score
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography
                              color={'text.secondary'}
                              fontSize={14}
                              fontWeight={400}
                              lineHeight={'20px'}
                              letterSpacing={'-0.07px'}
                            >
                              Image
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              color={'text.secondary'}
                              fontSize={14}
                              fontWeight={400}
                              lineHeight={'20px'}
                              letterSpacing={'-0.07px'}
                            >
                              Name
                            </Typography>
                          </Grid>
                        </Grid>
                        <Stack gap={'8px'} pt={'16px'}>
                          {products.map((product, index) => {
                            if (product.type === 'product') {
                              return (
                                <ProductCard
                                  key={product.id}
                                  product={product}
                                  onClick={() => {
                                    handleProductClick(product, index);
                                  }}
                                  variant="list"
                                  sx={{
                                    filter: freeTrial && index >= 3 ? 'blur(5px)' : 'none',
                                  }}
                                />
                              );
                            }
                            return null;
                          })}
                        </Stack>
                      </Box>
                    )}
                  </InfiniteScrollContainer>
                ) : (
                  <Typography
                    fontSize={14}
                    fontWeight={400}
                    fontFamily={'Poppins'}
                    color={'#42485D99'}
                    m={'32px auto'}
                  >
                    No product found
                  </Typography>
                )}
              </Stack>
            </Stack>
            <Box
              sx={{
                position: 'sticky',
                top: 0,
                maxHeight: '100svh',
                overflowY: 'auto',
                '::-webkit-scrollbar': { display: 'none' },
              }}
            >
              <Filter type="products" />
            </Box>
          </Stack>
        </DashboardLayout>
      )}
      <UpgradePlanModal
        open={upgradeModal}
        handleClose={() => {
          setUpgradeModal(false);
        }}
      />
    </>
  );
}

export default ProductsPage;
