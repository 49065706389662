import React from 'react';

export const ProductsUpload: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.00005 8.39065L10.8287 11.2186L9.88538 12.162L8.66672 10.9433V14.6666H7.33338V10.942L6.11472 12.162L5.17138 11.2186L8.00005 8.39065ZM8.00005 1.33331C9.14471 1.33337 10.2494 1.75406 11.1042 2.51539C11.9589 3.27673 12.5041 4.32562 12.6361 5.46265C13.4656 5.68886 14.1892 6.19938 14.6805 6.905C15.1718 7.61061 15.3995 8.46644 15.3238 9.32291C15.2481 10.1794 14.8738 10.982 14.2664 11.5905C13.659 12.199 12.857 12.5747 12.0007 12.652V11.3093C12.3075 11.2655 12.6025 11.161 12.8684 11.002C13.1344 10.8429 13.366 10.6325 13.5498 10.3829C13.7335 10.1334 13.8657 9.84973 13.9386 9.54854C14.0116 9.24735 14.0238 8.93465 13.9745 8.62869C13.9253 8.32273 13.8156 8.02965 13.6519 7.76655C13.4881 7.50344 13.2736 7.27559 13.0209 7.09629C12.7681 6.91699 12.4822 6.78984 12.1797 6.72225C11.8773 6.65467 11.5644 6.648 11.2594 6.70265C11.3638 6.21657 11.3581 5.71329 11.2429 5.22968C11.1276 4.74607 10.9056 4.29437 10.5931 3.90767C10.2807 3.52096 9.88565 3.20905 9.43703 2.99478C8.98841 2.7805 8.49755 2.66929 8.00038 2.66929C7.50322 2.66929 7.01235 2.7805 6.56374 2.99478C6.11512 3.20905 5.72011 3.52096 5.40765 3.90767C5.09519 4.29437 4.87319 4.74607 4.75791 5.22968C4.64262 5.71329 4.63698 6.21657 4.74138 6.70265C4.13316 6.58843 3.50446 6.7205 2.99361 7.06982C2.48276 7.41914 2.1316 7.95708 2.01738 8.56531C1.90316 9.17354 2.03524 9.80223 2.38456 10.3131C2.73388 10.8239 3.27182 11.1751 3.88005 11.2893L4.00005 11.3093V12.652C3.14368 12.5749 2.34166 12.1992 1.73414 11.5908C1.12663 10.9823 0.752269 10.1797 0.676501 9.3232C0.600732 8.46671 0.828371 7.61084 1.31963 6.90516C1.81089 6.19949 2.53452 5.6889 3.36405 5.46265C3.49586 4.32556 4.04099 3.27659 4.89577 2.51522C5.75055 1.75386 6.85536 1.33322 8.00005 1.33331Z"
      fill="#42485D"
      fillOpacity="0.6"
    />
  </svg>
);
