import React from 'react';
import Avatar from '@mui/material/Avatar';
import { useAppSelector } from '../../hooks/useStore';

interface IProps {
  name: string;
  sx: Record<string, number | string>;
}
const ColoredAvatar = ({ name, sx }: IProps) => {
  const { vendorsWithColor } = useAppSelector(state => state.dashboard);
  return (
    <Avatar
      sx={{
        bgcolor: vendorsWithColor[name]?.color,
        ...sx,
      }}
    >
      {name.charAt(0).toUpperCase()}
    </Avatar>
  );
};

export default ColoredAvatar;
