import React from 'react';

interface IIconProps {
  color: string;
}

export const SupportIcon: React.FC<IIconProps> = ({ color }) => (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.9429 17.3928C19.1241 16.8777 19.4818 16.4432 19.9526 16.1665C20.4235 15.8898 20.9771 15.7886 21.5153 15.881C22.0536 15.9733 22.5418 16.2532 22.8935 16.671C23.2452 17.0888 23.4377 17.6176 23.4369 18.1637C23.4369 19.7054 21.1244 20.4763 21.1244 20.4763M21.1542 23.562H21.1645M18.84 28.3963L20.3417 30.3985C20.565 30.6963 20.6767 30.8452 20.8136 30.8984C20.9335 30.9451 21.0665 30.9451 21.1864 30.8984C21.3233 30.8452 21.435 30.6963 21.6583 30.3985L23.16 28.3963C23.4615 27.9942 23.6123 27.7932 23.7962 27.6397C24.0413 27.4351 24.3308 27.2904 24.6416 27.217C24.8747 27.162 25.126 27.162 25.6285 27.162C27.0663 27.162 27.7852 27.162 28.3522 26.9271C29.1083 26.6139 29.709 26.0132 30.0222 25.2571C30.2571 24.69 30.2571 23.9712 30.2571 22.5334V16.6705C30.2571 14.9424 30.2571 14.0783 29.9208 13.4182C29.6249 12.8376 29.1529 12.3656 28.5723 12.0697C27.9122 11.7334 27.0481 11.7334 25.32 11.7334H16.68C14.9519 11.7334 14.0878 11.7334 13.4277 12.0697C12.8471 12.3656 12.3751 12.8376 12.0792 13.4182C11.7429 14.0783 11.7429 14.9424 11.7429 16.6705V22.5334C11.7429 23.9712 11.7429 24.69 11.9778 25.2571C12.291 26.0132 12.8917 26.6139 13.6478 26.9271C14.2148 27.162 14.9337 27.162 16.3715 27.162C16.874 27.162 17.1253 27.162 17.3584 27.217C17.6692 27.2904 17.9587 27.4351 18.2038 27.6397C18.3877 27.7932 18.5385 27.9942 18.84 28.3963Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
