import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Prompts from './Prompts';
import Questions from './Questions';
import Finish from './Finish';
import { useGetCurrentUser } from '../../hooks/api/user';
import { PageLoading } from '../../components/blocks';
import { useQuestions } from '../../hooks/api/questions';

export const SignUp = () => {
  const [step, setStep] = useState(1);
  const [isLoading] = useGetCurrentUser();
  const [questions] = useQuestions();

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      event.returnValue = 'Etes-vous sur de vouloir quitter la page ?';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <PageLoading />
      ) : (
        <Box>
          {step === 1 && <Prompts setStep={setStep} />}
          {step === 2 && (
            <Questions
              questions={questions}
              skip={() => {
                setStep(3);
              }}
            />
          )}
          {step === 3 && <Finish />}
        </Box>
      )}
    </>
  );
};
