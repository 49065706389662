import Box from '@mui/material/Box';
import React from 'react';

const SegmentedProgressBar = ({ progress }: { progress: number }) => {
  const segments = [
    { color: progress >= 25 ? '#7757C6' : 'lightgray' },
    { color: progress >= 50 ? '#7757C6' : 'lightgray' },
    { color: progress >= 75 ? '#7757C6' : 'lightgray' },
    { color: progress >= 100 ? '#7757C6' : 'lightgray' },
  ];

  return (
    <Box display="flex" width="35%">
      {segments.map((segment, index) => (
        <Box
          key={index}
          flex={1}
          sx={{
            backgroundColor: segment.color,
            display: 'flex',
            width: '100px',
            height: '4px',
            padding: '0px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '0px',
            borderRadius: '360px',
            '&:not(:last-child)': {
              marginRight: 1,
            },
            transition: 'background-color 0.5s ease',
          }}
        />
      ))}
    </Box>
  );
};

export default SegmentedProgressBar;
