import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { type IImagesArray, Steps } from '../types';
import { BrandSearchIcon } from '../../../assets/icons';
import Lightbox from 'yet-another-react-lightbox';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';

import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'yet-another-react-lightbox/styles.css';
import { Header } from './Header';
import { RefineSearch } from './RefineSearch';
import { RadarLoader } from './RadarLoader';
import { theme } from '../../../theme';
import { searchImages } from '../../../repositories/brands.service';

interface IIdentifying {
  setBrand: (value: string) => void;
  setStep: (value: number) => void;
  requestQuery: string;
  setSelectedChips: (value: string[]) => void;
  setChips: (value: string[]) => void;
  setBrandImages: (value: IImagesArray[]) => void;
  brandImages: IImagesArray[];
  setRequestQuery: (value: string) => void;
}

export const Identifying = ({
  setBrand,
  setStep,
  requestQuery,
  setSelectedChips,
  setChips,
  setBrandImages,
  brandImages,
  setRequestQuery,
}: IIdentifying) => {
  const [loading, setLoading] = useState(true);
  const [isRefineSearch, setIsRefineSearch] = useState(false);
  const [isViewAllImages, setIsViewAllImages] = useState(false);
  const [isOpenViewer, setIsOpenViewer] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    const handleSearchImages = async () => {
      try {
        const response = await searchImages(requestQuery);

        const images: IImagesArray[] = await Promise.all(
          response.images?.map(async (image: IImagesArray) => {
            image.src = image.url;

            try {
              const img = new Image();
              img.src = image.url;
              await new Promise((resolve, reject) => {
                img.onload = resolve;
                img.onerror = reject;
              });
              return image;
            } catch (error) {
              console.error(`Error loading image: ${image.url}`, error);
              return null;
            }
          })
        );

        setBrandImages(images.filter(image => image !== null));
      } catch (e) {
        console.log('Error searching images request: ', e);
        setStep(Steps.addBrand);
      } finally {
        setLoading(false);
      }
    };
    handleSearchImages();
  }, [requestQuery]);

  const Gallery = () => {
    const handleClickImage = (index: number) => {
      setImageIndex(index);
      setIsOpenViewer(true);
    };

    return (
      <Grid container spacing={2} mt="10px" mb="10px">
        {brandImages?.map((image, index) => {
          return isViewAllImages ? (
            <Grid
              item
              xs={3}
              key={image.src}
              onClick={() => {
                handleClickImage(index);
              }}
            >
              <Paper
                sx={{
                  borderRadius: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Box
                  component="img"
                  alt={requestQuery}
                  src={image.src}
                  sx={{
                    width: '100%',
                    height: '120px',
                    objectFit: 'contain',
                    borderRadius: '10px',
                  }}
                />
              </Paper>
            </Grid>
          ) : (
            index < 8 && (
              <Grid
                item
                xs={3}
                key={image.src}
                onClick={() => {
                  handleClickImage(index);
                }}
              >
                <Paper
                  sx={{
                    borderRadius: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    component="img"
                    alt={requestQuery}
                    src={image.src}
                    sx={{
                      width: '100%',
                      height: '120px',
                      objectFit: 'contain',
                      borderRadius: '10px',
                    }}
                  />
                </Paper>
              </Grid>
            )
          );
        })}
      </Grid>
    );
  };

  return (
    <Box paddingX="100px" paddingY="50px" display="flex" flexDirection="column" width="100%">
      {loading ? (
        <Box
          display="flex"
          height="70vh"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <RadarLoader />

          <Typography
            fontFamily="Poppins"
            fontSize={24}
            color={theme.palette.text.primary}
            fontWeight={400}
            mt="80px"
          >
            Searching...
          </Typography>
          <Typography
            fontFamily="Poppins"
            fontSize={16}
            color={theme.palette.text.secondary}
            fontWeight={400}
            mt="20px"
          >
            Scanning the web for your products images
          </Typography>
          <Box
            display="flex"
            bgcolor="#ece1fe"
            alignItems="center"
            borderRadius="50px"
            paddingX="20px"
            mt="10px"
          >
            <BrandSearchIcon active />
            <Typography
              fontFamily="Poppins"
              fontSize={14}
              color="#6439AF"
              fontWeight={400}
              m="10px"
              maxWidth="400px"
              noWrap
            >
              {requestQuery}
            </Typography>
          </Box>
        </Box>
      ) : isRefineSearch ? (
        <RefineSearch
          requestQuery={requestQuery}
          setBrand={setBrand}
          setIsRefineSearch={setIsRefineSearch}
          setSelectedChips={setSelectedChips}
          setRequestQuery={setRequestQuery}
          setLoading={setLoading}
        />
      ) : (
        <>
          <Box display="flex" flexDirection="column" mt="50px">
            <Header
              title="Are these images of your products?"
              description="Relevant images of your product will allow us to better calibrate the AI to your preferences"
              center
            />
            <Box
              display="flex"
              borderRadius="20px"
              alignItems="center"
              paddingX="10px"
              sx={{
                bgcolor: '#ece1fe',
                height: '40px',
                border: 'none',
                alignSelf: 'center',
                mt: '20px',
              }}
            >
              <BrandSearchIcon active />
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                color="#6439AF"
                fontWeight={400}
                m="10px"
                maxWidth="400px"
                noWrap
              >
                {requestQuery}
              </Typography>
            </Box>

            <Gallery />

            {!isViewAllImages && brandImages.length > 8 && (
              <Button
                variant="text"
                sx={{ mr: '20px', width: '150px', mt: '10px', alignSelf: 'center', color: 'black' }}
                onClick={() => {
                  setIsViewAllImages(true);
                }}
              >
                Show all {brandImages.length} images
              </Button>
            )}
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="space-between" mt="40px">
            <Button
              variant="outlined"
              onClick={() => {
                setStep(Steps.addBrand);
                setChips([]);
                setSelectedChips([]);
                setBrand('');
              }}
            >
              <Typography color="#352E65">Previous step</Typography>
            </Button>
            <Box>
              <Button
                variant="outlined"
                sx={{ mr: '20px', color: '#352E65' }}
                onClick={() => {
                  setIsRefineSearch(true);
                }}
                endIcon={<BrandSearchIcon active={false} />}
              >
                Refine search
              </Button>
              <Button
                variant="contained"
                sx={{ bgcolor: '#28205B' }}
                onClick={() => {
                  setStep(Steps.evaluation);
                  setLoading(false);
                }}
              >
                Yes, continue
              </Button>
            </Box>
          </Box>
        </>
      )}
      <Lightbox
        open={isOpenViewer}
        close={() => {
          setIsOpenViewer(false);
        }}
        slides={brandImages}
        plugins={[Thumbnails]}
        thumbnails={{
          border: 0,
        }}
        index={imageIndex}
      />
    </Box>
  );
};
