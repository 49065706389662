import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { ProductFilters, ViewType } from '../api/types/Product';

export const initialState: ProductFilters = {
  title: undefined,
  reseller: undefined,
  diagnostics: [],
  ordering: undefined,
  score_gte: undefined,
  score_lte: undefined,
  view: 'grid',
  gridColumns: 4,
};

const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilterTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setFilterReseller: (state, action: PayloadAction<string | undefined>) => {
      state.reseller = action.payload;
    },
    setFilterDiagnostics: (state, action: PayloadAction<string[]>) => {
      state.diagnostics = action.payload;
    },
    setFilterOrdering: (state, action: PayloadAction<string | undefined>) => {
      state.ordering = action.payload;
    },
    setFilterScoreGte: (state, action: PayloadAction<number | undefined>) => {
      state.score_gte = action.payload;
    },
    setFilterScoreLte: (state, action: PayloadAction<number | undefined>) => {
      state.score_lte = action.payload;
    },
    setView: (state, action: PayloadAction<ViewType>) => {
      state.view = action.payload;
    },
    setGridColumns: (state, action: PayloadAction<number>) => {
      state.gridColumns = action.payload;
    },

    resetFilters: state => {
      state.reseller = initialState.reseller;
      state.diagnostics = initialState.diagnostics;
      state.score_gte = initialState.score_gte;
      state.score_lte = initialState.score_lte;
    },
    resetAllFilters: state => {
      state.title = initialState.title;
      state.ordering = initialState.ordering;
      state.limit = initialState.limit;
      state.offset = initialState.offset;
      state.reseller = initialState.reseller;
      state.diagnostics = initialState.diagnostics;
      state.score_gte = initialState.score_gte;
      state.score_lte = initialState.score_lte;
    },
  },
});

export const {
  setFilterTitle,
  setFilterReseller,
  setFilterDiagnostics,
  setFilterOrdering,
  setFilterScoreGte,
  setFilterScoreLte,
  resetFilters,
  resetAllFilters,
  setView,
  setGridColumns,
} = filterSlice.actions;

export default filterSlice.reducer;
