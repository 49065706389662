import React, { useState } from 'react';
import { Search } from '@mui/icons-material';
import {
  Box,
  FormControl,
  InputAdornment,
  Menu,
  MenuItem,
  type MenuProps,
  TextField,
  Typography,
  alpha,
  styled,
} from '@mui/material';

import AssetItem from './AssetItem';
import {
  AssetsList,
  AssetsGrid,
  AssetsSortAbcAsc,
  AssetsSortAbcDesc,
  AssetsSortTimeAsc,
  AssetsSortTimeDesc,
} from '../../../../../assets/icons';
import UploadAsset from '../../../../../components/UploadAsset';
import { type IAsset } from '../../../types';

interface IAssetTab {
  assets: IAsset[];
  setSearch: (value: string) => void;
  setSelectedAsset: (value: IAsset | null) => void;
  selectedAsset: IAsset | null;
  handleOpenModal: () => void;
  filterAssetsByNameAsc: () => void;
  filterAssetsByNameDesc: () => void;
  filterAssetsByDateAsc: () => void;
  filterAssetsByDateDesc: () => void;
  sortType: number;
  updateAssets: () => void;
  setIsEditAsset: (value: boolean) => void;
  setSelectedId: (value: number) => void;
  selectedId: number;
  setAssetName: (value: string) => void;
  isAssetsLoading: boolean;
}

const AssetsTab = ({
  assets,
  setSearch,
  setSelectedAsset,
  selectedAsset,
  handleOpenModal,
  filterAssetsByNameAsc,
  filterAssetsByNameDesc,
  filterAssetsByDateAsc,
  filterAssetsByDateDesc,
  sortType,
  updateAssets,
  setIsEditAsset,
  setSelectedId,
  selectedId,
  setAssetName,
  isAssetsLoading,
}: IAssetTab) => {
  const [listView, setListView] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [hoveredAsset, setHoveredAsset] = useState(0);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const assetTabs = [
    {
      id: 0,
      name: 'Alphabetical A-Z',
      onClick: () => {
        handleClose();
        filterAssetsByNameAsc();
      },
      icon: (color: string) => <AssetsSortAbcAsc color={color} />,
    },
    {
      id: 1,
      name: 'Alphabetical Z-A',
      onClick: () => {
        handleClose();
        filterAssetsByNameDesc();
      },
      icon: (color: string) => <AssetsSortAbcDesc color={color} />,
    },
    {
      id: 2,
      name: 'Creation time ascending',
      onClick: () => {
        handleClose();
        filterAssetsByDateAsc();
      },
      icon: (color: string) => <AssetsSortTimeAsc color={color} />,
    },
    {
      id: 3,
      name: 'Creation time descending',
      onClick: () => {
        handleClose();
        filterAssetsByDateDesc();
      },
      icon: (color: string) => <AssetsSortTimeDesc color={color} />,
    },
  ];

  const SortItems = () => {
    return assetTabs.map(asset => {
      const color = sortType === asset.id ? '#FFF' : '#1B1B1E';
      const bgcolor = sortType === asset.id ? '#5930A5' : '#FFF';
      return (
        <MenuItem
          key={asset.id}
          onClick={asset.onClick}
          sx={{
            bgcolor: sortType === asset.id ? '#5930A5' : '',
            '&: hover': { bgcolor },
          }}
        >
          {asset.icon(color)}
          <Typography
            sx={{
              ml: '10px',
              fontFamily: 'Poppins',
              fontWeight: 400,
              fontSize: 16,
              color: sortType === asset.id ? '#FFF' : '',
            }}
          >
            {asset.name}
          </Typography>
        </MenuItem>
      );
    });
  };

  return (
    <>
      <Typography
        display="flex"
        alignItems="center"
        fontSize={26}
        color="#313234"
        fontFamily={'Poppins'}
        fontWeight={500}
        paddingX="20px"
        paddingY="20px"
      >
        Assets
      </Typography>
      <Box paddingX="20px">
        <Typography fontSize={16} color="#313234" fontFamily={'Poppins'} fontWeight={500}>
          Add New Product
        </Typography>
        <UploadAsset type="button" handleOpenModal={handleOpenModal} />
        <Box display="flex" justifyContent="space-between" mt="15px" alignItems="center">
          <Typography fontSize={16} color="#313234" fontFamily={'Poppins'} fontWeight={500}>
            Asset Library
          </Typography>
          <Box display="flex" alignItems="center">
            <Box
              onClick={() => {
                setListView(true);
              }}
              sx={{ cursor: 'pointer' }}
            >
              <AssetsList active={listView} />
            </Box>
            <Box
              onClick={() => {
                setListView(false);
              }}
              sx={{ cursor: 'pointer' }}
            >
              <AssetsGrid active={!listView} />
            </Box>
            <Box onClick={handleClick} sx={{ cursor: 'pointer', ml: '5px' }}>
              {sortType === 0 && <AssetsSortAbcAsc />}
              {sortType === 1 && <AssetsSortAbcDesc />}
              {sortType === 2 && <AssetsSortTimeAsc />}
              {sortType === 3 && <AssetsSortTimeDesc />}
            </Box>
            <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
              <SortItems />
            </StyledMenu>
          </Box>
        </Box>

        <FormControl sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            placeholder={'Search'}
            sx={{
              mt: '10px',
              border: '1px solid #EFEFF0',
              borderRadius: '8px',
              '& fieldset': {
                border: 0,
                borderRadius: '8px',
              },
              '& input': {
                py: '14px',
                fontSize: '14px',
                color: 'grey',
              },
              '& svg': {
                color: 'grey',
              },
            }}
            onChange={e => {
              setSearch(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>

        <Box
          mt="15px"
          overflow="scroll"
          height="calc(100vh - 370px)"
          sx={{
            '&::-webkit-scrollbar': { display: 'none' },
          }}
        >
          <AssetItem
            assets={assets}
            setSelectedAsset={setSelectedAsset}
            selectedAsset={selectedAsset}
            listView={listView}
            hoveredAsset={hoveredAsset}
            setHoveredAsset={setHoveredAsset}
            updateAssets={updateAssets}
            setIsEditAsset={setIsEditAsset}
            setSelectedId={setSelectedId}
            selectedId={selectedId}
            setAssetName={setAssetName}
            isAssetsLoading={isAssetsLoading}
          />
        </Box>
      </Box>
    </>
  );
};

export default AssetsTab;

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 5,
    padding: '5px',
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '1px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: '#81889E',
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha('#5A58CB', theme.palette.action.selectedOpacity),
      },
    },
  },
}));
