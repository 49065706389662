import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import VendorCard from './VendorCard'; // Adjust the import according to your project structure
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { type IVendor } from '../../pages/Dashboard/Products/Types';

interface IProps {
  tempVendors: IVendor[];
  setCurrentVendor: React.Dispatch<React.SetStateAction<IVendor | null>>;
  currentVendor: IVendor | null;
  setIsMargin: React.Dispatch<React.SetStateAction<boolean>>;
}

const VendorSlider = ({ tempVendors, setCurrentVendor, currentVendor, setIsMargin }: IProps) => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    setTotalPages(Math.ceil(tempVendors.length / 8));
  }, [tempVendors]);

  const stickyRef = useRef<HTMLDivElement>(null);
  let currStickyPos = 0;

  useEffect(() => {
    const stickyElem = stickyRef.current;
    if (!stickyElem) return;

    // Calculate the current position of the sticky element relative to the page
    const calculateStickyPos = () => {
      currStickyPos = stickyElem.getBoundingClientRect().top + window.scrollY;
    };

    calculateStickyPos();

    // Handle scroll event
    const handleScroll = () => {
      setTimeout(() => {
        if (window.scrollY > currStickyPos) {
          setIsMargin(true);
          stickyElem.style.position = 'fixed';
          stickyElem.style.top = '0px';
        } else {
          setIsMargin(false);
          stickyElem.style.position = 'relative';
          stickyElem.style.top = 'initial';
        }
      }, 100);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', calculateStickyPos);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', calculateStickyPos);
    };
  }, []);

  const handleUserKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'ArrowLeft') {
      handlePrev();
    } else if (event.key === 'ArrowRight') {
      handleNext();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  const handlePrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrev = () => {
    const currentVendorIndex = tempVendors?.findIndex(vendor => vendor.id === currentVendor?.id);

    if (currentVendorIndex > 0) {
      setCurrentVendor(tempVendors[currentVendorIndex - 1]);
    }
  };

  const handleNext = () => {
    const currentVendorIndex = tempVendors?.findIndex(vendor => vendor.id === currentVendor?.id);

    if (currentVendorIndex < tempVendors.length) {
      setCurrentVendor(tempVendors[currentVendorIndex + 1]);
    }
  };

  const itemsPerPage = 8;
  const totalItems = tempVendors.length;

  const getVisibleVendors = () => {
    let startIndex = itemsPerPage * page - itemsPerPage;
    let endIndex = startIndex + itemsPerPage;

    if (endIndex > totalItems) {
      endIndex = totalItems;
      startIndex = Math.max(endIndex - itemsPerPage, 0);
    }

    return tempVendors.slice(startIndex, endIndex);
  };

  const visibleVendors = getVisibleVendors();

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="row"
      flex={1}
      p="20px"
      position="relative"
      left={0}
      right={0}
      bgcolor="neutralSubtleMore"
      zIndex={2}
      ref={stickyRef}
    >
      {page > 1 && (
        <IconButton
          onClick={handlePrevPage}
          style={{
            position: 'absolute',
            left: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: 'lightgrey',
            opacity: 0.5,
          }}
        >
          <ArrowForwardIosIcon sx={{ transform: 'rotate(180deg)' }} />
        </IconButton>
      )}
      <div
        style={{
          display: 'flex',
          overflowX: 'hidden',
          scrollBehavior: 'smooth',
          width: '100%',
        }}
      >
        <Grid
          container
          spacing={1}
          style={{
            display: 'flex',
            transition: 'transform 0.5s ease',
            alignItems: 'center',
          }}
        >
          {visibleVendors?.map(vendor => (
            <Grid key={vendor.id} item xs={1.5}>
              <VendorCard
                vendor={vendor}
                onClick={() => {
                  const tempIndex = tempVendors.findIndex(v => v.id === vendor.id);
                  setCurrentVendor(tempVendors[tempIndex]);
                }}
                currentVendor={currentVendor}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      {page < totalPages && (
        <IconButton
          onClick={handleNextPage}
          style={{
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: 'lightgrey',
            opacity: 0.5,
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default VendorSlider;
