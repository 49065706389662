import React from 'react';

interface IIconProps {
  color: string;
}

export const BrandIcon: React.FC<IIconProps> = ({ color }) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_798_4953" maskUnits="userSpaceOnUse" x="8" y="8" width="24" height="24">
      <rect x="8" y="8" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_798_4953)">
      <path
        d="M20 29.5769C19.4949 29.5769 19.0593 29.4019 18.6932 29.0519C18.3272 28.7019 18.1282 28.2743 18.0961 27.7692H21.9038C21.8718 28.2743 21.6727 28.7019 21.3067 29.0519C20.9407 29.4019 20.5051 29.5769 20 29.5769ZM16.25 26.3846V24.8846H23.75V26.3846H16.25ZM16.4038 23.5C15.3564 22.8487 14.5272 21.9977 13.9163 20.9471C13.3054 19.8964 13 18.7474 13 17.5C13 15.5513 13.6795 13.8974 15.0385 12.5385C16.3974 11.1795 18.0513 10.5 20 10.5C21.9487 10.5 23.6025 11.1795 24.9615 12.5385C26.3205 13.8974 27 15.5513 27 17.5C27 18.7474 26.6945 19.8964 26.0836 20.9471C25.4727 21.9977 24.6435 22.8487 23.5961 23.5H16.4038ZM16.85 22H23.15C23.9 21.4666 24.4791 20.8083 24.8875 20.025C25.2958 19.2416 25.5 18.4 25.5 17.5C25.5 15.9666 24.9666 14.6666 23.9 13.6C22.8333 12.5333 21.5333 12 20 12C18.4666 12 17.1666 12.5333 16.1 13.6C15.0333 14.6666 14.5 15.9666 14.5 17.5C14.5 18.4 14.7041 19.2416 15.1125 20.025C15.5208 20.8083 16.1 21.4666 16.85 22Z"
        fill={color}
      />
    </g>
  </svg>
);
