import React from 'react';

export const SendPlaneLine: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M1.60252 7.80845C1.17752 7.63761 1.18252 7.38345 1.63085 7.23428L17.5358 1.93261C17.9767 1.78595 18.2292 2.03262 18.1059 2.46428L13.5608 18.3693C13.4358 18.8101 13.165 18.8301 12.9633 18.4276L9.16668 10.8334L1.60252 7.80845ZM5.67752 7.64178L10.3742 9.52095L12.9075 14.5893L15.8625 4.24762L5.67668 7.64178H5.67752Z"
      fill="white"
    />
  </svg>
);
