import { baseApi } from '.';
import { buildQueryString } from './helpers';
import type { GetImagesResponse, IDiagnostic } from './types/Images';
import type { ProductFilters } from './types/Product';

const token = localStorage.getItem('token') ?? '';

export const imagesApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getImages: builder.query<GetImagesResponse, ProductFilters>({
      query: filters => ({
        url: `/api/brand_monitoring/imagedata?${buildQueryString(filters, true)}`,
        method: 'GET',
        headers: {
          Authorization: `Token ${token}`,
        },
      }),
      transformResponse: (response: GetImagesResponse): GetImagesResponse => ({
        ...response,
        results: response.results.map(image => ({
          ...image,
          title: image.product_slug,
          image_url: image.image_s3_url,
        })),
      }),
    }),
    getDiagnostics: builder.query<IDiagnostic[], number | undefined | any>({
      query: brandId => ({
        url: `/api/brand_monitoring/diagnostics/${brandId ? `?brand_id=${brandId}` : ''}`,
        method: 'get',
        headers: {
          Authorization: `Token ${token}`,
        },
      }),
    }),
  }),
});

export const { useGetImagesQuery, useGetDiagnosticsQuery } = imagesApi;
