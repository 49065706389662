import React from 'react';

export const EditImageIcon: React.FC = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.85669 7.5679L4.30258 8.45968C4.45348 8.76149 4.52894 8.9124 4.62974 9.04317C4.71918 9.15921 4.82321 9.26323 4.93925 9.35268C5.07002 9.45348 5.22092 9.52893 5.52273 9.67984L6.41452 10.1257L5.52273 10.5716C5.22092 10.7225 5.07002 10.798 4.93925 10.8988C4.82321 10.9882 4.71918 11.0923 4.62974 11.2083C4.52894 11.3391 4.45348 11.49 4.30258 11.7918L3.85669 12.6836L3.4108 11.7918C3.25989 11.49 3.18444 11.3391 3.08364 11.2083C2.99419 11.0923 2.89017 10.9882 2.77413 10.8988C2.64336 10.798 2.49245 10.7225 2.19064 10.5716L1.29886 10.1257L2.19064 9.67984C2.49245 9.52893 2.64336 9.45348 2.77413 9.35268C2.89017 9.26323 2.99419 9.15921 3.08364 9.04317C3.18444 8.9124 3.25989 8.76149 3.4108 8.45968L3.85669 7.5679Z"
      stroke="#61646B"
      strokeWidth="1.13681"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.68814 1.31543L9.35808 3.05727C9.51838 3.47404 9.59852 3.68242 9.72316 3.85771C9.83363 4.01306 9.96936 4.14879 10.1247 4.25926C10.3 4.38389 10.5084 4.46404 10.9252 4.62434L12.667 5.29427L10.9252 5.96421C10.5084 6.12451 10.3 6.20466 10.1247 6.32929C9.96936 6.43976 9.83363 6.57549 9.72316 6.73084C9.59853 6.90613 9.51838 7.11451 9.35808 7.53129L8.68814 9.27312L8.01821 7.53128C7.85791 7.11451 7.77776 6.90613 7.65312 6.73084C7.54266 6.57549 7.40693 6.43976 7.25158 6.32929C7.07629 6.20466 6.86791 6.12451 6.45113 5.96421L4.7093 5.29427L6.45113 4.62434C6.86791 4.46404 7.07629 4.38389 7.25158 4.25926C7.40693 4.14879 7.54266 4.01306 7.65312 3.85771C7.77776 3.68242 7.85791 3.47404 8.01821 3.05726L8.68814 1.31543Z"
      stroke="#61646B"
      strokeWidth="1.13681"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.2459 1.31543L2.52114 1.86591C2.61429 2.05222 2.66087 2.14537 2.72309 2.22609C2.7783 2.29772 2.84252 2.36193 2.91415 2.41714C2.99487 2.47937 3.08802 2.52594 3.27432 2.61909L3.82481 2.89434L3.27432 3.16958C3.08802 3.26273 2.99487 3.30931 2.91415 3.37153C2.84252 3.42674 2.7783 3.49096 2.72309 3.56258C2.66087 3.64331 2.61429 3.73646 2.52114 3.92276L2.2459 4.47324L1.97066 3.92276C1.87751 3.73646 1.83093 3.6433 1.76871 3.56258C1.71349 3.49096 1.64928 3.42674 1.57765 3.37153C1.49693 3.30931 1.40378 3.26273 1.21748 3.16958L0.666992 2.89434L1.21748 2.61909C1.40378 2.52594 1.49693 2.47937 1.57765 2.41714C1.64928 2.36193 1.71349 2.29772 1.76871 2.22609C1.83093 2.14537 1.87751 2.05222 1.97066 1.86591L2.2459 1.31543Z"
      stroke="#61646B"
      strokeWidth="0.985238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
