import React from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

interface ISidebar {
  setQuery: (value: string) => void;
  query: string;
  handleSend: () => void;
  loading: boolean;
  textError: boolean;
  maskError: boolean;
  assets: IImagesArray[];
  isLoading: boolean;
  setSelectedAsset: (value: string) => void;
  selectedAsset: string;
  isShapesExist: boolean;
  setModalImage: (value: string) => void;
}

interface IImagesArray {
  url: string;
  id: string;
}

const Sidebar = ({
  setQuery,
  query,
  handleSend,
  loading,
  textError,
  maskError,
  assets,
  isLoading,
  setSelectedAsset,
  selectedAsset,
  isShapesExist,
  setModalImage,
}: ISidebar) => {
  return (
    <Grid
      item
      xs={12}
      md={3}
      sx={{ width: '100%', boxSizing: 'border-box', borderRight: '1px solid #EFEFF0' }}
    >
      <Box
        sx={{
          p: '25px',
          pb: '25px',
          borderBottom: '1px solid #EFEFF0',
        }}
      >
        <Typography fontFamily="Figtree" fontSize={26}>
          Edit Image
        </Typography>
      </Box>
      <Box sx={{ borderBottom: '1px solid #EFEFF0', p: '25px' }}>
        <Typography fontSize={18} fontFamily="Figtree" color="#313234">
          Prompt
        </Typography>
        <Typography fontSize={14} fontFamily="Figtree" color="#61646B" mt="10px">
          After selecting the area you want to modify with the brush, please describe what you would
          like to see instead
        </Typography>
        <Box sx={{ position: 'relative' }}>
          <TextField
            multiline
            rows={4}
            placeholder="e.g “Remove stone”, etc."
            onChange={newValue => {
              setQuery(newValue.target.value);
            }}
            value={query}
            onKeyDown={event => {
              const isShiftPressed = event.shiftKey;

              // If on computer, handle Enter key as form submission
              if (!isShiftPressed && event.key === 'Enter') {
                event.preventDefault();
                if (query !== '') {
                  handleSend();
                }
              }
            }}
            sx={{
              mt: '20px',
              width: '100%',
              borderColor: '#AFB1B6',
              '&:hover fieldset': {
                borderColor: '#AFB1B6 !important',
              },
            }}
            disabled={loading || isLoading}
          />
          <Box display="flex" justifyContent="flex-end">
            <LoadingButton
              variant="contained"
              onClick={handleSend}
              sx={{
                bgcolor: '#3B1E8E',
                borderRadius: '5px',
                alignSelf: 'flex-end',
                mt: '10px',
                width: '150px',
                border: 'none',
              }}
              disabled={!query || !isShapesExist}
              loading={loading || isLoading}
            >
              <Typography fontSize={16} fontFamily="Figtree" color="#FFF">
                Generate
              </Typography>
            </LoadingButton>
          </Box>
        </Box>
        {textError && (
          <Typography fontSize={14} fontFamily="Figtree" color="red">
            Text field required
          </Typography>
        )}
        {maskError && (
          <Typography fontSize={14} fontFamily="Figtree" color="red">
            Mask image required
          </Typography>
        )}
      </Box>
      <Typography fontSize={18} fontFamily="Figtree" color="#313234" paddingX="25px" pt="25px">
        Outputs
      </Typography>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={2}
        mt="15px"
        overflow="scroll"
        paddingX="25px"
        maxHeight={{ xs: '500px', md: 'calc(95vh - 530px)' }}
        sx={{
          '&::-webkit-scrollbar': { display: 'none' },
        }}
        mb={{ xs: '50px', md: '0px' }}
      >
        {assets?.map((asset, i) => {
          return (
            <Grid item xs={6} key={i}>
              <Box
                onClick={() => {
                  if (i === assets.length - 1) {
                    setSelectedAsset('');
                    setModalImage(asset.url);
                  } else {
                    setModalImage(asset.url);
                    selectedAsset ? setSelectedAsset('') : setSelectedAsset(asset.url);
                  }
                }}
                sx={{
                  width: '100%',
                  height: '180px',
                  border: '2px solid #EFEFF0',
                  borderRadius: '5px',
                  boxSizing: 'border-box',
                  objectFit: 'contain',
                  bgcolor: 'white',
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
                component="img"
                src={asset.url}
                alt={asset.id}
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default Sidebar;
