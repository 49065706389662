import React from 'react';

export const ArrowDropDownFill: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M7.99967 9.33329L5.33301 6.66663H10.6663L7.99967 9.33329Z"
      fill="#42485D"
      fillOpacity="0.6"
    />
  </svg>
);
