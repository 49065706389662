import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './axios-base-query';

export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL ?? '',
  }),

  tagTypes: ['User', 'Products', 'Vendors', 'Brands'],
  endpoints: () => ({}),
});
