import React from 'react';

interface IIconProps {
  active: boolean;
}

export const AssetsList: React.FC<IIconProps> = ({ active = true }) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    {active && <rect width="40" height="40" rx="8" fill="#F6F3FC" />}
    <path
      d="M12.5 13.3333H15.8333V16.6666H12.5V13.3333ZM17.5 14.1666V15.8333H27.5V14.1666H17.5ZM12.5 18.3333H15.8333V21.6666H12.5V18.3333ZM17.5 19.1666V20.8333H27.5V19.1666H17.5ZM12.5 23.3333H15.8333V26.6666H12.5V23.3333ZM17.5 24.1666V25.8333H27.5V24.1666H17.5Z"
      fill={active ? '#5930A5' : '#1B1B1E'}
    />
  </svg>
);
