import React from 'react';
import { Box } from '@mui/material';
import DesktopSidebar from './components/DesktopSidebar';
import MobileSidebar from './components/MobileSidebar';
import { type IOptions } from '../../types';

interface ITopSidebar {
  handleSend: (value: string) => void;
  handleSaveImage: () => void;
  setOpenEditModal: (value: boolean) => void;
  isLoading: boolean;
  setEditAsset: (value: boolean) => void;
  selectedValue: string;
  setSelectedValue: (value: string) => void;
  options: IOptions[];
  isChatHasOutputImg: boolean;
}

function TopSidebar({
  handleSend,
  handleSaveImage,
  setOpenEditModal,
  isLoading,
  setEditAsset,
  selectedValue,
  setSelectedValue,
  options,
  isChatHasOutputImg,
}: ITopSidebar) {
  return (
    <Box
      sx={{
        bgcolor: '#FFF',
        boxSizing: 'border-box',
        borderBottom: '1px solid #EFEFF0',
        width: '100%',
      }}
    >
      <Box
        display={{
          xs: 'none',
          md: 'flex',
        }}
      >
        <DesktopSidebar
          handleSend={handleSend}
          handleSaveImage={handleSaveImage}
          setOpenEditModal={setOpenEditModal}
          isLoading={isLoading}
          setEditAsset={setEditAsset}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          options={options}
          isChatHasOutputImg={isChatHasOutputImg}
        />
      </Box>
      <Box
        display={{
          xs: 'flex',
          md: 'none',
        }}
      >
        <MobileSidebar
          handleSend={handleSend}
          handleSaveImage={handleSaveImage}
          setOpenEditModal={setOpenEditModal}
          isLoading={isLoading}
          setEditAsset={setEditAsset}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          options={options}
          isChatHasOutputImg={isChatHasOutputImg}
        />
      </Box>
    </Box>
  );
}

export default TopSidebar;
