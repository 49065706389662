import React from 'react';

export const ProductsInfo: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 12C0 8.27247 0 6.4087 0.608964 4.93853C1.42092 2.97831 2.97831 1.42092 4.93853 0.608964C6.4087 0 8.27247 0 12 0C15.7275 0 17.5913 0 19.0615 0.608964C21.0217 1.42092 22.5791 2.97831 23.391 4.93853C24 6.4087 24 8.27247 24 12C24 15.7275 24 17.5913 23.391 19.0615C22.5791 21.0217 21.0217 22.5791 19.0615 23.391C17.5913 24 15.7275 24 12 24C8.27247 24 6.4087 24 4.93853 23.391C2.97831 22.5791 1.42092 21.0217 0.608964 19.0615C0 17.5913 0 15.7275 0 12Z"
      fill="white"
      fillOpacity="0.01"
    />
    <path
      d="M12 17.8333C8.77821 17.8333 6.16663 15.2218 6.16663 12C6.16663 8.77826 8.77821 6.16667 12 6.16667C15.2217 6.16667 17.8333 8.77826 17.8333 12C17.8333 15.2218 15.2217 17.8333 12 17.8333ZM12 16.6667C13.2376 16.6667 14.4246 16.175 15.2998 15.2998C16.175 14.4247 16.6666 13.2377 16.6666 12C16.6666 10.7623 16.175 9.57534 15.2998 8.70017C14.4246 7.825 13.2376 7.33334 12 7.33334C10.7623 7.33334 9.5753 7.825 8.70013 8.70017C7.82496 9.57534 7.33329 10.7623 7.33329 12C7.33329 13.2377 7.82496 14.4247 8.70013 15.2998C9.5753 16.175 10.7623 16.6667 12 16.6667ZM11.4166 9.08334H12.5833V10.25H11.4166V9.08334ZM11.4166 11.4167H12.5833V14.9167H11.4166V11.4167Z"
      fill="#42485D"
      fillOpacity="0.6"
    />
  </svg>
);
