import React from 'react';
import { Box } from '@mui/material';
import radar from '../../../assets/images/radar/radarSmall.webp';

export const BrandLoader = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        component="img"
        src={radar}
        sx={{
          animation: 'radarRotation 5s infinite linear',
          '@keyframes radarRotation': {
            from: {
              transform: 'rotate(0deg)',
            },
            to: {
              transform: 'rotate(360deg)',
            },
          },
        }}
      />
    </Box>
  );
};
