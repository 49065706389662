import React from 'react';

export const RedoIcon: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.502 7.5013H6.25195C4.18089 7.5013 2.50195 9.18023 2.50195 11.2513C2.50195 13.3224 4.18089 15.0013 6.25195 15.0013H10.002M17.502 7.5013L14.1686 4.16797M17.502 7.5013L14.1686 10.8346"
      stroke="#61646B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
