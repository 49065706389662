import React, { createContext, type FC, type ReactNode, useEffect, useState } from 'react';
import { getImageGenerated } from '../repositories/prompte.service';

interface ImagsGeneratedType {
  imagesGenerated: IImagesArray[];
  imageGenerated: IImagesArray;
  correctImageGenerated: IImagesArray;
  handleImageGenerated: (value: string, requestID: string) => void;
  handleImageRequestID: (value: string[]) => void;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  handleEditImageRequestID: (value: string) => void;
  handleEditCorrectImageRequestID: (value: string) => void;
  handleStopLoadingImages: () => void;
}

// Create a new context for file content value
export const ImagsGeneratedContext = createContext<ImagsGeneratedType>(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as ImagsGeneratedType
);

interface FileProviderProps {
  children?: ReactNode;
}

interface IImagesArray {
  url: string;
  id: string;
}

export const ImagsGeneratedProvider: FC<FileProviderProps> = ({ children }) => {
  const [imageRequestIDs, setImageRequestIDs] = useState<string[]>([]);
  const [imagesGenerated, setImagsGenerated] = useState<IImagesArray[]>([]);

  const [imageRequestID, setImageRequestID] = useState<string>('');
  const [imageGenerated, setImageGenerated] = useState<IImagesArray>({
    url: '',
    id: '',
  });

  const [correctImageRequestID, setCorrectImageRequestID] = useState<string>('');
  const [correctImageGenerated, setCorrectImageGenerated] = useState<IImagesArray>({
    url: '',
    id: '',
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [intervals, setIntervals] = useState<NodeJS.Timeout[]>([]);
  const [intervalCorrectImage, setIntervalCorrectImage] = useState<NodeJS.Timeout>();
  const [intervalImage, setIntervalImage] = useState<NodeJS.Timeout>();

  const handleImageGenerated = (value: string, requestID: string) => {
    setImageRequestIDs(prev => prev.filter(id => id !== requestID));
    setImagsGenerated(prev => [...prev, { url: value, id: requestID }]);
  };

  const handleImageRequestID = (values: string[]) => {
    setImageRequestIDs(values);
  };

  const handleEditImageGenerated = (value: string, requestID: string) => {
    setImageRequestID('');
    setImageGenerated({ url: value, id: requestID });
  };

  const handleEditImageRequestID = (value: string) => {
    setImageRequestID(value);
  };

  const handleEditCorrectImageGenerated = (value: string, requestID: string) => {
    setCorrectImageRequestID('');
    setCorrectImageGenerated({ url: value, id: requestID });
  };

  const handleEditCorrectImageRequestID = (value: string) => {
    setCorrectImageRequestID(value);
  };

  const handleStopLoadingImages = () => {
    intervals.forEach(interval => {
      clearInterval(interval);
    });

    clearInterval(intervalCorrectImage);

    clearInterval(intervalImage);

    setIsLoading(false);
  };

  useEffect(() => {
    intervals.forEach(interval => {
      clearInterval(interval);
    });

    const newIntervals: NodeJS.Timeout[] = [];

    imageRequestIDs.forEach((requestID, index) => {
      setIsLoading(true);

      const fetchImageGenerated = async () => {
        const data = await getImageGenerated(requestID);
        if (data?.status === 'FINISHED') {
          handleImageGenerated(data.image_url, requestID);
          setIsLoading(false);
        }
      };

      const interval = setInterval(fetchImageGenerated, 5000);
      newIntervals.push(interval);

      setIntervals(newIntervals);
    });
  }, [imageRequestIDs]);

  useEffect(() => {
    clearInterval(intervalImage);

    if (imageRequestID) {
      setIsLoading(true);

      const fetchImageGenerated = async () => {
        const data = await getImageGenerated(imageRequestID);
        if (data?.status === 'FINISHED') {
          handleEditImageGenerated(data.image_url, imageRequestID);
          setIsLoading(false);
          setImageRequestID('');
        }
      };

      const interval = setInterval(fetchImageGenerated, 5000);
      const newInterval: NodeJS.Timeout = interval;

      setIntervalImage(newInterval);
    }
  }, [imageRequestID]);

  useEffect(() => {
    clearInterval(intervalCorrectImage);

    if (correctImageRequestID) {
      setIsLoading(true);

      const fetchImageGenerated = async () => {
        const data = await getImageGenerated(correctImageRequestID);
        if (data?.status === 'FINISHED') {
          handleEditCorrectImageGenerated(data.image_url, correctImageRequestID);
          setIsLoading(false);
          setImageRequestID('');
        }
      };

      const interval = setInterval(fetchImageGenerated, 5000);
      const newInterval: NodeJS.Timeout = interval;

      setIntervalCorrectImage(newInterval);
    }
  }, [correctImageRequestID]);

  return (
    <ImagsGeneratedContext.Provider
      value={{
        imagesGenerated,
        handleImageGenerated,
        handleImageRequestID,
        isLoading,
        setIsLoading,
        handleEditImageRequestID,
        imageGenerated,
        handleEditCorrectImageRequestID,
        correctImageGenerated,
        handleStopLoadingImages,
      }}
    >
      {children}
    </ImagsGeneratedContext.Provider>
  );
};
