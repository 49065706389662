import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import UploadAsset from '../../components/UploadAsset';
import {
  controller,
  getAssets,
  getRemoveBackground,
  setSaveAsset,
  updateAssetName,
} from '../../repositories/prompte.service';
import { Sidebar, Header } from '../../components/blocks';
import SelectedAsset from '../../components/SelectedAsset';
import { type IAsset } from './types';
import { useGetUserAssets } from '../../hooks/api/user';
import { postUploadImage } from '../../hooks/api/image';
import moment from 'moment';
import { getThemes } from '../../repositories/theme.service';
import { useLocation } from 'react-router-dom';
import { AssetContentContext, BrandsContext, ImagsGeneratedContext } from '../../contexts';
import { AssetTab, Refine } from './components';
import { getBrand } from '../../common/utils';

interface ITheme {
  id: number;
  chat_id: string;
  history: any[];
  created_at: string;
  created_by: number;
  asset: number;
}

export const Assets: React.FC = () => {
  const location = useLocation();

  const [assetName, setAssetName] = useState('');
  const [assetNameError, setAssetNameError] = useState(false);
  const [assets, setAssets] = useState<IAsset[]>([]);
  const [filteredAssets, setFilteredAssets] = useState<IAsset[]>([]);
  const [search, setSearch] = useState('');
  const [refineMode, setRefineMode] = useState<'none' | 'restore' | 'removal'>('none');
  const [lineWidth, setLineWidth] = useState(10);
  const [selectedAsset, setSelectedAsset] = useState<IAsset | null>(null);
  const [sortType, setSortType] = useState(0);
  const [isAddNewAsset, setIsAddNewAsset] = useState(location.state?.addNewAsset);
  const [isEditAsset, setIsEditAsset] = useState(false);
  const [nextButtonText, setNextButtonText] = useState<'Save' | 'Refine'>('Save');
  const [maskImage, setMaskImage] = useState(null);
  const [shape, setShape] = useState<any[]>([]);
  const [image, setImage] = useState(null);
  const [selectedId, setSelectedId] = useState(0);
  const [themes, setThemes] = useState<ITheme[]>([]);
  const [isAssetsLoading, setIsAssetsLoading] = useState(false);
  const [assetsByBrand, setAssetsByBrand] = useState<IAsset[]>([]);

  window.history.replaceState({}, document.title);

  const {
    assetContentVal,
    setCroppedImage,
    setImageWithoutBackground,
    handleAssetVal,
    setCorrectedImage,
    loadedFile,
    isLoadingError,
    setIsLoadingError,
    croppedImage,
  } = useContext(AssetContentContext);

  const { handleStopLoadingImages, setIsLoading } = useContext(ImagsGeneratedContext);

  const { selectedBrand } = useContext(BrandsContext);

  useEffect(() => {
    if (!assets.length) {
      setIsAssetsLoading(true);
      updateAssets();
    }
    if (location.state?.selectedAsset && assets) {
      const brandsAsset = assets.find(asset => asset.id === location.state.selectedAsset) ?? null;
      setSelectedAsset(brandsAsset);
      setSelectedId(brandsAsset?.id ?? 0);
    }
  }, [assets]);

  const handleClickNext = async () => {
    setIsLoading(true);

    try {
      if (maskImage && nextButtonText === 'Refine') {
        setCroppedImage('');
        const maskImageS3 = await postUploadImage(maskImage);
        setShape([]);
        setCorrectedImage(maskImageS3);
        setMaskImage(null);
        const image = await getRemoveBackground(maskImageS3);
        setCroppedImage(image.transparent_image_cropped_url);
      } else {
        handleSaveAsset();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenModal = () => {
    setSelectedAsset(null);
    setIsAddNewAsset(true);
  };

  const handleCloseModal = () => {
    setImageWithoutBackground('');
    setCorrectedImage('');
    setCroppedImage('');
    controller.abort();
    handleStopLoadingImages();
    setIsAddNewAsset(false);
    setRefineMode('none');
    setShape([]);
  };

  useEffect(() => {
    if (!isAddNewAsset) {
      handleCloseModal();
    }
  }, [isAddNewAsset]);

  useEffect(() => {
    const handleGetUserThemes = async () => {
      const themes: ITheme[] = await getThemes();
      // Sort themes for use find method to search latest theme
      const sortedThemes = themes.sort((a, b) => {
        if (a.id > b.id) {
          return -1;
        } else {
          return 1;
        }
      });
      setThemes(sortedThemes);
    };

    handleGetUserThemes();
  }, []);

  const handleSaveAsset = async () => {
    if (!assetName) {
      setAssetNameError(true);
    } else if (image) {
      const maskImageS3 = await postUploadImage(image);
      const brandID = getBrand();

      handleOpenModal();
      const asset = await setSaveAsset(maskImageS3, assetName, brandID);
      await updateAssets();
      await getAssets()
        .then(items => {
          setAssets(items);
          setSelectedAsset(asset);
        })
        .then(() => {
          handleCloseModal();
        });
    }
  };

  const updateAssets = async () => {
    const assets: IAsset[] = await useGetUserAssets();
    const filteredArray = assets.sort(
      (a, b) => Number(moment(b.created_at).format('x')) - Number(moment(a.created_at).format('x'))
    );

    if (selectedBrand.id) {
      const sortedByBrand = filteredArray.filter(item => item.brand === selectedBrand.id);
      if (sortedByBrand.length > 0) {
        setAssetsByBrand(sortedByBrand);
      } else {
        setAssetsByBrand([]);
      }
    } else {
      const sortedByBrandNull = filteredArray.filter(item => item.brand === null);
      setAssetsByBrand(sortedByBrandNull);
    }

    setSelectedAsset(null);
    setSortType(2);
    setIsAssetsLoading(false);
  };

  const handleEditAsset = async () => {
    try {
      if (!assetName) {
        setAssetNameError(true);
      } else {
        await updateAssetName(selectedId, assetName)
          .then(() => {
            updateAssets();
          })
          .finally(() => {
            setIsEditAsset(false);
            setAssetName('');
          });
      }
    } catch (e) {
      console.log('Error asset rename', e);
    }
  };

  useEffect(() => {
    if (shape.length > 0) {
      setNextButtonText('Refine');
    } else {
      setNextButtonText('Save');
    }
  }, [shape]);

  useEffect(() => {
    setAssetNameError(false);
  }, [assetName]);

  useEffect(() => {
    setIsAssetsLoading(true);
    updateAssets();
  }, [selectedBrand.id]);

  useEffect(() => {
    const copyArray = [...assetsByBrand];
    const filteredArray = copyArray.filter(obj =>
      obj.name.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredAssets(filteredArray);
  }, [search, assetsByBrand]);

  useEffect(() => {
    if (assetContentVal && isAddNewAsset) {
      handleOpenModal();
      handleAssetVal('');
    }
  }, []);

  useEffect(() => {
    if (isLoadingError) {
      setIsLoadingError(false);
      handleCloseModal();
    }
  }, [isLoadingError]);

  const filterAssetsByNameAsc = () => {
    const copyArray = [...assetsByBrand];

    const filteredArray = copyArray.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    setFilteredAssets(filteredArray);
    setSortType(0);
  };

  const filterAssetsByNameDesc = () => {
    const copyArray = [...assetsByBrand];

    const filteredArray = copyArray.sort((a, b) => {
      return b.name.localeCompare(a.name);
    });

    setFilteredAssets(filteredArray);
    setSortType(1);
  };

  const filterAssetsByDateAsc = () => {
    const copyArray = [...assetsByBrand];

    const filteredArray = copyArray.sort(
      (a, b) => Number(moment(b.created_at).format('x')) - Number(moment(a.created_at).format('x'))
    );

    setFilteredAssets(filteredArray);
    setSortType(2);
  };

  const filterAssetsByDateDesc = () => {
    const copyArray = [...assetsByBrand];

    const filteredArray = copyArray.sort(
      (a, b) => Number(moment(a.created_at).format('x')) - Number(moment(b.created_at).format('x'))
    );

    setFilteredAssets(filteredArray);
    setSortType(3);
  };

  return (
    <Box
      sx={{
        height: { xs: '100%', lg: '100vh' },
      }}
    >
      <Sidebar />
      <Header />
      <Box
        sx={{
          pl: { xs: '0px', lg: '80px' },
        }}
      >
        <Grid container>
          <Grid item xs={12} lg={3}>
            <Box sx={{ height: 'calc(100vh - 64px)', bgcolor: '#FFF' }}>
              <AssetTab
                assets={filteredAssets}
                setSearch={setSearch}
                setSelectedAsset={setSelectedAsset}
                selectedAsset={selectedAsset}
                handleOpenModal={handleOpenModal}
                filterAssetsByNameAsc={filterAssetsByNameAsc}
                filterAssetsByNameDesc={filterAssetsByNameDesc}
                filterAssetsByDateAsc={filterAssetsByDateAsc}
                filterAssetsByDateDesc={filterAssetsByDateDesc}
                sortType={sortType}
                isAddNewAsset={isAddNewAsset}
                setIsAddNewAsset={setIsAddNewAsset}
                assetContentVal={assetContentVal}
                assetNameError={assetNameError}
                setAssetName={setAssetName}
                lineWidth={lineWidth}
                setLineWidth={setLineWidth}
                setRefineMode={setRefineMode}
                refineMode={refineMode}
                handleClickNext={handleClickNext}
                nextButtonText={nextButtonText}
                updateAssets={updateAssets}
                setIsEditAsset={setIsEditAsset}
                isEditAsset={isEditAsset}
                handleEditAsset={handleEditAsset}
                setSelectedId={setSelectedId}
                selectedId={selectedId}
                assetName={assetName}
                isAssetsLoading={isAssetsLoading}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={9}>
            {isAddNewAsset ? (
              <Box width="100%" height="calc(100vh - 64px)" bgcolor="#FAFAFA">
                <Refine
                  image={loadedFile.url}
                  mode={refineMode}
                  setRefineMode={setRefineMode}
                  lineWidth={lineWidth}
                  setLineWidth={setLineWidth}
                  setMaskImage={setMaskImage}
                  shape={shape}
                  setShape={setShape}
                  setImage={setImage}
                  croppedImage={croppedImage}
                />
              </Box>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="calc(100vh - 64px)"
                bgcolor="#FAFAFA"
              >
                {selectedAsset ? (
                  <SelectedAsset
                    asset={selectedAsset}
                    setSelectedAsset={setSelectedAsset}
                    themes={themes}
                  />
                ) : (
                  <UploadAsset handleOpenModal={handleOpenModal} />
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
