import React from 'react';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

function ImagesByScore() {
  const skeletonHeights = [50, 100, 150, 100, 80, 260, 120, 90, 70, 110];
  return (
    <Stack bgcolor={'#fff'} borderRadius={'16px'} gap={'16px'}>
      <Stack gap={'4px'} p={'16px 24px'}>
        <Skeleton variant="text" width={120} height={40} />

        <Skeleton variant="text" width={160} height={40} />
      </Stack>
      <Box
        sx={{
          height: 300,
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'flex-end',
          padding: '0 30px',
        }}
      >
        {skeletonHeights.map((height, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            width={77.2}
            height={height}
            sx={{
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12,
            }}
          />
        ))}
      </Box>
    </Stack>
  );
}

export default ImagesByScore;
