import React from 'react';
import { Box } from '@mui/material';
import SidebarMenu from './SidebarMenu';

export const Sidebar = () => {
  return (
    <>
      {/*  Desktop sidebar */}
      <Box
        sx={{
          width: '80px',
          height: 'calc(100vh - 64px)',
          display: {
            xs: 'none',
            lg: 'flex',
          },
          position: 'fixed',
          left: 0,
          bottom: 0,
          background: '#FFF',
          boxSizing: 'border-box',
          borderRight: '1px solid #EFEFF0',
        }}
      >
        <SidebarMenu />
      </Box>
      {/*  Mobile sidebar */}
      <Box
        sx={{
          height: '60px',
          width: '100vw',
          display: {
            xs: 'flex',
            lg: 'none',
          },
          position: 'fixed',
          left: 0,
          top: 0,
          background: '#FFF',
          boxSizing: 'border-box',
          borderBottom: '1px solid #EFEFF0',
          zIndex: 5,
        }}
      >
        <SidebarMenu isMobile />
      </Box>
    </>
  );
};
