import React from 'react';

export const CheckboxCircleLine: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path
      d="M9.99935 18.8333C5.39685 18.8333 1.66602 15.1025 1.66602 10.5C1.66602 5.89746 5.39685 2.16663 9.99935 2.16663C14.6018 2.16663 18.3327 5.89746 18.3327 10.5C18.3327 15.1025 14.6018 18.8333 9.99935 18.8333ZM9.99935 17.1666C11.7675 17.1666 13.4632 16.4642 14.7134 15.214C15.9636 13.9638 16.666 12.2681 16.666 10.5C16.666 8.73185 15.9636 7.03616 14.7134 5.78591C13.4632 4.53567 11.7675 3.83329 9.99935 3.83329C8.23124 3.83329 6.53555 4.53567 5.2853 5.78591C4.03506 7.03616 3.33268 8.73185 3.33268 10.5C3.33268 12.2681 4.03506 13.9638 5.2853 15.214C6.53555 16.4642 8.23124 17.1666 9.99935 17.1666ZM9.16852 13.8333L5.63268 10.2975L6.81102 9.11913L9.16852 11.4766L13.8819 6.76246L15.061 7.94079L9.16852 13.8333Z"
      fill="#40C6A7"
    />
  </svg>
);
