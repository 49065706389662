import React from 'react';

export const AssetSelected: React.FC = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.3332 24.1665L14.1665 19.9998L15.3415 18.8165L18.3332 21.8082L24.6582 15.4832L25.8332 16.6665L18.3332 24.1665ZM19.9998 11.6665C18.9055 11.6665 17.8219 11.8821 16.8108 12.3008C15.7998 12.7196 14.8811 13.3335 14.1073 14.1073C12.5445 15.6701 11.6665 17.7897 11.6665 19.9998C11.6665 22.21 12.5445 24.3296 14.1073 25.8924C14.8811 26.6662 15.7998 27.28 16.8108 27.6988C17.8219 28.1176 18.9055 28.3332 19.9998 28.3332C22.21 28.3332 24.3296 27.4552 25.8924 25.8924C27.4552 24.3296 28.3332 22.21 28.3332 19.9998C28.3332 18.9055 28.1176 17.8219 27.6988 16.8108C27.28 15.7998 26.6662 14.8811 25.8924 14.1073C25.1186 13.3335 24.1999 12.7196 23.1889 12.3008C22.1778 11.8821 21.0942 11.6665 19.9998 11.6665Z"
      fill="#5930A5"
    />
  </svg>
);
