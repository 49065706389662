import axios from 'axios';
import * as Sentry from '@sentry/react';

const url = process.env.REACT_APP_FOCAL_API_URL ?? 'https://focalstudio-science.com';

export const bootGPUs = async () => {
  return await axios
    .post(
      `${url}/boot_gpus`,
      {},
      {
        headers: {
          'api-key': 'focal-test-key-temp-2023',
          'Content-Type': 'application/json',
        },
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      Sentry.setTag('api_url', `${url}/boot_gpus`);
      Sentry.captureException(error);
      console.error(error);
    });
};
