import React from 'react';

export const DotsIconVertical: React.FC = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.0002 23.3333C20.4422 23.3333 20.8661 23.5088 21.1787 23.8214C21.4912 24.134 21.6668 24.5579 21.6668 24.9999C21.6668 25.4419 21.4912 25.8659 21.1787 26.1784C20.8661 26.491 20.4422 26.6666 20.0002 26.6666C19.5581 26.6666 19.1342 26.491 18.8217 26.1784C18.5091 25.8659 18.3335 25.4419 18.3335 24.9999C18.3335 24.5579 18.5091 24.134 18.8217 23.8214C19.1342 23.5088 19.5581 23.3333 20.0002 23.3333ZM20.0002 18.3333C20.4422 18.3333 20.8661 18.5088 21.1787 18.8214C21.4912 19.134 21.6668 19.5579 21.6668 19.9999C21.6668 20.4419 21.4912 20.8659 21.1787 21.1784C20.8661 21.491 20.4422 21.6666 20.0002 21.6666C19.5581 21.6666 19.1342 21.491 18.8217 21.1784C18.5091 20.8659 18.3335 20.4419 18.3335 19.9999C18.3335 19.5579 18.5091 19.134 18.8217 18.8214C19.1342 18.5088 19.5581 18.3333 20.0002 18.3333ZM20.0002 13.3333C20.4422 13.3333 20.8661 13.5088 21.1787 13.8214C21.4912 14.134 21.6668 14.5579 21.6668 14.9999C21.6668 15.4419 21.4912 15.8659 21.1787 16.1784C20.8661 16.491 20.4422 16.6666 20.0002 16.6666C19.5581 16.6666 19.1342 16.491 18.8217 16.1784C18.5091 15.8659 18.3335 15.4419 18.3335 14.9999C18.3335 14.5579 18.5091 14.134 18.8217 13.8214C19.1342 13.5088 19.5581 13.3333 20.0002 13.3333Z"
      fill="#1B1B1E"
    />
  </svg>
);
