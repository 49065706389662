import React from 'react';

interface IProps {
  color: string;
}

export const ReviewLeftIcon = ({ color }: IProps) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_714_1571)">
      <mask id="mask0_714_1571" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
        <rect y="0.5" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_714_1571)">
        <path
          d="M7.37302 13.25L13.0692 18.9461L12 20L4.5 12.5L12 5L13.0692 6.05383L7.37302 11.75H19.5V13.25H7.37302Z"
          fill={color}
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_714_1571">
        <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
