import useDeferredState from '../useDeferredState';
import { client } from '../../common/axios';
import { type IQuestion } from '../../common/types';

export const useQuestions = () => {
  return useDeferredState<IQuestion[]>(
    async () => await client.get('/api/meta/questions'),
    [],
    [],
    []
  );
};
