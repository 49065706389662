import React from 'react';
import DashboardLayout from '../../../components/Dashboard/DashboardLayout';
import { Highlights } from '../../../components/Vendors/Highlights';
import Table from '../../../components/Vendors/Table';
import {
  // Button,
  Stack,
} from '@mui/material';
import { useGetVendorsQuery } from '../../../core/api/vendors';
import { VendorsHighlightsPlaceholder } from '../../../components/Dashboard/Placeholders/VendorsHighlightPlaceholder';
import TablePlaceholder from '../Actions/TablePlaceholder';
import useOnboardingSearchParam from '../../../hooks/useOnboardingSearchParam';
import { useSubscriptionStatus } from '../../../hooks/useSubscriptionStatus';
import FreeTrialCard from '../../../components/Common/FreeTrialCard';

function VendorsPage() {
  const { brandId } = useOnboardingSearchParam();
  const { freeTrial } = useSubscriptionStatus();
  const { data: vendors, isLoading, isFetching } = useGetVendorsQuery(Number(brandId));

  return (
    <DashboardLayout
      title={'Vendors'}
      description={'Overview results of image audit over all vendors sites'}
    >
      <Stack display="flex" flexDirection="column" gap="32px" sx={{ mb: '16px' }}>
        {vendors ? <Highlights vendors={vendors} /> : <VendorsHighlightsPlaceholder />}
        {/* <Box display="flex" justifyContent="flex-end" mt="20px">
           <Button
             variant="contained"
             sx={{
               bgcolor: 'black',
               color: 'white',
               borderColor: 'black',
               height: '35px',
               borderRadius: '10px',
               '&:hover': {
                 bgcolor: 'black',
                 color: 'white',
               },
             }}
           >
             Add vendor
           </Button>
         </Box> */}
        {isLoading || isFetching ? <TablePlaceholder size={5} /> : <Table resellers={vendors} />}
        {freeTrial && (
          <Stack width={'70%'}>
            <FreeTrialCard title="Explore products with Enterprise Plan" sx={{ mt: 2 }} />
          </Stack>
        )}
      </Stack>
    </DashboardLayout>
  );
}

export default VendorsPage;
