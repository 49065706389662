import React from 'react';

export const PackageIcon: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.9006 6.16702L10.0009 9.99998M10.0009 9.99998L3.10115 6.16702M10.0009 9.99998L10.0009 17.711M17.3065 13.2943V6.70573C17.3065 6.42761 17.3065 6.28856 17.2655 6.16453C17.2293 6.05481 17.17 5.9541 17.0917 5.86912C17.0032 5.77307 16.8816 5.70553 16.6385 5.57047L10.6316 2.23354C10.4014 2.10565 10.2863 2.04171 10.1644 2.01664C10.0565 1.99445 9.94526 1.99445 9.83738 2.01664C9.71548 2.04171 9.60037 2.10565 9.37016 2.23354L3.36334 5.57047C3.12021 5.70554 2.99864 5.77307 2.91012 5.86912C2.83181 5.9541 2.77255 6.05481 2.73629 6.16453C2.69531 6.28856 2.69531 6.42762 2.69531 6.70573V13.2943C2.69531 13.5724 2.69531 13.7114 2.73629 13.8355C2.77255 13.9452 2.83181 14.0459 2.91012 14.1309C2.99864 14.2269 3.12021 14.2945 3.36335 14.4295L9.37016 17.7665C9.60037 17.8943 9.71548 17.9583 9.83738 17.9834C9.94526 18.0055 10.0565 18.0055 10.1644 17.9834C10.2863 17.9583 10.4014 17.8943 10.6316 17.7665L16.6385 14.4295C16.8816 14.2945 17.0032 14.2269 17.0917 14.1309C17.17 14.0459 17.2293 13.9452 17.2655 13.8355C17.3065 13.7114 17.3065 13.5724 17.3065 13.2943Z"
      stroke="#AFB1B6"
      strokeWidth="1.62342"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.6532 7.97053L6.34766 3.91211"
      stroke="#AFB1B6"
      strokeWidth="1.62342"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
