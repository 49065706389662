import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { theme } from '../../../theme';
import { type IImagesArray, Steps } from '../types';
import { BrandFinalizingIcon } from '../../../assets/icons';
import { useNavigate } from 'react-router-dom';

interface IFinalizing {
  setStep: (value: number) => void;
  brand: string;
  brandImages: IImagesArray[];
  setBrandImages: (value: IImagesArray[]) => void;
  stylePreference: string[];
  isFinishLoading: boolean;
  finishBrandsWorkflow: () => void;
  isImagesDescribed: boolean;
  setIsFinishLoading: (value: boolean) => void;
}

export const Finalizing = ({
  setStep,
  brand,
  brandImages,
  setBrandImages,
  stylePreference,
  isFinishLoading,
  finishBrandsWorkflow,
  isImagesDescribed,
  setIsFinishLoading,
}: IFinalizing) => {
  const [isLoading, setIsLoading] = useState(true);
  const [buttonHovered, setButtonHovered] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setIsFinishLoading(true);

    if (isImagesDescribed) {
      finishBrandsWorkflow();
    }
  }, [isImagesDescribed]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (!isFinishLoading && stylePreference) {
      timer = setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isFinishLoading, stylePreference]);

  const brandShortName = () => {
    const wordsCount = brand.split(' ');

    if (!brand) {
      return 'FS';
    }

    if (wordsCount.length > 1) {
      return `${wordsCount[0][0].toUpperCase()}${wordsCount[1][0].toUpperCase()}`;
    } else {
      return `${brand[0].toUpperCase()}${brand[1].toUpperCase()}`;
    }
  };

  const markImagesUnscored = () => {
    const tempImages = brandImages;
    tempImages.forEach(image => {
      image.reviewed = false;
    });

    setBrandImages(tempImages);
  };

  return (
    <Box paddingX="100px" paddingY="50px" display="flex" flexDirection="column" width="100%">
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            display="flex"
            position="absolute"
            sx={{
              transform: 'scale(0.5)',
              animation: !isFinishLoading ? 'scale 2000ms' : '',
              '@keyframes scale': {
                '0%': {
                  transform: 'scale(0.5)',
                },
                '60%': {
                  transform: 'scale(1)',
                },
                '80%': {
                  transform: 'scale(1.3)',
                },
                '100%': {
                  transform: 'scale(1)',
                },
              },
            }}
          >
            <BrandFinalizingIcon />
          </Box>

          <CircularProgress
            variant="determinate"
            sx={{
              color: '#5930A5',
              opacity: 0.3,
              position: 'absolute',
              animation: !isFinishLoading ? 'hide 2000ms ease-in-out forwards' : '',
              '@keyframes hide': {
                '0%': {
                  opacity: 1,
                },
                '60%': {
                  opacity: 0,
                },
                '100%': {
                  opacity: 0,
                },
              },
            }}
            size={250}
            thickness={1}
            value={100}
          />
          <CircularProgress
            variant="indeterminate"
            disableShrink
            sx={{
              color: '#5930A5',
              animationDuration: '5000ms',
              position: 'absolute',
              scale: '-1 1',
              opacity: !isFinishLoading ? 0 : 1,
            }}
            size={250}
            thickness={1}
          />
          <Typography
            fontFamily="Poppins"
            fontSize={24}
            color="#352E65"
            fontWeight={400}
            display="flex"
            position="absolute"
            mt="350px"
          >
            Finalizing...
          </Typography>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{
              animation: 'slide 1000ms ease-in-out forwards',
              '@keyframes slide': {
                '0%': {
                  opacity: 1,
                  transform: 'translateY(45%)',
                },
                '100%': {
                  opacity: 1,
                  transform: 'translateY(0%)',
                },
              },
            }}
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <BrandFinalizingIcon />
              <Box
                display="flex"
                position="absolute"
                width="33px"
                height="33px"
                borderRadius="5px"
                bgcolor="#6439AF"
                justifyContent="center"
                alignItems="center"
              >
                <Typography fontFamily="Poppins" fontSize={16} color="#FFF" fontWeight={400}>
                  {brandShortName()}
                </Typography>
              </Box>
            </Box>
            <Typography
              fontFamily="Poppins"
              fontSize={24}
              color="#352E65"
              fontWeight={400}
              display="flex"
              textAlign="center"
              mt="50px"
            >
              Congratulations, you have created your own
            </Typography>
            <Typography
              fontFamily="Poppins"
              fontSize={24}
              color="#352E65"
              fontWeight={400}
              display="flex"
              textAlign="center"
            >
              AI brand assistant
            </Typography>
            <Typography
              fontFamily="Poppins"
              fontSize={14}
              color={theme.palette.text.primary}
              fontWeight={400}
              display="flex"
              textAlign="center"
              width="80%"
              mt="20px"
            >
              {stylePreference.map(style => {
                return style + ', ';
              })}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" mt="70px">
            <Button
              variant="outlined"
              onClick={() => {
                markImagesUnscored();
                setStep(Steps.review);
              }}
            >
              <Typography
                color={theme.palette.text.primary}
                fontFamily="Poppins"
                fontSize={15}
                fontWeight={500}
              >
                Back
              </Typography>
            </Button>
            <Button
              variant="contained"
              sx={{ bgcolor: '#28205B', ml: '10px' }}
              onClick={() => {
                navigate('/assets');
              }}
              onMouseEnter={() => {
                setButtonHovered(true);
              }}
              onMouseLeave={() => {
                setButtonHovered(false);
              }}
            >
              <Typography
                color={buttonHovered ? '#28205B' : '#FFF'}
                fontFamily="Poppins"
                fontSize={15}
                fontWeight={500}
              >
                Finish
              </Typography>
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};
