import React from 'react';

interface IProps {
  color?: string;
}

export const CloseIconBrand = ({ color }: IProps) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.40002 13.6538L0.346191 12.6L5.94619 7.00002L0.346191 1.40002L1.40002 0.346191L7.00002 5.94619L12.6 0.346191L13.6538 1.40002L8.05384 7.00002L13.6538 12.6L12.6 13.6538L7.00002 8.05384L1.40002 13.6538Z"
      fill={color ?? '#28205B'}
    />
  </svg>
);
