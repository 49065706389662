/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { baseApi } from '.';
import { getRandomColor } from '../../common/helpers';
import { store } from '../store';
import { setVendorsWithColor } from '../store/dashboardSlice';
import { type IVendor } from './types/Vendors';

export const vendorsApi = baseApi.injectEndpoints({
  endpoints: builder => {
    const token = localStorage.getItem('token') ?? '';

    return {
      getVendors: builder.query<IVendor[], number | undefined | void>({
        query: brandId => ({
          url: `/api/brand_monitoring/vendors/${brandId ? `?brand_id=${brandId}` : ''}`,
          method: 'get',
          headers: {
            Authorization: `Token ${token}`,
          },
        }),
        transformResponse: (response: IVendor[]): IVendor[] => {
          const existingVendorsWithColor = store.getState().dashboard.vendorsWithColor || {};
          const updatedVendorsWithColor: Record<string, { color: string }> = {
            ...existingVendorsWithColor,
          };

          const transformedResponse = response.map(vendor => {
            let vendorColor: string | undefined;
            if (updatedVendorsWithColor[vendor.name]) {
              vendorColor = updatedVendorsWithColor[vendor.name].color;
            } else if (!vendor.icon_url) {
              vendorColor = getRandomColor();
              updatedVendorsWithColor[vendor.name] = { color: vendorColor };
            }

            return {
              ...vendor,
              vendorColor,
            };
          });

          if (
            Object.keys(existingVendorsWithColor).length !==
            Object.keys(updatedVendorsWithColor).length
          ) {
            store.dispatch(setVendorsWithColor(updatedVendorsWithColor));
          }

          return transformedResponse;
        },
      }),
    };
  },
});

export const { useGetVendorsQuery } = vendorsApi;
