import React, { useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Avatar, Stack } from '@mui/material';
import { roundGrade } from '../../common/helpers';
import { theme } from '../../theme';
// import moment from 'moment';
import {
  // VendorsGears,
  VendorsImages,
  //  VendorsRefresh
} from '../../assets/icons';
import { getColorAndLabel } from '../Dashboard/Helpers';
import { type IVendor } from '../../pages/Dashboard/Products/Types';
import ArrowUpwardAltIcon from '../../assets/icons/ArrowUpwardAlt';
import { ArrowDownwardAltIcon } from '../../assets/icons/ArrowDownwardAlt';
import { useSubscriptionStatus } from '../../hooks/useSubscriptionStatus';
import UpgradePlanModal from '../Dashboard/Landing/UpgradePlanModal';
import ColoredAvatar from '../Common/ColoredAvatar';

interface ResellerGridItemProps {
  title: string;
  onSortChange?: () => void;
  breakpoint: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
  };
  sortField?: ResellerSortKey;
  sortDirection?: 'asc' | 'desc';
}

interface Props {
  resellers?: IVendor[];
}

type ResellerSortKey =
  | 'name'
  | 'score'
  | 'unrealized_revenue_monthly_usd'
  | 'potentialImpact'
  | 'image_count'
  | '';

const ResellerGridItem = ({
  title,
  breakpoint,
  onSortChange,
  sortField,
  sortDirection,
}: ResellerGridItemProps) => {
  const { xs } = breakpoint;

  let value = '';

  switch (title) {
    case 'Vendor':
      value = 'name';
      break;
    case 'Grade':
      value = 'score';
      break;
    case 'Unrealized Revenue':
      value = 'unrealized_revenue_monthly_usd';
      break;
    case 'Improvement Potential':
      value = 'potentialImpact';
      break;
    case 'Number of images':
      value = 'image_count';
      break;
    default:
      value = '';
  }

  return (
    <Grid
      item
      xs={xs}
      display={'flex'}
      justifyContent={title === 'Actions' ? 'flex-end' : 'flex-start'}
      alignItems={'center'}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        gap={1}
        sx={{
          cursor: onSortChange ? 'pointer' : 'default',
        }}
        onClick={onSortChange}
      >
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            color: sortField === value ? theme.palette.text.primary : theme.palette.text.secondary,
          }}
        >
          {title}
        </Typography>

        {sortField === value &&
          (sortDirection === 'asc' ? <ArrowUpwardAltIcon /> : <ArrowDownwardAltIcon />)}
      </Stack>
    </Grid>
  );
};

const Table = ({ resellers }: Props) => {
  const { freeTrial } = useSubscriptionStatus();
  const [sortField, setSortField] = useState<ResellerSortKey>('image_count');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [upgradeModal, setUpgradeModal] = useState(false);

  const handleSortChange = (key: ResellerSortKey) => {
    if (sortField === key) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(key);
      setSortDirection('asc');
    }
  };

  const handleResellerClick = (index: number) => {
    if (freeTrial && index >= 3) {
      setUpgradeModal(true);
    }
  };

  const sortedResellers = useMemo(() => {
    if (!sortField) return resellers;
    if (resellers && resellers?.length > 0) {
      return [...resellers].sort((a, b) => {
        let valueA = a[sortField as keyof IVendor];
        let valueB = b[sortField as keyof IVendor];

        if (sortField === 'score') {
          valueA = roundGrade(valueA);
          valueB = roundGrade(valueB);
        }

        if (sortField === 'potentialImpact') {
          valueA = (a.unrealized_revenue_monthly_usd / a.current_sales_monthly_usd) * 100;
          valueB = (b.unrealized_revenue_monthly_usd / b.current_sales_monthly_usd) * 100;
        }

        if (valueA < valueB) return sortDirection === 'asc' ? -1 : 1;
        if (valueA > valueB) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      });
    }
  }, [resellers, sortField, sortDirection]);

  return (
    <>
      <Grid container sx={{ mt: '5px' }}>
        <Grid
          container
          item
          sx={{
            px: '24px',
          }}
        >
          <ResellerGridItem
            title="Vendor"
            breakpoint={{ xs: 3 }}
            onSortChange={() => {
              handleSortChange('name');
            }}
            sortField={sortField}
            sortDirection={sortDirection}
          />
          <ResellerGridItem title="Last update" breakpoint={{ xs: 2 }} />
          <ResellerGridItem
            title="Grade"
            breakpoint={{ xs: 1 }}
            onSortChange={() => {
              handleSortChange('score');
            }}
            sortField={sortField}
            sortDirection={sortDirection}
          />
          <ResellerGridItem
            title="Unrealized Revenue"
            breakpoint={{ xs: 2 }}
            onSortChange={() => {
              handleSortChange('unrealized_revenue_monthly_usd');
            }}
            sortField={sortField}
            sortDirection={sortDirection}
          />
          <ResellerGridItem
            title="Number of images"
            breakpoint={{ xs: 2 }}
            onSortChange={() => {
              handleSortChange('image_count');
            }}
            sortField={sortField}
            sortDirection={sortDirection}
          />
          <ResellerGridItem
            title="Improvement Potential"
            breakpoint={{ xs: 2 }}
            onSortChange={() => {
              handleSortChange('potentialImpact');
            }}
            sortField={sortField}
            sortDirection={sortDirection}
          />
        </Grid>
        {sortedResellers?.map((reseller, index) => (
          <Grid
            container
            item
            key={index}
            display={'flex'}
            alignItems={'center'}
            sx={{
              mt: '20px',
              p: '24px 32px',
              bgcolor: 'white',
              borderRadius: '20px',
              filter: freeTrial && index >= 3 ? 'blur(5px)' : 'none',
              cursor: freeTrial && index >= 3 ? 'pointer' : 'default',
            }}
            onClick={() => {
              handleResellerClick(index);
            }}
          >
            <Grid item xs={3} display={'flex'} gap={1} alignItems={'center'}>
              {reseller.icon_url ? (
                <Avatar
                  src={reseller.icon_url}
                  sx={{
                    width: '24px',
                    height: '24px',
                  }}
                />
              ) : (
                <ColoredAvatar
                  name={reseller.name}
                  sx={{ width: '24px', height: '24px', fontSize: '14px' }}
                />
              )}
              <Typography fontSize="18px" fontWeight="400">
                {reseller.name}
              </Typography>
            </Grid>
            <Grid item xs={2} display={'flex'}>
              <Typography
                sx={{ fontWeight: '400', fontSize: '14px', color: theme.palette.text.secondary }}
              >
                {/* {moment(reseller.lastUpdate).fromNow()} */}-
              </Typography>
            </Grid>
            <Grid item xs={1} display={'flex'}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '24px',
                  width: '24px',
                  borderRadius: '24px',
                  bgcolor: getColorAndLabel(roundGrade(reseller.score)).color,
                }}
              >
                <Typography sx={{ fontSize: '16px', fontWeight: '500' }} color={'#fff'}>
                  {roundGrade(reseller.score)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2} display={'flex'}>
              {reseller?.unrealized_revenue_monthly_usd ? (
                <>
                  <Typography fontSize="14px" fontWeight="400">
                    {reseller?.unrealized_revenue_monthly_usd &&
                    reseller?.unrealized_revenue_monthly_usd > 1000
                      ? `${(reseller?.unrealized_revenue_monthly_usd / 1000).toFixed(1)}k`
                      : `${reseller?.unrealized_revenue_monthly_usd}`}
                  </Typography>
                  &nbsp;
                  <Typography fontSize="14px" fontWeight="400" color={theme.palette.text.secondary}>
                    per year
                  </Typography>
                </>
              ) : (
                <Typography fontSize="14px" fontWeight="400" color={theme.palette.text.secondary}>
                  -
                </Typography>
              )}
            </Grid>
            <Grid item xs={2} display="flex" alignItems="center">
              <VendorsImages />
              <Typography fontSize="14px" fontWeight="400" ml="5px">
                {reseller.image_count}
              </Typography>
            </Grid>
            <Grid item xs={2} display={'flex'}>
              <Typography fontSize="14px" fontWeight="400">
                {reseller.unrealized_revenue_monthly_usd && reseller.current_sales_monthly_usd
                  ? `${Math.floor(
                      (reseller.unrealized_revenue_monthly_usd /
                        reseller.current_sales_monthly_usd) *
                        100
                    )}%`
                  : '-'}
              </Typography>
            </Grid>
          </Grid>
        ))}

        {resellers?.length === 0 && (
          <Typography
            fontSize={14}
            fontWeight={400}
            fontFamily={'Poppins'}
            color={'#42485D99'}
            m={'46px auto'}
          >
            No vendor found
          </Typography>
        )}
      </Grid>
      <UpgradePlanModal
        open={upgradeModal}
        handleClose={() => {
          setUpgradeModal(false);
        }}
      />
    </>
  );
};

export default Table;
