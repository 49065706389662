import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Menu,
  type MenuProps,
  alpha,
  styled,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import {
  DotsIconVertical,
  AssetsSelect,
  AssetsEdit,
  AssetsDelete,
} from '../../../../../assets/icons';
import { AssetNotSelected } from '../../../../../assets/icons/AssetNotSelected';
import { AssetSelected } from '../../../../../assets/icons/AssetSelected';
import { type IAsset } from '../../../types';
import { DeleteAssetModal } from './DeleteAssetModal';
import moment from 'moment';

interface IAssetItem {
  assets: IAsset[];
  setSelectedAsset: (value: IAsset | null) => void;
  selectedAsset: IAsset | null;
  listView: boolean;
  hoveredAsset: number;
  setHoveredAsset: (value: number) => void;
  updateAssets: () => void;
  setIsEditAsset: (value: boolean) => void;
  setSelectedId: (value: number) => void;
  selectedId: number;
  setAssetName: (value: string) => void;
  isAssetsLoading: boolean;
}

interface IAssetBox {
  isFullWidth: boolean;
  asset: IAsset;
  children: JSX.Element;
}

const AssetItem = ({
  assets,
  setSelectedAsset,
  selectedAsset,
  listView,
  hoveredAsset,
  setHoveredAsset,
  updateAssets,
  setIsEditAsset,
  setSelectedId,
  selectedId,
  setAssetName,
  isAssetsLoading,
}: IAssetItem) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectAsset = (asset: IAsset) => {
    if (selectedAsset?.url === asset.url) {
      setSelectedAsset(null);
    } else {
      setSelectedAsset(null);
      setTimeout(() => {
        setSelectedAsset(asset);
      }, 0);
    }
  };

  const AssetBox = ({ isFullWidth, asset, children }: IAssetBox) => {
    return (
      <Grid
        item
        xs={isFullWidth ? 12 : 6}
        display="flex"
        onMouseEnter={() => {
          setHoveredAsset(asset.id);
        }}
        onMouseLeave={() => {
          setHoveredAsset(0);
        }}
        position="relative"
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          handleSelectAsset(asset);
        }}
      >
        <Box
          sx={{
            width: isFullWidth ? '30%' : '100%',
            border: hoveredAsset === asset.id ? '2px solid #5930A5' : '2px solid #EFEFF0',
            borderRadius: '5px',
            boxSizing: 'border-box',
            objectFit: 'contain',
            bgcolor: 'white',
          }}
          component="img"
          src={asset.url}
          alt={asset.name}
        />
        {children}
      </Grid>
    );
  };

  const listItem = (asset: IAsset) => {
    return (
      <AssetBox asset={asset} isFullWidth key={asset.id}>
        <>
          <Box display="flex" justifyContent="space-between" flex={1}>
            <Box display="flex" flexDirection="column" justifyContent="center" ml="10px">
              <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '150px' }}>
                <Typography fontFamily="Poppins" fontSize={14} fontWeight={500} noWrap>
                  {asset.name}
                </Typography>
              </Box>
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight={400}
                color="rgba(27, 27, 30, 0.45)"
              >
                {moment(asset.created_at).format('MMM DD, YYYY')}
              </Typography>
            </Box>
          </Box>
          {hoveredAsset === asset.id ? (
            <>
              <Box position="absolute">
                {selectedAsset?.id === asset.id ? <AssetSelected /> : <AssetNotSelected />}
              </Box>
              <Box position="absolute" right="0px" onClick={handleClick} zIndex={2}>
                <DotsIconVertical />
              </Box>
              <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <AssetsSelect />
                  <Typography
                    sx={{ ml: '10px', fontFamily: 'Poppins', fontWeight: 400, fontSize: 16 }}
                  >
                    Select
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    setIsEditAsset(true);
                    setSelectedId(asset.id);
                    setAssetName(asset.name);
                  }}
                >
                  <AssetsEdit />
                  <Typography
                    sx={{ ml: '10px', fontFamily: 'Poppins', fontWeight: 400, fontSize: 16 }}
                  >
                    Edit
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    setOpenModal(true);
                    setSelectedId(asset.id);
                  }}
                >
                  <AssetsDelete />
                  <Typography
                    sx={{ ml: '10px', fontFamily: 'Poppins', fontWeight: 400, fontSize: 16 }}
                  >
                    Delete
                  </Typography>
                </MenuItem>
              </StyledMenu>
            </>
          ) : (
            <Box position="absolute">{selectedAsset?.id === asset.id && <AssetSelected />}</Box>
          )}
        </>
      </AssetBox>
    );
  };

  const gridItem = (asset: IAsset) => {
    return (
      <AssetBox asset={asset} isFullWidth={false} key={asset.id}>
        {hoveredAsset === asset.id ? (
          <>
            <Box position="absolute">
              {selectedAsset?.id === asset.id ? <AssetSelected /> : <AssetNotSelected />}
            </Box>
            <Box position="absolute" bottom="0px" width="95%" bgcolor="#3B1E8E">
              <Typography
                sx={{
                  ml: '10px',
                  fontFamily: 'Poppins',
                  fontWeight: 400,
                  fontSize: 16,
                  overflow: 'hidden',
                  color: 'white',
                }}
              >
                {asset.name}
              </Typography>
            </Box>
            <Box position="absolute" right="0px" onClick={handleClick}>
              <DotsIconVertical />
            </Box>
            <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
              <MenuItem
                onClick={() => {
                  handleClose();
                }}
              >
                <AssetsSelect />
                <Typography
                  sx={{ ml: '10px', fontFamily: 'Poppins', fontWeight: 400, fontSize: 16 }}
                >
                  Select
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  setIsEditAsset(true);
                  setSelectedId(asset.id);
                }}
              >
                <AssetsEdit />
                <Typography
                  sx={{ ml: '10px', fontFamily: 'Poppins', fontWeight: 400, fontSize: 16 }}
                >
                  Edit
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  setOpenModal(true);
                  setSelectedId(asset.id);
                }}
              >
                <AssetsDelete />
                <Typography
                  sx={{ ml: '10px', fontFamily: 'Poppins', fontWeight: 400, fontSize: 16 }}
                >
                  Delete
                </Typography>
              </MenuItem>
            </StyledMenu>
          </>
        ) : (
          <Box position="absolute">{selectedAsset?.id === asset.id && <AssetSelected />}</Box>
        )}
      </AssetBox>
    );
  };

  return (
    <Grid container spacing={1}>
      {isAssetsLoading ? (
        <Grid item>
          <CircularProgress />
        </Grid>
      ) : (
        assets?.map(asset => {
          if (listView) {
            return listItem(asset);
          } else {
            return gridItem(asset);
          }
        })
      )}
      <DeleteAssetModal
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
        }}
        id={selectedId}
        updateAssets={updateAssets}
      />
    </Grid>
  );
};

export default AssetItem;

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 5,
    padding: '5px',
    margin: '5px',
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '1px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: '#81889E',
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha('#5A58CB', theme.palette.action.selectedOpacity),
      },
    },
  },
}));
