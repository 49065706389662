import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, CircularProgress, Slider, Typography } from '@mui/material';
import CanvasNoneMode from './components/CanvasNoneMode';
import CanvasRefine from './components/CanvasRefine';
import {
  AssetsKeep,
  AssetsRedoIcon,
  AssetsRemove,
  AssetsUndoIcon,
  CloseSquareIcon,
  MinusIcon,
  PlusIcon,
} from '../../../../assets/icons';
import { AssetContentContext } from '../../../../contexts';

interface IRefine {
  image: string;
  mode: 'none' | 'restore' | 'removal';
  setRefineMode: (value: 'none' | 'restore' | 'removal') => void;
  lineWidth: number;
  setLineWidth: (value: number) => void;
  setMaskImage: (value: any) => void;
  shape: any[];
  setShape: (value: any[]) => void;
  setImage: any;
  croppedImage: string;
}

export const Refine = ({
  image,
  mode,
  setRefineMode,
  lineWidth,
  setLineWidth,
  setMaskImage,
  shape,
  setShape,
  setImage,
  croppedImage,
}: IRefine) => {
  const [undo, setUndo] = useState(false);
  const [redo, setRedo] = useState(false);
  const [nextShapes, setNextShapes] = useState<any[]>([]);
  const [scale, setScale] = useState(1);
  const [imageSize, setImageSize] = useState({ width: 512, height: 512 });
  const [imageLoaded, setImageLoaded] = useState(false);
  const [img, setImg] = useState<HTMLImageElement>();
  const [correctImageSize, setCorrectImageSize] = useState({ width: 512, height: 512 });
  const [correctImageLoaded, setCorrectImageLoaded] = useState(false);
  const [correctImage, setCorrectImage] = useState<HTMLImageElement>();
  const [startPostion, setStartPostion] = useState(false);

  const canvasRef: React.RefObject<HTMLCanvasElement> = useRef(null);
  const canvasRef2: React.RefObject<HTMLCanvasElement> = useRef(null);
  const modalRef: React.RefObject<HTMLCanvasElement> = useRef(null);
  const brushRef: React.RefObject<HTMLCanvasElement> = useRef(null);

  const boxRef: React.RefObject<HTMLDivElement> = useRef(null);

  const scaleRatio = 10;
  const scaleStep = 0.1;

  useEffect(() => {
    const img = new Image();
    img.src = image;
    img.crossOrigin = 'anonymous';

    img.onload = () => {
      const ratio = img.width / img.height;

      const height = 512;
      const width = height * ratio;

      setImageSize({ width, height });
      setImageLoaded(true);
      setImg(img);
    };
  }, [image]);

  useEffect(() => {
    if (boxRef.current?.clientWidth && boxRef.current?.clientWidth <= imageSize.width * scale) {
      setStartPostion(true);
    } else {
      setStartPostion(false);
    }
  }, [boxRef, imageSize.width, scale]);

  const { getUrlImageWithoutBackground, imageWithoutBackground, loadedFile, correctedImage } =
    useContext(AssetContentContext);

  useEffect(() => {
    const img = new Image();
    img.src = correctedImage || imageWithoutBackground;
    img.crossOrigin = 'anonymous';

    img.onload = () => {
      const ratio = img.width / img.height;

      const height = 512;
      const width = height * ratio;

      setCorrectImageSize({ width, height });
      setCorrectImageLoaded(true);
      setCorrectImage(img);
    };
  }, [correctedImage, imageWithoutBackground]);

  useEffect(() => {
    if (mode === 'none') {
      setScale(1);
    }
  }, [mode]);

  useEffect(() => {
    if (loadedFile.url) {
      getUrlImageWithoutBackground();
    }
  }, [loadedFile]);

  useEffect(() => {
    if (correctedImage) {
      setShape([]);
      setNextShapes([]);
      setLineWidth(10);
      setRefineMode('none');
    }
  }, [correctedImage]);

  const handleSliderChange = (newValue: number) => {
    if (newValue >= 1 && newValue <= 2) {
      setScale(newValue);
    }
  };

  useEffect(() => {
    if (shape.length) {
      setUndo(true);
    } else {
      setUndo(false);
    }
  }, [shape]);

  useEffect(() => {
    if (nextShapes.length) {
      setRedo(true);
    } else {
      setRedo(false);
    }
  }, [nextShapes]);

  const handleRedo = () => {
    if (redo && nextShapes) {
      const shapeCopy = [...shape];
      const nextShapesCopy = [...nextShapes];
      const lastShape = nextShapesCopy[nextShapesCopy.length - 1];
      nextShapesCopy.pop();
      setShape([...shapeCopy, lastShape]);
      if (nextShapesCopy.length) {
        setNextShapes(nextShapesCopy);
      } else {
        setNextShapes([]);
      }
    }
  };

  const handleUndo = () => {
    const canvas = canvasRef.current;
    const brushCanvas = brushRef.current;

    if (canvas && brushCanvas) {
      const brushContext = brushCanvas.getContext('2d', { willReadFrequently: true });

      const img2 = new Image();
      img2.src = correctedImage || imageWithoutBackground;
      img2.crossOrigin = 'anonymous';

      img2.onload = (e: any) => {
        if (brushContext) {
          brushCanvas.width = img2.width;
          brushCanvas.height = img2.height;

          brushContext.fillStyle = 'red';

          brushContext.fillRect(0, 0, img2.width, img2.height);

          brushContext.globalCompositeOperation = 'destination-out';
          brushContext.drawImage(img2, 0, 0, img2.width, img2.height);
          brushContext.globalCompositeOperation = 'source-over';
        }

        if (undo && shape) {
          const shapeCopy = [...shape];
          const lastShape = shapeCopy[shapeCopy.length - 1];
          shapeCopy.pop();
          setShape(shapeCopy);
          if (lastShape) {
            setNextShapes(prev => [...prev, lastShape]);
          }
        } else {
          setShape([]);
        }
      };
    }
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        position="relative"
      >
        {mode === 'removal' && (
          <Button
            variant="contained"
            sx={{
              height: '40px',
              bgcolor: '#5930A5',
              borderRadius: '50px',
              '&:hover': {
                bgcolor: '#2B0E7E',
              },
              position: 'fixed',
              top: '90px',
              zIndex: 4,
            }}
            onClick={() => {
              setRefineMode('none');
            }}
          >
            <AssetsKeep active />
            <Typography
              color={'#FFF'}
              fontFamily={'Poppins'}
              fontWeight={500}
              fontSize={14}
              ml="5px"
            >
              Keep
            </Typography>
            <CloseSquareIcon active />
          </Button>
        )}
        {mode === 'restore' && (
          <Button
            variant="contained"
            sx={{
              height: '40px',
              bgcolor: '#5930A5',
              borderRadius: '50px',
              '&:hover': {
                bgcolor: '#2B0E7E',
              },
              position: 'fixed',
              top: '90px',
              zIndex: 4,
            }}
            onClick={() => {
              setRefineMode('none');
            }}
          >
            <AssetsRemove active />
            <Typography
              color={'#FFF'}
              fontFamily={'Poppins'}
              fontWeight={500}
              fontSize={14}
              ml="5px"
            >
              Remove
            </Typography>
            <CloseSquareIcon active />
          </Button>
        )}
        {mode !== 'none' && (
          <Box
            sx={{
              bgcolor: '#FFF',
              borderRadius: '50px',
              padding: '10px',
              position: 'fixed',
              bottom: '5vh',
              boxShadow: '2',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              zIndex: 4,
            }}
          >
            <Box
              display="flex"
              onClick={handleUndo}
              sx={{
                '&:hover': {
                  cursor: undo ? 'pointer' : 'not-allowed',
                },
              }}
            >
              <AssetsUndoIcon active={undo} />
            </Box>
            <Box
              display="flex"
              onClick={handleRedo}
              sx={{
                '&:hover': {
                  cursor: redo ? 'pointer' : 'not-allowed',
                },
              }}
            >
              <AssetsRedoIcon active={redo} />
            </Box>
          </Box>
        )}
      </Box>
      <Box
        height="calc(100vh - 64px)"
        display="flex"
        flexDirection="column"
        justifyContent={startPostion ? 'flex-start' : 'center'}
        alignItems={startPostion ? 'flex-start' : 'center'}
        position="relative"
        overflow="auto"
        ref={boxRef}
      >
        {mode !== 'none' && (
          <Box
            sx={{
              bgcolor: '#FFF',
              borderRadius: '50px',
              padding: '10px',
              position: 'fixed',
              right: '30px',
              top: '90px',
              boxShadow: '2',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              flexDirection: 'column',
              zIndex: 4,
            }}
          >
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                handleSliderChange(scale + scaleStep);
              }}
            >
              <PlusIcon />
            </Box>

            <Slider
              orientation="vertical"
              defaultValue={scale}
              value={scale * scaleRatio}
              aria-label="Small"
              min={10}
              max={20}
              sx={{ height: '200px', color: '#5930A5', zIndex: 200, marginY: '10px' }}
              onChange={(e: any) => {
                handleSliderChange(e.target.value / scaleRatio);
              }}
            />
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                handleSliderChange(scale - scaleStep);
              }}
            >
              <MinusIcon />
            </Box>
          </Box>
        )}
        {imageLoaded && correctImageLoaded ? (
          <Box
            sx={{
              minHeight: 'calc(100vh - 64px)',
              width: `${imageSize.width * scale}px`,
              height: `${imageSize.height * scale}px`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              top: '0%',
            }}
          >
            <Box
              ref={modalRef}
              sx={{
                height: `${imageSize.height}px`,
                width: `${imageSize.width}px`,
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1,
                transform: `scale(${scale})`,
              }}
            >
              {mode === 'none' ? (
                <CanvasNoneMode
                  imageWithoutBackground={imageWithoutBackground}
                  correctedImage={correctedImage}
                  canvasRef={canvasRef}
                  canvasRef2={canvasRef2}
                  modalRef={modalRef}
                  mode={mode}
                  setImage={setImage}
                  croppedImage={croppedImage}
                  correctImage={correctImage}
                  correctImageSize={correctImageSize}
                />
              ) : (
                <CanvasRefine
                  image={image}
                  correctedImage={correctedImage}
                  imageWithoutBackground={imageWithoutBackground}
                  canvasRef={canvasRef}
                  modalRef={modalRef}
                  lineWidth={lineWidth}
                  shape={shape}
                  setShape={setShape}
                  setMaskImage={setMaskImage}
                  mode={mode}
                  brushRef={brushRef}
                  setNextShapes={setNextShapes}
                  scale={scale}
                  img={img}
                  correctImage={correctImage}
                />
              )}
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '10px',
              flexDirection: 'column',
              position: 'relative',
            }}
          >
            <CircularProgress
              variant="determinate"
              sx={{
                color: '#5930A5',
                opacity: 0.5,
                position: 'absolute',
              }}
              size={200}
              thickness={1}
              value={100}
            />
            <CircularProgress
              variant="indeterminate"
              disableShrink
              sx={{
                color: '#5930A5',
                animationDuration: '2000ms',
                position: 'absolute',
              }}
              size={200}
              thickness={1}
            />
            <Typography
              fontFamily="Poppins"
              fontWeight={400}
              fontSize="18px"
              color="#313234"
              position="relative"
              top="135px"
            >
              Loading asset...
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};
