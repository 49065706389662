import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { Header, Sidebar } from '../../components/blocks';
import { Filter } from './types';
import { PageSidebar, Content, ImageOverlay } from './components';
import { AssetContentContext, BrandsContext, type IAsset } from '../../contexts';

export const Brands = () => {
  const [filterItem, setFilterItem] = useState(Filter.risk);
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);
  const [hoveredItemUrl, setHoveredItemUrl] = useState<string | null>(null);
  const [riskAssets, setRiskAssets] = useState<IAsset[]>([]);
  const [popularAssets, setPopularAssets] = useState<IAsset[]>([]);
  const [open, setOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(0);

  const reviewRef = useRef<HTMLDivElement>(null);

  const { assets } = useContext(AssetContentContext);
  const { selectedBrand } = useContext(BrandsContext);

  useEffect(() => {
    const filteredAssets = assets.filter(asset => asset.brand === selectedBrand.id);
    const riskAssets = filteredAssets.filter(asset => asset.source > 0);
    const popularAssets = filteredAssets.filter(asset => asset.source > 0);

    const tempRiskAssets = riskAssets.sort((a, b) => {
      return a.score - b.score;
    });

    const tempPopularAssets = popularAssets.sort((a, b) => {
      return a.popularity - b.popularity;
    });

    setRiskAssets(tempRiskAssets);
    setPopularAssets(tempPopularAssets);
  }, [assets, selectedBrand]);

  useEffect(() => {
    if (reviewRef.current) {
      const divWidth = reviewRef.current.offsetWidth;
      setScreenWidth(divWidth);
    }
  }, [reviewRef]);

  return (
    <>
      <Sidebar />
      <Header />
      <Box
        sx={{
          pl: { xs: '0px', lg: '80px' },
        }}
        ref={reviewRef}
      >
        <Box
          display="flex"
          height="calc(100vh - 64px)"
          width={{ xs: '100%', md: 'calc(100vw - 80px)' }}
        >
          <PageSidebar
            filterItem={filterItem}
            setFilterItem={setFilterItem}
            assetsLength={filterItem === Filter.risk ? riskAssets.length : popularAssets.length}
          />
          <Content
            filterItem={filterItem}
            hoveredItem={hoveredItem}
            setHoveredItem={setHoveredItem}
            riskAssets={riskAssets}
            popularAssets={popularAssets}
            setOpenImageOverlay={setOpen}
          />
        </Box>
        {open && (
          <ImageOverlay
            open={open}
            setOpen={setOpen}
            riskAssets={riskAssets}
            popularAssets={popularAssets}
            hoveredItem={hoveredItem}
            screenWidth={screenWidth}
            filterItem={filterItem}
            setHoveredItem={setHoveredItem}
            setHoveredItemUrl={setHoveredItemUrl}
            setFilterItem={setFilterItem}
            hoveredItemUrl={hoveredItemUrl}
          />
        )}
      </Box>
    </>
  );
};
