import React, { useContext } from 'react';
import { Box } from '@mui/material';
import Card from '@mui/material/Card';
import { FileContentContext } from '../../../../../../contexts';

interface IGeneratedCard {
  image: string;
  clickedImage: string;
  setClickedImage: (value: string) => void;
  setEditAsset: (value: boolean) => void;
  setIsClicked: () => void;
}

export default function GeneratedCard({
  image,
  clickedImage,
  setClickedImage,
  setEditAsset,
  setIsClicked,
}: IGeneratedCard) {
  const { handleContentVal, fileContentVal } = useContext(FileContentContext);

  return (
    <Card
      onClick={() => {
        // Check browser, Safari cant support smooth behavior
        window.scrollTo({
          top: 0,
          behavior:
            !navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')
              ? 'auto'
              : 'smooth',
        });
        setEditAsset(false);
        setClickedImage(image);
        setIsClicked();
        if (fileContentVal !== image) {
          handleContentVal(image);
        }
      }}
      sx={{
        cursor: 'pointer',
        borderRadius: '10px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          opacity: image === clickedImage ? 1 : 0.5,
        }}
        component="img"
        src={image}
      />
    </Card>
  );
}
