import { createTheme } from '@mui/material';

interface CustomPalette {
  neutralGray: Record<number, string>;
  onSurface: string;
  brandPurple: Record<number, string>;
  neutralWhite: string;
  neutralSubtleMore: string;
  surfaceSuccess: string;
  surfaceDestructive: string;
  borderActionsNormal: string;
  button: Record<string, string>;
  score: Record<string, string>;
  accentGreen: string;
  accentRed: string;
}

declare module '@mui/material/styles' {
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

export let theme = createTheme({
  typography: {
    fontFamily: ['Poppins', 'Space Mono'].join(','),
  },
  palette: {
    common: { black: '#1D2028', white: '#fff' },
    action: { disabled: 'grey', focusOpacity: 0 },
    grey: {
      300: '#EFF0F3',
      400: '#E0E1E7',
      500: '#A1A6B6',
      600: '#81889E',
    },
    brandPurple: {
      1: '#7A16F9',
      3: '#21192B',
    },
    neutralWhite: '#FFF',
    neutralGray: {
      1: '#F4F4F4',
      2: '#DDDDDD',
      3: '#A4A4A4',
    },
    score: {
      low: '#EE3939',
      medium: '#FFB31A',
      high: '#40C6A7',
    },
    neutralSubtleMore: '#F8F8FB',
    surfaceSuccess: '#F4FCFA',
    surfaceDestructive: '#FEF3F3',
    borderActionsNormal: '#E9E9F0',
    button: {
      destructiveTertiary: '#EE39391A',
    },
    onSurface: '#21192B',
    primary: {
      main: '#754DBA',
      light: '#9382FF',
    },
    accentGreen: '#267E6A',
    accentRed: '#AE0F0F',
    secondary: {
      main: '#4733FF',
    },
    text: {
      primary: '#171D30',
      secondary: '#42485D99',
    },
  },
});

theme = createTheme(theme, {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'unset',
          whiteSpace: 'nowrap',
          boxShadow: 'unset',
          borderRadius: '21px',
          padding: '7px 20px',
          transition: 'all .15s',
          '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: 'unset',
          },
        },
        contained: {
          backgroundColor: '#5A58CB',
          border: `1px solid ${'#5A58CB'}`,
          color: theme.palette.common.white,
          fontWeight: 600,
          '&:hover': {
            color: '#5A58CB',
          },
        },
        outlined: {
          borderColor: theme.palette.grey[400],
          color: theme.palette.common.black,
          fontWeight: 500,
          '&:hover': {
            color: '#5A58CB',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
          fontSize: 14,
          fontWeight: 500,
          fontFamily: 'Poppins',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          border: `1px solid ${theme.palette.grey[400]}`,
          color: theme.palette.common.black,
          transition: 'all .15s',
          '& svg': {
            width: 19,
            height: 19,
          },
          '&:hover': {
            backgroundColor: 'transparent',
            color: '#5A58CB',
            borderColor: '#5A58CB',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          color: 'inherit',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 38,
          height: 22,
          padding: 0,
          margin: 8,
        },
        switchBase: {
          padding: 1,
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: '#000',
              opacity: 1,
              border: 'none',
            },
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        thumb: {
          width: 20,
          height: 20,
        },
        track: {
          borderRadius: 13,
          backgroundColor: '#E9E9EA',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      },
    },
  },
});

// Default breakpoints
// xs, extra-small: 0px.
// sm, small: 600px.
// md, medium: 900px.
// lg, large: 1200px.
// xl, extra-large: 1536px.
