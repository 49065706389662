import React, { useState } from 'react';
import { Box, Button, TextField } from '@mui/material';

interface IRefineSearch {
  requestQuery: string;
  setBrand: (value: string) => void;
  setIsRefineSearch: (value: boolean) => void;
  setSelectedChips: (value: string[]) => void;
  setRequestQuery: (value: string) => void;
  setLoading: (value: boolean) => void;
}

export const RefineSearch = ({
  requestQuery,
  setBrand,
  setIsRefineSearch,
  setSelectedChips,
  setRequestQuery,
  setLoading,
}: IRefineSearch) => {
  const [newRequest, setNewRequest] = useState('');

  return (
    <Box display="flex" flexDirection="column" width="80%" alignSelf="center">
      <Box display="flex" flexDirection="column">
        <TextField
          label="Search request"
          defaultValue={requestQuery}
          variant="standard"
          InputProps={{ sx: { fontSize: 24 } }}
          InputLabelProps={{ sx: { fontSize: 18 } }}
          onChange={e => {
            setNewRequest(e.target.value);
          }}
        />
      </Box>
      <Box display="flex" justifyContent="space-between" mt="10px">
        <Button
          variant="outlined"
          sx={{
            width: '100px',
            mt: '20px',
            '&: disabled': {
              border: '0px',
              color: 'white',
              bgcolor: '#f4f2f7',
            },
          }}
          onClick={() => {
            setIsRefineSearch(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{
            width: '100px',
            bgcolor: '#28205B',
            mt: '20px',
            '&: disabled': {
              border: '0px',
              color: 'white',
              bgcolor: '#f4f2f7',
            },
          }}
          disabled={!newRequest || newRequest.length < 3}
          onClick={() => {
            if (newRequest) {
              setLoading(true);
              setRequestQuery(newRequest);
              setBrand(newRequest);
              setSelectedChips([]);
            }
            setIsRefineSearch(false);
          }}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};
