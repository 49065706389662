import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Slider from '@mui/material/Slider';
import TableRowsOutlined from '@mui/icons-material/TableRowsOutlined';
import Search from '@mui/icons-material/Search';
import GridView from '@mui/icons-material/GridView';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import WindowRounded from '@mui/icons-material/WindowRounded';
import AppsRounded from '@mui/icons-material/AppsRounded';

import { useAppDispatch, useAppSelector } from '../../hooks/useStore';
import {
  setFilterOrdering,
  setFilterTitle,
  setGridColumns,
  setView,
} from '../../core/store/filterSlice';
import type { ViewType } from '../../core/api/types/Product';
import { sliderStyle } from '../../common/constants/Constants';
import useOnboardingSearchParam from '../../hooks/useOnboardingSearchParam';
import Box from '@mui/material/Box';

const SORTING_OPTIONS = [
  { label: 'Score - Asc', value: 'score' },
  { label: 'Score - Desc', value: '-score' },
];

interface Props {
  minGridColumn?: number;
  maxGridColumn?: number;
}

function SearchHeader({ minGridColumn = 2, maxGridColumn = 4 }: Props) {
  const dispatch = useAppDispatch();

  const { isOnboarding } = useOnboardingSearchParam();
  const { ordering: filterOrdering, view, gridColumns } = useAppSelector(state => state.filter);

  const [sortingAnchorEl, setSortingAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(sortingAnchorEl);

  const handleSearchChange = (searchVal: string) => {
    dispatch(setFilterTitle(searchVal));
  };

  const handleSortChange = (option: (typeof SORTING_OPTIONS)[number] | null) => {
    dispatch(setFilterOrdering(option?.value));
    setSortingAnchorEl(null);
  };

  const selectedSortBy = SORTING_OPTIONS.find(opt => opt.value === filterOrdering);

  const handleViewChange = (newView: ViewType) => {
    dispatch(setView(newView));
  };

  return (
    <Box
      sx={{
        position: 'sticky',
        top: '0px',
        zIndex: 999,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '42px',
          backgroundColor: 'transparent',
          backdropFilter: 'blur(3px)',
          transition: 'background-color 0.3s ease, backdrop-filter 0.3s ease',
          zIndex: 1,
        }}
      />

      <Stack
        mt={'16px'}
        direction={'row'}
        alignItems={'center'}
        gap={'8px'}
        sx={{
          position: 'relative',
          borderRadius: '16px',
          bgcolor: '#FFFFFF',
          p: '8px 8px 8px 16px',
          zIndex: 2,
        }}
      >
        <TextField
          fullWidth
          disabled={Boolean(isOnboarding)}
          placeholder={'Search by text, source, etc...'}
          onChange={e => {
            handleSearchChange(e.target.value);
          }}
          sx={{
            border: 'none',
            '& fieldset': {
              border: 'none',
              borderRadius: '8px',
            },
            '& input': {
              py: '14px',
              fontSize: '14px',
              color: '#2C3347',
            },
            '& svg': {
              color: '#2C334766',
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <Stack
          flex={1}
          direction={'row'}
          alignItems={'center'}
          justifyContent={'flex-end'}
          gap={'16px'}
        >
          <Button
            onClick={e => {
              setSortingAnchorEl(e.currentTarget);
            }}
            endIcon={<ArrowDropDown />}
            disabled={Boolean(isOnboarding)}
            sx={{
              border: 'none',
              p: '6px 10px',
              fontSize: 14,
              fontWeight: 400,
              color: '#171D30',
              gap: 0.5,
              width: { xs: '100%', md: 'auto' },
              label: { color: 'secondary.light', cursor: 'pointer' },
              '.MuiButton-endIcon': { ml: 0 },
              svg: {
                color: '#42485D99',
              },
              ':hover': {
                bgcolor: 'surfaceContainerHigh',
              },
            }}
          >
            Sort by {selectedSortBy?.label}
          </Button>
          <Menu
            anchorEl={sortingAnchorEl}
            open={open}
            disableScrollLock
            onClose={e => {
              setSortingAnchorEl(null);
            }}
          >
            <MenuItem
              selected={!filterOrdering}
              onClick={() => {
                handleSortChange(null);
              }}
            >
              None
            </MenuItem>
            {SORTING_OPTIONS.map(option => (
              <MenuItem
                key={option.value}
                selected={filterOrdering === option.value}
                onClick={() => {
                  handleSortChange(option);
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Menu>
          <Stack direction={'row'} alignItems={'center'} gap={'4px'}>
            <IconButton
              disabled={Boolean(isOnboarding)}
              onClick={() => {
                handleViewChange('grid');
              }}
              sx={iconBtnStyle}
              {...(view === 'grid' && {
                className: 'active',
              })}
            >
              <GridView />
            </IconButton>
            <IconButton
              disabled={Boolean(isOnboarding)}
              onClick={() => {
                handleViewChange('list');
              }}
              sx={iconBtnStyle}
              {...(view === 'list' && {
                className: 'active',
              })}
            >
              <TableRowsOutlined />
            </IconButton>
          </Stack>
          <Stack direction={'row'} alignItems={'center'} gap={'4px'}>
            <IconButton sx={iconBtnStyle} disableRipple>
              <WindowRounded />
            </IconButton>
            <Slider
              value={gridColumns}
              onChange={(_e, value) => {
                dispatch(setGridColumns(value as number));
              }}
              min={minGridColumn}
              max={maxGridColumn}
              disabled={Boolean(isOnboarding) || view === 'list'}
              step={1}
              sx={{
                ...sliderStyle,
                width: 100,
                '& .MuiSlider-thumb': {
                  transition: 'left 0.1s ease-out',
                  '&:hover, &.Mui-focusVisible, &.Mui-active': {
                    boxShadow: '0px 0px 0px 6px #fff, 0 0 0px 7px #e4e4e6',
                    backgroundColor: '#6C7187',
                  },
                },
                '& .MuiSlider-track': {
                  transition: 'width 0.1s ease-out',
                },
              }}
            />
            <IconButton sx={iconBtnStyle} disableRipple>
              <AppsRounded />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}

export default SearchHeader;

const iconBtnStyle = {
  p: '8px',
  border: 'none',
  borderRadius: '10px',
  svg: {
    color: '#42485D99',
  },
  '&.active': {
    bgcolor: '#171D300A',
    svg: {
      color: '#171D30',
    },
  },
};
