import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './useStore';
import useDebounce from './useDebounce';
import { initialState as initialFilterState, resetAllFilters } from '../core/store/filterSlice';
import { useGetProductsQuery } from '../core/api/product';
import { useGetImagesQuery } from '../core/api/images';
import type { IProductImage, IProductImageFilter } from '../core/api/types/Product';
import type { ImageData, GetImagesResponse } from '../core/api/types/Images';
import useOnboardingSearchParam from './useOnboardingSearchParam';

type ItemType = 'products' | 'images';

type FilteredItem = IProductImage | ImageData;
type FilteredItemResponse = IProductImageFilter | GetImagesResponse;

export function useFilteredItems(itemType: ItemType) {
  const dispatch = useAppDispatch();

  const { brandId } = useOnboardingSearchParam();

  const PAGINATION_LIMIT = itemType === 'images' ? 20 : 12;
  const filters = useAppSelector(state => state.filter);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { title, reseller, ordering = 'score', score_gte, score_lte, diagnostics } = filters;
  const searchTitle = useDebounce(title, 500);

  const [allItems, setAllItems] = useState<FilteredItem[]>([]);
  const [fetchingType, setFetchingType] = useState<'filter' | 'pagination' | null>(null);

  const [params, setParams] = useState({
    offset: 0,
    limit: PAGINATION_LIMIT,
    title: searchTitle,
    reseller,
    ordering,
    score_gte,
    score_lte,
    diagnostics,
    brand_id: Number(brandId),
  });

  useEffect(() => {
    dispatch(resetAllFilters());
  }, []);

  const useQueryHook = itemType === 'products' ? useGetProductsQuery : useGetImagesQuery;
  const { data, isLoading, isFetching } = useQueryHook(params, {
    refetchOnMountOrArgChange: true,
  }) as {
    data: FilteredItemResponse | undefined;
    isLoading: boolean;
    isFetching: boolean;
  };

  useEffect(() => {
    if (data?.results) {
      const transformedResults = data.results.map(item => ({
        ...item,
        type: itemType === 'products' ? 'product' : 'image',
      })) as FilteredItem[];

      if (params.offset === 0) {
        setAllItems(transformedResults);
      } else {
        setAllItems(prevItems => [...prevItems, ...transformedResults]);
      }
    }
  }, [data?.results, itemType]);

  useEffect(() => {
    setFetchingType('filter');
    setParams({
      offset: 0,
      limit: PAGINATION_LIMIT,
      title: searchTitle,
      reseller,
      ordering,
      score_gte,
      score_lte,
      diagnostics,
      brand_id: Number(brandId),
    });
  }, [searchTitle, reseller, ordering, score_gte, score_lte, diagnostics, brandId]);

  const handleNextPage = () => {
    if (data?.next) {
      setFetchingType('pagination');
      setParams(prevParams => ({
        ...prevParams,
        offset: prevParams.offset + PAGINATION_LIMIT,
      }));
    }
  };

  const handlePrevPage = () => {
    if (data?.previous) {
      setFetchingType('pagination');
      setParams(prevParams => ({
        ...prevParams,
        offset: prevParams.offset - PAGINATION_LIMIT,
      }));
    }
  };

  const allowResetFilter =
    filters.reseller !== initialFilterState.reseller ||
    !!filters.diagnostics?.length ||
    filters.score_gte !== initialFilterState.score_gte ||
    filters.score_lte !== initialFilterState.score_lte;

  const hasMore = !!data?.next;
  const hasPrev = !!data?.previous;
  const count = data?.count ?? 0;

  return {
    items: allItems,
    isLoading,
    isFetching,
    fetchingType,
    handleNextPage,
    handlePrevPage,
    hasMore,
    hasPrev,
    count,
    allowResetFilter,
  };
}
