import React, { useRef, useState } from 'react';
import {
  Avatar,
  Button,
  ClickAwayListener,
  Grow,
  IconButton,
  LinearProgress,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import useLogout from '../../hooks/useLogout';
import { NewLogo } from '../../assets/icons';
import Settings from '../../assets/icons/Dashboard/Settings';
import { HelpOutline } from '@mui/icons-material';

import BrandsSelectDropdown from './BrandsSelectDropdown';
import useOnboardingSearchParam from '../../hooks/useOnboardingSearchParam';
import { useSubscriptionStatus } from '../../hooks/useSubscriptionStatus';
import UpgradePlanModal from './Landing/UpgradePlanModal';
import { useAppDispatch } from '../../hooks/useStore';
import { resetDashboardStates } from '../../core/store/dashboardSlice';
import { UserIcon } from '../../assets/icons/UserIcon';

export const Header = () => {
  const dispatch = useAppDispatch();
  const [isMenuShown, setIsMenuShown] = useState(false);
  const menuAnchorRef = useRef<HTMLDivElement | null>(null);
  const { user, freeTrial, trialProgress } = useSubscriptionStatus();
  const navigate = useNavigate();
  const location = useLocation();
  const logout = useLogout();
  const [upgradeModal, setUpgradeModal] = useState(false);

  const { brandId } = useOnboardingSearchParam();

  const handleLogout = () => {
    dispatch(resetDashboardStates());
    logout();
  };

  const path = location.pathname;

  const links = [
    { name: 'Overview', path: '/dashboard', isActive: path === '/dashboard' || path === '/' },
    {
      name: 'Products',
      path: '/dashboard/products',
      isActive: path === '/dashboard/products',
    },
    {
      name: 'Vendors',
      path: '/dashboard/vendors',
      isActive: path === '/dashboard/vendors',
    },
    {
      name: 'Actions',
      path: '/dashboard/actions',
      isActive: path === '/dashboard/actions',
    },
  ];

  const handleNavigation = (path: string) => {
    const currentParams = new URLSearchParams(location.search);

    navigate({
      pathname: path,
      search: currentParams.toString(),
    });
  };

  return (
    <Stack
      direction={'row'}
      alignItems="center"
      justifyContent="space-between"
      height="64px"
      bgcolor="#FFF"
      boxSizing="border-box"
      borderBottom="1px solid #EFEFF0"
      borderRadius={'16px'}
      px={'12px'}
      m={'8px'}
    >
      <Stack
        direction={'row'}
        alignItems="center"
        sx={{ cursor: 'pointer' }}
        px={'8px'}
        gap={'32px'}
      >
        <Stack
          direction={'row'}
          alignItems="center"
          onClick={() => {
            brandId ? navigate(`/dashboard?brand_id=${brandId}`) : navigate('/dashboard');
          }}
        >
          <NewLogo />
          <Typography
            fontFamily="Lexend Deca"
            fontSize="18px"
            fontWeight={500}
            lineHeight="30px"
            ml="10px"
          >
            focalstudio
          </Typography>
          {/* <Typography sx={{ fontSize: 14 }} ml={1} mt={1} fontWeight={'bold'}>
            beta
          </Typography> */}
        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap={'8px'} position="relative">
          {links.map(link => (
            <Typography
              key={link.name}
              p={'10px 12px'}
              fontSize="14px"
              fontWeight={500}
              color={link.isActive ? '#000' : 'text.secondary'}
              lineHeight="30px"
              sx={{ cursor: 'pointer', position: 'relative' }}
              onClick={() => {
                if (link.name === 'Actions' && freeTrial) {
                  setUpgradeModal(true);
                } else {
                  handleNavigation(link.path);
                }
              }}
            >
              {link.name}
              {link.isActive && (
                <span
                  style={{
                    position: 'absolute',
                    bottom: '-6px',
                    left: '0',
                    right: '0',
                    height: '4px',
                    margin: '0px 10px',
                    backgroundColor: '#7C4DFF',
                    borderRadius: '8px 8px 0 0',
                  }}
                />
              )}
            </Typography>
          ))}
        </Stack>
      </Stack>
      {user ? (
        <>
          <Stack direction={'row'} alignItems="center" spacing={1}>
            <BrandsSelectDropdown />
            {freeTrial && (
              <Stack direction={'row'} gap={'16px'}>
                {trialProgress?.daysLeft !== 0 && (
                  <Stack gap={'4px'}>
                    <Typography fontSize={12} fontWeight={400} lineHeight={'16px'} color={'#000'}>
                      {trialProgress?.daysLeft} days left
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={trialProgress?.progress}
                      sx={{
                        width: '132px',
                        height: '8px',
                        borderRadius: '4px',
                        bgcolor: '#F3F3F7',
                        '& .MuiLinearProgress-bar': {
                          borderRadius: '4px',
                          bgcolor: '#6C7187',
                          transition: 'transform 0.4s linear',
                        },
                      }}
                    />
                  </Stack>
                )}

                <Button
                  onClick={() => {
                    setUpgradeModal(true);
                  }}
                  variant="contained"
                  sx={{
                    bgcolor: 'text.primary',
                    borderColor: 'text.primary',
                    borderRadius: '10px',
                    width: '90px',
                    fontSize: '14px',
                    fontWeight: 500,
                    letterSpacing: '-0.07px',
                    lineHeight: '20px',
                    height: '32px',
                    ':hover': {
                      bgcolor: '#575C70',
                      color: '#FFFFFF',
                    },
                  }}
                >
                  Upgrade
                </Button>
              </Stack>
            )}

            <IconButton sx={{ border: 'none' }}>
              <Settings />
            </IconButton>
            <IconButton sx={{ border: 'none' }}>
              <HelpOutline sx={{ color: '#42485D99' }} />
            </IconButton>

            {user?.avatar ? (
              <Avatar
                alt={'user'}
                src={user?.avatar}
                ref={menuAnchorRef}
                onClick={() => {
                  setIsMenuShown(true);
                }}
                sx={{
                  cursor: 'pointer',
                  width: '40px',
                  height: '40px',
                }}
              />
            ) : (
              <Stack
                ref={menuAnchorRef}
                onClick={() => {
                  setIsMenuShown(true);
                }}
                sx={{
                  display: 'flex',
                  width: 32,
                  height: 32,
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexShrink: 0,
                  borderRadius: '360px',
                  background: '#80839B',
                  cursor: 'pointer',
                }}
              >
                <UserIcon />
              </Stack>
            )}
          </Stack>

          <Popper
            open={isMenuShown}
            anchorEl={menuAnchorRef.current}
            role={undefined}
            placement={'top-end'}
            transition
            sx={{ zIndex: 2 }}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: 'right top',
                }}
              >
                <Paper sx={{ border: '1px solid #E3E3E3', borderRadius: '10px' }} elevation={1}>
                  <ClickAwayListener
                    onClickAway={() => {
                      setIsMenuShown(false);
                    }}
                  >
                    <MenuList autoFocusItem={isMenuShown} sx={{ width: '100%' }}>
                      <MenuItem
                        onClick={() => {
                          navigate('/profile');
                        }}
                      >
                        <Typography fontFamily="Figtree" fontSize={16} sx={{ paddingX: '20px' }}>
                          Profile
                        </Typography>
                      </MenuItem>
                      <MenuItem onClick={handleLogout}>
                        <Typography fontFamily="Figtree" fontSize={16} sx={{ paddingX: '20px' }}>
                          Log out
                        </Typography>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      ) : (
        <Button
          onClick={() => {
            navigate('/signin');
          }}
          sx={{
            color: '#1a1b1f',
            letterSpacing: 0.25,
            borderRadius: 99,
            marginLeft: '.33rem',
            marginRight: '.33rem',
            padding: '.5rem 1rem',
            fontSize: '1rem',
            fontWeight: 600,
            lineHeight: '120%',
            textDecoration: 'none',
            transition: 'all .3s',
            display: 'block',
            '&:hover': {
              backgroundColor: 'rgba(26, 27, 31, .08)',
            },
          }}
        >
          Sign in
        </Button>
      )}

      <UpgradePlanModal
        open={upgradeModal}
        handleClose={() => {
          setUpgradeModal(false);
        }}
      />
    </Stack>
  );
};
