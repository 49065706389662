import React, { useContext, useState } from 'react';
import { Box, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DeleteChatModal } from './DeleteChatModal';
import { ConversationContext } from '../../../../contexts';

interface IChatHeader {
  deleteChat: () => void;
}

export const ChatHeader = ({ deleteChat }: IChatHeader) => {
  const [openModal, setOpenModal] = useState(false);
  const { themeId, setThemeId } = useContext(ConversationContext);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="65px"
      bgcolor="#FFF"
      boxSizing="border-box"
      borderBottom="1px solid #EFEFF0"
      borderRight="1px solid #EFEFF0"
    >
      <Box display="flex" width="90%" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography fontSize={22} color="#313234" fontFamily="Figtree" fontWeight={500}>
            Chat with Focal Studio
          </Typography>
        </Box>
        <DeleteIcon
          sx={{
            fontSize: '25px',
            color: 'lightgrey',
            '&:hover': {
              cursor: themeId && themeId !== 0 ? 'pointer' : 'not-allowed',
            },
          }}
          onClick={() => {
            if (themeId && themeId !== 0) {
              setOpenModal(!openModal);
            }
          }}
        />
      </Box>
      <DeleteChatModal
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
        }}
        handleDelete={deleteChat}
        themeId={themeId}
        setThemeId={setThemeId}
      />
    </Box>
  );
};
