import React from 'react';

export const MoveAssetIcon: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.79951 6.20049L1.99951 8.00049M1.99951 8.00049L3.79951 9.80049M1.99951 8.00049H13.9995M6.19951 3.80049L7.99951 2.00049M7.99951 2.00049L9.79951 3.80049M7.99951 2.00049V14.0005M9.79951 12.2005L7.99951 14.0005M7.99951 14.0005L6.19951 12.2005M12.1995 6.20049L13.9995 8.00049M13.9995 8.00049L12.1995 9.80049"
      stroke="#61646B"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
