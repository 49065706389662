import React from 'react';

export const MainIcon: React.FC = () => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M68.6667 90H27.4728C24.7802 90 23.434 90 22.8106 89.4676C22.2697 89.0056 21.9826 88.3126 22.0384 87.6034C22.1028 86.7861 23.0547 85.8342 24.9586 83.9303L62.7495 46.1394C64.5095 44.3794 65.3896 43.4993 66.4044 43.1696C67.297 42.8796 68.2586 42.8796 69.1512 43.1696C70.166 43.4993 71.046 44.3794 72.8061 46.1394L90 63.3333V68.6667M68.6667 90C76.134 90 79.8677 90 82.7199 88.5468C85.2287 87.2684 87.2684 85.2287 88.5468 82.7199C90 79.8677 90 76.134 90 68.6667M68.6667 90H31.3333C23.866 90 20.1323 90 17.2801 88.5468C14.7713 87.2684 12.7316 85.2287 11.4532 82.7199C10 79.8677 10 76.134 10 68.6667V31.3333C10 23.866 10 20.1323 11.4532 17.2801C12.7316 14.7713 14.7713 12.7316 17.2801 11.4532C20.1323 10 23.866 10 31.3333 10H68.6667C76.134 10 79.8677 10 82.7199 11.4532C85.2287 12.7316 87.2684 14.7713 88.5468 17.2801C90 20.1323 90 23.866 90 31.3333V68.6667M43.3333 34.4444C43.3333 39.3536 39.3536 43.3333 34.4444 43.3333C29.5352 43.3333 25.5556 39.3536 25.5556 34.4444C25.5556 29.5352 29.5352 25.5556 34.4444 25.5556C39.3536 25.5556 43.3333 29.5352 43.3333 34.4444Z"
      stroke="#3B1E8E"
      strokeWidth="8.325"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
