import React from 'react';
import { Box, Button, FormControl, InputAdornment, TextField, Typography } from '@mui/material';
import { CloseSquareIcon, PackageIcon } from '../../../../../assets/icons';

interface IEditAsset {
  assetNameError: boolean;
  assetName: string;
  setAssetName: (value: string) => void;
  setIsEditAsset: (value: boolean) => void;
  handleEditAsset: () => void;
}

const EditAsset = ({
  assetNameError,
  setAssetName,
  setIsEditAsset,
  handleEditAsset,
  assetName,
}: IEditAsset) => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
      <Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          paddingLeft="20px"
          paddingRight="10px"
          paddingY="20px"
        >
          <Typography
            display="flex"
            alignItems="center"
            fontSize={26}
            color="#313234"
            fontFamily={'Poppins'}
            fontWeight={500}
          >
            Update Asset
          </Typography>
          <Box
            sx={{
              '&: hover': {
                cursor: 'pointer',
              },
            }}
            onClick={() => {
              setIsEditAsset(false);
            }}
          >
            <CloseSquareIcon />
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" mt="20px">
          <FormControl sx={{ width: '90%' }}>
            <Typography
              color={assetNameError ? 'red' : '#313234'}
              fontFamily={'Poppins'}
              fontWeight={400}
              fontSize={20}
            >
              {assetNameError ? 'This field required' : 'Name Asset'}
            </Typography>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              placeholder={'e.g. “bottle”, “can”, “sneakers”, etc.'}
              defaultValue={assetName}
              sx={{
                mt: '10px',
                width: '100%',
                border: assetNameError ? '2px solid red' : '1px solid #AFB1B6',
                borderRadius: '8px',
                '& fieldset': {
                  border: 0,
                  borderRadius: '8px',
                },
                '& input': {
                  py: '14px',
                  fontSize: '14px',
                },
                '& svg': {
                  color: 'common.black',
                },

                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    border: assetNameError ? '1px solid transparent' : '1px solid #3B1E8E',
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PackageIcon />
                  </InputAdornment>
                ),
              }}
              onChange={e => {
                setAssetName(e.target.value);
              }}
            />
          </FormControl>
        </Box>
      </Box>
      <Box display="flex" mb="20px" justifyContent="space-around">
        <Button
          variant="outlined"
          sx={{ width: '45%', borderRadius: '5px' }}
          onClick={() => {
            setIsEditAsset(false);
          }}
        >
          <Box color={'#313234'} fontFamily={'Poppins'} fontWeight={500} fontSize={14}>
            Cancel
          </Box>
        </Button>
        <Button
          variant="contained"
          sx={{ width: '45%', bgcolor: '#5930A5', borderRadius: '5px' }}
          onClick={handleEditAsset}
          disabled={assetNameError}
        >
          <Box color={'#FFF'} fontFamily={'Poppins'} fontWeight={500} fontSize={14}>
            Save
          </Box>
        </Button>
      </Box>
    </Box>
  );
};

export default EditAsset;
