import React, { useContext, useEffect } from 'react';
import Conversation from './Conversation';

import { Box } from '@mui/material';
import { ImagsGeneratedContext } from '../../../../contexts';

interface IImagesArray {
  url: string;
  id: string;
}
interface IChat {
  conversation: any;
  setConversation: (value: any) => void;
  requestError: boolean;
  isLoading: boolean;
  setModalImage: (value: string) => void;
  modalImage: string;
  handleCancelRequest: () => void;
  assets: IImagesArray[];
  setAssets: (value: IImagesArray[]) => void;
  setEditAsset: (value: boolean) => void;
}

const Chat = ({
  conversation,
  setConversation,
  requestError,
  isLoading,
  setModalImage,
  modalImage,
  handleCancelRequest,
  assets,
  setAssets,
  setEditAsset,
}: IChat) => {
  const { imagesGenerated } = useContext(ImagsGeneratedContext);

  useEffect(() => {
    const lastImageGenerated = imagesGenerated[imagesGenerated.length - 1];

    const conversationStore = [...conversation];

    conversationStore.forEach((item, index) => {
      if (item?.ids?.find((item: any) => item === lastImageGenerated?.id)) {
        conversationStore[index] = {
          ...item,
          isLoading: item?.ids?.length === item?.images?.length,
          images: item?.images ? [...item?.images, lastImageGenerated] : [lastImageGenerated],
        };
      }
    });

    setConversation([...conversationStore]);
  }, [imagesGenerated.length]);

  return (
    <Box
      sx={{
        height: isLoading ? 'calc(100vh - 416px)' : 'calc(100vh - 316px)',
        bgcolor: '#FFF',
        boxSizing: 'border-box',
        borderRight: '1px solid #EFEFF0',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ height: isLoading ? 'calc(100vh - 416px)' : 'calc(100vh - 316px)', width: '90%' }}>
        <Conversation
          conversation={conversation}
          requestError={requestError}
          setModalImage={setModalImage}
          modalImage={modalImage}
          handleCancelRequest={handleCancelRequest}
          assets={assets}
          setAssets={setAssets}
          setEditAsset={setEditAsset}
        />
      </Box>
    </Box>
  );
};

export default Chat;
