import React, { useContext } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { postUploadImage } from '../../hooks/api/image';
import { postChatInitialize } from '../../repositories/prompte.service';
import { toast } from 'react-toastify';
import { AssetImage } from '../../assets/icons';
import * as loadImage from 'blueimp-load-image';
import { AssetContentContext } from '../../contexts';

interface IUploadAsset {
  type?: 'button' | 'drag';
  handleOpenModal: () => void;
}

const UploadAsset = ({ type = 'drag', handleOpenModal }: IUploadAsset) => {
  const {
    handleChatID,
    setLoadedFile,
    setImageName,
    setIsLoadingError,
    isFileLoading,
    setIsFileLoading,
    isAssetLoading,
    setIsAssetLoading,
  } = useContext(AssetContentContext);

  const getUrlImage = async (image: File) => {
    setIsAssetLoading(true);
    setIsFileLoading(true);
    setIsLoadingError(false);
    try {
      await loadImage(
        image,
        async (canvas: any) => {
          canvas.toBlob(async (img: any) => {
            const imageS3 = await postUploadImage(img as unknown as File);
            // handleAssetVal(imageS3 as unknown as string);
            setLoadedFile({ url: imageS3 as unknown as string, name: image.name });
            handleOpenModal();
            const chatId = await postChatInitialize({ image: imageS3 });
            handleChatID(chatId);
            setIsFileLoading(false);
          });
        },
        { orientation: true, canvas: true }
      );
    } catch (error) {
      setIsLoadingError(true);
      toast.warn('Error uploading image', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } finally {
      setIsAssetLoading(false);
    }
  };

  const handlePicture = async (e: any) => {
    try {
      setImageName(e.target.files[0].name);
      await getUrlImage(e.target.files[0] as unknown as File);
    } catch (error) {}
  };

  const onDropHandler = (ev: any) => {
    ev.preventDefault();
    const itemType = ev.dataTransfer.items[0].type;
    const file = ev.dataTransfer.files[0];
    if (itemType === 'image/png' || itemType === 'image/jpeg' || itemType === 'image/webp') {
      getUrlImage(file as unknown as File);
    }
  };

  const onDragOver = (ev: any) => {
    ev.preventDefault();
  };

  return (
    <>
      {type === 'drag' && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100%"
          width="100%"
          onDrop={onDropHandler}
          onDragOver={onDragOver}
        >
          {!isAssetLoading && !isFileLoading ? (
            <Box display="flex" flexDirection="column" width="600px" alignItems="center">
              <Box mr="15px">
                <AssetImage />
              </Box>

              <Typography fontFamily="Poppins" fontWeight={400} fontSize="48px">
                Asset Library
              </Typography>
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: isFileLoading ? 'calc(100% - 90px)' : '80%',
                  ':hover': {
                    '.hover-upload': {
                      opacity: '1',
                    },
                  },
                }}
              >
                <Box
                  sx={{
                    transition: 'all 600ms ease 0s',
                    opacity: `${isFileLoading ? '0' : '0.7'}`,
                    width: '100%',
                    height: '100px',
                    display: 'inline-flex',
                    border: '2px dashed grey',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    borderRadius: '10px',
                  }}
                  className={`${isFileLoading ? '' : 'hover-upload'}`}
                >
                  <Box display="inline" flexDirection="row" textAlign="center" padding="20px">
                    <Typography
                      color="#61646B"
                      display="inline"
                      fontFamily="Poppins"
                      fontSize={16}
                      fontWeight={400}
                    >
                      Drag a image here to
                    </Typography>
                    <Typography
                      color="#3B1E8E"
                      display="inline"
                      fontFamily="Poppins"
                      fontSize={16}
                      fontWeight={500}
                    >
                      {' '}
                      create new asset{' '}
                    </Typography>
                    <Typography
                      color="#61646B"
                      display="inline"
                      fontFamily="Poppins"
                      fontSize={16}
                      fontWeight={400}
                    >
                      or select existing asset in the left
                    </Typography>
                  </Box>
                </Box>

                <Button
                  sx={{
                    marginBottom: '5px',
                    width: '100%',
                    height: '100%',
                    padding: 0,
                    position: 'absolute',
                    background: 'transparent',
                    zIndex: 2,
                  }}
                  component="label"
                >
                  <input
                    onChange={e => {
                      handlePicture(e);
                      e.target.value = '';
                    }}
                    type="file"
                    id="fileInput"
                    alt="fileInput"
                    accept="image/png, image/jpeg"
                    hidden
                  />
                </Button>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                marginBottom: '5px',
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '10px',
                flexDirection: 'column',
                position: 'relative',
              }}
            >
              <CircularProgress
                variant="determinate"
                sx={{
                  color: '#5930A5',
                  opacity: 0.5,
                  position: 'absolute',
                }}
                size={200}
                thickness={1}
                value={100}
              />
              <CircularProgress
                variant="indeterminate"
                disableShrink
                sx={{
                  color: '#5930A5',
                  animationDuration: '2000ms',
                  position: 'absolute',
                }}
                size={200}
                thickness={1}
              />
              <Typography
                fontFamily="Poppins"
                fontWeight={400}
                fontSize="18px"
                color="#313234"
                mt="275px"
              >
                Removing background...
              </Typography>
            </Box>
          )}
        </Box>
      )}
      {type === 'button' && (
        <Button
          variant="contained"
          sx={{
            borderRadius: '5px',
            bgcolor: '#3B1E8E',
            padding: '10px',
            mt: '10px',
            '&:hover': {
              bgcolor: '#2B0E7E',
            },
          }}
          component="label"
          fullWidth
        >
          <Typography fontSize={14} color="#FFF" fontFamily={'Poppins'} fontWeight={500}>
            Upload Image
          </Typography>
          <input
            onChange={e => {
              handlePicture(e);
              e.target.value = '';
            }}
            type="file"
            id="fileInput"
            accept="image/png, image/jpeg"
            hidden
          />
        </Button>
      )}
    </>
  );
};

export default UploadAsset;
