import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';
import './index.css';
import { Provider } from 'react-redux';
import { store } from './core/store';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';
import 'react-toastify/dist/ReactToastify.css';

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN ?? '';
const PRODUCTION = process.env.REACT_APP_ENV === 'production';

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing(), Sentry.replayIntegration()],
  enabled: PRODUCTION,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
        <ToastContainer />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);
