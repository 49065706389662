import React, { useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { SendPlaneLine } from '../../../assets/icons/SendPlaneLine';
import { ShareBoxFill } from '../../../assets/icons/ShareBoxFill';
import cardImg from '../../../assets/images/img2.webp';
import { PhotosIcon } from '../../../assets/icons/PhotosIcon';
import { ArrowDownwardAltIcon } from '../../../assets/icons/ArrowDownwardAlt';
import { ArrowUpwardAltIcon } from '../../../assets/icons/ArrowUpwardAlt';
import { getColorAndLabel } from '../../../components/Dashboard/Helpers';
import { formatValue, roundGrade } from '../../../common/helpers';
import DraftEmailModal from './DraftEmailModal';
import { type IAction } from '../../../core/api/types/Actions';
import TablePlaceholder from './TablePlaceholder';

type SortKey = 'total_unrealized_revenue_monthly_usd' | 'average_score' | '';

interface Props {
  actions: IAction[];
  todo: boolean;
  isFetching: boolean;
}

export default function ActionTable({ actions, todo, isFetching }: Props) {
  const [sortField, setSortField] = useState<SortKey>('average_score');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [selectedAction, setSelectedAction] = useState<IAction | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSortChange = (key: SortKey) => {
    if (sortField === key) {
      setSortDirection(prev => (prev === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortField(key);
      setSortDirection('asc');
    }
  };

  const handleOpenModal = (action: IAction) => {
    setSelectedAction(action);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedAction(null);
  };

  const sortedActions = useMemo(() => {
    if (!sortField) return actions;

    return [...actions].sort((a, b) => {
      const valueA = a[sortField as keyof IAction];
      const valueB = b[sortField as keyof IAction];

      if (valueA < valueB) return sortDirection === 'asc' ? -1 : 1;
      if (valueA > valueB) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  }, [actions, sortField, sortDirection]);

  return (
    <Stack gap={'8px'} width={'100%'}>
      <Grid container spacing={2} component="div" sx={{ mb: '16px', p: '14px 16px' }}>
        <Grid item xs={4}>
          <Typography sx={styles.headerItemText}>Description</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography sx={styles.headerItemText}>Number of images</Typography>
        </Grid>
        <Grid item xs={2}>
          <Stack
            direction={'row'}
            alignItems={'center'}
            gap={1}
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => {
              handleSortChange('average_score');
            }}
          >
            <Typography
              sx={{
                ...styles.headerItemText,
                ...(sortField === 'average_score' && { color: 'text.primary' }),
              }}
            >
              Score change
            </Typography>

            {sortField === 'average_score' &&
              (sortDirection === 'asc' ? <ArrowUpwardAltIcon /> : <ArrowDownwardAltIcon />)}
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <Stack
            direction={'row'}
            alignItems={'center'}
            gap={1}
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => {
              handleSortChange('total_unrealized_revenue_monthly_usd');
            }}
          >
            <Typography
              sx={{
                ...styles.headerItemText,
                ...(sortField === 'total_unrealized_revenue_monthly_usd' && {
                  color: 'text.primary',
                }),
              }}
            >
              Revenue change
            </Typography>

            {sortField === 'total_unrealized_revenue_monthly_usd' && (
              <>{sortDirection === 'asc' ? <ArrowDownwardAltIcon /> : <ArrowUpwardAltIcon />}</>
            )}
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <Box display="flex" justifyContent="flex-end" alignItems="center" height="100%">
            <Typography sx={styles.headerItemText}>
              {todo ? 'Actions' : 'Sent time & date'}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {!isFetching ? (
        sortedActions.map(action => (
          <Grid
            container
            spacing={2}
            key={action.id}
            sx={{
              py: todo ? '16px' : '24px',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: 'white',
              borderRadius: '16px',
              ml: 0,
              mb: '16px',
              width: '100%',
            }}
          >
            <Grid item xs={4} sx={styles.gridItemStyle}>
              <Box sx={styles.tableCell}>
                <img
                  src={cardImg}
                  alt="logo"
                  style={{
                    width: '24px',
                    height: '24px',
                    borderRadius: '999px',
                  }}
                />

                <Typography
                  sx={{
                    color: '#171D30',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '26px',
                    letterSpacing: '-0.216px',
                  }}
                >
                  Send images to {action.vendor_name}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2} sx={styles.gridItemStyle}>
              <Box sx={[styles.tableCell, { gap: '8px' }]}>
                <PhotosIcon />
                <Typography
                  sx={{
                    color: '#171D30',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px',
                    letterSpacing: '-0.07px',
                  }}
                >
                  {action.image_data.length}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2} sx={styles.gridItemStyle}>
              <Box sx={styles.tableCell}>
                <Box
                  sx={{
                    display: 'flex',
                    padding: '4px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px',
                    borderRadius: '999px',
                    bgcolor: getColorAndLabel(roundGrade(Math.round(action.average_score))).color,
                    width: '16px',
                    height: '16px',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#FFF',
                      textAlign: 'center',
                      fontSize: '10px',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      lineHeight: '14px',
                    }}
                  >
                    {Math.round(action.average_score)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    padding: '4px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px',
                  }}
                >
                  <ArrowForwardIcon
                    sx={{
                      width: '20px',
                      height: '20px',
                      color: 'rgba(23, 29, 48, 0.25)',
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    padding: '4px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px',
                    borderRadius: '999px',
                    bgcolor: getColorAndLabel(
                      roundGrade(Math.round(action.average_replacement_score))
                    ).color,
                    width: '16px',
                    height: '16px',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#FFF',
                      textAlign: 'center',
                      fontSize: '10px',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      lineHeight: '14px',
                    }}
                  >
                    {Math.round(action.average_replacement_score)}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={2}
              sx={[styles.gridItemStyle, { display: 'flex', flexDirection: 'row', gap: '4px' }]}
            >
              <Typography
                sx={{
                  color: '#171D30',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '20px',
                  letterSpacing: '-0.07px',
                }}
              >
                {formatValue(action.total_unrealized_revenue_monthly_usd)}
              </Typography>
              <Typography sx={styles.headerItemText}>per year</Typography>
            </Grid>
            <Grid item xs={2} sx={styles.gridItemStyle}>
              <Box sx={styles.tableCell} justifyContent="flex-end" height="100%">
                {todo ? (
                  <>
                    <IconButton sx={{ border: 'none' }}>
                      <ShareBoxFill />
                    </IconButton>
                    <Button
                      startIcon={<SendPlaneLine />}
                      sx={{
                        display: 'flex',
                        padding: '10px 12px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '4px',
                        borderRadius: '12px',
                        background: '#171D30',
                        boxShadow: '0px 1px 2px 0px rgba(20, 21, 26, 0.05)',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '20px',
                        letterSpacing: '-0.07px',
                        color: '#FFFFFF',
                        '&:hover': {
                          background: '#171D30',
                          boxShadow: '0px 1px 2px 0px rgba(20, 21, 26, 0.05)',
                        },
                      }}
                      onClick={() => {
                        handleOpenModal(action);
                      }}
                    >
                      Draft Email
                    </Button>
                  </>
                ) : (
                  <Typography sx={styles.headerItemText}>Fri, 24 May, 2024, 23:43</Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        ))
      ) : (
        <TablePlaceholder size={8} />
      )}
      {actions.length === 0 && (
        <Typography
          fontSize={14}
          fontWeight={400}
          fontFamily={'Poppins'}
          color={'#42485D99'}
          m={'32px auto'}
        >
          No action found
        </Typography>
      )}
      <DraftEmailModal open={isModalOpen} handleClose={handleCloseModal} action={selectedAction} />
    </Stack>
  );
}

const styles = {
  tableCell: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  headerItemText: {
    color: 'rgba(66, 72, 93, 0.60)',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '-0.07px',
  },
  gridItemStyle: {
    pt: '0 !important',
    px: '16px',
  },
};
