import React, { useEffect, useState, type ReactNode } from 'react';
import { Header } from './Header';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Onboarding from '../Onboarding';
import useOnboardingSearchParam from '../../hooks/useOnboardingSearchParam';
import { useLocation } from 'react-router-dom';
import { useSubscriptionStatus } from '../../hooks/useSubscriptionStatus';

interface Props {
  title: string;
  description: string;
  headerChildren?: ReactNode;
  children: ReactNode;
}

function DashboardLayout({ title, description, headerChildren, children }: Props) {
  const { isOnboarding, brandId, loadingOnboarding } = useOnboardingSearchParam();
  const [openToolbar, setOpenToolbar] = useState(true);

  const { freeTrial } = useSubscriptionStatus();

  const location = useLocation();

  const path = location.pathname;

  useEffect(() => {
    if (!openToolbar) {
      setOpenToolbar(true);
    }
  }, [brandId]);

  const isDashboardPage = path === '/dashboard' || path === '/';

  return (
    <Stack bgcolor={'neutralSubtleMore'} minHeight={'100svh'}>
      <Header />
      <Box px={'24px'} position="relative">
        {isOnboarding && <Onboarding />}
        <Box
          sx={{
            filter: isOnboarding && !loadingOnboarding ? 'blur(5px)' : 'none',
            userSelect: isOnboarding && !loadingOnboarding ? 'none' : 'inherit',
            transition: 'filter 0.3s ease',
          }}
        >
          {/* {freeTrial && !shouldShowOnboarding && (
            <BrandToolbar
              open={openToolbar}
              onClose={() => {
                setOpenToolbar(false);
              }}
            />
          )} */}

          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            gap={'6px'}
            p={'48px 0 24px'}
            display={'flex'}
          >
            {!isOnboarding && (!freeTrial || (freeTrial && isDashboardPage)) && (
              <Stack gap={'4px'}>
                <Typography
                  fontSize={48}
                  fontWeight={400}
                  fontFamily={'Poppins'}
                  letterSpacing={'-0.96px'}
                >
                  {title}
                </Typography>
                <Typography
                  fontSize={18}
                  fontWeight={400}
                  fontFamily={'Poppins'}
                  letterSpacing={'-0.216px'}
                  color={'#42485D99'}
                >
                  {description}
                </Typography>
              </Stack>
            )}
            {headerChildren}
          </Stack>
          {children}
        </Box>
      </Box>
    </Stack>
  );
}

export default DashboardLayout;
