import { baseApi } from '.';
import type {
  IBrandEvalutionStatus,
  IBrand,
  IStartEvalution,
  SuggestedCategoriesResponse,
} from './types/Brand';

export const brandApi = baseApi.injectEndpoints({
  endpoints: builder => {
    const token = localStorage.getItem('token') ?? '';
    return {
      createBrand: builder.mutation<
        IBrand,
        { name: string; category: string; logoUrl: string | null }
      >({
        query: ({ name, category, logoUrl }) => {
          const token = localStorage.getItem('token') ?? '';
          return {
            url: `/api/meta/brands/`,
            method: 'post',
            headers: {
              Authorization: `Token ${token}`,
            },
            data: {
              name,
              category,
              logo_url: logoUrl,
            },
          };
        },
        invalidatesTags: ['Brands'],
      }),
      startEvalution: builder.mutation<IStartEvalution, number>({
        query: brandId => {
          const token = localStorage.getItem('token') ?? '';
          return {
            url: `/api/brand_monitoring/imagedata/onboarding/`,
            method: 'post',
            headers: {
              Authorization: `Token ${token}`,
            },
            data: {
              brand_id: brandId,
            },
          };
        },
      }),
      evalutationStatus: builder.query<IBrandEvalutionStatus, number>({
        query: brandId => ({
          url: `/api/meta/brands/${brandId}/onboarding/`,
          method: 'get',
          headers: {
            Authorization: `Token ${token}`,
          },
        }),
      }),
      getUserBrands: builder.query<IBrand[], any>({
        query: () => ({
          url: `/api/meta/brands/`,
          method: 'get',
          headers: {
            Authorization: `Token ${token}`,
          },
        }),
        providesTags: ['Brands'],
      }),
      getBrandById: builder.query<IBrand, number>({
        query: brandId => ({
          url: `/api/meta/brands/${brandId}/`,
          method: 'get',
          headers: {
            Authorization: `Token ${token}`,
          },
        }),
      }),
      getSuggestedCategories: builder.query<SuggestedCategoriesResponse, string>({
        query: brandName => ({
          url: `/api/meta/brands/suggested_categories`,
          method: 'get',
          params: {
            brand_name: brandName,
          },
        }),
      }),
    };
  },
});

export const {
  useCreateBrandMutation,
  useStartEvalutionMutation,
  useLazyEvalutationStatusQuery,
  useGetUserBrandsQuery,
  useGetBrandByIdQuery,
  useLazyGetSuggestedCategoriesQuery,
} = brandApi;
