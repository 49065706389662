import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';

interface IUserAvatar {
  avatar?: string;
  username?: string;
}

export const UserAvatar = ({ avatar, username }: IUserAvatar) => {
  return (
    <Box display="flex" justifyContent="flex-start" alignItems="center" width="90%">
      <Avatar
        alt={'user'}
        src={avatar ?? 'https://placehold.it/50x50'}
        sx={{ width: '24px', height: '24px' }}
      />
      <Typography
        sx={{
          fontSize: 16,
          fontFamily: 'Figtree',
          color: '#61646B',
          ml: '15px',
        }}
      >
        {username ?? 'User'}
      </Typography>
    </Box>
  );
};
