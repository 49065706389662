import React from 'react';

export const OneToOne: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="16" height="16" fill="transparent" />
    <path
      d="M6 7.5V6H7.5M10 8.5V10H8.5M4 5V11C4 11.5523 4.44772 12 5 12H11C11.5523 12 12 11.5523 12 11V5C12 4.44772 11.5523 4 11 4H5C4.44772 4 4 4.44772 4 5Z"
      stroke="black"
    />
  </svg>
);
