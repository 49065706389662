import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { CheckIcon, DotsIcon, RedoIcon, TrashIconGrey, UndoIcon } from '../../assets/icons';
import AssetCanvas from './components/AssetCanvas';
import { useNavigate } from 'react-router-dom';
import { postChatInitialize, updateAsset } from '../../repositories/prompte.service';
import { postUploadImage } from '../../hooks/api/image';
import type Konva from 'konva';
import { type IAsset } from '../../pages/Assets/types';
import { createTheme } from '../../repositories/theme.service';
import {
  FileContentContext,
  ConversationContext,
  AssetContentContext,
  ImagsGeneratedContext,
  BrandsContext,
} from '../../contexts';

interface IImagesArray {
  url: string;
  id: string;
}

enum SPEAKER {
  user,
  bot,
}

interface ITheme {
  id: number;
  chat_id: string;
  history: any[];
  created_at: string;
  created_by: number;
  asset: number;
}

interface ISelectedAsset {
  isGenerated?: boolean;
  asset: IAsset | null;
  isFromChat?: boolean;
  isConfirm?: boolean;
  handleCloseModal?: () => void;
  setEditAsset?: (value: boolean) => void;
  setAssets?: (value: any) => void;
  setSelectedAsset?: React.Dispatch<React.SetStateAction<IAsset | null>>;
  ratio?: string;
  themes?: ITheme[];
}

const SelectedAsset = ({
  asset,
  isGenerated,
  isFromChat,
  isConfirm,
  handleCloseModal,
  setEditAsset,
  setAssets,
  setSelectedAsset,
  ratio = '1',
  themes = [],
}: ISelectedAsset) => {
  const { handleContentVal, handleChatID, chatID, isLoading, setIsLoading } =
    useContext(FileContentContext);
  const { handleDeleteConversation, setConversation, setThemeId } = useContext(ConversationContext);
  const { handleAssetVal, imageName, setImageName } = useContext(AssetContentContext);
  const { handleImageRequestID } = useContext(ImagsGeneratedContext);
  const { selectedBrand } = useContext(BrandsContext);

  const navigate = useNavigate();
  const modalRef = useRef(null);
  const imageRef = useRef() as React.MutableRefObject<Konva.Image>;

  const [selected, setSelected] = useState(true);
  const [theme, setTheme] = useState<ITheme>();

  useEffect(() => {
    const existTheme = themes.find(theme => theme.asset === asset?.id);

    if (existTheme) {
      if (
        existTheme.history[existTheme.history.length] > 0 &&
        existTheme.history[existTheme.history.length - 1].speaker_type === 0
      ) {
        existTheme.history = [
          ...existTheme.history,
          {
            speaker_type: SPEAKER.bot,
            query: 'Request canceled',
            isLoading: false,
            isSuccess: false,
          },
        ];
      }
      setTheme(existTheme);
    }
  }, [themes]);

  useEffect(() => {
    localStorage.setItem('isUseAsset', 'false');
  }, []);

  const handleUpdateAsset = async () => {
    setSelected(false);
    setIsLoading(true);
    setTimeout(async () => {
      try {
        if (imageRef.current) {
          const stage = imageRef.current.getStage();

          if (stage) {
            const dataURL = stage.toDataURL({ pixelRatio: 4 });
            const blob = await convertDataUrlToBlob(dataURL);
            const imageS3 = await postUploadImage(blob as File);
            handleContentVal(imageS3 as unknown as string);
            updateAsset(imageS3, chatID).finally(() => {
              if (handleCloseModal) {
                handleCloseModal();
              }
              if (setEditAsset) {
                setEditAsset(false);
              }
              if (setAssets) {
                setAssets((prev: IImagesArray[]) => [...prev, { url: imageS3, id: imageName }]);
              }
              setIsLoading(false);
            });
          }
        }
      } catch (error) {
        throw new Error('Error updating asset');
      }
    }, 0);
  };

  const getUrlImage = async (image: File) => {
    setIsLoading(true);
    navigate(`/asset/${asset?.id ?? ''}`);

    try {
      const imageS3 = await postUploadImage(image);
      handleContentVal(imageS3 as unknown as string);
      handleAssetVal(asset?.url as unknown as string);

      await handleCreateOrUseTheme(imageS3);

      if (setEditAsset) {
        setEditAsset(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUseAsset = async () => {
    setSelected(false);
    setIsLoading(true);
    setTimeout(async () => {
      try {
        if (imageRef.current && asset) {
          const stage = imageRef.current.getStage();
          asset.initUrl = asset.url;
          if (stage) {
            const dataURL = stage.toDataURL({ pixelRatio: 4 });
            const blob = await convertDataUrlToBlob(dataURL);
            handleDeleteConversation();
            getUrlImage(blob as File);
            setImageName(asset.name);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }, 0);
  };

  const handleCreateOrUseTheme = async (imageS3: string) => {
    if (theme) {
      handleChatID(theme.chat_id);
      setConversation(theme.history);
      setThemeId(theme.id);

      const loadingImages: string[] = [];

      theme.history.forEach(theme => {
        if (theme.loadingItems) {
          if (theme.isLoading && theme.loadingItems.length > 0) {
            loadingImages.push(theme.loadingItems);
          }
        }
      });

      handleImageRequestID([...loadingImages]);
    } else if (asset) {
      const chatId = await postChatInitialize({
        brand_id: selectedBrand.id,
        image: imageS3,
      });
      handleChatID(chatId);

      const newTheme = await createTheme({ chat_id: chatId, asset: asset.id, history: [] });

      setTheme(newTheme);
      setThemeId(newTheme.id);
    }
  };

  const convertDataUrlToBlob = async (dataUrl: string): Promise<Blob> => {
    try {
      const response = await fetch(dataUrl);
      const blob = await response.blob();
      return blob;
    } catch (error) {
      throw new Error('Error converting data url to Blob');
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="100%">
      <Box
        display="flex"
        flexDirection="column"
        // border="2px solid #AFB1B6"
        borderRadius="5px"
        width="65%"
      >
        <Box display="flex" justifyContent="center" gap="10px" mt="10px" mb="30px">
          <Box
            display="flex"
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={async () => {
              isFromChat ? await handleUpdateAsset() : await handleUseAsset();
            }}
          >
            <CheckIcon />
          </Box>
          <Box display="flex" onClick={() => null}>
            <UndoIcon />
          </Box>
          <Box display="flex" onClick={() => null}>
            <RedoIcon />
          </Box>
          <Box
            display="flex"
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={() => {
              if (setEditAsset) {
                setEditAsset(false);
              }
              if (setSelectedAsset) {
                setSelectedAsset(null);
              }
            }}
          >
            <TrashIconGrey />
          </Box>
          <Box display="flex" onClick={() => null}>
            <DotsIcon />
          </Box>
        </Box>
        {asset && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '55vh',
              position: 'relative',
            }}
            ref={modalRef}
          >
            <AssetCanvas
              imageSrc={asset?.url}
              modalRef={modalRef}
              isGenerated={isGenerated}
              handleUseAsset={handleUseAsset}
              handleUpdateAsset={handleUpdateAsset}
              imageRef={imageRef}
              selected={selected}
              isConfirm={isConfirm}
              setEditAsset={setEditAsset}
              isLoading={isLoading}
              ratio={ratio}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SelectedAsset;
