import React, { useContext } from 'react';
import { Box, Avatar, Typography } from '@mui/material';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { AssetContentContext } from '../../../../../../../contexts';

interface IAsset {
  setEditAsset: (value: boolean) => void;
}

export const Asset = ({ setEditAsset }: IAsset) => {
  const { assetContentVal, imageName } = useContext(AssetContentContext);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        paddingX: '20px',
        height: '50px',
        '&: hover': {
          bgcolor: 'lightgrey',
        },
        boxSizing: 'border-box',
        borderRight: '1px solid #EFEFF0',
        borderBottom: '1px solid #EFEFF0',
      }}
    >
      <Avatar
        alt={'name'}
        src={assetContentVal}
        sx={{ width: '40px', height: '40px', border: '1px solid #EFEFF0' }}
      />
      <Box display="flex" justifyContent="center" flexDirection="column" ml="10px">
        <Typography
          fontFamily="Figtree"
          color="#313234"
          fontSize={16}
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '200px',
            whiteSpace: 'nowrap',
          }}
        >
          {imageName || 'Asset name'}
        </Typography>
        <Typography fontFamily="Figtree" color="#AFB1B6" fontSize={12}>
          Asset
        </Typography>
      </Box>
      <Box
        onClick={() => {
          setEditAsset(true);
        }}
        ml="20px"
        sx={{
          '&: hover': {
            cursor: 'pointer',
          },
        }}
      >
        <CreateOutlinedIcon sx={{ color: '#61646B' }} />
      </Box>
    </Box>
  );
};
