import React from 'react';

interface IProps {
  color?: string;
}

export const AssetsSortAbcDesc = ({ color = '#1B1B1E' }: IProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 7H22L18 3L14 7H17V21H19V7ZM11 13V15L7.67 19H11V21H5V19L8.33 15H5V13H11ZM9 3H7C5.9 3 5 3.9 5 5V11H7V9H9V11H11V5C11 3.9 10.11 3 9 3ZM9 7H7V5H9V7Z"
      fill={color}
    />
  </svg>
);
