import React from 'react';

export const NewLogo: React.FC = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.50606 13.8139C1.54095 8.49504 1.79836 5.59563 3.69729 3.6967C5.89399 1.5 9.42952 1.5 16.5006 1.5H19.5006C26.5717 1.5 30.1072 1.5 32.3039 3.6967C34.0696 5.46238 34.416 8.09306 34.484 12.7295C34.2902 16.9793 32.4123 20.7899 29.5019 23.5097C30.3023 21.842 30.7506 19.9734 30.7506 18C30.7506 11.2101 25.4431 5.6599 18.7506 5.27169V5.25H17.2506C10.6508 5.25 4.8474 8.65986 1.50606 13.8139ZM1.51719 23.2705C1.71095 19.0207 3.58889 15.2101 6.49924 12.4903C5.6989 14.158 5.25059 16.0266 5.25059 18C5.25059 24.7899 10.5581 30.3401 17.2506 30.7283V30.75L18.0006 30.75L18.7506 30.75C25.3504 30.75 31.1538 27.3401 34.4951 22.1861C34.4602 27.505 34.2028 30.4044 32.3039 32.3033C30.1072 34.5 26.5717 34.5 19.5006 34.5H16.5006C9.42952 34.5 5.89399 34.5 3.69729 32.3033C1.9316 30.5376 1.58516 27.9069 1.51719 23.2705Z"
      fill="#5619A8"
    />
    <path
      d="M18.0005 8.25C18.5221 13.4033 22.5972 17.4783 27.7505 18C22.5972 18.5217 18.5221 22.5967 18.0005 27.75C17.4788 22.5967 13.4038 18.5217 8.25049 18C13.4038 17.4783 17.4788 13.4033 18.0005 8.25Z"
      fill="#7A17F9"
    />
  </svg>
);
