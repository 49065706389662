import * as React from 'react';
import {
  //  useContext,
  useEffect,
  useState,
} from 'react';
import { Modal, Box, Grid, Typography, Link, Button } from '@mui/material';
import {
  ArrowLeftIcon,
  ArrowOutIcon,
  ArrowRightIcon,
  BrandStarIcon,
  CloseIconBrand,
} from '../../../assets/icons';
import {
  // AssetContentContext,
  type IAsset,
} from '../../../contexts';
import { Filter } from '../types';
import { useNavigate } from 'react-router-dom';
// import { postChatInitialize } from '../../../repositories/prompte.service';

interface IImageOverlay {
  open: boolean;
  setOpen: (value: boolean) => void;
  riskAssets: IAsset[];
  popularAssets: IAsset[];
  hoveredItem: number | null;
  screenWidth: number;
  filterItem: Filter;
  setHoveredItem: (value: number | null) => void;
  setHoveredItemUrl: (value: string | null) => void;
  setFilterItem: (value: Filter) => void;
  hoveredItemUrl: string | null;
}

export const ImageOverlay = ({
  open,
  setOpen,
  riskAssets,
  popularAssets,
  hoveredItem,
  screenWidth,
  filterItem,
  setHoveredItem,
  setHoveredItemUrl,
  setFilterItem,
  hoveredItemUrl,
}: IImageOverlay) => {
  const [currentImage, setCurrentImage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const imagesBottomTabLength = Math.round(screenWidth / 200);
  const assets = filterItem === Filter.popular ? popularAssets : riskAssets;
  const imageScore = assets[currentImage].score * 10;

  // const { handleChatID, setLoadedFile } = useContext(AssetContentContext);

  useEffect(() => {
    if (hoveredItem) {
      const indexOfImage = assets.findIndex(asset => asset.id === hoveredItem);
      setCurrentImage(indexOfImage);
    }
  }, [hoveredItem]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickNext = () => {
    setCurrentImage(prev => prev + 1);
  };

  const handleClickBack = () => {
    setCurrentImage(prev => prev - 1);
  };

  useEffect(() => {
    const handleUserKeyPress = (event: KeyboardEvent) => {
      const { key } = event;

      if (key === 'ArrowRight' && currentImage < assets.length - 1) {
        handleClickNext();
      }

      if (key === 'ArrowLeft' && currentImage > 0) {
        handleClickBack();
      }
    };

    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [currentImage]);

  const getRandomTopAssets = () => {
    const randomTopAssets: IAsset[] = [];

    const filteredObjects = popularAssets.filter(asset => asset.score > 0.5);
    const shuffledArray = filteredObjects
      .sort(() => Math.random() - 0.5)
      .filter(asset => asset.url !== hoveredItemUrl);

    shuffledArray.forEach(asset => {
      const isUrlExists = randomTopAssets.filter(image => image.url === asset.url);
      if (!isUrlExists.length) {
        randomTopAssets.push(asset);
      }
    });

    const randomItems = randomTopAssets.slice(0, 4);
    return randomItems;
  };

  const handleGenerateNewAsset = async () => {
    setIsLoading(true);
    try {
      setIsLoading(false);
      setOpen(false);

      navigate('/assets', { state: { selectedAsset: assets[currentImage].id } });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          display: 'flex',
          bgcolor: 'black',
          width: '100vw',
          height: '100vh',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box display="flex" justifyContent="flex-end" width="95%" mt="30px">
          <Box display="flex" sx={{ cursor: 'pointer' }} onClick={handleClose}>
            <CloseIconBrand color="#FFF" />
          </Box>
        </Box>
        <Grid
          container
          display="flex"
          height="575px"
          width="50vw"
          borderRadius="20px"
          overflow="scroll"
          sx={{
            '&::-webkit-scrollbar': { display: 'none' },
          }}
        >
          <Grid
            item
            xs={6}
            display="flex"
            bgcolor="#2b2b2b"
            alignItems="center"
            flexDirection="column"
            justifyContent="space-between"
            padding="20px"
          >
            <Box
              component="img"
              src={assets[currentImage].original_url}
              sx={{
                display: 'flex',
                maxWidth: '100%',
                maxHeight: '350px',
                objectFit: 'contain',
                borderRadius: '10px',
                bgcolor: '#EFEFF0',
              }}
            />
            <Box display="flex" width="100%" flexDirection="column">
              <Box display="flex" width="100%">
                <Typography
                  fontFamily="Poppins"
                  fontSize={14}
                  color="#FFF"
                  fontWeight={500}
                  textAlign="start"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  noWrap
                >
                  {assets[currentImage].name}
                </Typography>
                <Typography
                  fontFamily="Poppins"
                  fontSize={14}
                  color="#a3a3a3"
                  fontWeight={500}
                  textAlign="start"
                >
                  &nbsp;- Reseller
                </Typography>
              </Box>
              <Link
                href={assets[currentImage].source_link}
                target="_blank"
                underline="none"
                display="flex"
                width="100%"
                alignItems="center"
                mt="5px"
              >
                <Typography
                  fontFamily="Poppins"
                  fontSize={12}
                  color="#a3a3a3"
                  fontWeight={400}
                  textAlign="start"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    mr: '10px',
                  }}
                  noWrap
                >
                  {assets[currentImage].source_link}
                </Typography>
                <ArrowOutIcon color="#a3a3a3" />
              </Link>

              <Box display="flex" justifyContent="space-between" mt="20px">
                <Box display="flex" alignItems="center">
                  <Box
                    width="10px"
                    height="10px"
                    borderRadius="10px"
                    bgcolor={assets[currentImage].popularity > 15 ? 'grey' : 'red'}
                  />
                  <Typography
                    fontFamily="Poppins"
                    fontSize={14}
                    color="#FFF"
                    fontWeight={400}
                    ml="10px"
                  >
                    {assets[currentImage].popularity > 15
                      ? 'Unpopular image'
                      : 'Very popular image'}
                  </Typography>
                </Box>
                <Typography fontFamily="Poppins" fontSize={14} color="#FFF" fontWeight={400}>
                  #{assets[currentImage].popularity}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            display="flex"
            bgcolor="#191919"
            flexDirection="column"
            justifyContent="space-between"
            padding="20px"
          >
            <Box display="flex" justifyContent="center" flexDirection="column">
              <Box display="flex" alignItems="center">
                <Box
                  display="flex"
                  borderRadius="10px"
                  width="48px"
                  height="48px"
                  bgcolor="#000"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    bgcolor={
                      imageScore < 4 ? 'red' : imageScore > 3 && imageScore < 7 ? 'orange' : 'green'
                    }
                    borderRadius="50px"
                    height="32px"
                    width="32px"
                  >
                    <Typography fontFamily="Poppins" fontSize={18} fontWeight={500} color="#FFF">
                      {imageScore}
                    </Typography>
                  </Box>
                </Box>
                <Box ml="10px">
                  <Typography fontFamily="Poppins" fontSize={16} fontWeight={500} color="#FFF">
                    {imageScore < 4
                      ? 'Very bad quality'
                      : imageScore > 3 && imageScore < 7
                      ? 'Normal quality'
                      : 'Good quality'}
                  </Typography>
                </Box>
              </Box>

              <Box display="flex" mt="10px" flexDirection="row" flexWrap="wrap">
                {assets[currentImage].diagnostics.map(item => {
                  return (
                    <Box
                      key={item}
                      display="flex"
                      bgcolor={
                        imageScore < 4
                          ? '#411e1e'
                          : imageScore > 3 && imageScore < 7
                          ? '#412d1f'
                          : '#193f2b'
                      }
                      borderRadius="10px"
                      padding="5px"
                      mb="5px"
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '100%',
                      }}
                    >
                      <Typography
                        fontFamily="Poppins"
                        fontSize={14}
                        fontWeight={500}
                        color={
                          imageScore < 4
                            ? '#dd3232'
                            : imageScore > 3 && imageScore < 7
                            ? '#db7836'
                            : '#00d371'
                        }
                        noWrap
                      >
                        {item}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
              <Box display="flex" flexDirection="column">
                <Box display="flex" mt="10px" flexDirection="column">
                  <Typography fontFamily="Poppins" fontSize={12} fontWeight={500} color="#999999">
                    ON THIS IMAGE
                  </Typography>
                  <Typography fontFamily="Poppins" fontSize={14} fontWeight={500} color="#FFF">
                    {assets[currentImage].description}
                  </Typography>
                </Box>
                <Box display="flex" mt="10px" flexDirection="column">
                  <Typography fontFamily="Poppins" fontSize={12} fontWeight={500} color="#999999">
                    METADATA
                  </Typography>
                  <Typography fontFamily="Poppins" fontSize={14} fontWeight={500} color="#FFF">
                    {assets[currentImage].url.split('.').pop()}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" mt="20px">
              <Box display="flex" flexDirection="column">
                <Typography fontFamily="Poppins" fontSize={12} fontWeight={500} color="#999999">
                  KNOWN ALTERNATIVIES
                </Typography>
                <Grid container spacing={1} mt="5px">
                  {getRandomTopAssets().map(image => {
                    return (
                      <Grid
                        item
                        xs={3}
                        key={image.id}
                        onClick={() => {
                          setFilterItem(Filter.popular);
                          setHoveredItem(image.id);
                          setHoveredItemUrl(image.original_url);
                        }}
                      >
                        <Box
                          component="img"
                          src={image.original_url}
                          sx={{
                            objectFit: 'contain',
                            borderRadius: '10px',
                            bgcolor: '#2b2b2b',
                            width: '100%',
                            height: '75px',
                          }}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
              <Box display="flex" mt="30px">
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    borderRadius: '10px',
                    bgcolor: '#7A17F9',
                    padding: '10px',
                    mr: '10px',
                    height: '40px',
                    '&:hover': {
                      bgcolor: '#9C4FFF',
                    },
                    '&:disabled': {
                      border: '1px solid grey',
                    },
                  }}
                  startIcon={<BrandStarIcon />}
                  onClick={handleGenerateNewAsset}
                  disabled={isLoading}
                >
                  <Typography fontFamily="Poppins" fontSize={14} fontWeight={500} color="#FFF">
                    {isLoading ? 'Loading...' : 'Generate new'}
                  </Typography>
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: '10px',
                    height: '40px',
                    border: '1px solid grey',
                    '&:hover': {
                      border: '1px solid grey',
                    },
                  }}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <Typography fontFamily="Poppins" fontSize={14} fontWeight={500} color="#FFF">
                    Ignore
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box display="flex" width="100%" justifyContent="center" height="10vh" mt="20px">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            alignContent="center"
            mb="20px"
          >
            <Box
              component="button"
              disabled={currentImage === 0}
              onClick={handleClickBack}
              sx={{
                height: '100%',
                border: 'none',
                bgcolor: '#000',
                cursor: currentImage === 0 ? 'default' : 'pointer',
              }}
            >
              <ArrowLeftIcon color="#FFF" />
            </Box>
            <Box display="flex" overflow="hidden">
              {currentImage < imagesBottomTabLength ? (
                <>
                  {assets.map((image, index) => {
                    return (
                      index < imagesBottomTabLength && (
                        <Box
                          key={index}
                          component="img"
                          src={image.url}
                          sx={{
                            width: '120px',
                            height: '60px',
                            ml: '10px',
                            objectFit: 'contain',
                            bgcolor: '#f4f2f7',
                            border: currentImage === index ? '4px solid #9571D5' : 'none',
                            borderRadius: '10px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setCurrentImage(index);
                          }}
                        />
                      )
                    );
                  })}
                </>
              ) : currentImage > assets.length - imagesBottomTabLength ? (
                <>
                  {assets.map((image, index) => {
                    return (
                      index > assets.length - imagesBottomTabLength && (
                        <Box
                          key={index}
                          component="img"
                          src={image.url}
                          sx={{
                            width: '120px',
                            height: '60px',
                            ml: '10px',
                            objectFit: 'contain',
                            bgcolor: '#f4f2f7',
                            border: currentImage === index ? '4px solid #9571D5' : 'none',
                            borderRadius: '10px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setCurrentImage(index);
                          }}
                        />
                      )
                    );
                  })}
                </>
              ) : (
                <>
                  {assets.map((image, index) => {
                    return (
                      index > currentImage - imagesBottomTabLength / 2 &&
                      index < currentImage + imagesBottomTabLength / 2 && (
                        <Box
                          key={index}
                          component="img"
                          src={image.url}
                          sx={{
                            width: '120px',
                            height: '60px',
                            ml: '10px',
                            objectFit: 'contain',
                            bgcolor: '#f4f2f7',
                            border: currentImage === index ? '4px solid #9571D5' : 'none',
                            borderRadius: '10px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setCurrentImage(index);
                          }}
                        />
                      )
                    );
                  })}
                </>
              )}
            </Box>
            <Box
              component={'button'}
              disabled={currentImage === assets.length - 1}
              onClick={handleClickNext}
              sx={{
                height: '100%',
                border: 'none',
                bgcolor: '#000',
                ml: '10px',
                cursor: currentImage === assets.length - 1 ? 'default' : 'pointer',
              }}
            >
              <ArrowRightIcon color="#FFF" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
