import React from 'react';
import { Box, Card, Typography } from '@mui/material';
import { theme } from '../../theme';
import { roundGrade } from '../../common/helpers';
import { getColorAndLabel } from '../Dashboard/Helpers';
import DoneIcon from '@mui/icons-material/Done';
import { type IVendor } from '../../pages/Dashboard/Products/Types';

interface Props {
  vendor: IVendor;
  onClick: () => void;
  currentVendor: IVendor | null;
}

function VendorCard({ vendor, onClick, currentVendor }: Props) {
  return (
    <Card
      sx={{
        borderRadius: '16px',
        boxShadow: 'none',
        border:
          currentVendor?.id === vendor.id
            ? `4px solid ${theme.palette.borderActionsNormal}`
            : 'none',
        boxSizing: 'border-box',
        cursor: 'pointer',
        height: '120px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
      onClick={onClick}
    >
      <Box
        display="flex"
        flexDirection="column"
        position="relative"
        justifyContent="center"
        px="15px"
        width="100%"
      >
        {currentVendor?.id === vendor.id && (
          <Box display="flex" position="absolute" right="10px" top="-5px">
            <DoneIcon sx={{ fontSize: '16px', color: 'grey' }} />
          </Box>
        )}
        <Box display="flex" alignItems="center" position="relative">
          <img
            src={vendor.icon_url || 'https://i.ibb.co/SwrWNbB/Image-Resolution.png'}
            style={{ width: 40, height: 40 }}
          />
          <Box
            display="flex"
            bgcolor={getColorAndLabel(roundGrade(vendor.score)).color}
            borderRadius="100%"
            width="25px"
            height="25px"
            justifyContent="center"
            alignItems="center"
            position="absolute"
            left="30px"
            top="6px"
            border="2px solid white"
          >
            <Typography color="white">{roundGrade(vendor.score)}</Typography>
          </Box>
        </Box>

        <Typography fontSize="14px" fontWeight="400" color={theme.palette.text.primary} mt="5px">
          {vendor.name}
        </Typography>
        <Box display="flex">
          <Typography fontSize="12px" fontWeight="400" color={theme.palette.text.primary}>
            {vendor.unrealized_revenue_monthly_usd}
          </Typography>
          &nbsp;
          <Typography fontSize="12px" fontWeight="400" color={theme.palette.text.secondary}>
            per year
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}

export default VendorCard;
