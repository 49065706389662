import React from 'react';
import { Box, Card, Stack, Typography } from '@mui/material';
import { roundGrade } from '../../common/helpers';
import { getColorAndLabel } from '../Dashboard/Helpers';
import { DotsIcon, ProductsUpload, ProductsBestImage, ProductsInfo } from '../../assets/icons';
import { theme } from '../../theme';
import { type IProductImage } from '../../core/api/types/Product';

interface Props {
  product: IProductImage;
}

function ProductCard({ product }: Props) {
  return (
    <Card
      sx={{
        borderRadius: '16px',
        overflow: 'hidden',
        boxShadow: 'none',
        width: '100%',
      }}
    >
      <Box display="flex" flexDirection="column" justifyContent="space-between" height="500px">
        <Box display="flex" alignItems="center" justifyContent="space-between" p="12px">
          <Box display="flex">
            <Stack
              justifyContent={'center'}
              alignItems={'center'}
              component={'span'}
              sx={{
                height: 24,
                width: 24,
                bgcolor: getColorAndLabel(roundGrade(product.score)).color,
                borderRadius: '50%',
                zIndex: 1,
                fontSize: 12,
                fontWeight: 500,
                color: '#FFFFFF',
                mr: '10px',
              }}
            >
              {product.score}
            </Stack>
            <ProductsBestImage />
            <Typography fontSize="14px" fontWeight="400" ml="5px">
              Best image
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Box
              display="flex"
              alignItems="center"
              sx={{ cursor: 'pointer', pr: '20px' }}
              onClick={() => {
                console.log('Upload clicked');
              }}
            >
              <ProductsUpload />
              <Typography fontSize="14px" ml="5px" color={theme.palette.text.secondary}>
                Upload
              </Typography>
            </Box>

            <Box
              display="flex"
              sx={{ transform: 'rotate(90deg)', cursor: 'pointer' }}
              onClick={() => {
                console.log('Dots clicked');
              }}
            >
              <DotsIcon />
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          sx={{
            height: 300,
            position: 'relative',
            background: `url(${product.image_url}) no-repeat center/contain`,
          }}
        />
        <Box display="flex" alignItems="center" justifyContent="space-between" p="12px" width="97%">
          {/* TODO: When api will be ready add product.issues ? product.issues : 'No Issues found' */}
          <Box display="flex">
            <Typography
              display="flex"
              fontSize="12px"
              fontWeight="400"
              color={theme.palette.text.secondary}
            >
              No Issues found
            </Typography>
          </Box>
          <Box display="flex">
            <ProductsInfo />
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

export default ProductCard;
