import { useEffect, useState } from 'react';

function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  let timeoutId: NodeJS.Timeout;

  useEffect(() => {
    if (value !== debouncedValue) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [value, debouncedValue, delay]);

  return debouncedValue;
}

export default useDebounce;
