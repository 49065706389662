import React from 'react';
import { Box, Typography } from '@mui/material';

interface IHeader {
  title: string;
  description: string;
  center?: boolean;
}

export const Header = ({ title, description, center }: IHeader) => {
  return (
    <Box display="flex" flexDirection="column" alignItems={center ? 'center' : 'flex-start'}>
      <Typography fontFamily="Poppins" fontSize="24px" fontWeight={400} color="#352E65">
        {title}
      </Typography>
      <Typography fontFamily="Poppins" fontSize="16px" fontWeight={400} color="#6A648D" mt="10px">
        {description}
      </Typography>
    </Box>
  );
};
