import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Steps, type IImagesArray } from '../types';
import { RadarLoader } from './RadarLoader';
import { theme } from '../../../theme';
import useDebounce from '../../../hooks/useDebounce';
import LoadingButton from '@mui/lab/LoadingButton';
import { fetchEventSource } from '@microsoft/fetch-event-source';

interface IEvaluation {
  setStep: (value: number) => void;
  brandImages: IImagesArray[];
  setImagesToDescribe: (value: IImagesArray[]) => void;
  setBrandImages: (value: IImagesArray[]) => void;
  isFinishLoading: boolean;
}

export const Evaluation = ({
  setStep,
  brandImages,
  setBrandImages,
  isFinishLoading,
  setImagesToDescribe,
}: IEvaluation) => {
  const [badImages, setBadImages] = useState<Array<{ src: string }>>([]);
  const [goodImages, setGoodImages] = useState<Array<{ src: string }>>([]);
  const [evaluatedImages, setEvaluatedImages] = useState<any[]>([]);
  const [evaluationLoading, setEvaluationLoading] = useState(false);
  const [evaluationImageName, setEvaluationImageName] = useState('');

  useEffect(() => {
    handleExecuteEvaluateImages();
  }, []);

  useEffect(() => {
    setImagesToDescribe(brandImages);
  }, []);

  useEffect(() => {
    if (!evaluationLoading && evaluatedImages.length) {
      setBrandImages(evaluatedImages);
    }
  }, [evaluationLoading]);

  // Use debounced value of array length to show it synchronous when image animation finish
  const debounceBadImages = useDebounce(badImages.length, 1000);
  const debounceGoodImages = useDebounce(goodImages.length, 1000);

  const handleExecuteEvaluateImages = () => {
    setEvaluationLoading(true);

    const imagesSrc = brandImages.map(image => image.src);

    const payload = {
      image_urls: imagesSrc,
    };

    const url = `${process.env.REACT_APP_API_URL}/api/meta/assets/evaluate_images/`;
    fetchEventSource(url, {
      method: 'POST',
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
      openWhenHidden: true,
      async onopen(res) {
        if (res.status >= 400 && res.status < 500 && res.status !== 429) {
          console.error('Client side error ', res);
        }
      },
      onmessage(msg) {
        try {
          const parseData = JSON.parse(msg.data);

          const message = parseData.message;
          const url = parseData.url;
          const score = parseData.score;
          const isError = score.error;

          if (message === '[CONNECTED]' || message === '[COMPLETED]') {
            return;
          }

          if (!isError) {
            const index = brandImages.findIndex(image => image.src === url);

            setEvaluationImageName(brandImages[index].origin_url);
            setEvaluatedImages(prev => [
              ...prev,
              {
                ...brandImages[index],
                score: score.score,
                reason: score.reason,
                user_score: 0,
                reviewed: false,
              },
            ]);

            if (score.score >= 0.5) {
              setGoodImages(prev => [...prev, { src: url }]);
            } else {
              setBadImages(prev => [...prev, { src: url }]);
            }
          }
        } catch (error) {
          console.error('Error parsing message data', error, msg.data);
        }
      },
      onerror(err) {
        setEvaluationLoading(false);
        console.log(err, 'something went wrong');
      },
      onclose() {
        setEvaluationLoading(false);
      },
    });
  };

  useEffect(() => {
    const timeoutId = setTimeout(
      () => {
        setEvaluationLoading(false);
      },
      3 * 60 * 1000
    );

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {evaluationLoading ? (
        <Typography
          fontFamily="Poppins"
          fontSize={24}
          color={theme.palette.text.primary}
          fontWeight={400}
        >
          Evaluating online images...
        </Typography>
      ) : (
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography
            fontFamily="Poppins"
            fontSize={24}
            color={theme.palette.text.primary}
            fontWeight={400}
          >
            Focal successfully evaluated your images
          </Typography>
          <Typography
            fontFamily="Poppins"
            fontSize={16}
            color={theme.palette.text.secondary}
            fontWeight={400}
            width="60%"
            textAlign="center"
            mt="10px"
          >
            You can review the default evaluation and change it. This will help to calibrate the AI
            to your brand style.
          </Typography>
        </Box>
      )}

      <Box
        display="flex"
        height="60vh"
        justifyContent="center"
        alignItems="center"
        flexDirection="row"
      >
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          sx={{ mr: evaluationLoading ? '50px' : '20px', mb: '100px' }}
          position="relative"
        >
          <Box
            width="275px"
            height="275px"
            bgcolor="#fbf4f6"
            borderRadius={50}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {badImages.map((image, index) => {
              if (index % 3 === 0) {
                return (
                  <Box
                    key={index}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    position="absolute"
                    component="img"
                    src={image.src}
                    sx={{
                      maxWidth: '240px',
                      maxHeight: '240px',
                      boxSizing: 'border-box',
                      objectFit: 'contain',
                      borderRadius: '20px',
                      boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                      transform: `translate(0, 0) rotate(340deg)`,
                      animation: `rotationBad1 1s`,
                      '@keyframes rotationBad1': {
                        '0%': {
                          opacity: 0,
                          transform: `translate(100px, 100px) rotate(250deg)`,
                        },
                        '50%': {
                          opacity: 1,
                        },
                        '100%': {
                          transform: `translate(0, 0) rotate(340deg)`,
                        },
                      },
                    }}
                  />
                );
              } else if (index % 2 === 0) {
                return (
                  <Box
                    key={index}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    position="absolute"
                    component="img"
                    src={image.src}
                    sx={{
                      maxWidth: '240px',
                      maxHeight: '240px',
                      boxSizing: 'border-box',
                      objectFit: 'contain',
                      borderRadius: '20px',
                      boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                      transform: `translate(0, 0) rotate(350deg)`,
                      animation: `rotationBad2 1s`,
                      '@keyframes rotationBad2': {
                        '0%': {
                          opacity: 0,
                          transform: `translate(100px, 100px) rotate(260deg)`,
                        },
                        '50%': {
                          opacity: 1,
                        },
                        '100%': {
                          transform: `translate(0, 0) rotate(350deg)`,
                        },
                      },
                    }}
                  />
                );
              } else {
                return (
                  <Box
                    key={index}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    position="absolute"
                    component="img"
                    src={image.src}
                    sx={{
                      maxWidth: '240px',
                      maxHeight: '240px',
                      boxSizing: 'border-box',
                      objectFit: 'contain',
                      borderRadius: '20px',
                      boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                      transform: `translate(0, 0) rotate(359deg)`,
                      animation: `rotationBad3 1s`,
                      '@keyframes rotationBad3': {
                        '0%': {
                          opacity: 0,
                          transform: `translate(100px, 100px) rotate(270deg)`,
                        },
                        '50%': {
                          opacity: 1,
                        },
                        '100%': {
                          transform: `translate(0, 0) rotate(359deg)`,
                        },
                      },
                    }}
                  />
                );
              }
            })}
          </Box>
          <Box
            width="100px"
            height="100px"
            bgcolor="#FFF"
            borderRadius={50}
            position="absolute"
            bottom="-80px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography color="red" fontFamily="Poppins" fontSize={32} fontWeight={300}>
              {debounceBadImages}
            </Typography>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            position="absolute"
            bottom="-120px"
          >
            <Typography
              fontFamily="Poppins"
              fontSize={16}
              color={theme.palette.text.primary}
              fontWeight={500}
            >
              Bad images
            </Typography>
            <Typography
              fontFamily="Poppins"
              fontSize={14}
              color={theme.palette.text.secondary}
              fontWeight={400}
            >
              Examples to avoid
            </Typography>
          </Box>
        </Box>

        {evaluationLoading && (
          <Box display="flex" justifyContent="center" flexDirection="column">
            <RadarLoader />
            <Typography
              fontFamily="Poppins"
              fontSize={14}
              color="#352E65"
              fontWeight={500}
              mt="60px"
              position="relative"
              alignSelf="center"
            >
              Processing...
            </Typography>
            <Typography
              fontFamily="Poppins"
              fontSize={12}
              color="#9D9AB4"
              fontWeight={400}
              position="relative"
              width="250px"
              sx={{ textOverflow: 'ellipsis' }}
              noWrap
            >
              {evaluationImageName}
            </Typography>
          </Box>
        )}

        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          sx={{ ml: evaluationLoading ? '50px' : '20px', mb: '100px' }}
          position="relative"
        >
          <Box
            width="275px"
            height="275px"
            bgcolor="#f4f9f9"
            borderRadius={50}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {goodImages.map((image, index) => {
              if (index % 3 === 0) {
                return (
                  <Box
                    key={index}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    position="absolute"
                    component="img"
                    src={image.src}
                    sx={{
                      maxWidth: '240px',
                      maxHeight: '240px',
                      boxSizing: 'border-box',
                      objectFit: 'contain',
                      borderRadius: '20px',
                      boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                      transform: `translate(0, 0) rotate(-340deg)`,
                      animation: `rotationGood1 1s`,
                      '@keyframes rotationGood1': {
                        '0%': {
                          opacity: 0,
                          transform: `translate(-100px, 100px) rotate(-250deg)`,
                        },
                        '50%': {
                          opacity: 1,
                        },
                        '100%': {
                          transform: `translate(0, 0) rotate(-340deg)`,
                        },
                      },
                    }}
                  />
                );
              } else if (index % 2 === 0) {
                return (
                  <Box
                    key={index}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    position="absolute"
                    component="img"
                    src={image.src}
                    sx={{
                      maxWidth: '240px',
                      maxHeight: '240px',
                      boxSizing: 'border-box',
                      objectFit: 'contain',
                      borderRadius: '20px',
                      boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                      transform: `translate(0, 0) rotate(-350deg)`,
                      animation: `rotationGood2 1s`,
                      '@keyframes rotationGood2': {
                        '0%': {
                          opacity: 0,
                          transform: `translate(-100px, 100px) rotate(-260deg)`,
                        },
                        '50%': {
                          opacity: 1,
                        },
                        '100%': {
                          transform: `translate(0, 0) rotate(-350deg)`,
                        },
                      },
                    }}
                  />
                );
              } else {
                return (
                  <Box
                    key={index}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    position="absolute"
                    component="img"
                    src={image.src}
                    sx={{
                      maxWidth: '240px',
                      maxHeight: '240px',
                      boxSizing: 'border-box',
                      objectFit: 'contain',
                      borderRadius: '20px',
                      boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                      transform: `translate(0, 0) rotate(-359deg)`,
                      animation: `rotationGood3 1s`,
                      '@keyframes rotationGood3': {
                        '0%': {
                          opacity: 0,
                          transform: `translate(-100px, 100px) rotate(-270deg)`,
                        },
                        '50%': {
                          opacity: 1,
                        },
                        '100%': {
                          transform: `translate(0, 0) rotate(-359deg)`,
                        },
                      },
                    }}
                  />
                );
              }
            })}
          </Box>
          <Box
            width="100px"
            height="100px"
            bgcolor="#FFF"
            borderRadius={50}
            position="absolute"
            bottom="-80px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography color="green" fontFamily="Poppins" fontSize={32} fontWeight={300}>
              {debounceGoodImages}
            </Typography>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            position="absolute"
            bottom="-120px"
          >
            <Typography
              fontFamily="Poppins"
              fontSize={16}
              color={theme.palette.text.primary}
              fontWeight={500}
            >
              Good images
            </Typography>
            <Typography
              fontFamily="Poppins"
              fontSize={14}
              color={theme.palette.text.secondary}
              fontWeight={400}
            >
              Examples to follow
            </Typography>
          </Box>
        </Box>
      </Box>

      {!evaluationLoading && (
        <Box display="flex" flexDirection="row" justifyContent="space-between" width="70%">
          <Button
            variant="outlined"
            onClick={() => {
              setBrandImages([]);
              setBadImages([]);
              setGoodImages([]);
              setStep(Steps.identifying);
            }}
          >
            <Typography color="#352E65">Previous step</Typography>
          </Button>
          <Box>
            <LoadingButton
              variant="outlined"
              sx={{ mr: '20px', color: '#352E65' }}
              onClick={() => {
                setStep(Steps.finalizing);
              }}
              loading={isFinishLoading}
            >
              Skip & Finish
            </LoadingButton>
            <Button
              variant="contained"
              sx={{ bgcolor: '#28205B' }}
              onClick={() => {
                setStep(Steps.review);
              }}
            >
              Start Review
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};
