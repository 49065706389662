import { useNavigate } from 'react-router-dom';
import useSetUser from './useSetUser';

const useLogout = () => {
  const navigate = useNavigate();
  const setUser = useSetUser();

  return () => {
    localStorage.removeItem('token');
    localStorage.removeItem('brand');
    localStorage.removeItem('onboardingProgressBrand');
    localStorage.removeItem('onboardingProgress');
    localStorage.removeItem('isOnboarding');
    localStorage.removeItem('isOnboarding');
    localStorage.setItem('isFirstLaunch', 'true');
    setUser(null);
    navigate('/signin');
  };
};

export default useLogout;
