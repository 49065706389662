import React from 'react';
import logodark from '../images/logodark.webp';

interface IProps {
  width?: string;
  height?: string;
}

export const LogoDark = ({ width, height }: IProps) => {
  return <img src={logodark} alt="logo" width={width} height={height} />;
};
