import React, { useState } from 'react';
import DashboardLayout from '../../../components/Dashboard/DashboardLayout';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ActionTable from './ActionTable';
import { useGetActionsQuery } from '../../../core/api/actions';
import useOnboardingSearchParam from '../../../hooks/useOnboardingSearchParam';
import FreeTrialCard from '../../../components/Common/FreeTrialCard';
import { useSubscriptionStatus } from '../../../hooks/useSubscriptionStatus';

function ActionsPage() {
  const { brandId } = useOnboardingSearchParam();
  const { freeTrial } = useSubscriptionStatus();

  const [value, setValue] = useState(0);
  const { data: actions = [], isFetching } = useGetActionsQuery(Number(brandId), {
    skip: freeTrial,
  });
  const sortedActionHistory = actions.filter(action => action.status !== 'Pending');

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <DashboardLayout
      title={'Actions'}
      description={'Overview results of image audit over all vendors sites'}
    >
      {freeTrial ? (
        <Stack mt={-3}>
          <FreeTrialCard title="Explore actions with Enterprise Plan" sx={{ mt: 2 }} />
        </Stack>
      ) : (
        <Stack
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
            alignSelf: 'stretch',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '40px',
              alignSelf: 'stretch',
              borderRadius: '16px',
              bgcolor: 'neutralWhite',
              pl: '16px',
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              sx={{
                '& .MuiTabs-indicator': {
                  borderRadius: '8px 8px 0 0',
                },
              }}
            >
              <Tab
                label={
                  <Box sx={styles.tabButtonStyle(value === 0)}>
                    To do
                    <Chip label={actions.length} size="small" sx={styles.chipStyle} />
                  </Box>
                }
                sx={styles.tabStyle}
                disableRipple
                disabled={actions.length === 0}
              />
              <Tab
                label={
                  <Box sx={styles.tabButtonStyle(value === 1)}>
                    Action history
                    <Chip label={sortedActionHistory.length} size="small" sx={styles.chipStyle} />
                  </Box>
                }
                sx={styles.tabStyle}
                disableRipple
                disabled={sortedActionHistory.length === 0}
              />
            </Tabs>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              alignSelf: 'stretch',
              width: '100%',
            }}
          >
            {value === 0 && <ActionTable actions={actions} todo={true} isFetching={isFetching} />}

            {value === 1 && (
              <ActionTable actions={sortedActionHistory} todo={false} isFetching={isFetching} />
            )}
          </Box>
        </Stack>
      )}
    </DashboardLayout>
  );
}

const styles = {
  tabStyle: {
    textTransform: 'none',
    color: 'neutralGray.3',
    display: 'flex',
    padding: '8px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0px',
    alignSelf: 'stretch',
    '&.Mui-selected': {
      color: 'brandPurple.3',
    },
  },
  tabButtonStyle: (isSelected: boolean) => ({
    display: 'flex',
    padding: '10px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    '&:hover': {
      ...(!isSelected && {
        bgcolor: '#f8f8fb',
        borderRadius: '8px',
        color: 'brandPurple.3',
      }),
    },
  }),
  chipStyle: {
    display: 'flex',
    padding: '2px 0px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0px',
    borderRadius: '8px',
    backgroundColor: 'background.button.tertiary',
  },
};

export default ActionsPage;
