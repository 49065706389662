import React from 'react';

interface IProps {
  color: string;
}

export const ReviewRightIcon = ({ color }: IProps) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_714_1616)">
      <mask id="mask0_714_1616" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
        <rect y="0.5" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_714_1616)">
        <path
          d="M16.6269 13.25H4.5V11.75H16.6269L10.9308 6.05383L12 5L19.5 12.5L12 20L10.9308 18.9461L16.6269 13.25Z"
          fill={color}
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_714_1616">
        <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
