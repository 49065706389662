import type { ImageStats } from '../core/api/types/Product';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useAppSelector } from './useStore';
import { useEffect, useState } from 'react';

const useOnboardingSearchParam = () => {
  const { stats, brandsCount, brandsLoading, statsLoading } = useAppSelector(
    state => state.dashboard
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const [showOnboarding, setShowOnboarding] = useState(false);

  const isOnboardingPage: boolean = location.pathname === '/onboarding';

  const isCompleted = searchParams.get('completed') === 'true';
  const brandId = searchParams.get('brand_id');
  const currentStep = searchParams.get('step');
  const loading = searchParams.get('loading') === 'true';

  const isStatsNullish = (stats: ImageStats | undefined): boolean => {
    if (!stats) return false;
    return (
      stats.total_images === 0 &&
      stats.average_score === null &&
      stats.unrealized_revenue === 0 &&
      Object.values(stats.image_quality).every(q => q.count === 0 && q.featured_image === null) &&
      Object.values(stats.score_distribution).every(v => v === 0) &&
      stats.diagnostics.length === 0 &&
      stats.resellers.length === 0 &&
      stats.actions.unrealized_revenue_monthly_usd === 0 &&
      stats.actions.score_change === 0 &&
      stats.actions.count === 0
    );
  };

  const failedBrand = !statsLoading && isStatsNullish(stats);
  const hasNoBrands = !brandsLoading && brandsCount === 0;

  useEffect(() => {
    if (hasNoBrands || failedBrand) {
      setShowOnboarding(true);
      searchParams.set('onboarding', 'true');
      setSearchParams(searchParams);
      return;
    }
    const onboarding = searchParams.get('onboarding') === 'true' || isOnboardingPage;
    setShowOnboarding(onboarding);
  }, [brandsCount, searchParams, isOnboardingPage, statsLoading, stats]);

  return {
    isOnboarding: showOnboarding,
    isCompleted,
    brandId,
    currentStep,
    initialStep: currentStep === '1',
    isOnboardingStart: currentStep === '2',
    loadingOnboarding: loading,
    isOnboardingPage,
  };
};

export default useOnboardingSearchParam;
