import React, { useEffect, useState } from 'react';
import { Box, Button, Chip, CircularProgress, Stack, TextField } from '@mui/material';
import { Header } from './Header';
import { getBrandChips } from '../../../repositories/brands.service';
import useDebounce from '../../../hooks/useDebounce';
import { ChipIcon } from '../../../assets/icons';
import { Steps } from '../types';

interface IAddBrand {
  setStep: (value: number) => void;
  brand: string;
  setBrand: (value: string) => void;
  chips: string[];
  setChips: (value: string[]) => void;
  selectedChips: string[];
  setSelectedChips: (value: string[]) => void;
  setRequestQuery: (value: string) => void;
}

export const AddBrand = ({
  setStep,
  brand,
  setBrand,
  chips,
  setChips,
  selectedChips,
  setSelectedChips,
  setRequestQuery,
}: IAddBrand) => {
  const [loading, setLoading] = useState<boolean>(false);

  const debounceBrand = useDebounce(brand, 500);

  useEffect(() => {
    const handleGetChips = async () => {
      const chipsResponse = await getBrandChips(brand);
      setChips(chipsResponse?.products || []);

      setLoading(false);

      if (chipsResponse?.products.length) {
        setChips(chipsResponse?.products);
      } else {
        setChips([]);
      }
    };

    if (brand.length >= 3) {
      setLoading(true);
      setSelectedChips([]);
      handleGetChips();
    }
  }, [debounceBrand]);

  const handleClickChip = (chip: string) => {
    const isChipSelected = selectedChips.find(item => item === chip);

    let newSelectedChips = selectedChips;

    if (isChipSelected) {
      newSelectedChips = newSelectedChips.filter(item => item !== chip);
    } else {
      newSelectedChips = [...newSelectedChips, chip];
    }

    setSelectedChips(newSelectedChips);
  };

  return (
    <Box paddingX="100px" paddingY="50px" display="flex" flexDirection="column" width="60%">
      <Box display="flex" flexDirection="column">
        <Header
          title="Create your brand's AI"
          description="Specify your brand and product type to get a tailored AI"
        />
        <Box display="flex" flexDirection="column" width="100%" mt="40px">
          <TextField
            label="Your brand name"
            variant="standard"
            InputProps={{ sx: { fontSize: 24 } }}
            InputLabelProps={{ sx: { fontSize: 24 } }}
            onChange={e => {
              setBrand(e.target.value);
            }}
          />
          <TextField
            label="Your product type"
            variant="standard"
            InputProps={{ sx: { fontSize: 24 } }}
            InputLabelProps={{ sx: { fontSize: 24 } }}
            sx={{
              mt: '30px',
            }}
            onChange={e => {
              setSelectedChips([]);
              setRequestQuery(brand + ' ' + e.target.value + ' ' + 'photography');
              if (e.target.value) {
                setSelectedChips([e.target.value]);
              } else {
                setSelectedChips([]);
              }
            }}
            value={selectedChips.join(', ')}
          />
          <Box mt="10px">
            {loading ? (
              <Box display="flex" justifyContent="flex-start">
                <Box display="flex" position="relative" alignItems="center" justifyContent="center">
                  <CircularProgress
                    size={40}
                    thickness={5}
                    sx={{ color: '#DABBF3', opacity: 0.3, animationDuration: '5s' }}
                  />
                  <Box position="absolute" mt={'5px'}>
                    <ChipIcon />
                  </Box>
                </Box>
              </Box>
            ) : (
              <Stack direction="row" spacing={1}>
                {chips.map(chip => {
                  const isChipSelected = selectedChips.find(item => item === chip);
                  return (
                    <Chip
                      key={chip}
                      variant={isChipSelected ? 'filled' : 'outlined'}
                      label={chip}
                      onClick={() => {
                        handleClickChip(chip);
                      }}
                      icon={
                        <Box display="flex">
                          <ChipIcon />
                        </Box>
                      }
                    />
                  );
                })}
              </Stack>
            )}
          </Box>
          <Button
            variant="contained"
            sx={{
              width: '100px',
              bgcolor: '#28205B',
              mt: '20px',
              '&: disabled': {
                border: '0px',
                color: 'white',
                bgcolor: '#f4f2f7',
              },
            }}
            disabled={!brand || !selectedChips.length}
            onClick={() => {
              setStep(Steps.identifying);
              setChips([]);
            }}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
