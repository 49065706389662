import React, { useRef, useState } from 'react';
import {
  Avatar,
  Box,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import { NewLogo } from '../../../assets/icons';
import { useNavigate } from 'react-router-dom';
import useLogout from '../../../hooks/useLogout';
import useUser from '../../../hooks/useUser';

export const Header = () => {
  const [isMenuShown, setIsMenuShown] = useState(false);

  const menuAnchorRef = useRef<HTMLDivElement | null>(null);
  const user = useUser();
  const navigate = useNavigate();
  const logout = useLogout();

  const handleLogout = () => {
    logout();
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      height="64px"
      width="100vw"
      bgcolor="#FFF"
      boxSizing="border-box"
      borderBottom="1px solid #EFEFF0"
      zIndex={2}
      mt={{ xs: '60px', lg: '0px' }}
    >
      <Box
        display="flex"
        alignItems="center"
        sx={{ ml: '25px', '&: hover': { cursor: 'pointer' } }}
      >
        <Box
          display="flex"
          alignItems="center"
          onClick={() => {
            navigate('/');
          }}
        >
          <NewLogo />
          <Typography
            fontFamily="Lexend Deca"
            fontSize="18px"
            fontWeight={500}
            lineHeight="30px"
            ml="10px"
          >
            focalstudio
          </Typography>
          <Typography sx={{ fontSize: 14 }} ml={1} mt={1} fontWeight={'bold'}>
            beta
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: 'none', lg: 'block' },
          mr: '25px',
        }}
      >
        <Avatar
          alt={'user'}
          src={user?.avatar ?? 'https://placehold.it/50x50'}
          ref={menuAnchorRef}
          onClick={() => {
            setIsMenuShown(true);
          }}
          sx={{
            cursor: 'pointer',
            width: '40px',
            height: '40px',
          }}
        />
      </Box>
      <Popper
        open={isMenuShown}
        anchorEl={menuAnchorRef.current}
        role={undefined}
        placement={'top-end'}
        transition
        sx={{ zIndex: 2 }}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'right top',
            }}
          >
            <Paper sx={{ border: '1px solid #E3E3E3', borderRadius: '10px' }} elevation={1}>
              <ClickAwayListener
                onClickAway={() => {
                  setIsMenuShown(false);
                }}
              >
                <MenuList autoFocusItem={isMenuShown} sx={{ width: '100%' }}>
                  <MenuItem
                    onClick={() => {
                      navigate('/profile');
                    }}
                  >
                    <Typography fontFamily="Figtree" fontSize={16} sx={{ paddingX: '20px' }}>
                      Profile
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>
                    <Typography fontFamily="Figtree" fontSize={16} sx={{ paddingX: '20px' }}>
                      Log out
                    </Typography>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};
