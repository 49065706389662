import React from 'react';

interface IProps {
  active?: boolean;
}

export const AssetsRedoIcon = ({ active }: IProps) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.4 22.6C28.55 21 26.15 20 23.5 20C18.85 20 14.92 23.03 13.54 27.22L15.9 28C16.95 24.81 19.95 22.5 23.5 22.5C25.45 22.5 27.23 23.22 28.62 24.38L25 28H34V19L30.4 22.6Z"
      fill={active ? '#000' : '#E1E2EC'}
      fillOpacity={active ? '1' : '0.6'}
    />
  </svg>
);
