import React from 'react';

function WallpaperIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M2.5 2C2.0875 2 1.73438 2.14688 1.44063 2.44063C1.14688 2.73438 1 3.0875 1 3.5V15H2.5V3.5H14V2H2.5Z"
        fill="#171D30"
      />
      <path
        d="M16.5 15.5H6.5L9.15625 11.4524L10.875 14.0714L13.2188 10.5L16.5 15.5Z"
        fill="#171D30"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 18C5.0875 18 4.73438 17.8531 4.44062 17.5594C4.14687 17.2656 4 16.9125 4 16.5V6.5C4 6.0875 4.14687 5.73438 4.44062 5.44063C4.73438 5.14688 5.0875 5 5.5 5H17.5C17.9125 5 18.2656 5.14688 18.5594 5.44063C18.8531 5.73438 19 6.0875 19 6.5V16.5C19 16.9125 18.8531 17.2656 18.5594 17.5594C18.2656 17.8531 17.9125 18 17.5 18H5.5ZM5.5 16.5H17.5V6.5H5.5V16.5Z"
        fill="#171D30"
      />
    </svg>
  );
}

export default WallpaperIcon;
