import { Stack, Typography } from '@mui/material';
import React from 'react';
import DoubleDollarIcon from '../../../assets/icons/Dashboard/DoubleDollarIcon';
import AverageScoreIndicator from './AverageScoreIndicator';
import OnboardingAverageScore from '../../Common/Placeholders/OnboardingAverageScore';
import useOnboardingSearchParam from '../../../hooks/useOnboardingSearchParam';

interface Props {
  score: number;
  unrealizedRevenue?: string;
}

function AverageScore({ score, unrealizedRevenue }: Props) {
  const { isOnboarding } = useOnboardingSearchParam();

  const displayUnrealizedRevenue = unrealizedRevenue === '0' ? '-' : unrealizedRevenue;

  return (
    <Stack width={'29%'} minWidth={'350px'} bgcolor={'#fff'} borderRadius={'16px'}>
      <Stack p={'16px 24px 12px 24px'} mx={'auto'}>
        <Typography
          fontSize={20}
          fontWeight={400}
          lineHeight={'28px'}
          letterSpacing={'-0.24px'}
          color={'text.primary'}
        >
          Average score
        </Typography>
      </Stack>
      {!isOnboarding ? (
        <AverageScoreIndicator score={score} />
      ) : (
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '16px',
            pt: '24px',
          }}
        >
          <OnboardingAverageScore />
        </Stack>
      )}
      <Stack p={'8px 16px 16px 16px'} textAlign={'center'}>
        <Typography
          fontSize={14}
          fontWeight={400}
          lineHeight={'20px'}
          letterSpacing={'-0.07px'}
          color={'text.secondary'}
        >
          Average score of images quality over all <br />
          vendors sites
        </Typography>
      </Stack>
      <Stack p={'16px 24px 0px 24px'} gap={'16px'}>
        <Stack
          border={'1px solid'}
          borderColor={'borderActionsNormal'}
          borderRadius={'12px'}
          p={'10px 12px'}
          direction={'row'}
          gap={'16px'}
          sx={{
            ...(isOnboarding && {
              alignItems: 'center',
            }),
          }}
        >
          <Stack
            width={'40px'}
            height={'40px'}
            bgcolor={!isOnboarding ? 'button.destructiveTertiary' : '#F8F8FB'}
            borderRadius={'12px'}
            direction={'row'}
            alignItems={'center'}
            justifyContent={'center'}
            sx={{
              filter: 'blur(5px)',
            }}
          >
            <DoubleDollarIcon bgcolor={!isOnboarding ? '#EE3939' : '#E9E9F0'} />
          </Stack>

          <Stack>
            <Typography
              fontSize={14}
              fontWeight={400}
              lineHeight={'20px'}
              letterSpacing={'-0.07px'}
            >
              Unrealized Revenue
            </Typography>
            {!isOnboarding && (
              <Typography
                fontSize={16}
                fontWeight={500}
                lineHeight={'24px'}
                letterSpacing={'-0.16px'}
                sx={{
                  filter: 'blur(5px)',
                }}
              >
                {displayUnrealizedRevenue} per year
              </Typography>
            )}
          </Stack>
        </Stack>

        <Stack p={'0px 0px 24px 0px'} gap={'16px'}>
          <Typography fontSize={16} fontWeight={400} lineHeight={'24px'} letterSpacing={'-0.16px'}>
            What does score mean?
          </Typography>
          <Typography
            fontSize={14}
            fontWeight={400}
            lineHeight={'20px'}
            letterSpacing={'-0.07px'}
            color={'text.secondary'}
          >
            Average score of images quality over all vendors sites and all products. Score
            calculated for each based on 12 parameters
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default AverageScore;
