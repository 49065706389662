import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Skeleton } from '@mui/material';

interface Props {
  size: number;
}

export default function TablePlaceholder({ size }: Props) {
  return (
    <>
      {Array.from({ length: size }).map((_, index) => (
        <Grid
          container
          spacing={2}
          key={index}
          sx={{
            py: '16px',
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: 'white',
            borderRadius: '16px',
            ml: 0,
            mb: '16px',
            width: '100%',
          }}
        >
          <Grid item xs={4} sx={styles.gridItemStyle}>
            <Box sx={styles.tableCell}>
              <Skeleton variant="circular" width={24} height={24} />

              <Skeleton variant="text" width={100} />
            </Box>
          </Grid>
          <Grid item xs={2} sx={styles.gridItemStyle}>
            <Box sx={[styles.tableCell, { gap: '8px' }]}>
              <Skeleton variant="text" width={100} />
            </Box>
          </Grid>
          <Grid item xs={2} sx={styles.gridItemStyle}>
            <Box sx={styles.tableCell}>
              <Skeleton variant="text" width={100} />
            </Box>
          </Grid>
          <Grid
            item
            xs={2}
            sx={[styles.gridItemStyle, { display: 'flex', flexDirection: 'row', gap: '4px' }]}
          >
            <Skeleton variant="text" width={100} />
          </Grid>
          <Grid item xs={2} sx={styles.gridItemStyle}>
            <Box sx={styles.tableCell} justifyContent="flex-end" height="100%">
              <Skeleton variant="text" width={100} />
            </Box>
          </Grid>
        </Grid>
      ))}
    </>
  );
}

const styles = {
  tableCell: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  headerItemText: {
    color: 'rgba(66, 72, 93, 0.60)',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '-0.07px',
  },
  gridItemStyle: {
    pt: '0 !important',
    px: '16px',
  },
};
