import React from 'react';
import Stack from '@mui/material/Stack';
import ProductItem from './ProductItem';
import type { ImageQuality } from '../../../core/api/types/Product';
import { useAppSelector } from '../../../hooks/useStore';

interface Props {
  imageQuality?: ImageQuality;
  totalImages?: number;
}

function ScrappedImages({ imageQuality, totalImages }: Props) {
  const images = useAppSelector(state => state.onboarding.images);
  const isImageOnboarding = images.length > 0;
  return (
    <Stack direction={'row'} alignItems={'center'} gap={'16px'} width={'100%'}>
      <ProductItem
        scoreLabel="Low quality images"
        minScore={1}
        maxScore={4}
        featuredImage={imageQuality?.low.featured_image ?? ''}
        count={imageQuality?.low.count ?? 0}
        totalImages={totalImages ?? 0}
        onboardingImage={isImageOnboarding ? images[0] : null}
      />
      <ProductItem
        scoreLabel="Medium quality images"
        minScore={5}
        maxScore={7}
        featuredImage={imageQuality?.medium.featured_image ?? ''}
        count={imageQuality?.medium.count ?? 0}
        totalImages={totalImages ?? 0}
        onboardingImage={isImageOnboarding ? images[1] : null}
      />
      <ProductItem
        scoreLabel="High quality images"
        minScore={8}
        maxScore={10}
        featuredImage={imageQuality?.high.featured_image ?? ''}
        count={imageQuality?.high.count ?? 0}
        totalImages={totalImages ?? 0}
        onboardingImage={isImageOnboarding ? images[2] : null}
      />
    </Stack>
  );
}

export default ScrappedImages;
