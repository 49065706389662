import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

const CanvasNoneMode = ({
  imageWithoutBackground,
  canvasRef,
  modalRef,
  mode,
  correctedImage,
  setImage,
  canvasRef2,
  croppedImage,
  correctImage,
  correctImageSize,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    canvas.width = correctImageSize.width;
    canvas.height = correctImageSize.height;

    const squareSize = 10;
    const numRows = canvas.height / squareSize;
    const numCols = canvas.width / squareSize;

    for (let row = 0; row < numRows; row++) {
      for (let col = 0; col < numCols; col++) {
        const x = col * squareSize;
        const y = row * squareSize;

        if ((row + col) % 2 === 0) {
          context.fillStyle = 'lightgrey';
        } else {
          context.fillStyle = 'white';
        }

        context.fillRect(x, y, squareSize, squareSize);
      }
    }
    context.drawImage(correctImage, 0, 0, correctImageSize.width, correctImageSize.height);
  }, [canvasRef, modalRef, mode, imageWithoutBackground, correctImage]);

  useEffect(() => {
    const canvas = canvasRef2.current;
    const context = canvas.getContext('2d');

    const img = new Image();
    img.src = croppedImage || correctedImage || imageWithoutBackground;
    img.crossOrigin = 'anonymous';

    img.onload = e => {
      setIsLoading(false);
      canvas.width = img.width;
      canvas.height = img.height;

      context.drawImage(img, 0, 0, img.width, img.height);

      canvas.toBlob(img => setImage(img));
    };
  }, [canvasRef, modalRef, mode, imageWithoutBackground, correctedImage, croppedImage]);

  return (
    <>
      <canvas ref={canvasRef} style={isLoading ? { display: 'none' } : style} />
      <canvas ref={canvasRef2} style={{ display: 'none' }} />
      {isLoading && (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '10px',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          <CircularProgress
            variant="determinate"
            sx={{
              color: '#5930A5',
              opacity: 0.5,
              position: 'absolute',
            }}
            size={200}
            thickness={1}
            value={100}
          />
          <CircularProgress
            variant="indeterminate"
            disableShrink
            sx={{
              color: '#5930A5',
              animationDuration: '2000ms',
              position: 'absolute',
            }}
            size={200}
            thickness={1}
          />
          <Typography
            fontFamily="Poppins"
            fontWeight={400}
            fontSize="18px"
            color="#313234"
            position="relative"
            top="135px"
          >
            Loading asset...
          </Typography>
        </Box>
      )}
    </>
  );
};

export default CanvasNoneMode;

const style = {
  borderRadius: '16px',
  bgcolor: '#EFEFF0',
  border: '1px solid #EFEFF0',
  height: '100%',
};
