import React from 'react';

export const ThreeToTwo: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="16" height="16" fill="transparent" />
    <path
      d="M5 7.5V6H6.5M11 8.5V10H9.5M3 5V11C3 11.5523 3.44772 12 4 12H12C12.5523 12 13 11.5523 13 11V5C13 4.44772 12.5523 4 12 4H4C3.44772 4 3 4.44772 3 5Z"
      stroke="black"
    />
  </svg>
);
