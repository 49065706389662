import { useMemo } from 'react';
import useUser from './useUser';

export function useSubscriptionStatus() {
  const user = useUser();
  const subscription = user?.subscription;

  const freeTrial = useMemo(() => {
    if (!subscription) return false;
    const { plan } = subscription;
    return plan.name.toLowerCase() === 'free_trial';
  }, [subscription]);

  const trialProgress = useMemo(() => {
    if (!freeTrial || !subscription) return null;
    const start = new Date(subscription.start_date);
    const end = new Date(subscription.end_date);
    const now = new Date();

    const totalDays = Math.ceil((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
    const daysElapsed = Math.ceil((now.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
    const daysLeft = Math.max(totalDays - daysElapsed, 0);

    const progress = Math.max((daysLeft / totalDays) * 100, 0);

    return { progress, daysLeft };
  }, [freeTrial, subscription]);

  return { user, freeTrial, trialProgress };
}
