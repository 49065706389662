import React from 'react';

interface IIconProps {
  color: string;
}

export const FolderIcon: React.FC<IIconProps> = ({ color }) => (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.9623 16.2003L20.8914 14.0586C20.5832 13.4422 20.4291 13.134 20.1992 12.9089C19.9959 12.7097 19.7508 12.5583 19.4818 12.4655C19.1775 12.3606 18.8329 12.3606 18.1437 12.3606H14.4743C13.399 12.3606 12.8614 12.3606 12.4507 12.5698C12.0894 12.7539 11.7957 13.0476 11.6116 13.4089C11.4023 13.8195 11.4023 14.3571 11.4023 15.4324V16.2003M11.4023 16.2003H25.9943C27.6073 16.2003 28.4138 16.2003 29.0298 16.5142C29.5717 16.7903 30.0123 17.2308 30.2884 17.7727C30.6023 18.3887 30.6023 19.1951 30.6023 20.8079V25.0316C30.6023 26.6444 30.6023 27.4508 30.2884 28.0669C30.0123 28.6087 29.5717 29.0493 29.0298 29.3254C28.4138 29.6392 27.6073 29.6392 25.9943 29.6392H16.0103C14.3974 29.6392 13.5909 29.6392 12.9749 29.3254C12.4329 29.0493 11.9924 28.6087 11.7162 28.0669C11.4023 27.4508 11.4023 26.6444 11.4023 25.0316V16.2003Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
