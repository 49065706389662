import axios from 'axios';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';

interface DATAPROMPT {
  image: string;
  prompt: string;
}

interface IpostChatInitialize {
  brand_id?: number | string;
  image: string;
}

interface IpostChatAnswer {
  chatID: string;
  message: string;
}

const url = process.env.REACT_APP_FOCAL_API_URL ?? 'http://focalstudio-science.com';

const newUrl = process.env.REACT_APP_API_URL ?? 'https://focal.adtitan.io';

export let controller = new AbortController();

export const postPromptImage = async (data: DATAPROMPT) => {
  // const file = new FormData();
  // file.append("file", data);

  return await axios
    .post(
      `${url}/image/generate/upscale`,
      {
        transparent_image_s3_uri: data.image,
        prompt: data.prompt,
        negative_prompt: '',
        width_ratio: 1,
        height_ratio: 1,
      },
      {
        headers: {
          'api-key': 'focal-test-key-temp-2023',
          'Content-Type': 'application/json',
        },
      }
    )
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      Sentry.setTag('api_url', `${url}/image/generate/upscale`);
      Sentry.captureException(error);
      console.error(error);
    });
};

// Chat bot API - Initilize

export const postChatInitialize = async (data: IpostChatInitialize) => {
  // const file = new FormData();
  //   file.append("file", data);

  return await axios
    .post(
      `${newUrl}/api/chat/initialize/`,
      {
        brand_id: data?.brand_id,
        transparent_image_url: data.image,
      },
      {
        headers: {
          'api-key': 'focal-test-key-temp-2023',
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token') ?? ''}`,
        },
      }
    )
    .then((response: any) => {
      // return response.data;
      return response.data.chat_id;
    })
    .catch((error: any) => {
      Sentry.setTag('api_url', `${newUrl}/api/chat/initialize/`);
      Sentry.captureException(error);
      throw new Error(`${url}/chat/initialize`, error);
    });
};

// Chat bot API - Answer

export const postChatAnswer = async (data: IpostChatAnswer) => {
  return await axios
    .post(
      `${url}/chat/answer`,
      {
        chat_id: data.chatID,
        message: data.message,
      },
      {
        headers: {
          'api-key': 'focal-test-key-temp-2023',
          'Content-Type': 'application/json',
        },
      }
    )
    .then((response: any) => {
      // return response.data;
      return {
        text: response.data.text,
        prompts: response.data.prompts,
      };
    })
    .catch((error: any) => {
      Sentry.setTag('api_url', `${url}/chat/answer`);
      Sentry.captureException(error);
      console.error(error);
    });
};

export const postChatAnswerV2 = async (data: IpostChatAnswer) => {
  controller = new AbortController();

  return await axios
    .post(
      `${url}/chat/answer/V3`,
      {
        chat_id: data.chatID,
        message: data.message,
      },
      {
        headers: {
          'api-key': 'focal-test-key-temp-2023',
          'Content-Type': 'application/json',
        },
        signal: controller.signal,
      }
    )
    .then((response: any) => {
      return {
        result: response.data.result,
      };
    })
    .catch(error => {
      console.error(error);
      Sentry.setTag('api_url', `${url}/chat/answer/V3`);
      Sentry.captureException(error);
      toast.error(error.message, {
        position: 'bottom-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    });
};

// Get generated image

export const getImageGenerated = async (requestId: string) => {
  return await axios
    .get(`${newUrl}/api/image`, {
      params: {
        request_id: requestId,
        // include_image_bytes: includeImageBytes
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token') ?? ''}`,
      },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      // Handle any errors that occurred during the request
      Sentry.setTag('api_url', `${newUrl}/api/image`);
      Sentry.captureException(error);
      console.error(error);
    });
};

export const getRemoveBackground = async (uri: string) => {
  return await axios
    .post(
      `${url}/image/remove_background/V2`,
      {
        image_url: uri,
        // include_image_bytes: includeImageBytes
      },
      {
        headers: {
          'api-key': 'focal-test-key-temp-2023',
          'Content-Type': 'application/json',
        },
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      // Handle any errors that occurred during the request
      Sentry.setTag('api_url', `${url}/image/remove_background/V2`);
      Sentry.captureException(error);
      console.error(error);
    });
};

export const getCorrectImage = async (image: string, mask: string) => {
  return await axios
    .post(
      `${url}/image/remove_background/correct`,
      {
        image_s3_uri: image,
        correction_mask_s3_uri: mask,
      },
      {
        headers: {
          'api-key': 'focal-test-key-temp-2023',
          'Content-Type': 'application/json',
        },
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      // Handle any errors that occurred during the request
      Sentry.setTag('api_url', `${url}/image/remove_background/correct`);
      Sentry.captureException(error);
      console.error(error);
    });
};

export const setSaveAsset = async (uri: string, name: string, brandID: number | null) => {
  return await axios
    .post(
      `${newUrl}/api/meta/assets/`,
      {
        url: uri,
        brand: brandID,
        name,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token') ?? ''}`,
        },
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      // Handle any errors that occurred during the request
      Sentry.setTag('api_url', `${newUrl}/api/meta/assets/`);
      Sentry.captureException(error);
      console.error(error);
    });
};

export const updateAsset = async (uri: string, chatId: string) => {
  return await axios
    .post(
      `${url}/chat/update_asset`,
      {
        transparent_image_s3_uri: uri,
        chat_id: chatId,
      },
      {
        headers: {
          'api-key': 'focal-test-key-temp-2023',
          'Content-Type': 'application/json',
        },
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      Sentry.setTag('api_url', `${url}/chat/update_asset`);
      Sentry.captureException(error);
      console.error(error);
    });
};

export const getAssets = async () => {
  return await axios
    .get(`${newUrl}/api/meta/assets/`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token') ?? ''}`,
      },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      // Handle any errors that occurred during the request
      Sentry.setTag('api_url', `${newUrl}/api/meta/assets/`);
      Sentry.captureException(error);
      console.error(error);
    });
};

export const deleteAsset = async (id: number) => {
  return await axios
    .delete(`${newUrl}/api/meta/assets/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token') ?? ''}`,
      },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      // Handle any errors that occurred during the request
      Sentry.setTag('api_url', `${newUrl}/api/meta/assets/${id}`);
      Sentry.captureException(error);
      console.error(error);
    });
};

export const updateAssetName = async (id: number, name: string) => {
  return await axios
    .patch(
      `${newUrl}/api/meta/assets/${id}/`,
      { name },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token') ?? ''}`,
        },
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      // Handle any errors that occurred during the request
      Sentry.setTag('api_url', `${newUrl}/api/meta/assets/${id}/`);
      Sentry.captureException(error);
      console.error(error);
    });
};
