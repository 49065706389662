import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import React from 'react';

function TopIssuesItemPlaceholder() {
  return (
    <Stack
      minWidth={'260px'}
      width={'100%'}
      borderRadius={'12px'}
      overflow={'hidden'}
      bgcolor={'#F1F1F1'}
    >
      <Skeleton variant="rectangular" width="100%" height={0} sx={{ paddingTop: '75%' }} />
      <Stack p={'16px'} gap={'16px'}>
        <Skeleton variant="circular" width={24} height={24} />
        <Stack>
          <Skeleton variant="text" height={26} />
          <Skeleton variant="text" height={26} />
          <Skeleton variant="text" height={56} />
        </Stack>
        <Stack direction={'row'} gap={'6px'} alignItems={'center'}>
          <Skeleton variant="circular" width={24} height={24} />
          <Skeleton variant="text" width={30} height={36} />
        </Stack>
      </Stack>
    </Stack>
  );
}

export default TopIssuesItemPlaceholder;
