import React from 'react';
import { type IConnection } from './types';
import { GitHub, LinkedIn, Reddit, Window } from '@mui/icons-material';
import { CONNECTIONS } from './constants/connections';
import { GoogleIcon } from '../assets/icons/GoogleIcon';
import { MicrosoftIcon } from '../assets/icons';

interface TokenResponse {
  token: string;
}

interface SavedPath {
  path: string;
}

export const saveToken = ({ token }: TokenResponse) => {
  localStorage.setItem('token', token);
};

export const getToken = () => {
  const token = localStorage.getItem('token');
  return token ?? null;
};

export const deleteToken = () => {
  localStorage.removeItem('token');
};

export const savePathURL = ({ path }: SavedPath) => {
  localStorage.setItem('path', path);
};

export const getPathURL = () => {
  const path = localStorage.getItem('path');
  return path ?? null;
};

export const deletePathURL = () => {
  localStorage.removeItem('path');
};

export const setBrand = (id: number | null) => {
  localStorage.setItem('brand', id ? id.toString() : '');
};

export const getBrand = () => {
  const token = localStorage.getItem('brand');
  return token ? Number(token) : null;
};

export const formatConnection = (item: IConnection) => {
  switch (item.provider) {
    case 'google':
      return {
        service: CONNECTIONS.GOOGLE,
        name: item.uid,
        icon: <GoogleIcon />,
        avatar: item.uid[0].toUpperCase(),
        id: item.id,
      };

    case 'windows':
      return {
        service: CONNECTIONS.WINDOWS,
        name: item.uid,
        icon: <Window />,
        avatar: item.uid[0].toUpperCase(),
        id: item.id,
      };

    case 'linkedin':
      return {
        service: CONNECTIONS.LINKEDIN,
        name: item.uid,
        icon: <LinkedIn />,
        avatar: item.uid[0].toUpperCase(),
        id: item.id,
      };

    case 'reddit':
      return {
        service: CONNECTIONS.REDDIT,
        name: item.uid,
        icon: <Reddit />,
        avatar: item.uid[0].toUpperCase(),
        id: item.id,
      };
    case 'microsoft':
      return {
        service: CONNECTIONS.MICROSOFT,
        name: item.uid,
        icon: <MicrosoftIcon />,
        avatar: item.uid[0].toUpperCase(),
        id: item.id,
      };
    case 'github':
      // eslint-disable-next-line no-case-declarations
      const extraDataString = item.extra_data.replace(/'/g, '"').replace(/None/g, 'null');
      // eslint-disable-next-line no-case-declarations
      const extraDataObj = JSON.parse(extraDataString);
      return {
        service: CONNECTIONS.GITHUB,
        name: extraDataObj.login,
        icon: <GitHub sx={{ width: '32px', height: '32px' }} />,
        avatar: extraDataObj.login[0].toUpperCase(),
        id: item.id,
      };

    default:
      return {};
  }
};
