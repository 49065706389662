import React from 'react';

function ShareIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M10.8333 11.6667H9.16667C7.79967 11.6661 6.45853 12.0393 5.28823 12.7458C4.11793 13.4522 3.163 14.4651 2.52667 15.675C2.50878 15.4504 2.49989 15.2253 2.5 15C2.5 10.3975 6.23083 6.66667 10.8333 6.66667V2.5L19.1667 9.16667L10.8333 15.8333V11.6667Z"
        fill="#42485D"
        fillOpacity="0.6"
      />
    </svg>
  );
}

export default ShareIcon;
