import React from 'react';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { NewLogo } from '../../../assets/icons/NewLogo';
import { useCalendlyEventListener } from 'react-calendly';
import SeePricingLanding from './SeePrincingLanding';
import { BrandGrid } from '../../../pages/Login/components/BrandGrid';

interface Props {
  open: boolean;
  handleClose: () => void;
}

const UpgradePlanModal = ({ open, handleClose }: Props) => {
  useCalendlyEventListener({
    onEventScheduled: () => {
      handleClose();
    },
  });
  return (
    <Modal open={open} onClose={handleClose}>
      <Stack sx={{ ...modalStyle }}>
        <Stack
          sx={{
            display: 'flex',
            height: 64,
            p: '16px 16px 16px 24px',
            gap: '32px',
            alignSelf: 'stretch',
          }}
        >
          <Stack direction={'row'} alignItems="center">
            <NewLogo />
            <Typography
              fontFamily="Lexend Deca"
              fontSize="18px"
              fontWeight={500}
              lineHeight="30px"
              ml="10px"
            >
              focalstudio
            </Typography>
          </Stack>
        </Stack>
        <Stack
          sx={{
            display: 'flex',
            padding: '24px 120px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '48px',
            flex: '1 0 0',
            alignSelf: 'stretch',
          }}
        >
          <SeePricingLanding onClose={handleClose} />

          <BrandGrid />
        </Stack>
      </Stack>
    </Modal>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'inline-flex',
  padding: '16px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '48px',
  bgcolor: '#f8f8fb',
  borderRadius: '24px',
  width: '100vw',
  height: '100vh',
};

export default UpgradePlanModal;
