import React from 'react';

interface IProps {
  active: boolean;
}

export const BrandSearchIcon = ({ active }: IProps) => (
  <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.99998 19C8.68716 19 7.45287 18.7503 6.2971 18.2509C5.14133 17.7516 4.13589 17.0743 3.28077 16.2192C2.42564 15.3641 1.7484 14.3586 1.24905 13.2029C0.749683 12.0471 0.5 10.8128 0.5 9.49998C0.5 8.18716 0.749683 6.95287 1.24905 5.7971C1.7484 4.64133 2.42564 3.63589 3.28077 2.78078C4.13589 1.92564 5.14133 1.2484 6.2971 0.749051C7.45287 0.249684 8.68716 0 9.99998 0C12.382 0 14.4519 0.765708 16.2096 2.29713C17.9673 3.82854 19.0198 5.73463 19.3673 8.0154H17.8365C17.5904 6.68334 17.0436 5.49006 16.1962 4.43558C15.3487 3.38108 14.2833 2.59613 13 2.08075V2.49998C13 3.04998 12.8041 3.52081 12.4125 3.91248C12.0208 4.30414 11.55 4.49998 11 4.49998H8.99998V6.49998C8.99998 6.78331 8.90414 7.02081 8.71247 7.21248C8.52081 7.40414 8.28331 7.49998 7.99997 7.49998H5.99997V9.49998H7.92305V12.5H6.99997L2.19998 7.69998C2.14998 7.99998 2.10414 8.29998 2.06248 8.59998C2.02081 8.89998 1.99998 9.19998 1.99998 9.49998C1.99998 11.6833 2.76664 13.5583 4.29997 15.125C5.83331 16.6916 7.73331 17.4833 9.99998 17.5V19ZM19.0615 18.6153L15.7365 15.3096C15.4122 15.516 15.0628 15.6826 14.6884 15.8096C14.3141 15.9365 13.9179 16 13.5 16C12.3846 16 11.4391 15.6121 10.6635 14.8365C9.88782 14.0609 9.5 13.1153 9.5 12C9.5 10.8846 9.88782 9.9391 10.6635 9.16345C11.4391 8.38782 12.3846 8 13.5 8C14.6153 8 15.5609 8.38782 16.3365 9.16345C17.1121 9.9391 17.5 10.8846 17.5 12C17.5 12.4243 17.4349 12.8253 17.3048 13.2029C17.1746 13.5804 17.0031 13.9314 16.7903 14.2557L20.1153 17.5615L19.0615 18.6153ZM13.5 14.5C14.2 14.5 14.7916 14.2583 15.275 13.775C15.7583 13.2916 16 12.7 16 12C16 11.3 15.7583 10.7083 15.275 10.225C14.7916 9.74164 14.2 9.49998 13.5 9.49998C12.8 9.49998 12.2083 9.74164 11.725 10.225C11.2416 10.7083 11 11.3 11 12C11 12.7 11.2416 13.2916 11.725 13.775C12.2083 14.2583 12.8 14.5 13.5 14.5Z"
      fill={active ? '#754DBA' : '#1C1B1F'}
    />
  </svg>
);
