import React from 'react';
import { Card, Box, Skeleton, Stack } from '@mui/material';

interface Props {
  count?: number;
}

const ProductCardPlaceholder = ({ count = 1 }: Props) => {
  return Array.from({ length: count }).map((_, i) => (
    <Card
      key={i}
      sx={{
        borderRadius: '16px',
        boxShadow: 'none',
      }}
    >
      <Stack
        justifyContent={'center'}
        alignItems={'center'}
        sx={{
          height: 299,
          position: 'relative',
          backgroundColor: '#ffffff',
        }}
      >
        <Skeleton
          animation="wave"
          sx={{
            height: '80%',
            width: '60%',
            m: 'auto',
          }}
        />
        <Skeleton
          variant="circular"
          animation="wave"
          width={28}
          height={28}
          sx={{
            bgcolor: '#e0e0e0',
            borderRadius: '50%',
            position: 'absolute',
            left: 12,
            top: 12,
            zIndex: 1,
          }}
        />
      </Stack>
      <Box p={'16px 24px 12px 24px'}>
        <Skeleton width="80%" animation="wave" />
        <Skeleton width="30%" animation="wave" />
      </Box>
    </Card>
  ));
};

export default ProductCardPlaceholder;
