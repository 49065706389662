import React from 'react';

export const KeyboardRightIcon: React.FC = () => (
  <svg width="14" height="21" viewBox="0 0 14 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="14" height="20" rx="4" transform="matrix(1 0 0 -1 0 20.5)" fill="#ECEBF2" />
    <rect
      x="0.5"
      y="-0.5"
      width="13"
      height="17"
      rx="3.5"
      transform="matrix(1 0 0 -1 0 17.5)"
      fill="white"
    />
    <rect
      x="0.5"
      y="-0.5"
      width="13"
      height="17"
      rx="3.5"
      transform="matrix(1 0 0 -1 0 17.5)"
      stroke="#ECEBF2"
    />
    <path
      opacity="0.5"
      d="M4.89 7.7H6.66L8.84 9.94L6.66 12.18H4.89L7.08 9.94L4.89 7.7Z"
      fill="#352E65"
    />
  </svg>
);
