import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import West from '@mui/icons-material/West';

import axios from 'axios';
import { useLazyGetSuggestedCategoriesQuery } from '../../core/api/brand';
import useDebounce from '../../hooks/useDebounce';
import { useSearchParams } from 'react-router-dom';

interface Brand {
  name: string;
  domain: string;
  logo: string;
}

interface BrandCategoryDropdownProps {
  query: string;
  onBrandSelect: (brand: Brand) => void;
  onCategorySelect: (category: string) => void;
  directCategorySelection: boolean;
}

const BrandCategoryDropdown: React.FC<BrandCategoryDropdownProps> = ({
  query,
  onBrandSelect,
  onCategorySelect,
  directCategorySelection,
}) => {
  const [selectionPhase, setSelectionPhase] = useState<'brand' | 'category'>('brand');
  const [brands, setBrands] = useState<Brand[]>([]);
  const [loadingBrands, setLoadingBrands] = useState(false);
  const [noBrandsFound, setNoBrandsFound] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);
  const [searchParams] = useSearchParams();

  const debouncedQuery = useDebounce(query, 500);

  useEffect(() => {
    const brandName = searchParams.get('brand_name');

    if (directCategorySelection && brandName) {
      setSelectionPhase('category');
      triggerGetSuggestedCategories(brandName);
    }
  }, [directCategorySelection]);

  const [
    triggerGetSuggestedCategories,
    { data: suggestedCategories, isLoading: loadingCategories, isFetching },
  ] = useLazyGetSuggestedCategoriesQuery();

  useEffect(() => {
    const fetchBrands = async () => {
      if (!debouncedQuery) {
        setBrands([]);
        setLoadingBrands(false);
        return;
      }
      try {
        setLoadingBrands(true);
        const response = await axios.get(
          `https://autocomplete.clearbit.com/v1/companies/suggest?query=${debouncedQuery}`
        );
        if (response.data.length > 0) {
          setBrands(response.data);
          setNoBrandsFound(false);
        } else {
          setNoBrandsFound(true);
        }
      } catch (error) {
        console.error('Error fetching brand data', error);
        setNoBrandsFound(true);
      } finally {
        setLoadingBrands(false);
      }
    };

    fetchBrands();
  }, [debouncedQuery]);

  const handleBrandSelect = (brand: Brand) => {
    setSelectedBrand(brand);
    setSelectionPhase('category');
    triggerGetSuggestedCategories(brand.name);
    onBrandSelect(brand);
  };

  const handleCategorySelect = (category: string) => {
    onCategorySelect(category);
  };

  const handleBackTobrands = () => {
    setSelectionPhase('brand');
    setSelectedBrand(null);
  };

  if (!query && selectionPhase === 'brand' && !loadingBrands) return null;

  return (
    <Paper
      sx={{
        position: 'absolute',
        top: '100%',
        left: 0,
        right: 0,
        zIndex: 1,
        mt: 1,
        maxHeight: '300px',
        overflow: 'auto',
        boxShadow:
          '0px 10px 16px -3px rgba(20, 21, 26, 0.08), 0px 3px 6px -2px rgba(20, 21, 26, 0.05)',
        borderRadius: '12px',
        border: '1px solid #E9E9F0',
      }}
    >
      <List sx={{ p: '2px 6px' }}>
        <Stack direction={'row'} alignItems={'center'} p={'6px'} gap={'6px'} minHeight={'36px'}>
          {selectionPhase === 'category' && (
            <West
              onClick={handleBackTobrands}
              sx={{
                cursor: 'pointer',
                color: 'text.secondary',
                fontSize: 20,
              }}
            />
          )}
          <Typography
            sx={{
              fontSize: '12px',
              color: 'text.primary',
              lineHeight: '16px',
              fontWeight: 400,
              flex: 1,
            }}
          >
            {selectionPhase === 'category' ? 'Select category:' : 'Select option:'}
          </Typography>
        </Stack>
        {selectionPhase === 'category' ? (
          isFetching || loadingCategories ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
              <CircularProgress size={24} />
            </Box>
          ) : suggestedCategories && suggestedCategories.product_categories?.length > 0 ? (
            suggestedCategories.product_categories.map((category, index) => (
              <ListItemButton
                key={`${category}_${index}`}
                onClick={() => {
                  handleCategorySelect(category);
                }}
                sx={{
                  borderRadius: '6px',
                  '&.Mui-selected': {
                    backgroundColor: 'rgba(23, 29, 48, 0.04)',
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    color: 'text.primary',
                    lineHeight: '20px',
                    fontWeight: 400,
                    letterSpacing: '-0.07px',
                  }}
                >
                  {category}
                </Typography>
              </ListItemButton>
            ))
          ) : (
            <Typography sx={{ p: 2, textAlign: 'center', color: 'text.secondary' }}>
              No suggested categories found
            </Typography>
          )
        ) : (
          <>
            {loadingBrands ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                <CircularProgress size={24} />
              </Box>
            ) : noBrandsFound ? (
              <Typography sx={{ p: 2, textAlign: 'center', color: 'text.secondary' }}>
                No brands found
              </Typography>
            ) : (
              brands.map((b, idx) => (
                <ListItemButton
                  sx={{
                    borderRadius: '6px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '6px',
                    '&.Mui-selected': {
                      backgroundColor: 'rgba(23, 29, 48, 0.04)',
                    },
                  }}
                  key={`${b.name}_${idx}`}
                  selected={b.name === selectedBrand?.name}
                  onClick={() => {
                    handleBrandSelect(b);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      src={b.logo}
                      alt={b.name}
                      sx={{
                        width: '52px',
                        height: '52px',
                        borderRadius: '8px',
                      }}
                    />
                  </ListItemAvatar>
                  <Stack>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        color: 'text.primary',
                        lineHeight: '20px',
                        fontWeight: 400,
                        letterSpacing: '-0.07px',
                      }}
                    >
                      {b.name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '12px',
                        color: 'text.secondary',
                        lineHeight: '16px',
                        fontWeight: 400,
                        letterSpacing: '-0.07px',
                      }}
                    >
                      {b.domain}
                    </Typography>
                  </Stack>
                </ListItemButton>
              ))
            )}
          </>
        )}
      </List>
    </Paper>
  );
};

export default BrandCategoryDropdown;
