import React from 'react';
import { Grid } from '@mui/material';
import { Asset } from './components/Asset';
import { Variations } from './components/Variations';
import { Ratio } from '../Ratio';
import { Download } from '../Download/Download';
import { type IOptions } from '../../../../types';

interface IRightSidebar {
  handleSend: (value: string) => void;
  handleSaveImage: () => void;
  setOpenEditModal: (value: boolean) => void;
  isLoading: boolean;
  setEditAsset: (value: boolean) => void;
  selectedValue: string;
  setSelectedValue: (value: string) => void;
  options: IOptions[];
  isChatHasOutputImg: boolean;
}

function MobileSidebar({
  handleSend,
  handleSaveImage,
  setOpenEditModal,
  isLoading,
  setEditAsset,
  selectedValue,
  setSelectedValue,
  options,
  isChatHasOutputImg,
}: IRightSidebar) {
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        padding: 0,
        margin: 0,
      }}
    >
      <Grid item xs={12} display="flex" alignItems="center">
        <Grid item xs={isChatHasOutputImg ? 10 : 12}>
          <Asset setEditAsset={setEditAsset} />
        </Grid>
        {isChatHasOutputImg && (
          <Grid item xs={2}>
            <Download handleSaveImage={handleSaveImage} />
          </Grid>
        )}
      </Grid>
      {isChatHasOutputImg && (
        <>
          <Grid item xs={12} display="flex" alignItems="center">
            <Grid item xs={12}>
              <Ratio
                isLoading={isLoading}
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                options={options}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} display="flex" alignItems="center">
            <Grid item xs={12}>
              <Variations
                setOpenEditModal={setOpenEditModal}
                handleSend={handleSend}
                isLoading={isLoading}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default MobileSidebar;
