import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { OverviewStart } from '../../../assets/icons/OverviewStart';
import { useAppSelector } from '../../../hooks/useStore';
import { getColorAndLabel } from '../../Dashboard/Helpers';
import useCountUp from '../../../hooks/useCountUp';

const OnboardingAverageScore: React.FC = () => {
  const averageScore = useAppSelector(state => state.onboarding.averageScore) ?? 0;
  const animatedScore = useCountUp(averageScore);

  const segments = 10;
  const segmentAngle = 360 / (segments + 2);
  const activeSegments = averageScore !== null ? Math.round((animatedScore / 10) * segments) : 0;
  const { color } = getColorAndLabel(animatedScore);
  const segmentPath =
    'M2.78976 35C0.876591 33.8954 0.213747 31.4436 1.40042 29.5803C8.62169 18.2412 18.2412 8.62169 29.5803 1.40042C31.4436 0.213743 33.8954 0.876589 35 2.78976L36.52 5.42248C37.6246 7.33565 36.963 9.77364 35.1067 10.9713C25.4381 17.2096 17.2096 25.4381 10.9713 35.1067C9.77364 36.9629 7.33565 37.6246 5.42248 36.52L2.78976 35Z';
  const arrowPath = 'M75 15 L70 27 L80 27 Z';

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap="18px">
      <Box position="relative" width={150} height={150}>
        {Array.from({ length: segments }).map((_, index) => {
          const isActive = index < activeSegments;
          const rotation = index * segmentAngle - 90;
          return (
            <svg
              key={index}
              width="150"
              height="150"
              viewBox="0 0 150 150"
              style={{
                position: 'absolute',
                transform: `rotate(${rotation}deg)`,
                transformOrigin: '75px 75px',
                zIndex: 1,
                transition: 'transform 0.3s ease-in-out',
              }}
            >
              <mask id={`mask-${index}`} fill="white">
                <path d={segmentPath} />
              </mask>
              <path
                d={segmentPath}
                fill={isActive ? color : '#F3F3F7'}
                stroke="white"
                strokeWidth="2"
                mask={`url(#mask-${index})`}
                style={{ transition: 'fill 0.3s ease-in-out' }}
              />
            </svg>
          );
        })}
        <svg
          width="150"
          height="150"
          viewBox="0 0 150 150"
          style={{
            position: 'absolute',
            transform: `rotate(${activeSegments * segmentAngle - 150}deg)`,
            transformOrigin: '75px 75px',
            zIndex: 2,
            transition: 'transform 0.3s ease-in-out',
          }}
        >
          <path d={arrowPath} fill={'#000'} />
        </svg>
        <Box
          position="absolute"
          top="50%"
          left="50%"
          width="94px"
          height="94px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor="white"
          borderRadius="50%"
          boxShadow={3}
          style={{ transform: 'translate(-50%, -50%)' }}
        >
          {animatedScore ? (
            <Typography
              fontSize={40}
              fontWeight={400}
              lineHeight={'56px'}
              letterSpacing={'-0.96px'}
            >
              {animatedScore}
            </Typography>
          ) : (
            <OverviewStart />
          )}
        </Box>
      </Box>
      <Typography
        sx={{
          color: 'rgba(66, 72, 93, 0.60)',
          textAlign: 'center',
          fontFamily: 'Poppins',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '20px',
          letterSpacing: '-0.07px',
        }}
      >
        Add new brand to build visualization
      </Typography>
    </Box>
  );
};

export default OnboardingAverageScore;
