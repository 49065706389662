import React, { useState } from 'react';
import { type SxProps } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import UpgradePlanModal from '../Dashboard/Landing/UpgradePlanModal';

interface Props {
  title: string;
  sx?: SxProps;
}

function FreeTrialCard({ title, sx }: Props) {
  const [upgradeModal, setUpgradeModal] = useState(false);
  return (
    <Stack gap={'24px'} p={'48px'} bgcolor={'#fff'} borderRadius={'16px'} mb={'48px'}>
      <Typography
        fontSize={'36px'}
        fontWeight={400}
        lineHeight={'44px'}
        letterSpacing={'-0.68px'}
        width={'50%'}
        sx={sx}
      >
        {title}
      </Typography>
      <Stack gap={'16px'} alignItems={'start'}>
        <Typography
          fontSize={'16px'}
          lineHeight={'24px'}
          letterSpacing={'-0.16px'}
          fontWeight={400}
        >
          You&apos;ve reached the limit of the Free Trial plan. Our Enterprise Plan offers
          comprehensive <br /> functionalities to monitor and resolve image quality issues across
          your vendors&apos; platforms.
        </Typography>
        <Button
          onClick={() => {
            setUpgradeModal(true);
          }}
          variant="contained"
          sx={{
            borderRadius: '12px',
            bgcolor: 'text.primary',
            borderColor: 'text.primary',
            fontSize: '14px',
            fontWeight: 500,
            letterSpacing: '-0.07px',
            lineHeight: '20px',
            ':hover': {
              bgcolor: '#575C70',
              color: '#FFFFFF',
            },
          }}
        >
          Pricing
        </Button>
      </Stack>

      <UpgradePlanModal
        open={upgradeModal}
        handleClose={() => {
          setUpgradeModal(false);
        }}
      />
    </Stack>
  );
}

export default FreeTrialCard;
