import React from 'react';

export const OneToTwo: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="16" height="16" fill="transparent" />
    <path
      d="M7.5 3H6L6 4.5M8.5 13L10 13V11.5M11 1L5 1C4.44772 1 4 1.44772 4 2L4 14C4 14.5523 4.44772 15 5 15H11C11.5523 15 12 14.5523 12 14L12 2C12 1.44772 11.5523 1 11 1Z"
      stroke="black"
    />
  </svg>
);
