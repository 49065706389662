import axios from 'axios';
import { getToken } from '../../common/utils';

const authClient = axios.create({
  baseURL: 'https://focalstudio-science.com/',
});

authClient.interceptors.request.use(config => {
  const token = getToken();

  if (token) {
    config.headers.Authorization = `Token ${token}`;
  }

  return config;
});

export const postUploadMaskImage = async (data: any) => {
  return await authClient
    .post(
      'image/retouch',
      {
        image_s3_uri: data.image,
        mask_s3_uri: data.mask,
        prompt: data.prompt,
      },
      {
        headers: {
          'api-key': 'focal-test-key-temp-2023',
          'Content-Type': 'application/json',
        },
      }
    )
    .then(response => {
      return response;
    });
};
