import React, { useEffect, useState } from 'react';
import {
  Grid,
  Stack,
  Box,
  Typography,
  Card,
  Switch,
  Button,
  CircularProgress,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Header } from '../Dashboard/Header';
import { theme } from '../../theme';
import { getColorAndLabel } from '../Dashboard/Helpers';
import { roundGrade } from '../../common/helpers';
import VendorSlider from './VendorSlider';
import { ProductsSwitchLeft, ProductsSwitchRight } from '../../assets/icons';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ProductCardReplace from './ProductCardReplace';
import VendorCardReplace from './VendorCardReplace';
import type { IProductImage } from '../../core/api/types/Product';
import {
  useGetProductQuery,
  useGetReplaceImageMutation,
  useGetReplaceableImagesQuery,
} from '../../core/api/product';
import { type IVendor } from '../../pages/Dashboard/Products/Types';
import { useGetDiagnosticsQuery } from '../../core/api/diagnostics';
import { toast } from 'react-toastify';
import useOnboardingSearchParam from '../../hooks/useOnboardingSearchParam';
import { ErrorWarningLine } from '../../assets/icons/ErrorWarningLine';

interface IProps {
  product: IProductImage;
  setProductOverview: React.Dispatch<React.SetStateAction<IProductImage | null>>;
}

export const ProductView = ({ product, setProductOverview }: IProps) => {
  const [currentVendor, setCurrentVendor] = useState<IVendor | null>(null);
  const [idsToReplace, setIdsToReplace] = useState<number[]>([]);
  const [disabledIdsToReplace, setDisabledIdsToReplace] = useState<number[]>([]);
  const [isMargin, setIsMargin] = useState(false);
  const { brandId } = useOnboardingSearchParam();

  const { data: productData, isLoading, isFetching } = useGetProductQuery({ id: product?.id });
  const { data: replaceableImages } = useGetReplaceableImagesQuery(
    {
      id: product?.id,
      // limit: 10,
      // offset: 0,
      reseller: currentVendor?.name,
      has_replacement: true,
    },
    {
      skip: !currentVendor?.name,
    }
  );
  const { data: diagnostics } = useGetDiagnosticsQuery(Number(brandId));

  const [replaceImage] = useGetReplaceImageMutation();

  const handleSwitch = (id: number, value: boolean) => {
    setIdsToReplace(prev => {
      if (value) {
        return [...prev, id];
      } else {
        return prev.filter(replaceId => replaceId !== id);
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (productData?.vendor) {
      setCurrentVendor(productData.vendor[0]);
    }
  }, [productData]);

  if (isLoading || isFetching) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  }

  const handleConfirm = async () => {
    try {
      if (currentVendor?.id) {
        const res = await replaceImage({
          product: product.id,
          vendor: currentVendor?.id,
          images: idsToReplace,
        });

        if (res) {
          toast.success('Images replaced successfully');
          setDisabledIdsToReplace(prev => [...prev, ...idsToReplace]);
        }
      }
    } catch (error) {
      toast.warn('Images replace failed');
      console.log('Image replace error:', error);
    } finally {
      const currentVendorIndex = productData?.vendor?.findIndex(
        vendor => vendor.id === currentVendor?.id
      );
      if (
        productData?.vendor &&
        currentVendorIndex &&
        currentVendorIndex < productData.vendor.length - 1
      ) {
        setCurrentVendor(productData.vendor[currentVendorIndex + 1]);
      }

      setIdsToReplace([]);
      window.scrollTo(0, 0);
    }
  };

  return (
    <Stack gap="8px" bgcolor={theme.palette.neutralSubtleMore} minHeight="100vh">
      <Header />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap="6px"
        p="48px 0 0 24px"
      >
        <Stack gap="4px">
          <Box
            display="flex"
            alignItems="center"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              setProductOverview(null);
            }}
          >
            <ArrowBackIcon sx={{ color: '#42485D99' }} />
            <Typography
              fontSize={24}
              fontWeight={400}
              fontFamily="Poppins"
              letterSpacing="-0.96px"
              color="#42485D99"
              pl="10px"
            >
              Products
            </Typography>
          </Box>

          <Typography fontSize={30} fontWeight={400} fontFamily="Poppins" letterSpacing="-0.216px">
            {productData?.name}
          </Typography>
          <Typography
            fontSize={18}
            fontWeight={400}
            fontFamily="Poppins"
            letterSpacing="-0.216px"
            color="#42485D99"
          >
            Overview results of image audit over all vendors sites
          </Typography>
        </Stack>
      </Stack>
      <Grid container px={'24px'} py="48px" spacing={2}>
        <Grid item xs={6}>
          <Card
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              bgcolor: 'white',
              width: '100%',
              borderRadius: '20px',
            }}
            elevation={0}
          >
            <Box
              display="flex"
              p="20px"
              justifyContent="space-between"
              alignItems="center"
              flex={1}
            >
              <Stack>
                <Typography fontSize="20px" fontWeight="400" color={theme.palette.text.primary}>
                  Average score
                </Typography>
                <Typography fontSize="14px" fontWeight="400" color={theme.palette.text.secondary}>
                  Your images across different vendors websites
                </Typography>
              </Stack>
              <Box
                display="flex"
                sx={{
                  bgcolor: getColorAndLabel(roundGrade(productData?.score ?? 1)).color,
                  p: '5px',
                  width: '40px',
                  height: '40px',
                  borderRadius: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography fontSize="24px" color={'white'}>
                  {roundGrade(productData?.score ?? 1)}
                </Typography>
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card
            sx={{
              display: 'flex',
              bgcolor: 'white',
              width: '100%',
              borderRadius: '20px',
            }}
            elevation={0}
          >
            <Box
              display="flex"
              p="20px"
              justifyContent="space-between"
              alignItems="center"
              flex={1}
            >
              <Stack display="flex">
                <Typography fontSize="20px" fontWeight="400" color={theme.palette.text.primary}>
                  Unrealized revenue
                </Typography>
                <Typography fontSize="14px" fontWeight="400" color={theme.palette.text.secondary}>
                  Your images across different
                </Typography>
              </Stack>
              <Stack display="flex" flexDirection="row">
                <Typography fontSize="20px" fontWeight="400" color={theme.palette.text.primary}>
                  {productData?.unrealized_revenue_monthly_usd &&
                  productData?.unrealized_revenue_monthly_usd > 1000
                    ? `${(productData?.unrealized_revenue_monthly_usd / 1000).toFixed(1)}k`
                    : `${productData?.unrealized_revenue_monthly_usd}`}
                </Typography>
                &nbsp;
                <Typography fontSize="20px" fontWeight="400" color={theme.palette.text.secondary}>
                  per year
                </Typography>
              </Stack>
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Stack display="flex" justifyContent="center" position="relative" direction="column">
        <Typography fontWeight="400" fontSize="24px" px="24px" color={theme.palette.text.primary}>
          Vendors with this product
        </Typography>
        <VendorSlider
          tempVendors={productData?.vendor ?? []}
          setCurrentVendor={setCurrentVendor}
          currentVendor={currentVendor}
          setIsMargin={setIsMargin}
        />
        <Stack
          display="flex"
          flexDirection="row"
          alignSelf="flex-end"
          alignItems="center"
          gap="5px"
          px="24px"
        >
          <ProductsSwitchLeft />
          <Typography fontWeight="400" fontSize="12px" color={theme.palette.text.secondary}>
            Switch vendor
          </Typography>
          <ProductsSwitchRight />
        </Stack>
      </Stack>

      {replaceableImages?.length === 0 && (
        <Stack
          sx={{
            display: 'flex',
            padding: '16px 24px',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '8px',
            alignSelf: 'stretch',
            borderRadius: '16px',
            background: 'rgba(238, 57, 57, 0.10)',
            mx: '20px',
          }}
        >
          <ErrorWarningLine />
          <Typography
            sx={{
              color: '#EE3939',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '28px',
              letterSpacing: '-0.24px',
            }}
          >
            No Replacement Images Found for Product
          </Typography>
        </Stack>
      )}

      {replaceableImages?.length > 0 && (
        <Stack direction="column" flex={1} gap="8px" px="24px" pt={isMargin ? '150px' : '20px'}>
          <Grid container justifyContent={'center'} spacing={1}>
            <Grid item xs={6}>
              <Typography fontWeight="400" fontSize="18px" color={theme.palette.text.primary}>
                Golden Set
              </Typography>
              <Typography fontWeight="400" fontSize="14px" color={theme.palette.text.secondary}>
                Best images for this product, highest conversion rate
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight="400" fontSize="18px" color={theme.palette.text.primary}>
                Vendor Images
              </Typography>
              <Typography fontWeight="400" fontSize="14px" color={theme.palette.text.secondary}>
                Best images for this product, highest conversion rate
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" spacing={1}>
            {replaceableImages.map((item: any) => (
              <React.Fragment key={item.id}>
                <Grid container item xs={12} spacing={1} alignItems="center" position="relative">
                  <Grid item xs={6}>
                    <ProductCardReplace product={product} />
                  </Grid>
                  <Grid item xs={6}>
                    <VendorCardReplace
                      product={item}
                      currentVendor={currentVendor}
                      tempVendors={productData?.vendor ?? []}
                      diagnostics={diagnostics}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      ml: '4px',
                      cursor: disabledIdsToReplace.includes(item.id) ? 'not-allowed' : '',
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        border: `8px solid ${theme.palette.neutralSubtleMore}`,
                        width: '100px',
                        height: '100px',
                        borderRadius: '100%',
                        backgroundColor: 'white',
                        zIndex: 1,
                      }}
                    >
                      <ArrowForwardIcon
                        sx={{
                          color: idsToReplace.includes(item.id)
                            ? theme.palette.text.primary
                            : theme.palette.text.secondary,
                          opacity: disabledIdsToReplace.includes(item.id) ? 0.5 : 1,
                        }}
                      />
                      <Typography
                        fontWeight="500"
                        fontSize="12px"
                        color={
                          idsToReplace.includes(item.id)
                            ? theme.palette.text.primary
                            : theme.palette.text.secondary
                        }
                        sx={{
                          opacity: disabledIdsToReplace.includes(item.id) ? 0.5 : 1,
                        }}
                      >
                        Replace
                      </Typography>
                      <Switch
                        onChange={e => {
                          handleSwitch(item.id, e.target.checked);
                        }}
                        checked={idsToReplace.includes(item.id)}
                        disabled={disabledIdsToReplace.includes(item.id)}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </React.Fragment>
            ))}
            <Button
              fullWidth
              variant="contained"
              sx={{
                border: 'none',
                my: '30px',
                mb: '100px',
                bgcolor: theme.palette.text.primary,
                '&:hover': {
                  bgcolor: theme.palette.text.primary,
                },
              }}
              onClick={handleConfirm}
              disabled={idsToReplace.length === 0}
            >
              <Typography color="white">Confirm & next vendor</Typography>
            </Button>
          </Grid>
        </Stack>
      )}
    </Stack>
  );
};
