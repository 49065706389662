export function roundGrade(grade: string | number) {
  return Math.round(Number(grade));
}

export function formatValue(value: number): string {
  if (value >= 1000000) {
    return (value / 1000000).toFixed(1) + 'M';
  } else if (value >= 1000) {
    return (value / 1000).toFixed(1) + 'K';
  } else {
    return value.toFixed(0);
  }
}

export function getDominantColor(img: HTMLImageElement) {
  let canvas = document.getElementById('canvas') as HTMLCanvasElement;

  if (!canvas) {
    canvas = document.createElement('canvas');
    canvas.id = 'canvas';
    canvas.style.display = 'none';
    document.body.appendChild(canvas);
  }

  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error('Canvas context not supported');
  }
  canvas.width = img.width;
  canvas.height = img.height;
  ctx.drawImage(img, 0, 0, img.width, img.height);
  const imageData = ctx.getImageData(0, 0, img.width, img.height);
  const data = imageData.data;

  const colorMap = new Map<string, number>();
  let maxCount = 0;
  let dominantColor = { r: 0, g: 0, b: 0 };

  for (let i = 0; i < data.length; i += 4) {
    const r = data[i];
    const g = data[i + 1];
    const b = data[i + 2];
    const a = data[i + 3];

    if (a === 0) {
      continue;
    }

    const key = `${r},${g},${b}`;

    if (!colorMap.has(key)) {
      colorMap.set(key, 0);
    }

    const count = (colorMap.get(key) ?? 0) + 1;
    colorMap.set(key, count);

    if (count > maxCount) {
      maxCount = count;
      dominantColor = { r, g, b };
    }
  }

  return [dominantColor.r, dominantColor.g, dominantColor.b];
}

export function rgbToHex(r: number, g: number, b: number): string {
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
}

export function ensureLightColor([r, g, b]: [number, number, number]): [number, number, number] {
  const lightenFactor = 35;

  return [
    Math.min(255, r + lightenFactor),
    Math.min(255, g + lightenFactor),
    Math.min(255, b + lightenFactor),
  ];
}

export function convertS3ToHttps(s3Uri: string) {
  const path = s3Uri.substring(5);
  const [bucketName, ...rest] = path.split('/');

  return `https://${bucketName}.s3.amazonaws.com/${rest.join('/')}`;
}

export const getRandomColor = (() => {
  const generatedColors = new Set<string>();

  return () => {
    const letters = '0123456789ABCDEF';
    let color = '#';

    do {
      color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * letters.length)];
      }
    } while (generatedColors.has(color));

    generatedColors.add(color);
    return color;
  };
})();
