import React from 'react';
import { Box, Typography } from '@mui/material';
import { Ratio } from '../../Ratio';
import { DownloadIcon, EditImageIcon, VariationIcon } from '../../../../../../../assets/icons';
import { type IOptions } from '../../../../../types';

interface IVariations {
  setOpenEditModal: (value: boolean) => void;
  handleSend: (value: string) => void;
  handleSaveImage: () => void;
  isLoading: boolean;
  selectedValue: string;
  setSelectedValue: (value: string) => void;
  options: IOptions[];
}

export const Variations = ({
  setOpenEditModal,
  handleSend,
  handleSaveImage,
  isLoading,
  selectedValue,
  setSelectedValue,
  options,
}: IVariations) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-around', height: '65px', width: '100%' }}>
      <Ratio
        isLoading={isLoading}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        options={options}
      />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          '&: hover': {
            cursor: 'pointer',
          },
          opacity: isLoading ? 0.5 : 1,
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={() => {
            !isLoading && setOpenEditModal(true);
          }}
          bgcolor="#F6F6F6"
          height="40px"
          paddingX="10px"
          borderRadius="10px"
          sx={{
            '&:hover': {
              bgcolor: '#E4E4E4',
            },
          }}
        >
          <EditImageIcon />
          <Typography fontFamily="Figtree" color="#61646B" fontSize={14} ml="10px">
            Edit image
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          '&: hover': {
            cursor: 'pointer',
          },
          opacity: isLoading ? 0.5 : 1,
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={() => {
            !isLoading && handleSend('Make variations');
          }}
          bgcolor="#F6F6F6"
          height="40px"
          paddingX="10px"
          borderRadius="10px"
          sx={{
            '&:hover': {
              bgcolor: '#E4E4E4',
            },
          }}
        >
          <VariationIcon />
          <Typography fontFamily="Figtree" color="#61646B" fontSize={14} ml="10px">
            Create variations
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&: hover': {
            cursor: 'pointer',
          },
          opacity: isLoading ? 0.5 : 1,
        }}
      >
        <Box
          onClick={() => {
            handleSaveImage();
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor="#F6F6F6"
          height="40px"
          width="50px"
          borderRadius="10px"
          sx={{
            '&:hover': {
              bgcolor: '#E4E4E4',
            },
          }}
        >
          <DownloadIcon />
        </Box>
      </Box>
    </Box>
  );
};
