import React from 'react';
import { styled, keyframes } from '@mui/material/styles';
import Box from '@mui/material/Box';

const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;

const Dot = styled(Box)(({ delay }: { delay: string }) => ({
  display: 'inline-block',
  width: '4px',
  height: '4px',
  margin: '0 2px',
  backgroundColor: 'rgba(44, 51, 71, 0.40)',
  borderRadius: '50%',
  animation: `${bounce} 1.4s infinite ease-in-out both`,
  animationDelay: delay,
}));

function LoadingDots() {
  return (
    <Box
      component={'p'}
      sx={{
        display: 'inline-block',
        margin: 0,
      }}
    >
      <Dot delay="0s" component={'p'} />
      <Dot delay="0.2s" component={'p'} />
      <Dot delay="0.4s" component={'p'} />
    </Box>
  );
}

export default LoadingDots;
