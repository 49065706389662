import React from 'react';

export const PlusIcon: React.FC = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="36" height="36" rx="18" fill="white" />
    <rect width="36" height="36" rx="18" fill="white" />
    <path
      d="M19.9688 16.1406V16.9844C19.9688 17.2656 19.8281 17.4062 19.5469 17.4062H17.1562V19.7969C17.1562 20.0781 17.0156 20.2188 16.7344 20.2188H15.8906C15.6094 20.2188 15.4688 20.0781 15.4688 19.7969V17.4062H13.0781C12.7969 17.4062 12.6562 17.2656 12.6562 16.9844V16.1406C12.6562 15.8594 12.7969 15.7188 13.0781 15.7188H15.4688V13.3281C15.4688 13.0469 15.6094 12.9062 15.8906 12.9062H16.7344C17.0156 12.9062 17.1562 13.0469 17.1562 13.3281V15.7188H19.5469C19.8281 15.7188 19.9688 15.8594 19.9688 16.1406ZM26.8594 26.3359L26.0859 27.1094C25.875 27.3203 25.6758 27.3203 25.4883 27.1094L21.2344 22.8555C21.1406 22.7852 21.0938 22.6914 21.0938 22.5742V22.082C19.7109 23.2773 18.1172 23.875 16.3125 23.875C14.2969 23.875 12.5742 23.1602 11.1445 21.7305C9.71484 20.3008 9 18.5781 9 16.5625C9 14.5469 9.71484 12.8242 11.1445 11.3945C12.5742 9.96484 14.2969 9.25 16.3125 9.25C18.3281 9.25 20.0508 9.96484 21.4805 11.3945C22.9102 12.8242 23.625 14.5469 23.625 16.5625C23.625 18.3672 23.0273 19.9609 21.832 21.3438H22.3242C22.418 21.3438 22.5117 21.3906 22.6055 21.4844L26.8594 25.7383C27.0703 25.9258 27.0703 26.125 26.8594 26.3359ZM20.2852 20.5352C21.3867 19.4336 21.9375 18.1094 21.9375 16.5625C21.9375 15.0156 21.3867 13.6914 20.2852 12.5898C19.1836 11.4883 17.8594 10.9375 16.3125 10.9375C14.7656 10.9375 13.4414 11.4883 12.3398 12.5898C11.2383 13.6914 10.6875 15.0156 10.6875 16.5625C10.6875 18.1094 11.2383 19.4336 12.3398 20.5352C13.4414 21.6367 14.7656 22.1875 16.3125 22.1875C17.8594 22.1875 19.1836 21.6367 20.2852 20.5352Z"
      fill="white"
    />
    <path
      d="M19.9688 16.1406V16.9844C19.9688 17.2656 19.8281 17.4062 19.5469 17.4062H17.1562V19.7969C17.1562 20.0781 17.0156 20.2188 16.7344 20.2188H15.8906C15.6094 20.2188 15.4688 20.0781 15.4688 19.7969V17.4062H13.0781C12.7969 17.4062 12.6562 17.2656 12.6562 16.9844V16.1406C12.6562 15.8594 12.7969 15.7188 13.0781 15.7188H15.4688V13.3281C15.4688 13.0469 15.6094 12.9062 15.8906 12.9062H16.7344C17.0156 12.9062 17.1562 13.0469 17.1562 13.3281V15.7188H19.5469C19.8281 15.7188 19.9688 15.8594 19.9688 16.1406ZM26.8594 26.3359L26.0859 27.1094C25.875 27.3203 25.6758 27.3203 25.4883 27.1094L21.2344 22.8555C21.1406 22.7852 21.0938 22.6914 21.0938 22.5742V22.082C19.7109 23.2773 18.1172 23.875 16.3125 23.875C14.2969 23.875 12.5742 23.1602 11.1445 21.7305C9.71484 20.3008 9 18.5781 9 16.5625C9 14.5469 9.71484 12.8242 11.1445 11.3945C12.5742 9.96484 14.2969 9.25 16.3125 9.25C18.3281 9.25 20.0508 9.96484 21.4805 11.3945C22.9102 12.8242 23.625 14.5469 23.625 16.5625C23.625 18.3672 23.0273 19.9609 21.832 21.3438H22.3242C22.418 21.3438 22.5117 21.3906 22.6055 21.4844L26.8594 25.7383C27.0703 25.9258 27.0703 26.125 26.8594 26.3359ZM20.2852 20.5352C21.3867 19.4336 21.9375 18.1094 21.9375 16.5625C21.9375 15.0156 21.3867 13.6914 20.2852 12.5898C19.1836 11.4883 17.8594 10.9375 16.3125 10.9375C14.7656 10.9375 13.4414 11.4883 12.3398 12.5898C11.2383 13.6914 10.6875 15.0156 10.6875 16.5625C10.6875 18.1094 11.2383 19.4336 12.3398 20.5352C13.4414 21.6367 14.7656 22.1875 16.3125 22.1875C17.8594 22.1875 19.1836 21.6367 20.2852 20.5352Z"
      fill="black"
    />
  </svg>
);
