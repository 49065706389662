import React from 'react';

export const MicrosoftIcon: React.FC = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="13.5" y="13" width="9" height="9" fill="#FEBA08" />
    <rect x="2.5" y="12.9097" width="9" height="9" fill="#05A6F0" />
    <rect x="13.5" y="2" width="9" height="9" fill="#80BC06" />
    <rect x="2.5" y="2" width="9" height="9" fill="#F25325" />
  </svg>
);
