import type { IGetReplaceableImagesPayload, ProductFilters } from './types/Product';

export const buildQueryString = (filters: ProductFilters, isImageParam?: boolean): string => {
  const params = new URLSearchParams();

  if (filters.offset !== undefined) params.append('offset', filters.offset.toString());
  if (filters.limit !== undefined) params.append('limit', filters.limit.toString());
  if (filters.title) params.append(isImageParam ? 'search' : 'name', filters.title);
  if (filters.reseller) params.append(isImageParam ? 'reseller' : 'vendor_name', filters.reseller);
  if (filters.diagnostics?.length) params.append('diagnostics', filters.diagnostics.join(','));
  if (filters.score_gte !== undefined) params.append('score__gte', filters.score_gte.toString());
  if (filters.score_lte !== undefined) params.append('score__lte', filters.score_lte.toString());
  if (filters.ordering) params.append('ordering', filters.ordering);
  if (filters.brand_id !== undefined) params.append('brand_id', filters.brand_id.toString());

  return params.toString();
};

export const buildQueryStringForProduct = (payload: IGetReplaceableImagesPayload): string => {
  const params = new URLSearchParams();

  if (payload.id !== undefined) params.append('product_id', payload.id.toString());
  if (payload.limit !== undefined) params.append('limit', payload.limit.toString());
  if (payload.offset !== undefined) params.append('offset', payload.offset.toString());
  if (payload.reseller !== undefined) params.append('reseller', payload.reseller);
  if (payload.has_replacement !== undefined)
    params.append('has_replacement', payload.has_replacement.toString());

  return params.toString();
};
