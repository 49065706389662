import React from 'react';

export const BrandArrow = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_702_1023" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_702_1023)">
      <path
        d="M6.2942 17.6442L5.25 16.6L15.0904 6.74995H6.14422V5.25H17.6442V16.75H16.1442V7.80378L6.2942 17.6442Z"
        fill="#754DBA"
      />
    </g>
  </svg>
);
