import React from 'react';

export const PenIcon: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.79183 14.8732C2.82748 14.5523 2.84531 14.3918 2.89386 14.2419C2.93693 14.1088 2.99779 13.9822 3.07478 13.8655C3.16155 13.7339 3.27571 13.6197 3.50401 13.3914L13.7529 3.14289C14.6101 2.2857 15.9999 2.2857 16.8571 3.14289C17.7143 4.00007 17.7143 5.38985 16.8571 6.24703L6.60824 16.4956C6.37994 16.7239 6.26579 16.838 6.13419 16.9248C6.01744 17.0018 5.89082 17.0626 5.75777 17.1057C5.6078 17.1542 5.44736 17.1721 5.12647 17.2077L2.5 17.4995L2.79183 14.8732Z"
      stroke="#61646B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
