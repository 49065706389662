import React from 'react';

interface IProps {
  active?: boolean;
}

export const AssetsUndoIcon = ({ active }: IProps) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.5 20C21.85 20 19.45 21 17.6 22.6L14 19V28H23L19.38 24.38C20.77 23.22 22.54 22.5 24.5 22.5C28.04 22.5 31.05 24.81 32.1 28L34.47 27.22C33.08 23.03 29.15 20 24.5 20Z"
      fill={active ? '#000' : '#E1E2EC'}
      fillOpacity={active ? '1' : '0.6'}
    />
  </svg>
);
