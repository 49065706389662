import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const TopVendorsPlaceholder = () => {
  return (
    <Stack bgcolor={'#fff'} borderRadius={'16px'} width={'30%'} minWidth={'300px'}>
      <Stack p={'16px 24px'} gap={'4px'}>
        <Skeleton variant="text" width={120} height={40} />
        <Skeleton variant="text" width={160} height={40} />
      </Stack>

      <Stack p={'24px 24px'} gap={'24px'}>
        <Box
          display="flex"
          width="100%"
          height="32px"
          borderRadius="4px"
          overflow="hidden"
          bgcolor="#F3F3F7"
        >
          <Skeleton variant="rectangular" width="100%" height={40} />
        </Box>
        <Stack gap={'19px'} mt={'22px'}>
          {Array.from({ length: 6 }).map((_, index) => (
            <Stack
              direction={'row'}
              alignItems={'center'}
              key={index}
              justifyContent={'space-between'}
            >
              <Stack
                direction={'row'}
                alignItems={'center'}
                gap={'8px'}
                sx={{
                  color: '#171D30',
                  fontSize: 16,
                  fontWeight: 400,
                  lineHeight: '28px',
                  img: { width: 24, height: 24, borderRadius: '50%' },
                }}
              >
                <Skeleton variant="circular" width={36} height={36} />
                <Skeleton variant="text" width={100} height={40} />
              </Stack>
              <Stack direction="row" alignItems="center" gap="8px">
                <Skeleton variant="text" width={60} height={40} />
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TopVendorsPlaceholder;
