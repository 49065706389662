import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const GradientCircularProgress = styled(CircularProgress)(({ theme }) => ({
  '& .MuiCircularProgress-circle': {
    stroke: 'url(#gradient)',
  },
}));

const CustomCircularProgress = ({ size = 124, thickness = 4, showText = false }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection={'column'}
      gap={showText ? '24px' : 0}
    >
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="rgba(233, 233, 240, 0)" />
            <stop offset="100%" stopColor="rgba(66, 72, 93, 0.6)" />
          </linearGradient>
        </defs>
      </svg>
      <GradientCircularProgress size={size} thickness={thickness} />

      {showText && (
        <Typography
          sx={{
            color: 'rgba(66, 72, 93, 0.60)',
            textAlign: 'center',
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '-0.07px',
          }}
        >
          Add new brand to build visualization
        </Typography>
      )}
    </Box>
  );
};

export default CustomCircularProgress;
