import React, {
  createContext,
  type FC,
  type ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

import { toast } from 'react-toastify';
import { ImagsGeneratedContext } from './useImageContext';
import { getCorrectImage, getRemoveBackground } from '../repositories/prompte.service';
import { useGetCurrentUser, useGetUserAssets } from '../hooks/api/user';

interface AssetContentType {
  assetContentVal: string;
  imageWithoutBackground: string;
  correctedImage: string;
  handleAssetVal: (value: string) => void;
  setImageWithoutBackground: (value: string) => void;
  chatID: string;
  handleChatID: (value: string) => void;
  setLoadedFile: (value: any) => void;
  loadedFile: {
    url: string;
    name: string;
  };
  getUrlImageWithoutBackground: () => void;
  getCorrectedUrlImageWithoutBackground: (val1: string, val2: string) => void;
  setCorrectedImage: (value: string) => void;
  imageName: string;
  setImageName: (value: string) => void;
  isLoadingError: boolean;
  setIsLoadingError: (value: boolean) => void;
  setCroppedImage: (value: string) => void;
  croppedImage: string;
  assets: IAsset[];
  getAssets: () => void;
  isFileLoading: boolean;
  setIsFileLoading: (value: boolean) => void;
  isAssetLoading: boolean;
  setIsAssetLoading: (value: boolean) => void;
}

export interface IAsset {
  brand: number | null;
  id: number;
  name: string;
  description: string | null;
  diagnostics: string[];
  url: string;
  created_at: string;
  created_by: number;
  initUrl: string;
  original_url: string;
  popularity: number;
  score: number;
  source: number;
  source_link: string;
  user_score: number;
}

// Create a new context for file content value
export const AssetContentContext = createContext<AssetContentType>(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as AssetContentType
);

interface AssetProviderProps {
  children?: ReactNode;
}

// Component that provides the context value
export const AssetContentProvider: FC<AssetProviderProps> = ({ children }) => {
  const [loadedFile, setLoadedFile] = useState({ url: '', name: '' });
  const [assetContentVal, setAssetContentVal] = useState('');
  const [imageWithoutBackground, setImageWithoutBackground] = useState('');
  const [correctedImage, setCorrectedImage] = useState('');
  const [chatID, setChatID] = useState('');
  const [imageName, setImageName] = useState('');
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [croppedImage, setCroppedImage] = useState('');
  const [assets, setAssets] = useState<IAsset[]>([]);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [isAssetLoading, setIsAssetLoading] = useState(false);
  const [user] = useGetCurrentUser();

  const { handleEditCorrectImageRequestID, imageGenerated, setIsLoading, correctImageGenerated } =
    useContext(ImagsGeneratedContext);

  useEffect(() => {
    setImageWithoutBackground(imageGenerated.url);
  }, [imageGenerated]);

  useEffect(() => {
    setCorrectedImage(correctImageGenerated.url);
  }, [correctImageGenerated]);

  useEffect(() => {
    if (user) {
      getAssets();
    }
  }, [user]);

  const getAssets = async () => {
    const assets: IAsset[] = await useGetUserAssets();

    const filteredArray = assets.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    setAssets(filteredArray);
  };

  const handleAssetVal = (value: string) => {
    setAssetContentVal(value);
  };

  const handleChatID = (value: string) => {
    setChatID(value);
  };

  const getUrlImageWithoutBackground = async () => {
    setIsLoading(true);
    try {
      const imageS3 = await getRemoveBackground(loadedFile.url);
      setImageWithoutBackground(imageS3.transparent_image_original_shape_url);
      setCroppedImage(imageS3.transparent_image_cropped_url);
    } catch (error) {
      setIsLoadingError(true);
      toast.error('Error removing background', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getCorrectedUrlImageWithoutBackground = async (image: string, mask: string) => {
    setIsLoading(true);
    try {
      const imageS3 = await getCorrectImage(image, mask);
      handleEditCorrectImageRequestID(imageS3.request_id);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <AssetContentContext.Provider
      value={{
        assetContentVal,
        handleAssetVal,
        chatID,
        handleChatID,
        setLoadedFile,
        loadedFile,
        setImageWithoutBackground,
        getUrlImageWithoutBackground,
        imageWithoutBackground,
        getCorrectedUrlImageWithoutBackground,
        correctedImage,
        setCorrectedImage,
        imageName,
        setImageName,
        isLoadingError,
        setIsLoadingError,
        setCroppedImage,
        croppedImage,
        assets,
        getAssets,
        isFileLoading,
        setIsFileLoading,
        isAssetLoading,
        setIsAssetLoading,
      }}
    >
      {children}
    </AssetContentContext.Provider>
  );
};
