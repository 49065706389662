import React from 'react';

export const LoadedIcon2: React.FC = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.33841 13.0208L9.23841 12.9708C8.83424 12.7625 8.50508 12.4333 8.29674 12.0292L8.24674 11.9292C8.04674 11.5333 7.48008 11.5333 7.27591 11.9292L7.22591 12.0292C7.01758 12.4333 6.68841 12.7625 6.28424 12.9708L6.18424 13.0208C5.78841 13.2208 5.78841 13.7875 6.18424 13.9917L6.28424 14.0417C6.68841 14.25 7.01758 14.5792 7.22591 14.9833L7.27591 15.0833C7.47591 15.4792 8.04258 15.4792 8.24674 15.0833L8.29674 14.9833C8.50508 14.5792 8.83424 14.25 9.23841 14.0417L9.33841 13.9917C9.73424 13.7917 9.73424 13.225 9.33841 13.0208Z"
      fill="#3B1E8E"
    />
    <path
      d="M13.6259 8.80833L13.4926 8.74167C13.0759 8.52917 12.7426 8.19583 12.5259 7.775L12.4592 7.64167C12.2301 7.19167 11.7717 6.9125 11.2676 6.9125C10.7634 6.9125 10.3051 7.19167 10.0759 7.64167L10.0092 7.775C9.79674 8.19167 9.46341 8.525 9.04258 8.74167L8.90924 8.80833C8.45924 9.0375 8.18008 9.49583 8.18008 10C8.18008 10.5042 8.45924 10.9625 8.90924 11.1917L9.04258 11.2583C9.45924 11.4708 9.79258 11.8042 10.0092 12.225L10.0759 12.3583C10.3051 12.8083 10.7634 13.0875 11.2676 13.0875C11.7717 13.0875 12.2301 12.8083 12.4592 12.3583L12.5259 12.225C12.7384 11.8083 13.0717 11.475 13.4926 11.2583L13.6259 11.1917C14.0759 10.9625 14.3551 10.5042 14.3551 10C14.3551 9.49583 14.0759 9.0375 13.6259 8.80833ZM13.0592 10.0833L12.9259 10.15C12.2717 10.4833 11.7509 11.0042 11.4176 11.6583L11.3509 11.7917C11.3509 11.7917 11.3259 11.8417 11.2717 11.8417C11.2176 11.8417 11.1967 11.8042 11.1926 11.7917L11.1259 11.6583C10.7926 11.0042 10.2717 10.4833 9.61758 10.15L9.48424 10.0833C9.48424 10.0833 9.43424 10.0583 9.43424 10.0042C9.43424 9.95 9.47174 9.92917 9.48424 9.925L9.61758 9.85833C10.2717 9.525 10.7926 9.00417 11.1259 8.35L11.1926 8.21667C11.1926 8.21667 11.2176 8.16667 11.2717 8.16667C11.3259 8.16667 11.3467 8.20417 11.3509 8.21667L11.4176 8.35C11.7509 9.00417 12.2717 9.525 12.9259 9.85833L13.0592 9.925C13.0592 9.925 13.1092 9.95 13.1092 10.0042C13.1092 10.0583 13.0717 10.0792 13.0592 10.0833Z"
      fill="#3B1E8E"
    />
    <path
      d="M13.6134 4.375H11.7842C11.4176 2.94167 10.1134 1.875 8.56758 1.875H5.63424C3.80091 1.875 2.30924 3.36667 2.30924 5.2V7.01667C2.04258 7.59167 1.89258 8.22917 1.89258 8.90417V13.5958C1.89258 16.0917 3.92591 18.125 6.42174 18.125H13.6134C16.1092 18.125 18.1426 16.0917 18.1426 13.5958V8.90417C18.1426 6.40833 16.1092 4.375 13.6134 4.375ZM5.63424 3.125H8.56758C9.41758 3.125 10.1509 3.64167 10.4676 4.375H6.41758C5.33424 4.375 4.33841 4.75833 3.55508 5.39583V5.19583C3.55508 4.05 4.48424 3.12083 5.63008 3.12083L5.63424 3.125ZM16.8926 13.5958C16.8926 15.4042 15.4217 16.875 13.6134 16.875H6.42174C4.61341 16.875 3.14258 15.4042 3.14258 13.5958V8.90417C3.14258 7.09583 4.61341 5.625 6.42174 5.625H13.6134C15.4217 5.625 16.8926 7.09583 16.8926 8.90417V13.5958Z"
      fill="#3B1E8E"
    />
  </svg>
);
