import React from 'react';

export const UndoIcon: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.5 7.5013H13.75C15.8211 7.5013 17.5 9.18023 17.5 11.2513C17.5 13.3224 15.8211 15.0013 13.75 15.0013H10M2.5 7.5013L5.83333 4.16797M2.5 7.5013L5.83333 10.8346"
      stroke="#61646B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
