import React, { lazy, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import DashboardLayout from '../../components/Dashboard/DashboardLayout';
import ScrappedImages from '../../components/Dashboard/Overview/ScrappedImages';
import AverageScore from '../../components/Dashboard/Overview/AverageScore';
import ImagesByScore from '../../components/Dashboard/Overview/ImagesByScore';
import TopVendors from '../../components/Dashboard/Overview/TopVendors';
import { formatValue, roundGrade } from '../../common/helpers';
import ShareIcon from '../../assets/icons/Dashboard/ShareIcon';
import { useGetStatsQuery } from '../../core/api/product';
import { LazyLoad } from '../../components/Common/LazyLoad';
import ActionsBarPlaceholder from '../../components/Common/Placeholders/ActionsBarPlaceholder';
import TopIssuesPlaceholder from '../../components/Common/Placeholders/TopIssuesPlaceholder';
import AverageScorePlaceholder from '../../components/Dashboard/Placeholders/AverageScodePlaceholder';
import ScrappedImagesPlaceholder from '../../components/Dashboard/Placeholders/ScrappedImagesPlaceholder';
import ImagesByScorePlaceholder from '../../components/Dashboard/Placeholders/ImagesByScorePlaceholder';
import TopVendorsPlaceholder from '../../components/Dashboard/Placeholders/TopVendorsPlaceholder';
import useCopyToClipboard from '../../hooks/useCopyToClipboard';
import useOnboardingSearchParam from '../../hooks/useOnboardingSearchParam';
import { useAppDispatch } from '../../hooks/useStore';
import { setDashboardStats, setStatsLoading } from '../../core/store/dashboardSlice';
import Images from '../../components/Dashboard/Overview/Images';

const LazyTopIssues = lazy(async () => {
  const module = await import('../../components/Dashboard/Overview/TopIssues');
  return { default: module.default };
});

const LazyActions = lazy(async () => {
  const module = await import('../../components/Dashboard/Overview/Actions');
  return { default: module.default };
});

function DashboardPage() {
  const dispatch = useAppDispatch();

  const [copyToClipboard] = useCopyToClipboard();
  const [copyStatus, setCopyStatus] = useState(false);
  const { isOnboarding, brandId, loadingOnboarding } = useOnboardingSearchParam();
  const { data: stats, isLoading } = useGetStatsQuery(Number(brandId));

  useEffect(() => {
    if (stats && !isLoading) {
      dispatch(setDashboardStats(stats));
    }
    if (!isLoading) {
      dispatch(setStatsLoading(false));
    }
  }, [stats, isLoading]);

  const handleShare = () => {
    const currentUrl = window.location.href;
    copyToClipboard(currentUrl);
    setCopyStatus(true);

    setTimeout(() => {
      setCopyStatus(false);
    }, 2000);
  };

  return (
    <DashboardLayout
      title="Overview Dashboard"
      description="Overview results of image audit over all vendors sites"
      headerChildren={
        stats &&
        !isOnboarding && (
          <Stack gap={'16px'} justifyContent={'flex-end'} alignItems={'flex-end'}>
            <Button
              variant="contained"
              startIcon={<ShareIcon />}
              sx={{
                color: 'text.primary',
                width: '120px',
                border: '1px solid #fff',
                bgcolor: '#fff',
                borderRadius: '12px',
              }}
              onClick={handleShare}
            >
              {copyStatus ? 'Copied' : 'Share'}
            </Button>
            <Typography
              fontSize={14}
              fontWeight={400}
              lineHeight={'20px'}
              letterSpacing={'-0.07px'}
              color={'text.secondary'}
            >
              Images scrapped: {stats.total_images} of {stats.total_images}
            </Typography>
          </Stack>
        )
      }
    >
      <Stack gap={'16px'}>
        <Stack direction={'row'} gap={'16px'} alignItems={'self-start'}>
          {stats?.average_score ? (
            <AverageScore
              score={roundGrade(stats.average_score)}
              unrealizedRevenue={formatValue(stats.unrealized_revenue) ?? '-'}
            />
          ) : (
            <AverageScorePlaceholder isOnboarding={isOnboarding} loading={loadingOnboarding} />
          )}

          <Stack gap={'16px'} width={'100%'}>
            {stats?.image_quality ? (
              <ScrappedImages
                imageQuality={stats?.image_quality}
                totalImages={stats.total_images}
              />
            ) : (
              <ScrappedImagesPlaceholder />
            )}
            {stats?.score_distribution ? (
              <ImagesByScore
                scoreDistribution={stats.score_distribution}
                totalImages={stats.total_images}
              />
            ) : (
              <ImagesByScorePlaceholder />
            )}
          </Stack>
        </Stack>
        <Stack direction={'row'} gap={'16px'} alignItems={{ md: 'self-start' }}>
          <LazyLoad fallback={<TopIssuesPlaceholder />}>
            <LazyTopIssues />
          </LazyLoad>
          {stats?.resellers ? (
            <TopVendors key={brandId} resellers={stats.resellers} />
          ) : (
            <TopVendorsPlaceholder />
          )}
        </Stack>
        <LazyLoad fallback={<ActionsBarPlaceholder />}>
          <LazyActions />
        </LazyLoad>
        {/* <LazyLoad fallback={<ImagesSectionPlaceholder />}>
          <LazyImages />
        </LazyLoad> */}
        <Images />
      </Stack>
    </DashboardLayout>
  );
}

export default DashboardPage;
