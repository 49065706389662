import writer from './images/writer.webp';
import cardImg from './images/cardImgBig.webp';
import cardImg2 from './images/img2.webp';
import cardImg3 from './images/img3.webp';
import cardImg4 from './images/img4.webp';
import cardImg5 from './images/img5.webp';
import cardImg6 from './images/img6.webp';
import cardImg7 from './images/img1.webp';
import cardImg8 from './images/img8.webp';
import CoverLogin from './images/Col.webp';

const Images = {
  WRITER: writer,
  CARDIMG: cardImg,
  CARDIMG8: cardImg8,
  CARDIMG2: cardImg2,
  CARDIMG3: cardImg3,
  CARDIMG4: cardImg4,
  CARDIMG5: cardImg5,
  CARDIMG6: cardImg6,
  CARDIMG7: cardImg7,
  COVERLOGIN: CoverLogin,
};
export default Images;
