import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CheckboxCircleLine } from '../../../assets/icons/CheckboxCircleLine';
import { PopupButton } from 'react-calendly';
import styled from '@emotion/styled';
import { BackArrowIcon } from '../../../assets/icons/BackArrowIcon';

const spanStyle = `
  color: var(--Normal, #313234);
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.07px;
`;

const information = [
  `Monitor from <span style="${spanStyle}">1 up to 10,000</span> brands.`,
  `Track <span style="${spanStyle}">5 to 20 vendors</span> for thorough analysis.`,
  `Enjoy <span style="${spanStyle}">dynamic data refresh</span> for up-to-date insights.`,
  `Comprehensive <span style="${spanStyle}">evaluation of all images</span>.`,
  `<span style="${spanStyle}">Compare vendors</span> to identify the best performers.`,
  `Access a dedicated center for <span style="${spanStyle}">resolving image quality issues</span>.`,
  `Compare your images <span style="${spanStyle}">against 1 to 10 competitors</span>.`,
];

const StyledPopupButton = styled(PopupButton)`
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 100%;
  align-self: stretch;
  border-radius: 360px;
  border: 1px solid #e9e9f0;
  color: #ffffff;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.16px;
  background: #171d30;
  cursor: pointer;
  transition:
    background 0.3s ease,
    color 0.3s ease;
  &:hover {
    background: transparent;
    color: #171d30;
  }
`;

interface Props {
  onClose?: () => void;
}

export default function SeePricingLanding({ onClose }: Props) {
  return (
    <Stack
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '16px',
      }}
    >
      <Stack
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          gap: '48px',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <Stack
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: '48px',
            width: { md: '516px', xl: '630px' },
          }}
        >
          <Button sx={btnStyle} onClick={onClose} startIcon={<BackArrowIcon />}>
            Back to dashboard
          </Button>
          <Box>
            <Typography
              sx={{
                color: '#171D30',
                fontFamily: 'Poppins',
                fontSize: { md: '48px', xl: '56px' },
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '56px',
                letterSpacing: -0.96,
              }}
            >
              Unlock Premium Features with the Enterprise Plan
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '16px',
              alignSelf: 'stretch',
            }}
          >
            <Typography
              sx={{
                color: '#313234',
                fontFamily: 'Poppins',
                fontSize: { md: '18px', xl: '22px' },
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '26px',
                letterSpacing: -0.216,
              }}
            >
              Enhance your image monitoring and evaluation capabilities beyond the Free Trial.
            </Typography>
            <Typography
              sx={{
                color: '#313234',
                fontSize: { md: '18px', xl: '22px' },
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '26px',
                letterSpacing: -0.216,
              }}
            >
              Our Enterprise Plan offers comprehensive functionalities to monitor and resolve image
              quality issues across your vendors&apos; platforms.
            </Typography>
          </Box>
        </Stack>
        <Box
          sx={{
            display: 'flex',
            flexFirection: 'column',
            alignItems: 'flex-start',
            gap: '24px',
            flexDirection: 'column',
            width: { md: '592px', xl: '806px' },
          }}
        >
          <Stack
            sx={{
              display: 'flex',
              padding: '32px 48px',
              alignItems: 'flex-start',
              gap: '16px',
              alignSelf: 'stretch',
              borderRadius: '24px',
              border: '2px solid #7A17F9',
              background: '#FFF',
            }}
          >
            <Typography
              sx={{
                color: '#171D30',
                fontFamily: 'Poppins',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '32px',
                letterSpacing: -0.336,
              }}
            >
              Enterprise Plan
            </Typography>

            <Box
              sx={{
                height: '1px',
                alignSelf: 'stretch',
                background: '#E9E9F0',
                my: '16px',
              }}
            />

            <Box gap={'8px'} display={'flex'} flexDirection={'column'}>
              {information.map((info, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    gap: '8px',
                  }}
                >
                  <CheckboxCircleLine />
                  <Typography
                    key={index}
                    dangerouslySetInnerHTML={{ __html: info }}
                    sx={{
                      color: '#313234',
                      fontFamily: 'Poppins',
                      fontSize: { md: '16px', xl: '22px' },
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '20px',
                      letterSpacing: -0.07,
                    }}
                  />
                </Box>
              ))}
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: '8px',
                mt: '28px',
                width: '100%',
              }}
            >
              <StyledPopupButton
                url={process.env.REACT_APP_CALENDLY_URL as string}
                rootElement={document.getElementById('root') as HTMLElement}
                text="Book a demo"
              />
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
}

const btnStyle = {
  display: 'flex',
  padding: '10px 12px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4px',
  borderRadius: '360px',
  border: '1px solid #E9E9F0',
  color: '#171D30',
  textAlign: 'center',
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: -0.07,
  transition: 'background 0.3s ease, color 0.3s ease',
  '&:hover': {
    background: '#171D30',
    color: '#FFFFFF',
  },
};
