import React from 'react';
import ImageScoreChart from './ImagesByScoreChart';
import { Stack, Typography } from '@mui/material';
import { OverviewStart } from '../../../assets/icons/OverviewStart';
import useOnboardingSearchParam from '../../../hooks/useOnboardingSearchParam';
import CustomCircularProgress from '../../Common/CustomCircularProgress';

interface Props {
  scoreDistribution: Record<string, number> | null;
  totalImages: number;
}

function ImagesByScore({ scoreDistribution, totalImages }: Props) {
  const { isOnboarding, loadingOnboarding } = useOnboardingSearchParam();
  return (
    <Stack bgcolor={'#fff'} borderRadius={'16px'} gap={'16px'}>
      <Stack gap={'4px'} p={'16px 24px'}>
        <Typography fontSize={20} fontWeight={400} lineHeight={'28px'} letterSpacing={'-0.24px'}>
          Images by score
        </Typography>

        <Typography
          fontSize={14}
          fontWeight={400}
          lineHeight={'20px'}
          letterSpacing={'-0.07px'}
          color={'textSecondary'}
        >
          Number of image by quality scores from 1 to 10
        </Typography>
      </Stack>
      {!isOnboarding && scoreDistribution ? (
        <ImageScoreChart scoreDistribution={scoreDistribution} totalImages={totalImages} />
      ) : (
        <Stack
          sx={{
            display: 'flex',
            width: '324px',
            height: '240px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '24px',
            m: '16px auto',
          }}
        >
          {loadingOnboarding ? <CustomCircularProgress size={124} /> : <OverviewStart />}

          <Typography
            sx={{
              color: 'rgba(66, 72, 93, 0.60)',
              textAlign: 'center',
              fontFamily: 'Poppins',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '20px',
              letterSpacing: '-0.07px',
            }}
          >
            Add new brand to build visualization
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}

export default ImagesByScore;
