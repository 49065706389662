import React from 'react';

export const BrandFinalizingIcon = () => (
  <svg
    width="128"
    height="128"
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="mask0_972_2212" maskUnits="userSpaceOnUse" x="0" y="0" width="128" height="128">
      <rect width="128" height="128" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_972_2212)">
      <path
        d="M42.2673 106.667H58.0006C58.9784 106.667 59.845 106.356 60.6006 105.733C61.3562 105.111 62.0006 103.333 62.0006 103.333V74.0002H46.4006L41.0673 63.3335H26.6673L30.6673 59.3335H43.7339L48.9339 70.0002H62.0006V52.6668H46.6673L50.6673 48.6668H62.0006V25.3335C62.0006 24.1779 61.6228 23.2224 60.8673 22.4668C60.1117 21.7113 59.1562 21.3335 58.0006 21.3335H42.2673C41.5562 21.3335 40.8895 21.5113 40.2673 21.8668C39.645 22.2224 39.1562 22.7113 38.8006 23.3335L27.6006 43.3335H37.0673L42.6673 32.6668H54.6673V36.6668H45.0673L39.4673 47.3335H25.4673L17.0673 62.0002C16.7117 63.1557 16.5339 63.8224 16.5339 64.0002C16.5339 64.3557 16.5784 64.6891 16.6673 65.0002C16.7562 65.3113 16.8895 65.6446 17.0673 66.0002L25.4673 80.6668H39.4673L45.0673 91.3335H54.6673V95.3335H42.6673L37.0673 84.6668H27.6006L38.8006 104.667C39.1562 105.289 39.645 105.778 40.2673 106.133C40.8895 106.489 41.5562 106.667 42.2673 106.667Z"
        fill="#6439AF"
        fillOpacity="0.2"
      />
      <path
        d="M85.867 106.667H70.1337C69.1559 106.667 68.2892 106.356 67.5337 105.733C66.7781 105.111 66.1337 103.333 66.1337 103.333V74.0002H81.7337L87.067 63.3335H101.467L97.467 59.3335H84.4003L79.2003 70.0002H66.1337V52.6668H81.467L77.467 48.6668H66.1337V25.3335C66.1337 24.1779 66.5115 23.2224 67.267 22.4668C68.0226 21.7113 68.9781 21.3335 70.1337 21.3335H85.867C86.5781 21.3335 87.2448 21.5113 87.867 21.8668C88.4892 22.2224 88.9781 22.7113 89.3337 23.3335L100.534 43.3335H91.067L85.467 32.6668H73.467V36.6668H83.067L88.667 47.3335H102.667L111.067 62.0002C111.423 63.1557 111.6 63.8224 111.6 64.0002C111.6 64.3557 111.556 64.6891 111.467 65.0002C111.378 65.3113 111.245 65.6446 111.067 66.0002L102.667 80.6668H88.667L83.067 91.3335H73.467V95.3335H85.467L91.067 84.6668H100.534L89.3337 104.667C88.9781 105.289 88.4892 105.778 87.867 106.133C87.2448 106.489 86.5781 106.667 85.867 106.667Z"
        fill="#6439AF"
        fillOpacity="0.2"
      />
    </g>
  </svg>
);
