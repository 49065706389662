import React from 'react';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

function ActionsBarPlaceholder() {
  return (
    <Stack
      bgcolor={'#fff'}
      borderRadius={'16px'}
      p={'32px 48px'}
      direction={'row'}
      gap={'24px'}
      alignItems={'center'}
    >
      <Stack gap={'8px'} width={'48%'}>
        <Skeleton variant="text" width={'80%'} height={44} />
        <Skeleton variant="text" width={'100%'} height={26} />
        <Skeleton variant="text" width={'90%'} height={26} />
      </Stack>
      <Stack gap={'8px'} width={'20%'}>
        <Skeleton variant="text" width={'80%'} height={26} />
        <Skeleton variant="text" width={'100%'} height={32} />
      </Stack>
      <Stack gap={'8px'} width={'20%'}>
        <Skeleton variant="text" width={'80%'} height={26} />
        <Skeleton variant="text" width={'60%'} height={32} />
      </Stack>
      <Skeleton variant="rectangular" width={150} height={50} sx={{ borderRadius: '12px' }} />
    </Stack>
  );
}

export default ActionsBarPlaceholder;
