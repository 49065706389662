import React from 'react';
import asset from '../images/asset.webp';

interface IProps {
  width?: string;
  height?: string;
}

export const AssetImage = ({ width, height }: IProps) => {
  return <img src={asset} alt="asset" width={width} height={height} />;
};
