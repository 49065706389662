import React from 'react';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

function ScrappedImagesPlaceholder() {
  return (
    <Stack direction={'row'} alignItems={'center'} gap={'16px'} width={'100%'}>
      {Array.from(Array(3)).map((_, index) => (
        <Stack
          key={index}
          bgcolor={'#FFF'}
          borderRadius={'16px'}
          width={'100%'}
          gap={'16px'}
          position={'relative'}
        >
          <Stack px={'6px'} position={'absolute'} top={'10px'} left={'14px'} borderRadius={'16px'}>
            <Skeleton variant="text" width={40} height={40} />
          </Stack>
          <Stack p={'16px 24px 0px 24px'}>
            <Skeleton variant="text" width={'100%'} height={240} />
          </Stack>
          <Stack p={'12px 16px 16px 24px'} direction={'row'} justifyContent={'space-between'}>
            <Stack direction={'row'} gap={'6px'} alignItems={'center'}>
              <Skeleton variant="text" width={110} height={40} />
            </Stack>

            <Stack
              p={'2px 8px'}
              bgcolor={'neutralSubtleMore'}
              borderRadius={'8px'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Skeleton variant="text" width={40} height={40} />
            </Stack>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
}

export default ScrappedImagesPlaceholder;
