import React from 'react';
import { Box, Typography } from '@mui/material';

interface IThemeBox {
  query: string;
  opacity: number;
  onClick: () => void;
}

export const ThemeBox = ({ query, opacity, onClick }: IThemeBox) => {
  return (
    <Box
      sx={{
        display: 'flex',
        borderRadius: '10px',
        mb: '1.5rem',
        p: '1rem',
        bgcolor: '#f7f5ff',
        opacity,
      }}
      onClick={onClick}
    >
      <Typography
        sx={{
          fontSize: 16,
          fontFamily: 'Figtree',
          fontWeight: 600,
          pl: '1rem',
          borderLeft: '3px solid #3B1E8E',
          color: '#3B1E8E',
        }}
      >
        {`"${query}"`}
      </Typography>
    </Box>
  );
};
