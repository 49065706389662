import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export interface IOnBoardingState {
  images: string[];
  averageScore: number | undefined;
}

export const initialState: IOnBoardingState = {
  images: [],
  averageScore: undefined,
};

const onBoardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setImages: (state, action: PayloadAction<string[]>) => {
      state.images = action.payload;
    },
    setAverageScore: (state, action: PayloadAction<number>) => {
      state.averageScore = action.payload;
    },
  },
});

export const { setImages, setAverageScore } = onBoardingSlice.actions;

export default onBoardingSlice.reducer;
