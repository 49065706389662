import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

function ProductFilterPlaceholder() {
  const skeletonHeights = [20, 31, 38, 49, 40, 32, 26, 21, 16, 10];
  return (
    <Box sx={{ width: 400, my: '16px', borderRadius: '16px', bgcolor: '#FFFFFF' }}>
      <Stack p="16px 24px" gap={'16px'}>
        <Skeleton variant="text" width={120} height={40} />
        <Box
          sx={{
            height: 77,
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'flex-end',
          }}
        >
          {skeletonHeights.map((height, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              width={31.8}
              height={height}
              sx={{
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
              }}
            />
          ))}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Skeleton variant="rectangular" width={53} height={32} sx={{ borderRadius: '10px' }} />
          <Skeleton variant="rectangular" width={80} height={32} sx={{ borderRadius: '10px' }} />
          <Skeleton variant="rectangular" width={58} height={32} sx={{ borderRadius: '10px' }} />
        </Box>
      </Stack>
      <Stack p="16px 24px" gap={'16px'}>
        <Skeleton variant="text" width={120} height={40} />

        {Array.from({ length: 3 }).map((_, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '8px',
              flexDirection: 'row',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '8px',
                flexDirection: 'row',
              }}
            >
              <Skeleton variant="circular" width={24} height={24} />
              <Skeleton variant="text" width={80} height={32} />
            </Box>
            <Skeleton variant="text" width={58} height={32} />
          </Box>
        ))}
      </Stack>
    </Box>
  );
}

export default ProductFilterPlaceholder;
