import React from 'react';

interface IIconProps {
  color: string;
}

export const PhotoIcon: React.FC<IIconProps> = ({ color }) => (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.48 30.5999H15.5935C14.9473 30.5999 14.6242 30.5999 14.4746 30.4721C14.3447 30.3612 14.2759 30.1949 14.2892 30.0247C14.3047 29.8286 14.5332 29.6001 14.9901 29.1432L24.0599 20.0734C24.4823 19.651 24.6935 19.4397 24.9371 19.3606C25.1513 19.291 25.3821 19.291 25.5963 19.3606C25.8399 19.4397 26.0511 19.6509 26.4735 20.0734L30.6 24.1999V25.4799M25.48 30.5999C27.2722 30.5999 28.1683 30.5999 28.8528 30.2511C29.4549 29.9443 29.9445 29.4548 30.2512 28.8527C30.6 28.1682 30.6 27.2721 30.6 25.4799M25.48 30.5999H16.52C14.7279 30.5999 13.8318 30.5999 13.1473 30.2511C12.5451 29.9443 12.0556 29.4548 11.7488 28.8527C11.4 28.1682 11.4 27.2721 11.4 25.4799V16.5199C11.4 14.7277 11.4 13.8317 11.7488 13.1471C12.0556 12.545 12.5451 12.0555 13.1473 11.7487C13.8318 11.3999 14.7279 11.3999 16.52 11.3999H25.48C27.2722 11.3999 28.1683 11.3999 28.8528 11.7487C29.4549 12.0555 29.9445 12.545 30.2512 13.1471C30.6 13.8317 30.6 14.7277 30.6 16.5199V25.4799M19.4 17.2666C19.4 18.4448 18.4449 19.3999 17.2667 19.3999C16.0885 19.3999 15.1334 18.4448 15.1334 17.2666C15.1334 16.0884 16.0885 15.1332 17.2667 15.1332C18.4449 15.1332 19.4 16.0884 19.4 17.2666Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
