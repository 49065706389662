import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { AssetContentContext } from '../../../../../../../contexts';

interface IAsset {
  setEditAsset: (value: boolean) => void;
}

export const Asset = ({ setEditAsset }: IAsset) => {
  const { assetContentVal, imageName } = useContext(AssetContentContext);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        boxSizing: 'border-box',
        borderRight: '1px solid #EFEFF0',
        paddingX: '10px',
        height: '65px',
      }}
    >
      <Box display="flex">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '60px',
            height: '40px',
            backgroundColor: '#F6F3FC',
            borderRadius: '5px',
          }}
        >
          <img
            src={assetContentVal}
            style={{
              width: '60px',
              height: '40px',
              objectFit: 'contain',
            }}
          />
        </Box>
        <Box display="flex" justifyContent="center" flexDirection="column" ml="10px">
          <Typography
            fontFamily="Figtree"
            color="#313234"
            fontSize={16}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100px',
              whiteSpace: 'nowrap',
            }}
          >
            {imageName || 'Asset name'}
          </Typography>
          <Typography fontFamily="Figtree" color="#AFB1B6" fontSize={12}>
            Asset
          </Typography>
        </Box>
      </Box>
      <Box
        onClick={() => {
          setEditAsset(true);
        }}
        ml="20px"
        sx={{
          '&: hover': {
            cursor: 'pointer',
          },
        }}
      >
        <EditIcon sx={{ color: '#61646B' }} />
      </Box>
    </Box>
  );
};
