import { Box, Button, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { RankingRevenue } from './RankingRevenue';
import { RankingScore } from './RankingScore';
import { type IVendor } from '../../pages/Dashboard/Products/Types';

interface IProps {
  vendors: IVendor[];
}
export const Highlights = ({ vendors }: IProps) => {
  const [highlightsBy, setHighlightsBy] = useState<'score' | 'revenue'>('score');
  const allUnrealizedRevenueZero = vendors.every(
    vendor => vendor.unrealized_revenue_monthly_usd === 0
  );
  return (
    <Stack display="flex" bgcolor="white" borderRadius="16px" p="24px 32px">
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" flexDirection="column">
          <Typography fontSize={20} fontWeight={400} fontFamily={'Poppins'}>
            Highlights
          </Typography>
          <Typography fontSize={14} fontWeight={400} fontFamily={'Poppins'} color={'#42485D99'}>
            Ranking of your images quality across your different resellers websites
          </Typography>
        </Box>

        <Box display="flex" gap="10px">
          <Button
            variant="contained"
            onClick={() => {
              setHighlightsBy('score');
            }}
            sx={{
              bgcolor: highlightsBy === 'score' ? 'black' : 'white',
              borderColor: 'borderActionsNormal',
              height: '35px',
              borderRadius: '10px',
              '&:hover': {
                bgcolor: highlightsBy === 'score' ? 'black' : 'white',
              },
            }}
          >
            <Typography color={highlightsBy === 'score' ? 'white' : 'black'}>
              Average score
            </Typography>
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setHighlightsBy('revenue');
            }}
            sx={{
              bgcolor: highlightsBy === 'revenue' ? 'black' : 'white',
              borderColor: 'borderActionsNormal',
              height: '35px',
              borderRadius: '10px',
              '&:hover': {
                bgcolor: highlightsBy === 'revenue' ? 'black' : 'white',
              },
            }}
            disabled={allUnrealizedRevenueZero}
          >
            <Typography
              color={highlightsBy === 'revenue' ? 'white' : 'black'}
              sx={{
                opacity: allUnrealizedRevenueZero ? 0.5 : 1,
              }}
            >
              Unrealized revenue
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box display="flex" width="100%">
        {highlightsBy === 'score' && <RankingScore vendors={vendors} />}
        {highlightsBy === 'revenue' && <RankingRevenue vendors={vendors} />}
      </Box>
    </Stack>
  );
};
