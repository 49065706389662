import useDeferredState from '../useDeferredState';
import { authClient } from '../../common/axios';
import { type IQuestion, type IUser, type IUserQA } from '../../common/types';
import useDeferredAction from '../useDeferredAction';
import useSetUser from '../useSetUser';
import useToken from '../useToken';

export const useGetCurrentUser = (deps: any[] = []) => {
  const token = useToken();
  const setUser = useSetUser();
  return useDeferredState<IUser>(
    async () => {
      if (!token) {
        return {
          data: null,
        };
      }

      return await authClient.get('/api/me/').then(response => {
        setUser(response.data);
        return response;
      });
    },
    [token, ...deps],
    null
  );
};

export const useGetUserAssets = async () => {
  return await authClient.get('/api/meta/assets/').then(response => {
    return response.data;
  });
};

export const useUpdateUser = (deps: any[] = []) => {
  const setUser = useSetUser();

  return useDeferredAction(
    async data => {
      return await authClient
        .put('/api/me/', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          setUser(response.data);
          return response;
        });
    },
    [setUser, ...deps]
  );
};

export const useUserAnswers = (deps: any[] = []) => {
  return useDeferredState<IUserQA[]>(
    async () => await authClient.get('/api/me/answers'),
    deps,
    [],
    []
  );
};

export const useUpdateAnswers = (deps: any[] = []) => {
  return useDeferredAction(async (question: IQuestion, option: number) => {
    return await authClient.post(`/api/meta/questions/${question.id}/answer/`, {
      option,
    });
  }, deps);
};
