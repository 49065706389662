import React from 'react';

interface IProps {
  color?: string;
}

export const AssetsSortTimeDesc = ({ color = '#1B1B1E' }: IProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 7H15L19 3L23 7H20V21H18V7ZM8 5C4.14 5 1 8.13 1 12C1 15.87 4.13 19 8 19C11.86 19 15 15.87 15 12C15 8.13 11.87 5 8 5ZM8 7.15C10.67 7.15 12.85 9.32 12.85 12C12.85 14.68 10.68 16.85 8 16.85C5.32 16.85 3.15 14.68 3.15 12C3.15 9.32 5.32 7.15 8 7.15ZM7 9V12.69L10.19 14.53L10.94 13.23L8.5 11.82V9"
      fill={color}
    />
  </svg>
);
