import React from 'react';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import TopIssuesItemPlaceholder from './TopissuesItemPlaceholder';

function TopIssuesPlaceholder() {
  return (
    <Stack
      bgcolor={'#fff'}
      borderRadius={'16px'}
      width={'100%'}
      minWidth={'664px'}
      overflow={'hidden'}
      zIndex={3}
      sx={{ userSelect: 'none' }}
    >
      <Stack
        p={'16px 24px'}
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Stack gap={'4px'}>
          <Skeleton variant="text" width={100} height={28} />
          <Skeleton variant="text" width={300} height={20} />
        </Stack>
        <Skeleton variant="rectangular" width={100} height={32} />
      </Stack>
      <Stack direction={'row'} alignItems={'center'} p={'0px 8px 8px 8px'} gap={1}>
        {[1, 2, 3].map((_, index) => (
          <TopIssuesItemPlaceholder key={index} />
        ))}
      </Stack>
    </Stack>
  );
}

export default TopIssuesPlaceholder;
