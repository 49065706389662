import React from 'react';

export const LoadingIcon3: React.FC = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.89258 11.3706L4.94258 11.2706C5.15091 10.8664 5.48008 10.5372 5.88424 10.3289L5.98424 10.2789C6.38008 10.0789 6.38008 9.51224 5.98424 9.30807L5.88424 9.25807C5.48008 9.04974 5.15091 8.72057 4.94258 8.31641L4.89258 8.21641C4.69258 7.82057 4.12591 7.82057 3.92174 8.21641L3.87174 8.31641C3.66341 8.72057 3.33424 9.04974 2.93008 9.25807L2.83008 9.30807C2.43424 9.50807 2.43424 10.0747 2.83008 10.2789L2.93008 10.3289C3.33424 10.5372 3.66341 10.8664 3.87174 11.2706L3.92174 11.3706C4.12174 11.7664 4.68841 11.7664 4.89258 11.3706Z"
      fill="#AFB1B6"
    />
    <path
      d="M16.8217 14.7247L16.7217 14.6747C16.3176 14.4664 15.9884 14.1372 15.7801 13.7331L15.7301 13.6331C15.5301 13.2372 14.9634 13.2372 14.7592 13.6331L14.7092 13.7331C14.5009 14.1372 14.1717 14.4664 13.7676 14.6747L13.6676 14.7247C13.2717 14.9247 13.2717 15.4914 13.6676 15.6956L13.7676 15.7456C14.1717 15.9539 14.5009 16.2831 14.7092 16.6872L14.7592 16.7872C14.9592 17.1831 15.5259 17.1831 15.7301 16.7872L15.7801 16.6872C15.9884 16.2831 16.3176 15.9539 16.7217 15.7456L16.8217 15.6956C17.2176 15.4956 17.2176 14.9289 16.8217 14.7247Z"
      fill="#AFB1B6"
    />
    <path
      d="M5.20924 6.15807L5.34258 6.22474C5.75924 6.43724 6.09258 6.77057 6.30508 7.18724L6.37174 7.32057C6.60091 7.77057 7.05924 8.04974 7.56341 8.04974C8.06758 8.04974 8.52591 7.77057 8.75924 7.32057L8.82591 7.18724C9.03841 6.77057 9.37174 6.43724 9.78841 6.22474L9.92174 6.15807C10.3717 5.92891 10.6509 5.47057 10.6509 4.96641C10.6509 4.46224 10.3717 4.00391 9.92174 3.77057L9.78841 3.70391C9.37174 3.49141 9.03841 3.15807 8.82591 2.74141L8.75924 2.60807C8.53008 2.15807 8.07174 1.87891 7.56341 1.87891C7.05508 1.87891 6.60091 2.15807 6.36758 2.60807L6.30091 2.74141C6.08841 3.15807 5.75508 3.49141 5.33841 3.70391L5.20508 3.77057C4.75508 3.99974 4.47591 4.45807 4.47591 4.96641C4.47591 5.47474 4.75508 5.92891 5.20508 6.16224L5.20924 6.15807ZM5.77591 4.88307L5.90925 4.81641C6.56341 4.48307 7.08424 3.96224 7.41758 3.30807L7.48424 3.17474C7.48424 3.17474 7.50924 3.12474 7.56341 3.12474C7.61758 3.12474 7.63841 3.15807 7.64258 3.17474L7.70924 3.30807C8.04258 3.96224 8.56341 4.48307 9.21758 4.81641L9.35091 4.88307C9.35091 4.88307 9.40091 4.90807 9.40091 4.96224C9.40091 5.01641 9.36341 5.03724 9.35091 5.04141L9.21758 5.10807C8.56341 5.44141 8.04258 5.96224 7.70924 6.61641L7.64258 6.74974C7.64258 6.74974 7.61758 6.79974 7.56341 6.79974C7.50924 6.79974 7.48841 6.76224 7.48424 6.74974L7.41758 6.61641C7.08424 5.96224 6.56341 5.44141 5.90925 5.10807L5.77591 5.04141C5.77591 5.04141 5.72591 5.01641 5.72591 4.96224C5.72591 4.90807 5.76341 4.88724 5.77591 4.88307Z"
      fill="#AFB1B6"
    />
    <path
      d="M17.6551 5.27057L16.8467 4.46224C16.5217 4.13724 16.0884 3.95807 15.6301 3.95807C15.1717 3.95807 14.7384 4.13724 14.4134 4.46224L5.75924 13.1164C5.75924 13.1164 5.75091 13.1289 5.74674 13.1372C5.69674 13.1914 5.65508 13.2497 5.62591 13.3164V13.3247L4.42174 16.1956C4.23424 16.6414 4.33841 17.1497 4.68424 17.4914C4.90924 17.7122 5.20508 17.8289 5.50924 17.8289C5.66758 17.8289 5.83008 17.7956 5.98424 17.7289L8.79675 16.4956L8.81341 16.4872C8.88008 16.4581 8.93424 16.4164 8.98424 16.3706C8.98841 16.3664 8.99675 16.3622 9.00508 16.3581L17.6592 7.70391C17.9842 7.37891 18.1634 6.94557 18.1634 6.48724C18.1634 6.02891 17.9842 5.59557 17.6592 5.27057H17.6551ZM8.55924 15.0331L7.08424 13.5581L13.5676 7.07474L15.0426 8.54974L8.55924 15.0331ZM16.7717 6.82057L15.9259 7.66641L14.4509 6.19141L15.2967 5.34557C15.4717 5.16641 15.7842 5.16641 15.9634 5.34557L16.7717 6.15391C16.8592 6.24141 16.9092 6.36224 16.9092 6.48724C16.9092 6.61224 16.8592 6.72891 16.7717 6.82057Z"
      fill="#AFB1B6"
    />
  </svg>
);
