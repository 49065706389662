import React, { useEffect, useState } from 'react';
import type Konva from 'konva';
import useImage from 'use-image';
import { Image as KonvaImage, Transformer, Stage, Layer } from 'react-konva';
import { Box, Button, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LoadingButton } from '@mui/lab';
import { SpinnerLoader } from '../../blocks';
interface IAssetCanvas {
  imageSrc: string;
  modalRef: any;
  isGenerated?: boolean;
  handleUseAsset: () => void;
  imageRef: any;
  selected: boolean;
  isConfirm?: boolean;
  handleUpdateAsset?: () => void;
  setEditAsset?: (value: boolean) => void;
  isLoading: boolean;
  ratio: string;
}

const AssetCanvas = ({
  imageSrc,
  modalRef,
  isGenerated,
  handleUseAsset,
  imageRef,
  selected,
  isConfirm,
  handleUpdateAsset,
  setEditAsset,
  isLoading,
  ratio,
}: IAssetCanvas) => {
  const [canvasHeight, setCanvasHeight] = useState(0);
  const [canvasWidth, setCanvasWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const [imageWidth, setImageWidth] = useState(0);
  // Image size less than modal at 100px for position the image in center setting margin left = margin top = 50px
  // const [marginLeft, setMarginLeft] = useState(50);
  // const [marginTop, setMarginTop] = useState(50);
  const [isImageLoading, setIsImageLoading] = useState(true);

  const trRef = React.useRef() as React.MutableRefObject<Konva.Transformer>;

  const minImageWidth = 100;
  const minImageHeight = 100;

  const [image, loading] = useImage(imageSrc + '?x', 'anonymous');
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (image) {
      const ratio = image?.width / image?.height;
      let height = canvasWidth - 100;
      let width = height * ratio;

      while (width > canvasWidth) {
        height = height - 50;
        width = height * ratio;
      }

      setImageHeight(height);
      setImageWidth(width);
      // setMarginLeft((canvasWidth - width) / 2);
      // setMarginTop((canvasHeight - height) / 2);
    }
  }, [image]);

  useEffect(() => {
    if (loading === 'failed' || loading === 'loaded') {
      setIsImageLoading(false);
    } else {
      setIsImageLoading(true);
    }
  }, [loading]);

  useEffect(() => {
    if (selected) {
      trRef.current?.nodes([imageRef.current]);
      trRef.current?.getLayer()?.batchDraw();
    }
  }, [selected, isImageLoading]);

  useEffect(() => {
    const modal = modalRef.current;
    const modalHeight = modal.offsetHeight;
    const modalWidth = modal.offsetWidth;

    if (modal) {
      if (modalWidth < modalHeight) {
        switch (ratio) {
          // 1:1
          case '1':
            setCanvasHeight(modalWidth);
            setCanvasWidth(modalWidth);
            // setImageHeight(modalWidth - 100);
            // setImageWidth(modalWidth - 100);
            // setMarginLeft((modalWidth - (modalWidth - 100)) / 2);
            // setMarginTop(50);
            break;
          // 1 : 2
          case '2':
            setCanvasHeight(modalWidth);
            setCanvasWidth(modalWidth / 2);
            // setImageHeight((modalWidth - 100) / 2);
            // setImageWidth((modalWidth - 100) / 2);
            // setMarginLeft((modalWidth / 2 - (modalWidth - 100) / 2) / 2);
            // setMarginTop(50);
            break;
          // 2:3
          case '3':
            setCanvasHeight(modalWidth);
            setCanvasWidth(modalWidth / 1.5);
            // setImageHeight((modalWidth - 100) / 1.5);
            // setImageWidth((modalWidth - 100) / 1.5);
            // setMarginLeft((modalWidth / 1.5 - (modalWidth - 100) / 1.5) / 2);
            // setMarginTop(50);
            break;
          // 2:1
          case '4':
            setCanvasHeight(modalWidth / 2);
            setCanvasWidth(modalWidth);
            // setImageHeight((modalWidth - 100) / 2);
            // setImageWidth((modalWidth - 100) / 2);
            // setMarginLeft((modalWidth - (modalWidth - 100) / 2) / 2);
            // setMarginTop(50);
            break;
          // 3:2
          case '5':
            setCanvasHeight(modalWidth / 1.5);
            setCanvasWidth(modalWidth);
            // setImageHeight((modalWidth - 100) / 1.5);
            // setImageWidth((modalWidth - 100) / 1.5);
            // setMarginLeft((modalWidth - (modalWidth - 100) / 1.5) / 2);
            // setMarginTop(50);
            break;
          // 1:1
          default:
            setCanvasHeight(modalWidth);
            setCanvasWidth(modalWidth);
            // setImageHeight(modalWidth - 100);
            // setImageWidth(modalWidth - 100);
            // setMarginLeft((modalWidth - (modalWidth - 100)) / 2);
            // setMarginTop(50);
            break;
        }
      } else {
        switch (ratio) {
          // 1:1
          case '1':
            setCanvasHeight(modalHeight);
            setCanvasWidth(modalHeight);
            // setImageHeight(modalHeight - 100);
            // setImageWidth(modalHeight - 100);
            // setMarginLeft((modalHeight - (modalHeight - 100)) / 2);
            // setMarginTop(50);
            break;
          // 1 : 2
          case '2':
            setCanvasHeight(modalHeight);
            setCanvasWidth(modalHeight / 2);
            // setImageHeight((modalHeight - 100) / 2);
            // setImageWidth((modalHeight - 100) / 2);
            // setMarginLeft((modalHeight / 2 - (modalHeight - 100) / 2) / 2);
            // setMarginTop(50);
            break;
          // 2:3
          case '3':
            setCanvasHeight(modalHeight);
            setCanvasWidth(modalHeight / 1.5);
            // setImageHeight((modalHeight - 100) / 1.5);
            // setImageWidth((modalHeight - 100) / 1.5);
            // setMarginLeft((modalHeight / 1.5 - (modalHeight - 100) / 1.5) / 2);
            // setMarginTop(50);
            break;
          // 2:1
          case '4':
            setCanvasHeight(modalHeight / 2);
            setCanvasWidth(modalHeight);
            // setImageHeight((modalHeight - 100) / 2);
            // setImageWidth((modalHeight - 100) / 2);
            // setMarginLeft((modalHeight - (modalHeight - 100) / 2) / 2);
            // setMarginTop(50);
            break;
          // 3:2
          case '5':
            setCanvasHeight(modalHeight / 1.5);
            setCanvasWidth(modalHeight);
            // setImageHeight((modalHeight - 100) / 1.5);
            // setImageWidth((modalHeight - 100) / 1.5);
            // setMarginLeft((modalHeight - (modalHeight - 100) / 1.5) / 2);
            // setMarginTop(50);
            break;
          // 1:1
          default:
            setCanvasHeight(modalHeight);
            setCanvasWidth(modalHeight);
            // setImageHeight(modalHeight - 100);
            // setImageWidth(modalHeight - 100);
            // setMarginLeft((modalHeight - (modalHeight - 100)) / 2);
            // setMarginTop(50);
            break;
        }
      }
    }
  }, [modalRef, imageSrc, ratio]);

  const newImageHeight = Math.min(imageHeight, canvasHeight / 2);
  const newImageWidth = newImageHeight * (imageWidth / imageHeight);

  const shape = {
    width: newImageWidth,
    height: newImageHeight,
    x: (canvasWidth - newImageWidth) / 2,
    y: canvasHeight / 2.5,
  };

  const crop = {
    width: newImageWidth,
    height: newImageHeight,
    x: (canvasWidth - newImageWidth) / 2,
    y: canvasHeight / 2.5,
  };

  const getCrop2 = (image: any, newSize: any) => {
    const aspectRatio = newSize.width / newSize.height;
    const imageRatio = image.width / image.height;

    const newWidth = aspectRatio >= imageRatio ? image.width : image.height * aspectRatio;
    const newHeight = aspectRatio >= imageRatio ? image.width / aspectRatio : image.height;

    const x = (image.width - newWidth) / 2;
    const y = (image.height - newHeight) / 2;

    return {
      x,
      y,
      width: newWidth,
      height: newHeight,
    };
  };

  const transformer = () => {
    const node = imageRef.current;

    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    const newWidth = Math.max(5, node.width() * scaleX);
    const newHeight = Math.max(node.height() * scaleY);

    node?.scaleX(1);
    node?.scaleY(1);

    node?.width(newWidth);
    node?.height(newHeight);

    const crop = getCrop2(
      { width: image?.width, height: image?.height },
      { width: newWidth, height: newHeight }
    );
    node.crop(crop);

    setIsChanged(true);
  };

  return (
    <Box display="flex" flexDirection="column">
      {isGenerated && (
        <Box display="flex" justifyContent="flex-end" position="absolute" top={-100} right={0}>
          <Button
            variant="outlined"
            onClick={() => {
              if (setEditAsset) {
                setEditAsset(false);
              }
            }}
            sx={{
              display: 'flex',
              borderRadius: '5px',
            }}
          >
            <Typography fontSize={16} fontFamily="Figtree" color="#313131" display="flex">
              <ArrowBackIcon sx={{ mr: '10px' }} /> Back to Output
            </Typography>
          </Button>
        </Box>
      )}
      {loading === 'loaded' ? (
        <Stage width={canvasWidth} height={canvasHeight} style={{ border: '1px solid lightgrey' }}>
          <Layer>
            <KonvaImage
              ref={imageRef}
              y={shape.y}
              x={shape.x}
              image={image}
              scaleX={1}
              scaleY={1}
              width={shape.width}
              height={shape.height}
              crop={getCrop2(
                { width: image?.width, height: image?.height },
                { width: crop.width, height: crop.height }
              )}
              onTransform={e => {
                transformer();
              }}
              onDragMove={() => {
                setIsChanged(true);
              }}
              draggable={true}
              rotation={0}
            />
            {selected && (
              <Transformer
                ref={trRef}
                resizeEnabled={true}
                rotateEnabled={true}
                boundBoxFunc={(oldBox, newBox) => {
                  if (newBox.width < minImageWidth || newBox.height < minImageHeight) {
                    return oldBox;
                  }
                  return newBox;
                }}
                enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
              />
            )}
          </Layer>
        </Stage>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" height="55vh">
          <SpinnerLoader />
        </Box>
      )}
      {!isGenerated && !isConfirm && (
        <Box display="flex" justifyContent="flex-end" position="relative" bottom={-20} right={0}>
          <LoadingButton
            variant="contained"
            onClick={handleUseAsset}
            sx={{
              display: 'flex',
              borderRadius: '5px',
              bgcolor: '#3B1E8E',
              '&:hover': {
                bgcolor: '#3B1E8E',
              },
            }}
            disabled={isImageLoading}
            loading={isLoading}
          >
            <Typography fontSize={16} fontFamily="Figtree" color="#FFF" display="flex">
              Use This Asset <ArrowForwardIcon sx={{ ml: '10px' }} />
            </Typography>
          </LoadingButton>
        </Box>
      )}
      {isConfirm && (
        <Box
          display="flex"
          justifyContent="center"
          position="absolute"
          bottom={-70}
          left={50}
          right={50}
        >
          <LoadingButton
            variant={!isChanged ? 'outlined' : 'contained'}
            onClick={handleUpdateAsset}
            sx={{
              borderRadius: '5px',
              borderWidth: '0px',
              bgcolor: !isChanged ? '' : '#3B1E8E',
              paddingY: '10px',
              '&:hover': {
                bgcolor: !isChanged ? '' : '#2B0E7E',
              },
            }}
            disabled={!isChanged}
            loading={isLoading}
          >
            <Typography
              fontSize={16}
              fontFamily="Figtree"
              display="flex"
              color={!isChanged ? '#000' : '#FFF'}
            >
              Confirm Changes
            </Typography>
          </LoadingButton>
        </Box>
      )}
    </Box>
  );
};

export default AssetCanvas;
