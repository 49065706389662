import React from 'react';

export const ArrowUpwardAltIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <mask id="mask0_1731_44757" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
      <rect width="20" height="20" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1731_44757)">
      <path
        d="M10 6L14 10L12.9375 11.0625L10.75 8.875V15H9.25V8.875L7.0625 11.0625L6 10L10 6Z"
        fill="#171D30"
      />
    </g>
  </svg>
);

export default ArrowUpwardAltIcon;
