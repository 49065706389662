import React, { useEffect, useState } from 'react';
import Routing from './common/routing';
import { type IUser } from './common/types';
import {
  AssetContentProvider,
  ConversationProvider,
  FileContentProvider,
  ImagsGeneratedProvider,
  SetUserContext,
  UserContext,
  BrandsProvider,
} from './contexts';
import { ProductFruits } from 'react-product-fruits';
import * as buffer from 'buffer';
import { bootGPUs } from './repositories/boot.service';

window.Buffer = buffer.Buffer;

function App() {
  const [authorizedUser, setAuthorizedUser] = useState<IUser | null>(null);
  const [isMobile, setIsMobile] = useState(false);

  const productFruitsKey = process.env.REACT_APP_PRODUCT_FRUITS_API_KEY ?? '';

  const detectMobile = () => window.innerWidth <= 800;

  // Boot GPUs once app loading
  useEffect(() => {
    bootGPUs();
    setIsMobile(detectMobile());
  }, []);

  return (
    <>
      <UserContext.Provider value={authorizedUser}>
        {isMobile && authorizedUser && (
          <ProductFruits
            workspaceCode={productFruitsKey}
            language="en"
            user={{ username: authorizedUser.username }}
            lifeCycle="unmount"
          />
        )}
        <SetUserContext.Provider
          value={(u: IUser | null) => {
            setAuthorizedUser(u);
          }}
        >
          <ImagsGeneratedProvider>
            <FileContentProvider>
              <AssetContentProvider>
                <ConversationProvider>
                  <BrandsProvider>
                    <Routing />
                  </BrandsProvider>
                </ConversationProvider>
              </AssetContentProvider>
            </FileContentProvider>
          </ImagsGeneratedProvider>
        </SetUserContext.Provider>
      </UserContext.Provider>
    </>
  );
}

export default App;
