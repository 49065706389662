import React from 'react';
import { Stack, Typography, Box, TextField, Chip, Button } from '@mui/material';
import { SearchIcon } from '../../assets/icons/SearchIcon';
import BrandCategoryDropdown from './BrandCategoryDropdown';

interface Brand {
  name: string;
  domain: string;
  logo: string;
}

interface BrandSelectionFormProps {
  query: string;
  brand: Brand | null;
  selectedCategory: string;
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBrandSelect: (brand: Brand) => void;
  onCategorySelect: (category: string) => void;
  onConfirm: () => void | Promise<void>;
  showDropdown: boolean;
  setShowDropdown: React.Dispatch<React.SetStateAction<boolean>>;
  directCategorySelection: boolean;
}

const BrandCreationForm: React.FC<BrandSelectionFormProps> = ({
  query,
  brand,
  selectedCategory,
  onSearch,
  onBrandSelect,
  onCategorySelect,
  onConfirm,
  showDropdown,
  setShowDropdown,
  directCategorySelection,
}) => {
  const dropdownRef = React.useRef<HTMLDivElement>(null);

  return (
    <Stack
      sx={{
        display: 'flex',
        padding: '24px 32px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '16px',
        flex: '1 0 0',
        alignSelf: 'stretch',
      }}
    >
      <Typography
        sx={{
          color: 'rgba(66, 72, 93, 0.60)',
          textAlign: 'center',
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '26px',
          letterSpacing: '-0.216px',
        }}
      >
        Welcome to Focal Studio
      </Typography>
      <Typography
        sx={{
          color: '#171D30',
          textAlign: 'center',
          fontSize: '36px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '44px',
          letterSpacing: '-0.648px',
        }}
      >
        All we need is your brand name
      </Typography>
      <Stack
        direction={'row'}
        alignItems={'center'}
        width={'100%'}
        justifyContent={'center'}
        gap={'16px'}
      >
        <Box sx={{ position: 'relative', width: '100%', maxWidth: '400px' }}>
          <TextField
            autoFocus
            variant="outlined"
            placeholder={brand && selectedCategory ? '' : 'Brand name or website URL'}
            value={brand && selectedCategory ? '' : query}
            onChange={onSearch}
            onFocus={() => {
              setShowDropdown(true);
            }}
            onBlur={e => {
              if (dropdownRef.current && !dropdownRef.current.contains(e.relatedTarget as Node)) {
                setTimeout(() => {
                  setShowDropdown(false);
                }, 200);
              }
            }}
            InputProps={{
              style: {
                display: 'flex',
                padding: '14px 16px',
                alignItems: 'center',
                gap: '0px',
                flex: '1 0 0',
                borderRadius: '12px',
                border: '1px solid #E9E9F0',
                background: '#FFF',
                width: '100%',
                height: '48px',
              },
              startAdornment: brand && selectedCategory && (
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  gap={'12px'}
                  mr={'10px'}
                  width={'100%'}
                  sx={{ flexGrow: 1, flexShrink: 0, overflow: 'hidden' }}
                >
                  <Typography
                    noWrap
                    fontWeight={400}
                    lineHeight={'20px'}
                    letterSpacing={'-0.07px'}
                    fontSize={14}
                    sx={{
                      flexShrink: 0,
                      maxWidth: 'calc(100% - 60px)',
                    }}
                  >
                    {brand.name}
                  </Typography>
                  <Chip
                    label={selectedCategory}
                    size="small"
                    sx={{
                      backgroundColor: '#F3F3F7',
                      borderRadius: '6px',
                      color: '#42485D99',
                      '& .MuiChip-label': {
                        padding: '2px 6px',
                        fontSize: '12px',
                        fontWeight: '400',
                        fontFamily: 'Poppins',
                        lineHeight: '20px',
                        letterSpacing: '-0.07px',
                      },
                    }}
                  />
                </Stack>
              ),
              endAdornment: (
                <Box sx={{ flexShrink: 0, position: 'absolute', top: '12px', right: '10px' }}>
                  <SearchIcon />
                </Box>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
                '& fieldset': {
                  borderColor: '#E9E9F0',
                  borderWidth: '1px',
                },
                '&:hover fieldset': {
                  borderColor: '#E9E9F0',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#A08AD7',
                  borderWidth: '2px',
                  boxShadow: '0 0 0 2px #dfd4fb',
                },
              },
              '& .MuiOutlinedInput-input': {
                padding: 0,
              },
            }}
            fullWidth
          />
          {showDropdown && (
            <div ref={dropdownRef}>
              <BrandCategoryDropdown
                query={query}
                onCategorySelect={category => {
                  onCategorySelect(category);
                  setShowDropdown(false);
                }}
                onBrandSelect={selectedBrand => {
                  onBrandSelect(selectedBrand);
                }}
                directCategorySelection={directCategorySelection}
              />
            </div>
          )}
        </Box>
        <Button
          onClick={onConfirm}
          disabled={!brand || !selectedCategory}
          sx={{
            display: 'flex',
            padding: '10px 12px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '4px',
            borderRadius: '12px',
            background: '#171D30',
            boxShadow: '0px 1px 2px 0px rgba(20, 21, 26, 0.05)',
            color: '#FFF',
            textAlign: 'center',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.07px',
            height: '48px',
            '&:hover': {
              bgcolor: '#575C70',
              boxShadow: '0px 1px 2px 0px rgba(20, 21, 26, 0.05)',
            },
            '&:disabled': {
              bgcolor: '#F3F3F7',
              color: '#171D3040',
            },
          }}
        >
          Confirm
        </Button>
      </Stack>
    </Stack>
  );
};

export default BrandCreationForm;
