import React from 'react';

export const ProductsSwitchLeft: React.FC = () => (
  <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="26" rx="4" fill="#F3F3F7" />
    <rect x="2" y="2" width="20" height="20" rx="2" fill="white" />
    <path
      d="M9.21875 11.3331H17.3334V12.6665H9.21875L12.7947 16.2425L11.8521 17.1851L6.66675 11.9998L11.8521 6.81445L12.7947 7.75712L9.21875 11.3331Z"
      fill="#171D30"
      fillOpacity="0.25"
    />
  </svg>
);
