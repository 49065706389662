import axios from 'axios';
import * as Sentry from '@sentry/react';

const newUrl = process.env.REACT_APP_API_URL ?? 'https://focal.adtitan.io';

enum SPEAKER {
  user,
  bot,
}

interface ICONVERSATION {
  speaker_type: SPEAKER;
  query: string;
  isLoading?: boolean;
  images?: Array<{ url: string; id: string }>;
  isSuccess?: boolean;
  loadingItems?: string[];
  ids?: string[];
}

interface INewTheme {
  chat_id: string;
  history: any[];
  asset: number;
}

export const createTheme = async (theme: INewTheme) => {
  return await axios
    .post(
      `${newUrl}/api/themes/`,
      { ...theme },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token') ?? ''}`,
        },
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      Sentry.setTag('api_url', `${newUrl}/api/themes/`);
      Sentry.captureException(error);
      console.error(error);
    });
};

export const getThemes = async () => {
  return await axios
    .get(
      `${newUrl}/api/themes/`,

      {
        params: {},
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token') ?? ''}`,
        },
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      Sentry.setTag('api_url', `${newUrl}/api/themes/`);
      Sentry.captureException(error);
      console.error(error);
    });
};

export const updateTheme = async (id: number, history: ICONVERSATION[]) => {
  return await axios
    .patch(
      `${newUrl}/api/themes/${id}/`,
      { history },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token') ?? ''}`,
        },
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      Sentry.setTag('api_url', `${newUrl}/api/themes/${id}/`);
      Sentry.captureException(error);
      console.error(error);
    });
};

export const deleteTheme = async (id: number) => {
  return await axios
    .delete(`${newUrl}/api/themes/${id}/`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token') ?? ''}`,
      },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error(error);
    });
};
