import { type User } from './types/user';
import { baseApi } from '.';

interface PostSubscriptionPayload {
  plan: number;
}

export const userApi = baseApi.injectEndpoints({
  endpoints: builder => {
    const token = localStorage.getItem('token') ?? '';

    return {
      getCurrentUser: builder.query<User, any>({
        query: () => ({
          url: '/api/me/',
          method: 'get',
          headers: {
            Authorization: `Token ${token}`,
          },
        }),
      }),

      postSubscription: builder.mutation<User, { data: PostSubscriptionPayload }>({
        query: ({ data }) => {
          const token = localStorage.getItem('token') ?? '';

          return {
            url: '/api/me/subscriptions',
            method: 'post',
            ...(token && {
              headers: {
                Authorization: `Token ${token}`,
              },
            }),
            data,
          };
        },
      }),

      postLimitClick: builder.mutation<{ details: string }, any>({
        query: () => {
          const token = localStorage.getItem('token') ?? '';

          return {
            url: '/api/me/limit-click',
            method: 'post',
            headers: {
              Authorization: `Token ${token}`,
            },
          };
        },
      }),
    };
  },
});

export const { useGetCurrentUserQuery, usePostSubscriptionMutation, usePostLimitClickMutation } =
  userApi;
