import React, { useEffect, useState } from 'react';
import { LinearProgress, Box, Typography, Button } from '@mui/material';
import {
  LoadedIcon2,
  LoadedIcon3,
  LoadedIcon4,
  LoadingIcon1,
  LoadingIcon2,
  LoadingIcon3,
  LoadingIcon4,
  LoadingIcon5,
} from '../../../../../../assets/icons/fakeLoadingIcons';
import CloseIcon from '@mui/icons-material/Close';

interface IFakeLoadingLine {
  handleCancelRequest: () => void;
}

const FakeLoadingLine = ({ handleCancelRequest }: IFakeLoadingLine) => {
  const [step, setStep] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(oldProgress => {
        if (oldProgress >= 100) {
          setStep(prevStep => {
            if (prevStep < 4) {
              return prevStep + 1;
            }
            return prevStep;
          });

          return 0;
        }

        return oldProgress + Math.round(Math.random() * 1);
      });
    }, 150);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const stepTexts = [
    'Setting-up the environment...',
    'Integrating the product...',
    'Lighting and reflections...',
    'Final touches...',
    'Final touches...',
  ];

  return (
    <Box display="flex" pt="10px" flexDirection="column" alignItems="center" bgcolor="#FFF">
      <Typography color="#61646B" fontFamily="Figtree" fontSize={14}>
        {stepTexts[step]}
      </Typography>
      <Box display="flex" alignItems="center">
        <Box display="flex" alignItems="center">
          <LoadingIcon1 />
          <LinearProgress
            variant="determinate"
            value={step === 0 ? progress : step > 0 ? 100 : 0}
            sx={{ borderRadius: 5, margin: 1, padding: 0, width: { xs: '40px', md: '80px' } }}
          />
        </Box>
        <Box display="flex" alignItems="center">
          {step > 0 ? <LoadedIcon2 /> : <LoadingIcon2 />}
          <LinearProgress
            variant="determinate"
            value={step === 1 ? progress : step > 1 ? 100 : 0}
            sx={{ borderRadius: 5, margin: 1, padding: 0, width: { xs: '40px', md: '80px' } }}
          />
        </Box>
        <Box display="flex" alignItems="center">
          {step > 1 ? <LoadedIcon3 /> : <LoadingIcon3 />}
          <LinearProgress
            variant="determinate"
            value={step === 2 ? progress : step > 2 ? 100 : 0}
            sx={{ borderRadius: 5, margin: 1, padding: 0, width: { xs: '40px', md: '80px' } }}
          />
        </Box>
        <Box display="flex" alignItems="center">
          {step > 2 ? <LoadedIcon4 /> : <LoadingIcon4 />}
          <LinearProgress
            variant="determinate"
            value={step === 3 ? progress : step > 3 ? 90 : 0}
            sx={{ borderRadius: 5, margin: 1, padding: 0, width: { xs: '40px', md: '80px' } }}
          />
          <LoadingIcon5 />
        </Box>
      </Box>
      <Box display="flex" mt="10px" flexDirection="column" alignItems="center">
        <Button
          variant="outlined"
          sx={{ borderRadius: '5px', borderColor: '#61646B' }}
          onClick={() => {
            handleCancelRequest();
          }}
          endIcon={<CloseIcon />}
        >
          <Typography color="#61646B" fontFamily="Figtree" fontSize={14}>
            Cancel
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default FakeLoadingLine;
