import React from 'react';

export const OverviewStart: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
    <path
      d="M21 0C22.1236 11.0994 30.9006 19.8764 42 21C30.9006 22.1236 22.1236 30.9006 21 42C19.8764 30.9006 11.0994 22.1236 0 21C11.0994 19.8764 19.8764 11.0994 21 0Z"
      fill="#F3F3F7"
    />
  </svg>
);
