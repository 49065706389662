import React from 'react';

export const DotsIcon: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.9987 10.8327C10.4589 10.8327 10.832 10.4596 10.832 9.99935C10.832 9.53911 10.4589 9.16602 9.9987 9.16602C9.53846 9.16602 9.16536 9.53911 9.16536 9.99935C9.16536 10.4596 9.53846 10.8327 9.9987 10.8327Z"
      stroke="#61646B"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.832 10.8327C16.2923 10.8327 16.6654 10.4596 16.6654 9.99935C16.6654 9.53911 16.2923 9.16602 15.832 9.16602C15.3718 9.16602 14.9987 9.53911 14.9987 9.99935C14.9987 10.4596 15.3718 10.8327 15.832 10.8327Z"
      stroke="#61646B"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.16536 10.8327C4.6256 10.8327 4.9987 10.4596 4.9987 9.99935C4.9987 9.53911 4.6256 9.16602 4.16536 9.16602C3.70513 9.16602 3.33203 9.53911 3.33203 9.99935C3.33203 10.4596 3.70513 10.8327 4.16536 10.8327Z"
      stroke="#61646B"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
