import React, {
  type Dispatch,
  type SetStateAction,
  type MutableRefObject,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Box, Button, Divider, Grid, Slider, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Canvas from '../Canvas/Canvas';
import { EraserIcon, PenIcon, RedoIcon, UndoIcon } from '../../../../../../assets/icons';
import { toast } from 'react-toastify';
import { FileContentContext, ConversationContext } from '../../../../../../contexts';
import { SpinnerLoader } from '../../../../../../components/blocks';

interface IContent {
  handleClose: () => void;
  handleSliderChange: (event: Event, newValue: number | number[]) => void;
  undo: boolean;
  handleUndo: () => void;
  redo: boolean;
  handleRedo: () => void;
  modalRef: MutableRefObject<null>;
  image: string;
  canvasRef: MutableRefObject<null>;
  shapeRef: MutableRefObject<null>;
  initImageRef: MutableRefObject<null>;
  lineWidth: number;
  shape: never[];
  isRedraw: boolean;
  setShape: Dispatch<SetStateAction<never[]>>;
  setInitialImage: Dispatch<SetStateAction<null>>;
  setMaskImage: Dispatch<SetStateAction<null>>;
  setIsRedraw: Dispatch<SetStateAction<boolean>>;
  mode: 'none' | 'restore' | 'removal';
  setRefineMode: (value: 'none' | 'restore' | 'removal') => void;
  selectedAsset: string;
  modalImage: string;
  isGeneratedImageLoading: boolean;
  setModalImage: (value: string) => void;
}

const Content = ({
  handleClose,
  handleSliderChange,
  undo,
  handleUndo,
  redo,
  handleRedo,
  modalRef,
  image,
  canvasRef,
  shapeRef,
  initImageRef,
  lineWidth,
  shape,
  isRedraw,
  setShape,
  setInitialImage,
  setMaskImage,
  setIsRedraw,
  mode,
  setRefineMode,
  selectedAsset,
  modalImage,
  isGeneratedImageLoading,
  setModalImage,
}: IContent) => {
  const { fileContentVal, handleContentVal } = useContext(FileContentContext);
  const { conversation } = useContext(ConversationContext);

  const [images, setImages] = useState<string[]>([]);

  // Get all images
  useEffect(() => {
    const arrayOfOutputImages: string[] = [];

    conversation.forEach(item => {
      return item?.images?.forEach(image => {
        return arrayOfOutputImages.push(image.url);
      });
    });
    setImages(arrayOfOutputImages);
  }, [conversation]);

  // Check if try to add the image with same url as we have in outputs
  const checkAndReplaceOutputImage = () => {
    let isTryToAddDuplicate = false;

    conversation.forEach(item => {
      return item?.images?.forEach(image => {
        const numberOfUniqImages = images.filter(image => image === modalImage);

        if (numberOfUniqImages.length >= 1) {
          isTryToAddDuplicate = true;
        } else if (image.url === fileContentVal) {
          image.url = modalImage;
        }
      });
    });
    return isTryToAddDuplicate;
  };

  const handleConfirm = () => {
    try {
      const isTryToAddDuplicate = checkAndReplaceOutputImage();
      if (isTryToAddDuplicate) {
        toast.warn('This image exist in outputs', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      } else {
        handleContentVal(modalImage);
      }

      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isGeneratedImageLoading) {
      setShape([]);
      setModalImage('');
    }
  }, [isGeneratedImageLoading]);

  return (
    <Grid item xs={12} md={9} p={{ xs: '5px', md: '25px' }} display="flex" flexDirection="column">
      <Box display="flex" justifyContent="flex-end">
        <CloseIcon
          onClick={handleClose}
          sx={{
            fontSize: 30,
            '&:hover': {
              cursor: 'pointer',
              opacity: 0.7,
            },
          }}
        />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        alignSelf="center"
        sx={{
          borderRadius: '25px',
          border: '1px solid #61646B',
          gap: { xs: '10px', md: '20px' },
          paddingX: { xs: '10px', md: '25px' },
          paddingY: '5px',
        }}
      >
        <Box
          onClick={() => null}
          sx={{
            display: 'flex',
            boxShadow: mode === 'restore' ? 1 : 0,
            borderRadius: '50px',
            '&:hover': {
              cursor: 'pointer',
            },
          }}
        >
          <PenIcon />
        </Box>
        <Box
          onClick={() => null}
          sx={{
            display: 'flex',
            boxShadow: mode === 'removal' ? 1 : 0,
            borderRadius: '50px',
            '&:hover': {
              cursor: 'pointer',
            },
          }}
        >
          <EraserIcon />
        </Box>

        <Typography fontSize={14} fontFamily="Figtree" color="#61646B">
          Size
        </Typography>
        <Slider
          value={lineWidth || 40}
          min={40}
          max={200}
          aria-label="Default"
          valueLabelDisplay="auto"
          sx={{ ml: '5px', color: '#3B1E8E', width: { xs: '100px', md: '200px' } }}
          onChange={handleSliderChange}
        />
        <Divider orientation="vertical" sx={{ bgcolor: '#61646B' }} />
        <Box
          display="flex"
          onClick={handleUndo}
          sx={{
            opacity: undo ? 1 : 0.5,
            '&:hover': {
              cursor: undo ? 'pointer' : 'default',
            },
          }}
        >
          <UndoIcon />
        </Box>
        <Box
          display="flex"
          onClick={handleRedo}
          sx={{
            opacity: redo ? 1 : 0.5,
            '&:hover': {
              cursor: redo ? 'pointer' : 'default',
            },
          }}
        >
          <RedoIcon />
        </Box>
      </Box>

      <Box
        display="flex"
        ref={modalRef}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px',
          mt: '25px',
          height: { xs: '400px', md: '100%' },
          position: 'relative',
        }}
      >
        {image ? (
          <Canvas
            image={image}
            canvasRef={canvasRef}
            modalRef={modalRef}
            shapeRef={shapeRef}
            initImageRef={initImageRef}
            lineWidth={lineWidth}
            shape={shape}
            isRedraw={isRedraw}
            setShape={setShape}
            setInitialImage={setInitialImage}
            setMaskImage={setMaskImage}
            setIsRedraw={setIsRedraw}
            isGeneratedImageLoading={isGeneratedImageLoading}
          />
        ) : (
          <SpinnerLoader />
        )}
      </Box>

      <Box display="flex" justifyContent="center">
        <Button
          variant="outlined"
          onClick={handleConfirm}
          sx={{
            display: 'flex',
            borderRadius: '5px',
            opacity: modalImage === fileContentVal ? 0.5 : 1,
          }}
          disabled={modalImage === fileContentVal}
        >
          <Typography fontSize={16} fontFamily="Figtree" display="flex">
            Confirm Changes
          </Typography>
        </Button>
      </Box>
    </Grid>
  );
};

export default Content;
