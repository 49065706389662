import React from 'react';

interface IIconProps {
  active?: boolean;
}

export const StepIcon: React.FC<IIconProps> = ({ active = false }) => (
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="8" height="8" rx="4" fill={active ? '#6439AF' : '#ECEBF2'} />
  </svg>
);
