import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { ArrowDropDownFill } from '../../assets/icons/ArrowDropDownFill';
import { useGetUserBrandsQuery } from '../../core/api/brand';
import type { IBrand } from '../../core/api/types/Brand';
import useOnboardingSearchParam from '../../hooks/useOnboardingSearchParam';
import useUser from '../../hooks/useUser';
import { BrandLogo } from '../Common/BrandLogo';
import { useAppDispatch, useAppSelector } from '../../hooks/useStore';
import {
  setBrandsCount,
  setBrandsLoading,
  setDashboardStats,
  setLogoDominantColor,
} from '../../core/store/dashboardSlice';
import { getDominantColor, getRandomColor } from '../../common/helpers';
import { Avatar } from '@mui/material';
import { ArrowDownLineIcon } from '../../assets/icons/ArrowDownLine';

function BrandsSelectDropdown() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useUser();

  const [isBrandMenuShown, setIsBrandMenuShown] = useState(false);
  const brandMenuAnchorRef = useRef<HTMLDivElement | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: brands, isLoading, isError } = useGetUserBrandsQuery({}, { skip: !user });
  const { isOnboardingStart, isOnboarding } = useOnboardingSearchParam();

  const [selectedBrand, setSelectedBrand] = useState<IBrand>();
  const { logoDominantColor } = useAppSelector(state => state.dashboard);

  const completedBrands = useMemo(() => {
    return (
      brands
        ?.filter(brand => brand.evaluation_status === 'completed')
        .map(brand => {
          return {
            ...brand,
            ...(!brand.logo_url ? { color: brand.color ?? getRandomColor() } : {}),
          };
        }) ?? []
    );
  }, [brands, isLoading]);

  const lastCompletedBrand = useMemo(() => {
    return completedBrands
      ?.filter(brand => brand?.evaluation_completed_at !== null)
      .sort(
        (a, b) =>
          new Date(b.evaluation_completed_at ?? new Date(0)).getTime() -
          new Date(a.evaluation_completed_at ?? new Date(0)).getTime()
      )[0];
  }, [completedBrands, isLoading]);

  useEffect(() => {
    dispatch(setBrandsCount(brands?.length ?? 0));

    if (!isLoading) {
      dispatch(setBrandsLoading(false));
    }

    if (!brands?.length) {
      return;
    }
    const brandIdFromUrl = searchParams.get('brand_id');
    const initialBrand = brandIdFromUrl
      ? brands.find(brand => String(brand.id) === brandIdFromUrl)
      : lastCompletedBrand;

    if (initialBrand) {
      if (initialBrand?.logo_url) {
        getDominantColorFromLogoUrl(initialBrand.logo_url ?? '');
      } else if (initialBrand?.color) {
        dispatch(setLogoDominantColor(initialBrand.color));
      }
      setSelectedBrand(initialBrand);
      setSearchParams(
        prev => {
          prev.set('brand_id', String(initialBrand.id));
          return prev;
        },
        { replace: true }
      );
    }
  }, [brands, isLoading, searchParams, setSearchParams]);

  async function getImageFromUrl(url: string): Promise<HTMLImageElement> {
    return await new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      img.onload = () => {
        resolve(img);
      };
      img.onerror = () => {
        reject(new Error('Failed to load image'));
      };
    });
  }

  async function getDominantColorFromLogoUrl(logoUrl: string) {
    if (logoUrl) {
      try {
        const img = await getImageFromUrl(logoUrl);
        const dominantColor = getDominantColor(img);
        dispatch(setLogoDominantColor([dominantColor[0], dominantColor[1], dominantColor[2]]));
        return dominantColor;
      } catch (error) {
        console.error('Error fetching dominant color:', error);
        dispatch(setLogoDominantColor([100, 57, 175]));
      }
    } else {
      dispatch(setLogoDominantColor([100, 57, 175]));
    }
  }

  useEffect(() => {
    const brandIdFromUrl = searchParams.get('brand_id');
    if (brandIdFromUrl && brandIdFromUrl !== String(selectedBrand?.id)) {
      const newSelectedBrand = completedBrands?.find(brand => String(brand.id) === brandIdFromUrl);
      if (newSelectedBrand) {
        setSelectedBrand(newSelectedBrand);
      }
    }
  }, [searchParams, completedBrands, selectedBrand]);

  const handleBrandChange = (brand: IBrand) => {
    dispatch(setBrandsCount(brands?.length ?? 0));
    if (brand?.color) {
      dispatch(setLogoDominantColor(brand.color));
    } else if (brand?.logo_url) {
      getDominantColorFromLogoUrl(brand.logo_url);
    }
    dispatch(setDashboardStats(undefined));
    setSelectedBrand(brand);
    searchParams.delete('onboarding');
    navigate(`/?brand_id=${brand.id}`);
  };

  const handleCreateNewBrand = () => {
    const brandIdFromUrl = searchParams.get('brand_id');
    navigate(
      `/dashboard?onboarding=true&step=1${brandIdFromUrl ? `&brand_id=${brandIdFromUrl}` : ''}`
    );
  };

  return (
    <Stack
      ref={brandMenuAnchorRef}
      sx={{
        display: 'flex',
        width: isOnboarding && !isOnboardingStart ? 'auto' : '200px',
        padding: '6px 10px',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        cursor: 'pointer',
        borderRadius: '8px',
      }}
      onClick={() => {
        setIsBrandMenuShown(prev => !prev);
      }}
    >
      <Stack direction="row" alignItems="center" spacing={1} sx={{ maxWidth: 'calc(100% - 24px)' }}>
        {isOnboarding && !isOnboardingStart ? (
          <Stack
            sx={{
              display: 'inline-flex',
              padding: '6px 10px',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              gap: '6px',
            }}
          >
            <Stack
              sx={{
                display: 'flex',
                width: 16,
                height: 16,
                p: '2px',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '360px',
                background: '#80839B',
              }}
            >
              <ArrowDownLineIcon />
            </Stack>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '20px',
                letterSpacing: '-0.07px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: 'rgba(66, 72, 93, 0.60)',
                textAlign: 'center',
              }}
            >
              Enter brand name below...
            </Typography>
          </Stack>
        ) : (
          <>
            <Avatar
              src={selectedBrand?.logo_url ?? ''}
              sx={{
                bgcolor: selectedBrand?.logo_url ? 'transparent' : logoDominantColor,
                ...(logoDominantColor === '#FFFFFF' && {
                  border: '2px solid #F3F3F7',
                  color: '#000',
                }),
                width: 16,
                height: 16,
                fontSize: 12,
                fontWeight: 700,
              }}
            >
              {selectedBrand?.name.slice(0, 1) ?? 'B'}
            </Avatar>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '20px',
                letterSpacing: '-0.07px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {selectedBrand ? selectedBrand.name : 'Select a brand'}
            </Typography>
          </>
        )}
      </Stack>
      <ArrowDropDownFill />
      <Popper
        open={isBrandMenuShown}
        anchorEl={brandMenuAnchorRef.current}
        role={undefined}
        placement={'bottom-start'}
        transition
        sx={{ zIndex: 77, width: '200px' }}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'left top',
            }}
          >
            <Paper
              sx={{
                border: '1px solid #E9E9F0',
                borderRadius: '10px',
                width: '100%',
                maxHeight: '250px',
                overflow: 'auto',
                boxShadow:
                  '0px 10px 16px -3px rgba(20, 21, 26, 0.08), 0px 3px 6px -2px rgba(20, 21, 26, 0.05)',
              }}
              elevation={1}
            >
              <ClickAwayListener
                onClickAway={() => {
                  setIsBrandMenuShown(false);
                }}
              >
                <MenuList
                  autoFocusItem={isBrandMenuShown}
                  sx={{
                    py: '4px',
                  }}
                >
                  {isLoading && (
                    <MenuItem disabled>
                      <Typography>Loading brands...</Typography>
                    </MenuItem>
                  )}
                  {isError && (
                    <MenuItem disabled>
                      <Typography color="error">Error loading brands</Typography>
                    </MenuItem>
                  )}
                  {completedBrands?.map(brand => (
                    <MenuItem
                      key={brand.id}
                      onClick={event => {
                        event.stopPropagation();
                        handleBrandChange(brand as unknown as IBrand);
                        setIsBrandMenuShown(false);
                      }}
                      selected={brand.id === selectedBrand?.id}
                    >
                      <Stack direction="row" alignItems="center" spacing={1} sx={{ width: '100%' }}>
                        <BrandLogo name={brand.name} logo={brand.logo_url} color={brand.color} />
                        <Typography
                          fontSize={14}
                          sx={{
                            fontWeight: 400,
                            lineHeight: '20px',
                            letterSpacing: '-0.07px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {brand.name}
                        </Typography>
                      </Stack>
                    </MenuItem>
                  ))}
                  <Divider sx={{ my: 0 }} />
                  <MenuItem onClick={handleCreateNewBrand} disabled={isOnboardingStart}>
                    <Typography
                      fontSize={14}
                      color="text.primary"
                      sx={{
                        fontWeight: 400,
                        lineHeight: '20px',
                        letterSpacing: '-0.07px',
                      }}
                    >
                      + Add new brand
                    </Typography>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Stack>
  );
}

export default BrandsSelectDropdown;
