import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';

export const VendorsHighlightsPlaceholder = () => {
  return (
    <Stack display="flex" bgcolor="white" borderRadius="16px" p="24px 32px">
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" flexDirection="column">
          <Skeleton variant="text" width={120} height={40} />
          <Skeleton variant="text" width={260} height={40} />
        </Box>
        <Box display="flex" gap="10px">
          <Skeleton variant="rectangular" width={110} height={32} sx={{ borderRadius: '10px' }} />
          <Skeleton variant="rectangular" width={130} height={32} sx={{ borderRadius: '10px' }} />
        </Box>
      </Box>
      <Box display="flex" width="100%">
        <Grid container spacing={1} width="100%">
          {Array.from({ length: 10 }).map((_, index) => (
            <Grid item xs={1.2} key={index} mt="30px">
              <Box display="flex" justifyContent="center">
                <Skeleton variant="text" width={132} height={8} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Stack>
  );
};
