import { Stack } from '@mui/material';
import { styled } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { ConversationContext, FileContentContext } from '../../../../contexts';

interface TextareaProps {
  query: string;
  setQuery: (value: string) => void;
  onSend: (query: string) => void;
  isInputsDisabled: boolean;
}

enum SPEAKER {
  user,
  bot,
}

const Textarea: React.FC<TextareaProps> = ({ query, setQuery, onSend, isInputsDisabled }) => {
  const [rows, setRows] = useState(1);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [sendQueryAfterLoading, setSendQueryAfterLoading] = useState(false);
  const [queryueryAfterLoading, setQueryAfterLoading] = useState('');

  const { isLoading, chatID } = useContext(FileContentContext);
  const { conversation, setConversation } = useContext(ConversationContext);

  const handleMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setQuery(event.target.value);
    setScrollHeight(event.target.scrollHeight);
  };

  useEffect(() => {
    setRows((scrollHeight - 26) / 20);
    if (query === '') setRows(1);
  }, [scrollHeight, query]);

  useEffect(() => {
    if (chatID && sendQueryAfterLoading && !isLoading) {
      const tempConversation = [...conversation];
      tempConversation.pop();
      setConversation(tempConversation);
      onSend(queryueryAfterLoading);
      setQueryAfterLoading('');
    }
  }, [sendQueryAfterLoading, chatID, isLoading]);

  const handleSend = () => {
    if (isLoading) {
      setSendQueryAfterLoading(true);
      setQueryAfterLoading(query);
      setConversation(prev => [...prev, { speaker_type: SPEAKER.user, query, isLoading: true }]);
      setQuery('');
      return;
    }

    if (!isInputsDisabled && query.trim() !== '') {
      onSend(query);
      setQuery('');
    }
  };

  return (
    <StackStyled>
      <DivStyled>
        <StyledTextarea
          disabled={isInputsDisabled}
          value={query}
          placeholder={isInputsDisabled ? 'Please upload an image' : 'Type here...'}
          rows={rows}
          onChange={handleMessageChange}
          onKeyDown={event => {
            const isShiftPressed = event.shiftKey;

            // If on computer, handle Enter key as form submission
            if (!isShiftPressed && event.key === 'Enter') {
              event.preventDefault();
              if (query !== '') {
                handleSend();
              }
              // setQuery('');
              // handleChat();
            }
          }}
          sx={{ opacity: isInputsDisabled ? 0.2 : 1 }}
        />
        <SendRoundedIconStyled
          onClick={handleSend}
          sx={{ color: isInputsDisabled ? '#EFEFF0' : '#3b1e8e' }}
        />
      </DivStyled>
    </StackStyled>
  );
};

const StackStyled = styled(Stack)`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 90%;
`;
const DivStyled = styled('div')`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledTextarea = styled('textarea')`
  background: white;
  padding: 0.8rem;
  padding-right: 2.5rem;
  width: 100%;
  max-height: 100px;
  border: none;
  white-space: pre-wrap;
  word-wrap: break-word;
  resize: none;
  line-height: 20px;
  font-family: Lato;
  font-size: 18px;

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    margin-block: 0.8rem 2rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #42464b58;
    border-radius: 10px;
    border: 4px solid white;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #282a2e79;
  }

  &:focus {
    outline: none !important;
  }
`;

const SendRoundedIconStyled = styled(SendRoundedIcon)`
  position: absolute;
  right: 25px;
  z-index: 2;
  &:hover {
    color: #282a2e;
  }
`;

export default Textarea;
