import React from 'react';

export const TwoToThree: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="16" height="16" fill="transparent" />
    <path
      d="M6 6.5V5H7.5M10 9.5V11H8.5M11 3L5 3C4.44772 3 4 3.44772 4 4L4 12C4 12.5523 4.44772 13 5 13H11C11.5523 13 12 12.5523 12 12L12 4C12 3.44772 11.5523 3 11 3Z"
      stroke="black"
    />
  </svg>
);
