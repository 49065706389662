import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { getColorAndLabel } from '../Dashboard/Helpers';
import { roundGrade } from '../../common/helpers';
import ScoreIndicator from '../Common/ScoreIndicator';
import useOnboardingSearchParam from '../../hooks/useOnboardingSearchParam';
import ColoredAvatar from '../Common/ColoredAvatar';

interface IProduct {
  id: number;
  name: string;
  grade: number;
  image: string;
  url: string;
  brand: {
    name: string;
    image: string;
  };
}

interface Props {
  product: IProduct;
  variant?: 'grid' | 'list';
  onImageClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

const ProductInfo = ({ product }: Props) => (
  <Stack
    gap={'4px'}
    className="info"
    sx={{
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: 'calc(100% - 16px)',
      bgcolor: '#F3F3F7',
      borderRadius: '0 0 16px 16px',
      p: '12px 8px',
      zIndex: 2,
      opacity: 0,
      visibility: 'hidden',
      transform: 'translateY(100%)',
      transition: 'opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease',
    }}
  >
    <Typography fontSize={14} fontWeight={400} color={'#171D30'}>
      {product.name}
    </Typography>
    <Stack
      direction={'row'}
      alignItems={'center'}
      gap={'8px'}
      sx={{
        fontSize: 14,
        fontWeight: 400,
        color: '#171D30',
      }}
    >
      {product.brand.image ? (
        <img
          src={product.brand.image}
          alt="Brand"
          style={{ width: 16, height: 16, borderRadius: '50%' }}
        />
      ) : (
        <ColoredAvatar
          name={product.brand.name}
          sx={{ width: '24px', height: '24px', fontSize: '14px' }}
        />
      )}
      {product.brand.name}
    </Stack>
  </Stack>
);

function ImageCard({ product, variant = 'grid', onImageClick }: Props) {
  const { isOnboarding } = useOnboardingSearchParam();

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    onImageClick?.(event);
  };

  if (variant === 'list') {
    return (
      <a
        href={product.url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'none' }}
        onClick={handleClick}
      >
        <Grid
          container
          alignItems="center"
          sx={{ p: '16px 24px 16px 16px' }}
          bgcolor={'#fff'}
          borderRadius={'16px'}
        >
          <Grid
            item
            xs={1}
            display={'flex'}
            alignItems={'self-end'}
            justifyContent={'end'}
            pr={'37px'}
          >
            <Box
              sx={{
                width: 24,
                height: 24,
                borderRadius: '50%',
                bgcolor: getColorAndLabel(roundGrade(product.grade)).color,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                fontWeight: 'bold',
              }}
            >
              {roundGrade(product.grade)}
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box
              component="img"
              src={product.image}
              alt={product.name}
              sx={{ width: '130px', height: '100px', objectFit: 'cover', borderRadius: '8px' }}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">{product.name} </Typography>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row" alignItems="center" spacing={1}>
              {product.brand.image ? (
                <Box
                  component="img"
                  src={product.brand.image}
                  alt={product.brand.name}
                  sx={{ width: 20, height: 20, borderRadius: '50%' }}
                />
              ) : (
                <ColoredAvatar
                  name={product.brand.name}
                  sx={{ width: '24px', height: '24px', fontSize: '14px' }}
                />
              )}

              <Typography variant="body2">{product.brand.name}</Typography>
            </Stack>
          </Grid>
        </Grid>
      </a>
    );
  }

  return (
    <a
      href={product.url}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: 'none' }}
      onClick={handleClick}
    >
      <Box
        sx={{
          p: '4px',
          borderRadius: '12px 12px 0 0',
          overflow: 'hidden',
          width: '100%',
          position: 'relative',
          '&:hover': {
            bgcolor: '#F3F3F7',
            overflow: 'visible',
          },
          '&:hover .info': {
            opacity: 1,
            visibility: 'visible',
          },
        }}
      >
        <Card
          sx={{
            borderRadius: '12px',
            overflow: 'hidden',
            boxShadow: 'none',
          }}
        >
          <Box
            sx={{
              aspectRatio: '4/3',
              position: 'relative',
              background: `url(${product.image}) no-repeat center/contain`,
              borderRadius: '12px',
              filter: isOnboarding ? 'blur(12px)' : 'none',
            }}
          >
            {!isOnboarding && <ScoreIndicator score={product.grade} variant={variant} />}
          </Box>
        </Card>
        <ProductInfo product={product} />
      </Box>
    </a>
  );
}

export default ImageCard;
