import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import { deleteTheme } from '../../../../repositories/theme.service';

interface IDeleteChatProps {
  open: boolean;
  handleClose: () => void;
  handleDelete: () => void;
  themeId: number;
  setThemeId: (value: number) => void;
}

export const DeleteChatModal = ({
  open,
  handleClose,
  handleDelete,
  themeId,
  setThemeId,
}: IDeleteChatProps) => {
  const handleDeleteAsset = async () => {
    await deleteTheme(themeId);
    handleDelete();
    handleClose();
    setThemeId(0);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: 18 }}>
            Are you sure want to delete this chat?
          </Typography>
          <Box display="flex" justifyContent="flex-end" mt="40px">
            <Button
              variant="outlined"
              sx={{ mr: '10px', borderRadius: '5px' }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ borderRadius: '5px', bgcolor: '#3B1E8E' }}
              onClick={handleDeleteAsset}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#FFF',
  boxShadow: 2,
  p: 4,
  borderRadius: '5px',
};
