import React from 'react';
import { Box } from '@mui/material';
import radarBig from '../../../assets/images/radar/radarBig.webp';
import radar from '../../../assets/images/radar/radar.webp';
import { ChipIcon } from '../../../assets/icons';

export const RadarLoader = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" position="relative">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="relative"
        sx={{
          animation: 'radarRotation 5s infinite linear',
          '@keyframes radarRotation': {
            from: {
              transform: 'rotate(0deg)',
            },
            to: {
              transform: 'rotate(360deg)',
            },
          },
        }}
      >
        <Box component="img" src={radarBig} position="absolute" sx={{ opacity: 0.4 }} />
        <Box component="img" src={radar} position="absolute" />
      </Box>
      <Box display="flex" position="absolute" zIndex={2}>
        <ChipIcon />
      </Box>
      <Box
        display="flex"
        position="absolute"
        border="1px solid lightgrey"
        borderRadius={50}
        height="275px"
        width="275px"
        sx={{ opacity: 0.3 }}
      />
    </Box>
  );
};
