import { Avatar, AvatarGroup, Box, Card, Grid, Typography } from '@mui/material';
import React from 'react';
import { theme } from '../../theme';
import { type IVendor } from '../../pages/Dashboard/Products/Types';

interface IProps {
  vendors: IVendor[];
}

export const RankingRevenue = ({ vendors }: IProps) => {
  const items = [
    { number: '90%', index: 9, color: '#EE3939' },
    { number: '80%', index: 8, color: '#EE3939' },
    { number: '70%', index: 7, color: '#EE3939' },
    { number: '60%', index: 6, color: '#EE3939' },
    { number: '50%', index: 5, color: '#FFB31A' },
    { number: '40%', index: 4, color: '#FFB31A' },
    { number: '30%', index: 3, color: '#FFB31A' },
    { number: '20%', index: 2, color: '#40C6A7' },
    { number: '10%', index: 1, color: '#40C6A7' },
    { number: '0%', index: 0, color: '#40C6A7' },
  ];

  return (
    <Grid container spacing={1} width="100%">
      {items.map((item, index) => (
        <Grid item xs={1.2} key={index} mt="30px">
          <Card elevation={0}>
            <Box
              bgcolor={item.color}
              height="10px"
              display="flex"
              borderRadius="5px"
              justifyContent="center"
              alignItems="center"
              mt="20px"
            >
              <AvatarGroup spacing="small">
                {vendors.map(reseller => {
                  if (
                    Math.floor(
                      (reseller.unrealized_revenue_monthly_usd /
                        reseller.current_sales_monthly_usd) *
                        10
                    ) === item.index
                  ) {
                    return (
                      <Avatar
                        key={reseller.name}
                        src={reseller.icon_url}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          width: '35px',
                          height: '35px',
                        }}
                        style={{
                          border: '2px solid white',
                          boxSizing: 'border-box',
                        }}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              </AvatarGroup>
            </Box>
            <Box display="flex" justifyContent="center">
              <Typography
                fontWeight="400"
                fontSize="18px"
                color={theme.palette.text.secondary}
                mt="30px"
              >
                {item.number}
              </Typography>
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
