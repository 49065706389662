import React from 'react';
import { Box, Grid, type SxProps, Typography } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import { type AxiosResponse } from 'axios';
import { type IContinueWithSocialMediaResponse } from '../../../common/types';
import { saveToken } from '../../../common/utils';
import useSetUser from '../../../hooks/useSetUser';
import { GoogleIcon, MicrosoftIcon } from '../../../assets/icons';
import { client } from '../../../common/axios';

const CODE_TOKEN_ENDPOINT = '/api/login/social/token/';

interface IProps {
  enabled?: boolean;
  preLogin: () => void;
  postLogin: (data: AxiosResponse<IContinueWithSocialMediaResponse> | null) => void;
  sx?: SxProps;
}

export const SocialButtons: React.FC<IProps> = ({ postLogin, preLogin, sx }) => {
  const setUser = useSetUser();

  const doPostLogin = (r: AxiosResponse<IContinueWithSocialMediaResponse>) => {
    setUser(r.data);
    saveToken(r.data);
    postLogin(r);
  };

  const loginGoogle = useGoogleLogin({
    onSuccess: ({ code }) => {
      preLogin();
      client
        .post(CODE_TOKEN_ENDPOINT, {
          provider: 'google',
          code,
          token: localStorage.getItem('token') ?? '',
        })
        .then(res => {
          doPostLogin(res);
          localStorage.setItem('provider', 'google');
        })
        .catch(() => {
          postLogin(null);
        });
    },
    flow: 'auth-code',
    redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URI,
  });

  const handleLogin = () => {
    const clientId = process.env.REACT_APP_MICROSOFT_CLIENT_ID ?? '';
    const redirectUri = process.env.REACT_APP_MICROSOFT_REDIRECT_URI ?? '';
    const scope = 'openid email profile User.Read';

    localStorage.setItem('provider', 'microsoft');

    const authUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&scope=${scope}`;

    window.location.href = authUrl;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '0px',
        gap: '16px',
        width: { xs: '90%', sm: '70%' },
        ...sx,
      }}
    >
      <Grid
        onClick={handleLogin}
        className="login-button"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '8px 22px',
          height: '42px',
          width: '100%',
          border: '1px solid lightgrey',
          borderRadius: '100px',
          gap: '0.5em',
          '&:hover': {
            cursor: 'pointer',
            border: '1px solid grey',
          },
        }}
      >
        <MicrosoftIcon />
        <Typography
          className="button-label"
          sx={{
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: { xs: '12px', sm: '16px' },
            lineHeight: '26px',
            letterSpacing: '0.46px',
          }}
        >
          Continue with Microsoft
        </Typography>
      </Grid>
      <Grid
        onClick={loginGoogle}
        className="login-button"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '8px 22px',
          height: '42px',
          width: '100%',
          borderRadius: '100px',
          border: '1px solid lightgrey',
          gap: '0.5em',
          '&:hover': {
            cursor: 'pointer',
            border: '1px solid grey',
          },
        }}
      >
        <GoogleIcon />
        <Typography
          className="button-label"
          sx={{
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: { xs: '12px', sm: '16px' },
            lineHeight: '26px',
            letterSpacing: '0.46px',
            color: 'primary',
          }}
        >
          Continue with Google
        </Typography>
      </Grid>
    </Box>
  );
};
