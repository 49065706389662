import React from 'react';

export const UserIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M13.3346 14.6666H12.0013V13.3333C12.0013 12.8029 11.7906 12.2942 11.4155 11.9191C11.0404 11.544 10.5317 11.3333 10.0013 11.3333H6.0013C5.47087 11.3333 4.96216 11.544 4.58709 11.9191C4.21202 12.2942 4.0013 12.8029 4.0013 13.3333V14.6666H2.66797V13.3333C2.66797 12.4492 3.01916 11.6014 3.64428 10.9763C4.2694 10.3511 5.11725 9.99996 6.0013 9.99996H10.0013C10.8854 9.99996 11.7332 10.3511 12.3583 10.9763C12.9834 11.6014 13.3346 12.4492 13.3346 13.3333V14.6666ZM8.0013 8.66663C7.47601 8.66663 6.95587 8.56316 6.47057 8.36214C5.98527 8.16113 5.54431 7.86649 5.17287 7.49505C4.80144 7.12362 4.5068 6.68266 4.30578 6.19736C4.10476 5.71206 4.0013 5.19191 4.0013 4.66663C4.0013 4.14134 4.10476 3.62119 4.30578 3.13589C4.5068 2.65059 4.80144 2.20963 5.17287 1.8382C5.54431 1.46676 5.98527 1.17213 6.47057 0.971108C6.95587 0.770089 7.47601 0.666626 8.0013 0.666626C9.06217 0.666626 10.0796 1.08805 10.8297 1.8382C11.5799 2.58834 12.0013 3.60576 12.0013 4.66663C12.0013 5.72749 11.5799 6.74491 10.8297 7.49505C10.0796 8.2452 9.06217 8.66663 8.0013 8.66663ZM8.0013 7.33329C8.70855 7.33329 9.38682 7.05234 9.88692 6.55224C10.387 6.05215 10.668 5.37387 10.668 4.66663C10.668 3.95938 10.387 3.2811 9.88692 2.78101C9.38682 2.28091 8.70855 1.99996 8.0013 1.99996C7.29406 1.99996 6.61578 2.28091 6.11568 2.78101C5.61559 3.2811 5.33464 3.95938 5.33464 4.66663C5.33464 5.37387 5.61559 6.05215 6.11568 6.55224C6.61578 7.05234 7.29406 7.33329 8.0013 7.33329Z"
      fill="white"
    />
  </svg>
);
