import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { SettingsLineIcon } from '../../assets/icons/SettingsLineIcon';
import { BrandLogo } from '../Common/BrandLogo';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  useCreateBrandMutation,
  useLazyEvalutationStatusQuery,
  useStartEvalutionMutation,
} from '../../core/api/brand';
import { type IBrand } from '../../core/api/types/Brand';
import LoadingDots from './LoadingDots';
import SegmentedProgressBar from './SegmentProgressBar';
import BrandCreationForm from './BrandCreationForm';
import { segmentTitles } from '../../common/constants/Constants';
import { removeSpecialCharacters } from '../Dashboard/Helpers';
import { useAppDispatch } from '../../hooks/useStore';
import { setAverageScore, setImages } from '../../core/store/onBoardingSlice';
import { convertS3ToHttps, roundGrade } from '../../common/helpers';
import useUser from '../../hooks/useUser';
import { useSubscriptionStatus } from '../../hooks/useSubscriptionStatus';
import UpgradePlanModal from '../Dashboard/Landing/UpgradePlanModal';
import { getBrandLogo } from '../../repositories/brands.service';
import { getAnonymousUser } from '../../repositories/users.service';
import useOnboardingSearchParam from '../../hooks/useOnboardingSearchParam';
import { toast } from 'react-toastify';

interface Brand {
  name: string;
  domain: string;
  logo: string;
}

const Onboarding = () => {
  const user = useUser();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [query, setQuery] = useState('');
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [updateTime, setUpdateTime] = useState('');
  const [brand, setBrand] = useState<Brand | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentBrand, setCurrentBrand] = useState<IBrand | null>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [seePracingModal, setSeePracingModal] = useState(false);

  const [createBrand] = useCreateBrandMutation();
  const [startEvaluation] = useStartEvalutionMutation();
  const [triggerEvaluationStatus] = useLazyEvalutationStatusQuery();
  const [selectedCategory, setSelectedCategory] = useState('');
  const [directCategorySelection, setDirectCategorySelection] = useState(false);
  const { freeTrial } = useSubscriptionStatus();
  const { isOnboardingPage } = useOnboardingSearchParam();

  useEffect(() => {
    const storedProgress = localStorage.getItem('onboardingProgress');
    const storedBrand = localStorage.getItem('onboardingProgressBrand');

    if (storedProgress) {
      setProgress(Number(storedProgress));
    }

    if (storedBrand) {
      setCurrentBrand(JSON.parse(storedBrand));
    }
  }, []);

  useEffect(() => {
    const onboardingParam = searchParams.get('onboarding');
    if (onboardingParam === 'true') {
      const storedBrandCreation = localStorage.getItem('brandCreation');
      if (storedBrandCreation) {
        handleConfirm(false);
        localStorage.removeItem('brandCreation');
      }
    }
  }, [searchParams]);

  useEffect(() => {
    const landingParam = searchParams.get('landing');
    const brandName = searchParams.get('brand_name');

    if (landingParam === 'true' && brandName) {
      setBrand({ name: brandName, domain: '', logo: '' });
      setQuery(brandName);
      setShowDropdown(true);
      setDirectCategorySelection(true);
    } else {
      const brandParam = searchParams.get('brand');
      const updateTimeParam = searchParams.get('updateTime');
      const completedParam = searchParams.get('completed');
      if (brandParam && updateTimeParam && completedParam === 'true') {
        setBrand({ name: brandParam, domain: '', logo: '' });
        setUpdateTime(updateTimeParam);
        setCompleted(true);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    const simulateProgress = () => {
      if (!currentBrand?.id) {
        return;
      }
      try {
        setSearchParams({
          onboarding: 'true',
          step: '2',
          loading: 'true',
          brand_id: currentBrand.id.toString(),
          brand_name: currentBrand.name,
        });
        localStorage.setItem('onboardingProgressBrand', JSON.stringify(currentBrand));
        intervalId = setInterval(async () => {
          const { status, items } = await triggerEvaluationStatus(currentBrand?.id).unwrap();
          const statusProgress =
            status === 'starting'
              ? 25
              : status === 'data_collection'
              ? 50
              : status === 'data_processing'
              ? 75
              : status === 'uploading'
              ? 90
              : 100;
          setProgress(statusProgress);
          localStorage.setItem('onboardingProgress', JSON.stringify(statusProgress));

          if (status === 'data_collection') {
            if (items && items.length > 0) {
              const brandImages = items.map(item => convertS3ToHttps(item.image_s3_uri));
              dispatch(setImages(brandImages));
            }
          }
          if (status === 'data_processing') {
            if (items && items.length > 0) {
              const itemsWithScores = items.filter(item => typeof item.score === 'number');
              const totalScore = itemsWithScores.reduce((acc, item) => acc + item.score, 0);
              const newAverageScore =
                itemsWithScores.length > 0 ? totalScore / itemsWithScores.length : 0;

              const _averageScore = (newAverageScore * 100) / 100;

              dispatch(setAverageScore(roundGrade(_averageScore)));
            }
          }
          if (status === 'error') {
            clearInterval(intervalId);
            setLoading(false);
            toast.error('An error occurred while onboarding the device. Please try again later.');
            localStorage.removeItem('onboardingProgressBrand');
            localStorage.removeItem('onboardingProgress');
            localStorage.removeItem('isOnboarding');
            navigate('/dashboard?onboarding=true');
          }
          if (status === 'completed') {
            clearInterval(intervalId);
            setLoading(false);
            setCompleted(true);
            const now = new Date();
            const datetimeOps = Intl.DateTimeFormat().resolvedOptions();
            const timeZone = datetimeOps.timeZone;
            const locale = datetimeOps.locale;
            const formattedDate = now
              .toLocaleString(locale, {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
                timeZone,
              })
              .replace(' at', ',');
            const utcOffset = new Intl.DateTimeFormat(locale, {
              hour: 'numeric',
              timeZone,
              timeZoneName: 'short',
            })
              .format(now)
              .split(' ')
              .pop();
            const updateTime = `${formattedDate} ${utcOffset}`;
            setUpdateTime(updateTime);
            setSearchParams({
              brand_id: currentBrand?.id.toString(),
            });
            localStorage.removeItem('onboardingProgressBrand');
            localStorage.removeItem('onboardingProgress');
            localStorage.removeItem('isOnboarding');
            if (isOnboardingPage) {
              navigate(`/?brand_id=${currentBrand?.id}`);
            } else {
              window.location.reload();
            }
          }
        }, 5000);
      } catch (error) {
        console.error('Error fetching evaluation status', error);
        localStorage.removeItem('onboardingProgressBrand');
        localStorage.removeItem('onboardingProgress');
        localStorage.removeItem('isOnboarding');
        toast.error('Something went wrong please try again');
        if (isOnboardingPage) {
          navigate(`/?brand_id=${currentBrand?.id}`);
        } else {
          window.location.href = `/`;
        }
      }
    };
    simulateProgress();
  }, [currentBrand, location]);

  useEffect(() => {
    const hasTempToken = localStorage.getItem('hasTempToken') === 'true';
    if (searchParams.get('step') === '2' && hasTempToken) {
      localStorage.setItem('isOnboarding', 'true');
      setTimeout(() => {
        navigate('/signin');
      }, 10000);
    }
  }, [searchParams]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    setShowDropdown(true);
  };

  const handleBrandSelect = (selectedBrand: Brand) => {
    setBrand(selectedBrand);
    setQuery(selectedBrand.name);
    setShowDropdown(true);
  };

  const handleCategorySelect = (category: string) => {
    setSelectedCategory(category);
    setShowDropdown(false);
  };

  const handleCreateNewBrand = async () => {
    const _categoryName = selectedCategory ?? '';
    const _brandName = brand?.name ?? '';

    const brandLogo = await getBrandLogo(_brandName);

    if (_brandName && _categoryName) {
      setLoading(true);
      try {
        const createdBrand = await createBrand({
          name: _brandName,
          category: _categoryName,
          logoUrl: brandLogo,
        }).unwrap();
        await startEvaluation(createdBrand.id).unwrap();
        setCurrentBrand(createdBrand);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleConfirm = async (continueOnboarding = true) => {
    if (continueOnboarding && !user) {
      try {
        const anonymousUser = await getAnonymousUser();
        if (anonymousUser?.token) {
          localStorage.setItem('token', anonymousUser.token);
          localStorage.setItem('hasTempToken', 'true');
          await handleCreateNewBrand();
        } else {
          console.error('Failed to retrieve anonymous user token');
        }
      } catch (error) {
        console.error('Failed to retrieve anonymous user token');
      }
    } else {
      await handleCreateNewBrand();
    }
  };

  const showCreationForm = !loading && !completed && searchParams.get('step') !== '2';

  const showSegmentProgressBar = searchParams.get('step') === '2';

  const showBrandToolbar = searchParams.get('completed') === 'true' && brand && updateTime;

  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '16px',
        background: '#FFF',
        padding: (loading && !completed) || completed ? '8px' : 0,
        mt: '24px',
      }}
    >
      {showCreationForm && (
        <BrandCreationForm
          query={query}
          brand={brand}
          selectedCategory={selectedCategory}
          onSearch={handleSearch}
          onBrandSelect={handleBrandSelect}
          onCategorySelect={handleCategorySelect}
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
          directCategorySelection={directCategorySelection}
          onConfirm={
            freeTrial
              ? async () => {
                  await handleCreateNewBrand();
                }
              : () => {
                  handleConfirm();
                }
          }
        />
      )}

      {showSegmentProgressBar && (
        <Stack
          sx={{
            display: 'flex',
            padding: '8px 16px',
            alignItems: 'center',
            gap: '16px',
            flex: '1 0 0',
            alignSelf: 'stretch',
            flexDirection: 'row',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '4px',
            }}
          >
            <BrandLogo name={currentBrand?.name} logo={currentBrand?.logo_url} />
            <Typography
              sx={{
                color: '#171D30',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '-0.07px',
              }}
            >
              {removeSpecialCharacters(searchParams.get('brand_name') as unknown as string)}
            </Typography>
          </Box>
          <SegmentedProgressBar progress={progress} />
          <Typography
            sx={{
              color: 'rgba(44, 51, 71, 0.40)',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '16px',
            }}
          >
            {progress === 25 && segmentTitles[0]}
            {progress === 50 && segmentTitles[1]}
            {progress === 75 && segmentTitles[2]}
            {progress === 100 && segmentTitles[3]}
            <LoadingDots />
          </Typography>
        </Stack>
      )}
      {showBrandToolbar && (
        <Stack
          sx={{
            display: 'flex',
            padding: '8px 16px',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '16px',
            flex: '1 0 0',
            alignSelf: 'stretch',
            flexDirection: 'row',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '4px',
            }}
          >
            <BrandLogo name={brand.name} logo={brand.logo} />
            <Typography
              sx={{
                color: '#171D30',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '-0.07px',
              }}
            >
              {removeSpecialCharacters(brand.name)}
            </Typography>
            <SettingsLineIcon />
          </Box>
          <Typography
            sx={{
              color: 'rgba(44, 51, 71, 0.40)',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '16px',
            }}
          >
            Updated: {updateTime}
          </Typography>
        </Stack>
      )}

      <UpgradePlanModal
        open={seePracingModal}
        handleClose={() => {
          setSeePracingModal(false);
        }}
      />
    </Stack>
  );
};

export default Onboarding;
