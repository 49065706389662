import axios from 'axios';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';

const url = process.env.REACT_APP_API_URL ?? 'https://focal.adtitan.io';

export const getDemoUser = async () => {
  return await axios
    .post(`${url}/api/create_demo_user/`, {
      headers: {
        'api-key': 'focal-test-key-temp-2023',
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token') ?? ''}`,
      },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      Sentry.setTag('api_url', `${url}/api/create_demo_user/`);
      Sentry.captureException(error);
      console.error(error);
    });
};

export const getAnonymousUser = async () => {
  return await axios
    .post(`${url}/api/create-temp-user/`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      Sentry.setTag('api_url', `${url}/api/create-temp-user/`);
      Sentry.captureException(error);
      toast.error(error.response?.data?.error || error.message, {
        position: 'bottom-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    });
};
