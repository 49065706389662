import React, { useContext, useEffect, useState } from 'react';
import { Box, CircularProgress, Grid } from '@mui/material';
import Chat from './components/Chat';
import { controller, postChatAnswerV2 } from '../../repositories/prompte.service';
import ImageEditModal from './components/ImageEditModal';
import { Sidebar, Header } from '../../components/blocks';
import TopSidebar from './components/TopSidebar';
import { ChatHeader } from './components/ChatHeader';
import { ChatFooter } from './components/ChatFooter';
import FakeLoadingLine from './components/Chat/components/FakeLoadingLine';
import SelectedAsset from '../../components/SelectedAsset';
import { ChatWelcome } from './components/ChatWelcome';
import { type IAsset } from '../Assets/types';
import { OneToOne, OneToTwo, ThreeToTwo, TwoToOne, TwoToThree } from '../../assets/icons/ratio';
import { getThemes, updateTheme } from '../../repositories/theme.service';
import { useGetUserAssets } from '../../hooks/api/user';
import { useNavigate } from 'react-router-dom';
import {
  FileContentContext,
  AssetContentContext,
  ImagsGeneratedContext,
  ConversationContext,
  BrandsContext,
} from '../../contexts';
import { getBrand } from '../../common/utils';

enum SPEAKER {
  user,
  bot,
}

interface IImagesArray {
  url: string;
  id: string;
}

interface IOptions {
  value: string;
  label: string;
  icon: JSX.Element;
  onClick: () => void;
}

interface ITheme {
  id: number;
  chat_id: string;
  asset_brand_id: number;
  history: any[];
  created_at: string;
  created_by: number;
  asset: number;
}

export const Home: React.FC = () => {
  const { chatID, fileContentVal, handleContentVal, handleChatID } = useContext(FileContentContext);
  const { assetContentVal, imageName, setImageName, handleAssetVal } =
    useContext(AssetContentContext);
  const { handleImageRequestID, isLoading, handleStopLoadingImages } =
    useContext(ImagsGeneratedContext);
  const { conversation, setConversation, handleDeleteConversation, setThemeId, themeId } =
    useContext(ConversationContext);

  const navigate = useNavigate();

  const [query, setQuery] = useState('');
  const [requestError, setRequestError] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [modalImage, setModalImage] = useState(fileContentVal);
  const [editAsset, setEditAsset] = useState(false);
  const [assetImage, setAssetImage] = useState<IAsset | null>(null);
  const [assets, setAssets] = useState<IImagesArray[]>([] as IImagesArray[]);
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [isChatHasOutputImg, setIsChatHasOutputImg] = useState(false);

  const { selectedBrand } = useContext(BrandsContext);

  const handleLoadLastTheme = async () => {
    handleStopLoadingImages();
    const themes: ITheme[] = await getThemes();
    const assets: IAsset[] = await useGetUserAssets();

    const selectedBrandId = getBrand();
    // Sort themes for use find method to search latest theme

    if (selectedBrandId) {
      const sortedThemes = themes
        .filter(theme => theme.asset_brand_id === selectedBrandId)
        .sort((a, b) => {
          if (a.id > b.id) {
            return -1;
          } else {
            return 1;
          }
        });

      const lastCreatedTheme = sortedThemes[0];
      const lastCreatedAsset = assets.find(asset => asset.id === lastCreatedTheme?.asset);

      if (lastCreatedTheme && lastCreatedAsset) {
        setImageName(lastCreatedAsset.name);
        handleChatID(lastCreatedTheme.chat_id);
        setThemeId(lastCreatedTheme.id);

        if (
          lastCreatedTheme.history[lastCreatedTheme.history.length] > 0 &&
          lastCreatedTheme.history[lastCreatedTheme.history.length - 1].speaker_type === 0
        ) {
          setConversation([
            ...lastCreatedTheme.history,
            {
              speaker_type: SPEAKER.bot,
              query: 'Request canceled',
              isLoading: false,
              isSuccess: false,
            },
          ]);
        } else {
          setConversation(lastCreatedTheme.history);
        }

        const loadingImages: string[] = [];

        lastCreatedTheme.history.forEach(theme => {
          if (theme.loadingItems) {
            if (theme.isLoading && theme.loadingItems.length > 0) {
              loadingImages.push(theme.loadingItems);
            }
          }
        });

        handleImageRequestID([...loadingImages]);

        handleContentVal(lastCreatedAsset.url as unknown as string);
        handleAssetVal(lastCreatedAsset?.url as unknown as string);
      } else {
        setImageName('');
        handleChatID('');
        setThemeId(0);
        setConversation([]);
        handleContentVal('');
        handleAssetVal('');
      }
    }

    setIsChatLoading(false);
  };

  useEffect(() => {
    if (window.location.pathname === '/') {
      setIsChatLoading(true);
      handleLoadLastTheme();
    }
  }, [selectedBrand.id]);

  const options: IOptions[] = [
    {
      value: '1',
      label: 'Ratio: 1:1',
      icon: <OneToOne />,
      onClick: () => {
        handleSend('Change the aspect ratio to 1:1');
      },
    },
    {
      value: '2',
      label: 'Ratio: 1:2',
      icon: <OneToTwo />,
      onClick: () => {
        handleSend('Change the aspect ratio to 1:2');
      },
    },
    {
      value: '3',
      label: 'Ratio: 2:3',
      icon: <TwoToThree />,
      onClick: () => {
        handleSend('Change the aspect ratio to 2:3');
      },
    },
    {
      value: '4',
      label: 'Ratio: 2:1',
      icon: <TwoToOne />,
      onClick: () => {
        handleSend('Change the aspect ratio to 2:1');
      },
    },
    {
      value: '5',
      label: 'Ratio: 3:2',
      icon: <ThreeToTwo />,
      onClick: () => {
        handleSend('Change the aspect ratio to 3:2');
      },
    },
  ];

  const [selectedValue, setSelectedValue] = useState(options[0].value);
  const resetAssets = () => {
    setAssets([]);
  };

  const handleCancelRequest = () => {
    controller.abort();
    handleStopLoadingImages();

    const lastLoadingMessage = conversation.reverse().find(item => item.isLoading === true);

    if (lastLoadingMessage) {
      lastLoadingMessage.isLoading = false;
      lastLoadingMessage.loadingItems = [];
      lastLoadingMessage.isSuccess = true;
      setConversation(conversation.reverse());
    }
  };

  const handleSend = async (query: string) => {
    setRequestError(false);
    if (query.trim() !== '') {
      setConversation(prev => [...prev, { speaker_type: SPEAKER.user, query, isLoading: true }]);

      try {
        const response = await postChatAnswerV2({
          chatID,
          message: query,
        });

        if (response?.result) {
          const requestIds: string[] = [];

          const prompts = response?.result.filter((request: any) => {
            return request.user_prompt;
          });

          prompts.forEach((prompt: any) => {
            requestIds.push(...prompt.request_ids);
          });

          response.result.forEach((res: any) => {
            const prompt = res.user_prompt ? res.user_prompt : '';

            if (prompt.length) {
              setConversation(prev => [
                ...prev,
                {
                  speaker_type: SPEAKER.bot,
                  query: res.user_prompt,
                  isLoading: true,
                  isSuccess: true,
                  loadingItems: res.request_ids,
                  ids: res.request_ids,
                },
              ]);
            } else if (res?.text.length > 0) {
              setConversation(prev => [
                ...prev,
                {
                  speaker_type: SPEAKER.bot,
                  query: res?.text,
                  isLoading: false,
                  isSuccess: false,
                },
              ]);
            }

            handleImageRequestID(requestIds);
          });
        } else {
          setRequestError(true);
          setConversation(prev => {
            prev.pop();
            return [...prev];
          });
        }
      } catch (error) {
        setRequestError(true);
        setConversation(prev => {
          prev.pop();
          return [...prev];
        });
      }
      setQuery('');
    }
  };

  const handleSaveImage = async () => {
    const path = window.location.pathname.split('/');
    const brandName = selectedBrand.name;

    // Make this checks for get the image without CORS errors
    if (path.length === 3 && path[1] === 'asset') {
      try {
        const response = await fetch(fileContentVal);
        const blob = await response.blob();

        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = brandName + '_asset.png';
        downloadLink.click();
      } catch (error) {
        console.error('Error downloading image:', error);
      }
    } else {
      const link = document.createElement('a');
      link.href = fileContentVal;
      link.download = brandName + '_asset.png';
      link.click();
    }
  };

  useEffect(() => {
    setModalImage(fileContentVal);
  }, [fileContentVal]);

  useEffect(() => {
    setAssetImage({
      id: 0,
      name: imageName,
      description: '',
      url: assetContentVal,
      created_at: '',
      created_by: 0,
      initUrl: '',
      brand: null,
    });
    resetAssets();
  }, [assetContentVal]);

  useEffect(() => {
    handleContentVal(fileContentVal);
    setModalImage(fileContentVal);
    handleChatID(chatID);
    return () => {
      resetAssets();
    };
  }, []);

  useEffect(() => {
    if (conversation.length) {
      const tempImages = [];

      conversation.forEach(message => {
        if (message.loadingItems) {
          tempImages.push(message.loadingItems);
        }
      });
      if (tempImages.length) {
        setIsChatHasOutputImg(true);
      }
    }

    return () => {
      setIsChatHasOutputImg(false);
    };
  }, [conversation]);

  return (
    <Box
      sx={{
        height: { xs: '100%', lg: '100vh' },
      }}
    >
      <Sidebar />
      <Header />
      <Box sx={{ pl: { xs: '0px', lg: '80px' } }}>
        {isChatLoading ? (
          <Box
            sx={{
              display: 'flex',
              height: { xs: 'calc(100vh - 124px)', lg: 'calc(100vh - 64px)' },
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Grid
              container
              sx={{ display: 'flex', flexDirection: { xs: 'column-reverse', sm: 'row' } }}
            >
              <Grid item xs={12} lg={5} mb={{ xs: '70px', sm: '0px' }}>
                <ChatHeader
                  deleteChat={() => {
                    handleCancelRequest();
                    handleDeleteConversation();
                    navigate('/');
                  }}
                />
                <Chat
                  conversation={conversation}
                  setConversation={setConversation}
                  requestError={requestError}
                  isLoading={isLoading}
                  setModalImage={setModalImage}
                  modalImage={modalImage}
                  handleCancelRequest={handleCancelRequest}
                  assets={assets}
                  setAssets={setAssets}
                  setEditAsset={setEditAsset}
                />
                {isLoading && (
                  <FakeLoadingLine
                    handleCancelRequest={() => {
                      handleCancelRequest();
                      updateTheme(themeId, conversation);
                    }}
                  />
                )}
                <ChatFooter
                  query={query}
                  setQuery={setQuery}
                  handleSend={handleSend}
                  setOpenEditModal={setOpenEditModal}
                  isInputsDisabled={
                    !fileContentVal ||
                    !!conversation[conversation.length - 1]?.isLoading ||
                    isLoading
                  }
                  setEditAsset={setEditAsset}
                  isChatHasOutputImg={isChatHasOutputImg}
                />
              </Grid>
              <Grid item xs={12} lg={7}>
                {fileContentVal && (
                  <TopSidebar
                    handleSend={handleSend}
                    handleSaveImage={handleSaveImage}
                    setOpenEditModal={setOpenEditModal}
                    isLoading={
                      !fileContentVal ||
                      !!conversation[conversation.length - 1]?.isLoading ||
                      isLoading
                    }
                    setEditAsset={setEditAsset}
                    selectedValue={selectedValue}
                    setSelectedValue={setSelectedValue}
                    options={options}
                    isChatHasOutputImg={isChatHasOutputImg}
                  />
                )}
                {editAsset ? (
                  <Box
                    sx={{
                      display: 'flex',
                      height: fileContentVal ? 'calc(100vh - 116px)' : 'calc(100vh - 64px)',
                      justifyContent: 'center',
                      alignItems: 'center',
                      bgcolor: '#FFF',
                    }}
                  >
                    <SelectedAsset
                      asset={assetImage}
                      setEditAsset={setEditAsset}
                      setAssets={setAssets}
                      ratio={selectedValue}
                      isGenerated
                      isConfirm
                    />
                  </Box>
                ) : (
                  <ChatWelcome />
                )}
              </Grid>
            </Grid>
            {fileContentVal && (
              <ImageEditModal
                open={openEditModal}
                setOpen={setOpenEditModal}
                modalImage={modalImage}
                conversation={conversation}
                handleCancelRequest={handleCancelRequest}
                setModalImage={setModalImage}
                assets={assets}
                setAssets={setAssets}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  );
};
