import React, { Suspense, type ReactNode } from 'react';
import Stack from '@mui/material/Stack';

interface LazyLoadProps {
  children: ReactNode;
  fallback: ReactNode;
}

export function LazyLoad({ children, fallback }: LazyLoadProps) {
  const [isVisible, setIsVisible] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { rootMargin: '100px' }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <Stack ref={ref} width={'100%'}>
      {isVisible && <Suspense fallback={fallback}>{children}</Suspense>}
    </Stack>
  );
}
