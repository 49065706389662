/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { baseApi } from '.';
import { getRandomColor } from '../../common/helpers';
import { store } from '../store';
import { setVendorsWithColor } from '../store/dashboardSlice';
import { buildQueryString, buildQueryStringForProduct } from './helpers';
import type {
  IProductFilter,
  IProductImageFilter,
  ProductFilters,
  ImageStats,
  IProductNew,
  IGetReplaceableImagesPayload,
  IReplaceImage,
} from './types/Product';

export const productApi = baseApi.injectEndpoints({
  endpoints: builder => {
    const token = localStorage.getItem('token') ?? '';

    return {
      getProducts: builder.query<IProductImageFilter, ProductFilters>({
        query: filters => ({
          url: `/api/brand_monitoring/products/?${buildQueryString(filters)}`,
          method: 'get',
          headers: {
            Authorization: `Token ${token}`,
          },
        }),
        transformResponse: (response: IProductFilter): IProductImageFilter => ({
          ...response,
          results: response.results.map(product => ({
            id: product.id,
            image_id: product.image_id,
            title: product.name,
            diagnostics: product.diagnostics,
            reseller: product.vendor,
            score: product.score,
            image_url: product.product_url,
            type: 'product',
          })),
        }),
      }),
      getProduct: builder.query<IProductNew, { id: number }>({
        query: params => ({
          url: `/api/brand_monitoring/products/${params.id}`,
          method: 'get',
          headers: {
            Authorization: `Token ${token}`,
          },
        }),
      }),
      getStats: builder.query<ImageStats, number | undefined | void>({
        query: brandId => ({
          url: `/api/brand_monitoring/imagedata/stats/${brandId ? `?brand_id=${brandId}` : ''}`,
          method: 'get',
          headers: {
            Authorization: `Token ${token}`,
          },
        }),
        transformResponse: (response: ImageStats): ImageStats => {
          const existingVendorsWithColor = store.getState().dashboard.vendorsWithColor || {};
          const updatedVendorsWithColor: Record<string, { color: string }> = {
            ...existingVendorsWithColor,
          };

          const transformedResellers = response.resellers.map(reseller => {
            const vendorName = reseller.reseller.trim().toLowerCase(); // Normalize case
            let vendorColor: string | undefined;

            if (updatedVendorsWithColor[vendorName]) {
              vendorColor = updatedVendorsWithColor[vendorName].color;
            } else if (!reseller.reseller_logo_s3_url) {
              vendorColor = getRandomColor();
              updatedVendorsWithColor[vendorName] = { color: vendorColor };
            }

            return {
              ...reseller,
              vendorColor,
            };
          });

          if (
            Object.keys(existingVendorsWithColor).length !==
            Object.keys(updatedVendorsWithColor).length
          ) {
            store.dispatch(setVendorsWithColor(updatedVendorsWithColor));
          }

          return {
            ...response,
            resellers: transformedResellers,
          };
        },
      }),
      getReplaceableImages: builder.query<any, IGetReplaceableImagesPayload>({
        query: payload => ({
          url: `/api/brand_monitoring/imagedata/?${buildQueryStringForProduct(payload)}`,
          method: 'get',
          headers: {
            Authorization: `Token ${token}`,
          },
        }),
      }),
      getReplaceImage: builder.mutation<any, IReplaceImage>({
        query: params => ({
          url: `/api/brand_monitoring/actions/`,
          method: 'post',
          headers: {
            Authorization: `Token ${token}`,
          },
          data: params,
        }),
      }),
    };
  },
});

export const {
  useGetProductsQuery,
  useGetProductQuery,
  useGetStatsQuery,
  useGetReplaceableImagesQuery,
  useGetReplaceImageMutation,
} = productApi;
