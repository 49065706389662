import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Filter } from '../types';
import { type IAsset } from '../../../contexts';
import { useNavigate } from 'react-router-dom';

interface IContent {
  filterItem: number;
  hoveredItem: number | null;
  setHoveredItem: (value: number | null) => void;
  riskAssets: IAsset[];
  popularAssets: IAsset[];
  setOpenImageOverlay: (value: boolean) => void;
}

export const Content = ({
  filterItem,
  hoveredItem,
  setHoveredItem,
  riskAssets,
  popularAssets,
  setOpenImageOverlay,
}: IContent) => {
  const assetItem = (asset: IAsset) => {
    const score = (asset?.score && asset?.score * 10) ?? 0;

    return (
      <Grid
        item
        xs={3}
        key={asset.id}
        onMouseEnter={() => {
          setHoveredItem(asset.id);
        }}
        onMouseLeave={() => {
          setHoveredItem(null);
        }}
        display="flex"
        position="relative"
        justifyContent="center"
      >
        <Box
          component="img"
          src={asset.original_url}
          sx={{
            borderRadius: '10px',
            boxSizing: 'border-box',
            objectFit: 'contain',
            width: '100%',
            height: '250px',
            position: 'relative',
          }}
          loading="lazy"
        />
        {hoveredItem === asset.id && (
          <Box
            display="flex"
            position="absolute"
            bottom="10px"
            bgcolor="#FFF"
            width="85%"
            borderRadius="10px"
            height="100px"
            alignSelf="center"
            flexDirection="column"
            border="1px solid #EFEFF0"
            p="10px"
            justifyContent="space-around"
          >
            <Box display="flex" alignItems="center">
              <Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  bgcolor={score < 4 ? 'red' : score > 3 && score < 7 ? 'orange' : 'green'}
                  borderRadius="50px"
                  height="32px"
                  width="32px"
                >
                  <Typography fontFamily="Poppins" fontSize={18} fontWeight={500} color="#FFF">
                    {score}
                  </Typography>
                </Box>
              </Box>
              <Box ml="10px">
                <Typography fontFamily="Poppins" fontSize={16} fontWeight={500}>
                  {score < 4
                    ? 'Very bad quality'
                    : score > 3 && score < 7
                    ? 'Normal quality'
                    : 'Good quality'}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-around">
              <Button
                variant="outlined"
                sx={{
                  height: '40px',
                  borderRadius: '10px',
                  '&:hover': {
                    border: '1px solid black',
                  },
                }}
                fullWidth
              >
                <Typography fontFamily="Poppins" fontSize={14} fontWeight={500} color="#000">
                  Ignore
                </Typography>
              </Button>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  borderRadius: '10px',
                  bgcolor: '#7A17F9',
                  padding: '10px',
                  ml: '10px',
                  height: '40px',
                  '&:hover': {
                    bgcolor: '#9C4FFF',
                  },
                }}
                onClick={() => {
                  setOpenImageOverlay(true);
                }}
              >
                <Typography fontFamily="Poppins" fontSize={14} fontWeight={500} color="#FFF">
                  Review
                </Typography>
              </Button>
            </Box>
          </Box>
        )}
      </Grid>
    );
  };

  return (
    <Box display="flex" paddingX="40px" paddingY="20px" width="100%">
      {filterItem === Filter.risk && (
        <Box
          display="flex"
          flex={1}
          flexDirection="column"
          justifyContent={riskAssets.length ? 'flex-start' : 'center'}
          alignItems="center"
        >
          {riskAssets.length ? (
            <>
              <Typography
                fontFamily="Poppins"
                fontSize={24}
                fontWeight={500}
                color="#151729"
                alignSelf="flex-start"
              >
                High risk
              </Typography>
              <Grid container display="flex" overflow="auto" mt="20px" spacing={1}>
                {riskAssets.map((asset, index) => {
                  return assetItem(asset);
                })}
              </Grid>
            </>
          ) : (
            <CreateBrand />
          )}
        </Box>
      )}
      {filterItem === Filter.popular && (
        <Box
          display="flex"
          flex={1}
          flexDirection="column"
          justifyContent={popularAssets.length ? 'flex-start' : 'center'}
          alignItems="center"
        >
          {popularAssets.length ? (
            <>
              <Typography
                fontFamily="Poppins"
                fontSize={24}
                fontWeight={500}
                color="#151729"
                alignSelf="flex-start"
              >
                Most popular
              </Typography>
              <Grid container display="flex" overflow="auto" mt="20px" spacing={1}>
                {popularAssets.map((asset, index) => {
                  return assetItem(asset);
                })}
              </Grid>
            </>
          ) : (
            <CreateBrand />
          )}
        </Box>
      )}
    </Box>
  );
};

export const CreateBrand = () => {
  const navigate = useNavigate();

  return (
    <Box display="flex" justifyContent="center" alignContent="center">
      <Button
        onClick={() => {
          navigate('/brand');
        }}
        variant="outlined"
      >
        <Typography>Create New Brand</Typography>
      </Button>
    </Box>
  );
};
