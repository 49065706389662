import React from 'react';

export const ProductsAction: React.FC = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.98648 8.99999H5.49998V6.49999H6.49998V8.99999H7.01348C7.07948 8.39899 7.38598 7.90299 7.88348 7.36149C7.93998 7.30049 8.29948 6.92799 8.34198 6.87499C8.69512 6.4338 8.91647 5.90184 8.98054 5.34037C9.04461 4.7789 8.94878 4.21075 8.7041 3.70136C8.45943 3.19196 8.07584 2.76203 7.59753 2.46108C7.11922 2.16012 6.56563 2.00039 6.00052 2.00027C5.43541 2.00015 4.88175 2.15965 4.40331 2.46039C3.92487 2.76114 3.54111 3.19091 3.29621 3.7002C3.05132 4.2095 2.95525 4.7776 3.01908 5.3391C3.0829 5.9006 3.30403 6.43265 3.65698 6.87399C3.69998 6.92749 4.06048 7.30049 4.11598 7.36099C4.61398 7.90299 4.92048 8.39899 4.98648 8.99999ZM4.99998 9.99999V10.5H6.99998V9.99999H4.99998ZM2.87698 7.49999C2.40608 6.91163 2.11095 6.20222 2.02561 5.45347C1.94027 4.70472 2.06818 3.94709 2.39461 3.26786C2.72103 2.58863 3.2327 2.01542 3.87066 1.61428C4.50862 1.21313 5.24692 1.00037 6.00052 1.00049C6.75412 1.00061 7.49235 1.21361 8.13019 1.61496C8.76802 2.01631 9.2795 2.58968 9.60571 3.26901C9.93192 3.94835 10.0596 4.70602 9.974 5.45474C9.88842 6.20347 9.59307 6.91278 9.12198 7.50099C8.81198 7.88699 7.99998 8.49999 7.99998 9.24999V10.5C7.99998 10.7652 7.89462 11.0196 7.70709 11.2071C7.51955 11.3946 7.2652 11.5 6.99998 11.5H4.99998C4.73476 11.5 4.48041 11.3946 4.29287 11.2071C4.10534 11.0196 3.99998 10.7652 3.99998 10.5V9.24999C3.99998 8.49999 3.18748 7.88699 2.87698 7.49999Z"
      fill="#42485D"
      fillOpacity="0.6"
    />
  </svg>
);
