import React, { type ReactNode, useState } from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import { Stack, Typography } from '@mui/material';
import useOnboardingSearchParam from '../../hooks/useOnboardingSearchParam';
import UpgradePlanModal from '../Dashboard/Landing/UpgradePlanModal';
import { useSubscriptionStatus } from '../../hooks/useSubscriptionStatus';
import ColoredAvatar from './ColoredAvatar';

export interface Item {
  name: string;
  icon?: string;
  count: number;
}

const COLLAPSE_LIMIT = 3;

interface Props {
  title: string;
  items?: Item[];
  selected?: string[];
  onSelect?: (item: Item) => void;
  children?: ReactNode;
}

function Collapsible({ title, selected = [], items = [], onSelect, children }: Props) {
  const { isOnboarding } = useOnboardingSearchParam();
  const { freeTrial } = useSubscriptionStatus();
  const expandLessOnboarding =
    (title === 'Source' && isOnboarding) || (title === 'Issues' && isOnboarding);
  const disableSelectOnboarding =
    expandLessOnboarding || (title === 'Image quality score' && isOnboarding);
  const [open, setOpen] = useState(!expandLessOnboarding);
  const [showAll, setShowAll] = useState<boolean>(false);
  const [upgradeModal, setUpgradeModal] = useState(false);

  const onClickHandler = () => {
    setOpen(!open);
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const displayedItems = showAll ? items : items.slice(0, COLLAPSE_LIMIT);

  const isItemSelected = (item: Item) => selected.includes(item.name);

  if (!title && items.length === 0) {
    return null;
  }

  return (
    <List sx={{ borderRadius: '16px', overflow: 'hidden', p: 0 }}>
      <ListItemButton
        onClick={onClickHandler}
        disabled={Boolean(disableSelectOnboarding)}
        sx={{
          p: '8px 12px',
          fontSize: 16,
          fontWeight: 400,
          color: '#171D30',
          justifyContent: 'space-between',
          svg: {
            fontSize: 14,
            color: '#42485D99',
          },
          '&:hover': {
            bgcolor: '#F8F8FB',
          },
        }}
      >
        {title}
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto">
        <List
          component="div"
          sx={{
            p: '8px 12px',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          {children ? (
            <>{children}</>
          ) : (
            <>
              {displayedItems.map(item => (
                <ListItemButton
                  key={item.name}
                  onClick={() => onSelect?.(item)}
                  selected={isItemSelected(item)}
                  disabled={Boolean(isOnboarding)}
                  sx={{
                    p: '10px 12px',
                    border: '1px solid #F3F3F7',
                    borderRadius: '12px',
                    justifyContent: 'space-between',
                    '&:hover': {
                      bgcolor: '#F8F8FB',
                      borderColor: '#CECEDC',
                    },
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap="4px"
                    sx={{
                      color: '#171D30',
                      fontSize: 12,
                      fontWeight: 500,
                      img: { width: 24, height: 24, borderRadius: '50%' },
                    }}
                  >
                    {item.icon ? (
                      <img src={item.icon} alt={item.name} />
                    ) : (
                      <ColoredAvatar
                        name={item.name}
                        sx={{ width: '24px', height: '24px', fontSize: '14px' }}
                      />
                    )}
                    {item.name}
                  </Stack>
                  <Typography fontSize={14} fontWeight={400} color="#171D30">
                    {item.count}
                  </Typography>
                </ListItemButton>
              ))}
            </>
          )}
          {items.length > COLLAPSE_LIMIT && !showAll && (
            <ListItemButton
              onClick={() => {
                freeTrial ? setUpgradeModal(true) : toggleShowAll();
              }}
            >
              <Typography sx={{ fontSize: 12, fontWeight: 500, color: '#42485D99' }}>
                More
              </Typography>
            </ListItemButton>
          )}
        </List>
      </Collapse>
      <UpgradePlanModal
        open={upgradeModal}
        handleClose={() => {
          setUpgradeModal(false);
        }}
      />
    </List>
  );
}

export default Collapsible;
