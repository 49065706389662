import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import Textarea from '../Chat/Textarea';
import { EditImageIcon, MoveAssetIcon, VariationIcon } from '../../../../assets/icons';

interface IChatFooter {
  query: string;
  setQuery: (value: string) => void;
  handleSend: (query: string) => void;
  setOpenEditModal: (value: boolean) => void;
  isInputsDisabled: boolean;
  setEditAsset: (value: boolean) => void;
  isChatHasOutputImg: boolean;
}

interface IButton {
  onClick: () => void;
  startIcon: any;
  text: string;
}

export const ChatFooter = ({
  query,
  setQuery,
  handleSend,
  setOpenEditModal,
  isInputsDisabled,
  setEditAsset,
  isChatHasOutputImg,
}: IChatFooter) => {
  const CustomButton = ({ onClick, startIcon, text }: IButton) => {
    return (
      <Button
        variant="text"
        startIcon={startIcon}
        sx={{ ...buttonStyle, opacity: isInputsDisabled ? 0.5 : 1 }}
        onClick={onClick}
        disabled={isInputsDisabled}
      >
        <Typography sx={typography}>{text}</Typography>
      </Button>
    );
  };

  return (
    <Box sx={container}>
      {isChatHasOutputImg && (
        <Grid container mt="20px" width="90%" spacing={1}>
          <Grid item xs={12} md={4}>
            <CustomButton
              onClick={() => {
                handleSend('Make variations');
              }}
              startIcon={<VariationIcon />}
              text="Create Variations"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomButton
              onClick={() => {
                setOpenEditModal(true);
              }}
              startIcon={<EditImageIcon />}
              text="Edit Image"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomButton
              onClick={() => {
                // Check browser, Safari cant support smooth behavior
                window.scrollTo({
                  top: 0,
                  behavior:
                    !navigator.userAgent.includes('Chrome') &&
                    navigator.userAgent.includes('Safari')
                      ? 'auto'
                      : 'smooth',
                });
                setEditAsset(true);
              }}
              startIcon={<MoveAssetIcon />}
              text="Move Asset"
            />
          </Grid>
        </Grid>
      )}
      <Textarea
        query={query}
        setQuery={setQuery}
        onSend={handleSend}
        isInputsDisabled={isInputsDisabled}
      />
    </Box>
  );
};

const container = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%',
  height: '185px',
  bgcolor: '#FFF',
  boxSizing: 'border-box',
  borderTop: '1px solid #EFEFF0',
  borderRight: '1px solid #EFEFF0',
  zIndex: 2,
};

const typography = {
  color: '#61646B',
  fontSize: 14,
  fontFamily: 'Figtree',
};

const buttonStyle = {
  borderRadius: '5px',
  borderColor: '#61646B',
  width: '95%',
  bgcolor: '#F6F6F6',
  '&:hover': {
    bgcolor: '#E4E4E4',
  },
};
