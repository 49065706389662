import React from 'react';

export const SearchIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M15.0262 13.8476L18.5953 17.4159L17.4162 18.5951L13.8478 15.0259C12.5201 16.0903 10.8687 16.6692 9.16699 16.6667C5.02699 16.6667 1.66699 13.3067 1.66699 9.16675C1.66699 5.02675 5.02699 1.66675 9.16699 1.66675C13.307 1.66675 16.667 5.02675 16.667 9.16675C16.6694 10.8684 16.0905 12.5199 15.0262 13.8476ZM13.3545 13.2292C14.4121 12.1417 15.0027 10.6838 15.0003 9.16675C15.0003 5.94341 12.3895 3.33341 9.16699 3.33341C5.94366 3.33341 3.33366 5.94341 3.33366 9.16675C3.33366 12.3892 5.94366 15.0001 9.16699 15.0001C10.684 15.0025 12.1419 14.4118 13.2295 13.3542L13.3545 13.2292Z"
      fill="#2C3347"
      fillOpacity="0.4"
    />
  </svg>
);
