import React from 'react';
import { BallTriangle } from 'react-loader-spinner';

export const SpinnerLoader = () => {
  return (
    <BallTriangle
      height={100}
      width={100}
      radius={5}
      color="#5A58CB"
      ariaLabel="ball-triangle-loading"
      visible={true}
    />
  );
};
