import React from 'react';

export const LoadingIcon5: React.FC = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.69727 10.002L8.94727 12.252L13.4473 7.75195M17.5723 10.002C17.5723 14.1441 14.2144 17.502 10.0723 17.502C5.93013 17.502 2.57227 14.1441 2.57227 10.002C2.57227 5.85982 5.93013 2.50195 10.0723 2.50195C14.2144 2.50195 17.5723 5.85982 17.5723 10.002Z"
      stroke="#AFB1B6"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
