import React, { useEffect, useState, useRef } from 'react';
import { Box, Button, Chip, Typography } from '@mui/material';
import { theme } from '../../../theme';
import { Steps, type IImagesArray } from '../types';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ColoredChipIcon,
  KeyboardLeftIcon,
  KeyboardRightIcon,
  ReviewLeftIcon,
  ReviewRightIcon,
} from '../../../assets/icons';
import { LoadingButton } from '@mui/lab';

interface IReview {
  setStep: (value: number) => void;
  brandImages: IImagesArray[];
  setBrandImages: (value: IImagesArray[]) => void;
  isFinishLoading: boolean;
}

export const Review = ({ brandImages, setStep, setBrandImages, isFinishLoading }: IReview) => {
  const [reviewedImages, setReviewedImages] = useState(0);
  const [currentImage, setCurrentImage] = useState(0);
  const [screenWidth, setScreenWidth] = useState(0);

  const [badButtonHovered, setBadButtonHovered] = useState(false);
  const [goodButtonHovered, setGoodButtonHovered] = useState(false);
  const [isKeyPressed, setIsKeyPressed] = useState(false);

  const reviewRef = useRef<HTMLDivElement>(null);

  const imagesBottomTabLength = Math.round(screenWidth / 200);

  const handleClickGood = () => {
    if (currentImage < brandImages.length) {
      setCurrentImage(prev => prev + 1);

      const tempArray = brandImages;

      tempArray[currentImage].user_score = 1;
      tempArray[currentImage].reviewed = true;

      setBrandImages(tempArray);
    }

    hangleCountReviewedImages();
    handleNextPageIfReviewLastImage();
  };

  const handleClickBad = () => {
    if (currentImage < brandImages.length) {
      setCurrentImage(prev => prev + 1);

      const tempArray = brandImages;

      tempArray[currentImage].user_score = 0;
      tempArray[currentImage].reviewed = true;

      setBrandImages(tempArray);
    }

    hangleCountReviewedImages();
    handleNextPageIfReviewLastImage();
  };

  const handleClickNext = () => {
    if (currentImage < brandImages.length - 1) {
      setCurrentImage(prev => prev + 1);
    }
  };

  const handleClickBack = () => {
    if (currentImage > 0) {
      setCurrentImage(prev => prev - 1);
    }
  };

  const handleUserKeyPress = (event: KeyboardEvent) => {
    const { key } = event;

    if (key === 'ArrowRight') {
      setGoodButtonHovered(true);
      setIsKeyPressed(true);
    }

    if (key === 'ArrowLeft') {
      setBadButtonHovered(true);
      setIsKeyPressed(true);
    }
  };

  useEffect(() => {
    if (goodButtonHovered && isKeyPressed) {
      handleClickGood();
    }

    if (badButtonHovered && isKeyPressed) {
      handleClickBad();
    }
  }, [isKeyPressed]);

  const handleUserKeyUp = (event: KeyboardEvent) => {
    const { key } = event;

    if (key === 'ArrowRight') {
      setIsKeyPressed(false);
      setGoodButtonHovered(false);
    }

    if (key === 'ArrowLeft') {
      setIsKeyPressed(false);
      setBadButtonHovered(false);
    }
  };

  const handleNextPageIfReviewLastImage = () => {
    if (reviewedImages === brandImages.length - 1 || currentImage === brandImages.length - 1) {
      setStep(Steps.finalizing);
    }
  };

  const hangleCountReviewedImages = () => {
    const reviewedImagesCount = brandImages.filter(image => image?.reviewed).length;
    setReviewedImages(reviewedImagesCount);
  };

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    window.addEventListener('keyup', handleUserKeyUp);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
      window.removeEventListener('keyup', handleUserKeyUp);
    };
  }, []);

  useEffect(() => {
    if (reviewRef.current) {
      const divWidth = reviewRef.current.offsetWidth;
      setScreenWidth(divWidth);
    }
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      width="100%"
      height="100%"
      paddingX="70px"
      ref={reviewRef}
    >
      <Box display="flex" alignItems="center" flexDirection="column" mb="20px" mt="50px">
        <Typography
          fontFamily="Poppins"
          fontSize={24}
          color={theme.palette.text.primary}
          fontWeight={400}
        >
          {reviewedImages < 10
            ? 'Please, review at least 10 images'
            : 'Great work! The more your review the better.'}
        </Typography>
        <Typography
          fontFamily="Poppins"
          fontSize={14}
          color={theme.palette.text.secondary}
          fontWeight={400}
        >
          {reviewedImages} images of {brandImages.length} reviewed
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        maxHeight="70vh"
        width="100%"
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" mb="40px">
          <Box display="flex" flexDirection="column" alignItems="center">
            <Button
              variant="outlined"
              onClick={handleClickBad}
              sx={{
                borderColor: '#D45F74',
                height: '50px',
                borderRadius: '100px',
                backgroundColor: badButtonHovered ? '#D45F74' : '#FFF',
                '&: hover': {
                  borderColor: '#D45F74',
                  backgroundColor: '#D45F74',
                },
              }}
              startIcon={<ReviewLeftIcon color={badButtonHovered ? '#FFF' : '#D45F74'} />}
              onMouseOver={() => {
                setBadButtonHovered(true);
              }}
              onMouseLeave={() => {
                setBadButtonHovered(false);
              }}
            >
              <Typography
                fontFamily="Poppins"
                fontSize={18}
                fontWeight={500}
                color={badButtonHovered ? '#FFF' : '#D45F74'}
              >
                Bad
              </Typography>
            </Button>
            <Box display="flex" mt="20px">
              <KeyboardLeftIcon />
              <Typography
                fontFamily="Poppins"
                fontSize={11}
                color={theme.palette.text.primary}
                fontWeight={500}
                sx={{ opacity: 0.5, ml: '10px' }}
              >
                Keyboard Left
              </Typography>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            maxHeight="50vh"
            maxWidth="40vw"
            minWidth="20vw"
            bgcolor="#f4f2f7"
            borderRadius="15px"
            position="relative"
            p="10px"
          >
            <Box
              display="flex"
              alignItems="center"
              position="absolute"
              left="10px"
              top="10px"
              bgcolor="#FFF"
              borderRadius="10px"
              px="10px"
              py="5px"
            >
              <ColoredChipIcon
                color={brandImages[currentImage]?.score < 0.5 ? '#D45F74' : '#539F9A'}
              />
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight={500}
                color={brandImages[currentImage]?.score < 0.5 ? '#D45F74' : '#539F9A'}
                ml="2px"
              >
                {brandImages[currentImage]?.score < 0.5 ? 'AI Bad' : 'AI Good'}
              </Typography>
            </Box>
            <Box
              component="img"
              src={brandImages[currentImage]?.src}
              sx={{
                maxHeight: '30vh',
                maxWidth: '90%',
                objectFit: 'contain',
                borderRadius: '10px',
              }}
            />
            <Box display="flex" width="95%" mt="10px">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                color={theme.palette.text.primary}
                fontWeight={500}
              >
                {brandImages[currentImage]?.title}
              </Typography>
            </Box>

            <Box display="flex" width="100%" overflow="auto" mt="10px">
              {brandImages[currentImage]?.reason?.map(reason => {
                return (
                  <Chip
                    key={reason}
                    variant={'filled'}
                    label={reason}
                    sx={{
                      bgcolor: '#FFF',
                      ml: '10px',
                      mb: '10px',
                      fontFamily: 'Poppins',
                      fontSize: 12,
                      fontWeight: 500,
                    }}
                  />
                );
              })}
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" alignItems="center">
            <Button
              variant="outlined"
              onClick={handleClickGood}
              sx={{
                borderColor: '#539F9A',
                height: '50px',
                borderRadius: '100px',
                backgroundColor: goodButtonHovered ? '#539F9A' : '#FFF',
                '&: hover': {
                  borderColor: '#539F9A',
                  backgroundColor: '#539F9A',
                },
              }}
              endIcon={<ReviewRightIcon color={goodButtonHovered ? '#FFF' : '#539F9A'} />}
              onMouseOver={() => {
                setGoodButtonHovered(true);
              }}
              onMouseLeave={() => {
                setGoodButtonHovered(false);
              }}
            >
              <Typography
                fontFamily="Poppins"
                fontSize={18}
                fontWeight={500}
                color={goodButtonHovered ? '#FFF' : '#539F9A'}
              >
                Good
              </Typography>
            </Button>
            <Box display="flex" mt="20px">
              <Typography
                fontFamily="Poppins"
                fontSize={11}
                color={theme.palette.text.primary}
                fontWeight={500}
                sx={{ opacity: 0.5, mr: '10px' }}
              >
                Keyboard Right
              </Typography>
              <KeyboardRightIcon />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" mb="50px">
        <Box display="flex" width="100%" justifyContent="center" height="10vh">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            alignContent="center"
            mb="20px"
          >
            <Box
              component="button"
              disabled={currentImage === 0}
              onClick={handleClickBack}
              sx={{
                height: '100%',
                border: 'none',
                bgcolor: '#FFF',
                cursor: currentImage === 0 ? 'default' : 'pointer',
              }}
            >
              <ArrowLeftIcon />
            </Box>
            <Box display="flex" overflow="hidden">
              {currentImage < imagesBottomTabLength ? (
                <>
                  {brandImages.map((image, index) => {
                    return (
                      index < imagesBottomTabLength && (
                        <Box
                          key={index}
                          component="img"
                          src={image.src}
                          sx={{
                            width: '120px',
                            height: '60px',
                            ml: '10px',
                            objectFit: 'contain',
                            bgcolor: '#f4f2f7',
                            border: currentImage === index ? '2px solid #9571D5' : 'none',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setCurrentImage(index);
                          }}
                        />
                      )
                    );
                  })}
                </>
              ) : currentImage > brandImages.length - imagesBottomTabLength ? (
                <>
                  {brandImages.map((image, index) => {
                    return (
                      index > brandImages.length - imagesBottomTabLength && (
                        <Box
                          key={index}
                          component="img"
                          src={image.src}
                          sx={{
                            width: '120px',
                            height: '60px',
                            ml: '10px',
                            objectFit: 'contain',
                            bgcolor: '#f4f2f7',
                            border: currentImage === index ? '2px solid #9571D5' : 'none',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setCurrentImage(index);
                          }}
                        />
                      )
                    );
                  })}
                </>
              ) : (
                <>
                  {brandImages.map((image, index) => {
                    return (
                      index > currentImage - Math.round(imagesBottomTabLength / 2) &&
                      index < currentImage + Math.round(imagesBottomTabLength / 2) && (
                        <Box
                          key={index}
                          component="img"
                          src={image.src}
                          sx={{
                            width: '120px',
                            height: '60px',
                            ml: '10px',
                            objectFit: 'contain',
                            bgcolor: '#f4f2f7',
                            border: currentImage === index ? '2px solid #9571D5' : 'none',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setCurrentImage(index);
                          }}
                        />
                      )
                    );
                  })}
                </>
              )}
            </Box>
            <Box
              component={'button'}
              disabled={currentImage === brandImages.length - 1}
              onClick={handleClickNext}
              sx={{
                height: '100%',
                border: 'none',
                bgcolor: '#FFF',
                ml: '10px',
                cursor: currentImage === brandImages.length - 1 ? 'default' : 'pointer',
              }}
            >
              <ArrowRightIcon />
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
          <Button
            variant="outlined"
            onClick={() => {
              setStep(Steps.evaluation);
            }}
          >
            <Typography color="#352E65">Previous step</Typography>
          </Button>

          <LoadingButton
            variant="contained"
            sx={{
              bgcolor: '#28205B',
              '&: disabled': { bgcolor: '#FFF', border: '1px solid lightgrey' },
            }}
            onClick={() => {
              setStep(Steps.finalizing);
            }}
            loading={isFinishLoading}
          >
            Finish Review
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};
