import logodark from '../../assets/images/logodark.webp';

export const sliderStyle = {
  height: 8,
  color: '#6C7187',
  '.MuiSlider-rail': {
    opacity: 0.1,
  },
  '.MuiSlider-thumb, .MuiSlider-thumb:hover': {
    boxShadow: '0px 0px 0px 6px #fff, 0 0 0px 7px #e4e4e6',
    width: 12,
    height: 12,
  },
};

export const RESELLERS = [
  {
    name: 'Instacart',
    icon: logodark,
    count: 101,
  },
  {
    name: 'Walmart',
    icon: logodark,
    count: 101,
  },
  {
    name: 'Uber Eats',
    icon: logodark,
    count: 245,
  },
  {
    name: 'Metro',
    icon: logodark,
    count: 258,
  },
];

export const ISSUES_ITEMS = [
  {
    name: 'Image Resolution',
    icon: 'https://test-rr694.s3.amazonaws.com/experiments/563d4f60-8943-4ed1-a491-cddfa5f1decb.png',
    count: 258,
  },
  {
    name: 'Lighting and Color Depth',
    icon: 'https://test-rr694.s3.amazonaws.com/experiments/563d4f60-8943-4ed1-a491-cddfa5f1decb.png',
    count: 245,
  },
  {
    name: 'Text Readability',
    icon: 'https://test-rr694.s3.amazonaws.com/experiments/563d4f60-8943-4ed1-a491-cddfa5f1decb.png',
    count: 101,
  },
];

export const segmentTitles = [
  'Fetching images',
  'Evaluating Data',
  'Normalizing Data',
  'Uploading Data, almost done!',
];
