import React from 'react';

export const TwoToOne: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="16" height="16" fill="transparent" />
    <path
      d="M3 7.5V6H4.5M13 8.5V10H11.5M1 5V11C1 11.5523 1.44772 12 2 12H14C14.5523 12 15 11.5523 15 11V5C15 4.44772 14.5523 4 14 4H2C1.44772 4 1 4.44772 1 5Z"
      stroke="black"
    />
  </svg>
);
