import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { EditImageIcon, VariationIcon } from '../../../../../../../assets/icons';

interface IVariations {
  setOpenEditModal: (value: boolean) => void;
  handleSend: (value: string) => void;
  isLoading: boolean;
}

export const Variations = ({ setOpenEditModal, handleSend, isLoading }: IVariations) => {
  return (
    <Grid
      container
      sx={{
        justifyContent: 'space-between',
        width: '100%',
        height: '50px',
      }}
    >
      <Grid
        item
        xs={6}
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: '50px',
          opacity: isLoading ? 0.5 : 1,
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={() => {
            !isLoading && setOpenEditModal(true);
          }}
          bgcolor="#F6F6F6"
          width="95%"
          height="40px"
          borderRadius="10px"
        >
          <EditImageIcon />
          <Typography fontFamily="Figtree" color="#61646B" fontSize={14} ml="10px">
            Edit image
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={6}
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: '50px',
          opacity: isLoading ? 0.5 : 1,
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={() => {
            !isLoading && handleSend('Make variations');
          }}
          bgcolor="#F6F6F6"
          width="95%"
          height="40px"
          borderRadius="10px"
        >
          <VariationIcon />
          <Typography fontFamily="Figtree" color="#61646B" fontSize={14} ml="10px">
            Make variations
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
