import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';

import type { IProductImage, ViewType } from '../../core/api/types/Product';
import ScoreIndicator from '../Common/ScoreIndicator';

interface Props {
  product: IProductImage;
  onClick: () => void;
  variant: ViewType;
  sx: Record<string, unknown>;
}

function ProductCard({ product, onClick, variant, sx }: Props) {
  if (variant === 'list') {
    return (
      <Grid
        container
        alignItems="center"
        sx={{ p: '16px 24px 16px 16px', cursor: 'pointer', borderRadius: '16px' }}
        bgcolor={'#fff'}
        onClick={onClick}
      >
        <Grid
          item
          xs={1}
          display={'flex'}
          alignItems={'self-end'}
          justifyContent={'end'}
          pr={'37px'}
        >
          <ScoreIndicator score={product.score} variant="list" />
        </Grid>
        <Grid item xs={4}>
          <Box
            component="img"
            src={product.image_url}
            alt={product.title}
            sx={{ width: '130px', height: '100px', objectFit: 'cover', borderRadius: '8px' }}
          />
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body1">{product.title}</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Card
      sx={{
        borderRadius: '16px',
        overflow: 'hidden',
        boxShadow: 'none',
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        ...sx,
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          aspectRatio: '4/3',
          position: 'relative',
          background: `url(${product.image_url}) no-repeat center/contain`,
        }}
      >
        <ScoreIndicator score={product.score} variant="grid" />
      </Box>
      <Typography fontSize={20} fontWeight={400} color={'#171D30'} p={'16px 24px 12px 24px'}>
        {product.title}
      </Typography>
    </Card>
  );
}

export default ProductCard;
