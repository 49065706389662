import React from 'react';

export const ArrowDownwardAltIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <mask id="mask0_1731_44757" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
      <rect width="20" height="20" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1731_44757)">
      <path
        d="M10 14L6 10L7.0625 8.9375L9.25 11.125V5H10.75V11.125L12.9375 8.9375L14 10L10 14Z"
        fill="#171D30"
      />
    </g>
  </svg>
);
