import { baseApi } from '.';
import { type IAction } from './types/Actions';

export const actionsApi = baseApi.injectEndpoints({
  endpoints: builder => {
    const token = localStorage.getItem('token') ?? '';

    return {
      getActions: builder.query<IAction[], number | undefined | any>({
        query: brandId => ({
          url: `/api/brand_monitoring/actions/${brandId ? `?brand_id=${brandId}` : ''}`,
          method: 'get',
          headers: {
            Authorization: `Token ${token}`,
          },
        }),
      }),
    };
  },
});

export const { useGetActionsQuery } = actionsApi;
