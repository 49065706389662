import { configureStore } from '@reduxjs/toolkit';
import { baseApi } from '../api';
import filterSlice from './filterSlice';
import onBoardingSlice from './onBoardingSlice';
import dashboardSlice from './dashboardSlice';

export const createStore = () =>
  configureStore({
    reducer: {
      [baseApi.reducerPath]: baseApi.reducer,
      filter: filterSlice,
      onboarding: onBoardingSlice,
      dashboard: dashboardSlice,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(baseApi.middleware),
  });

export const store = createStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
