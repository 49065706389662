import React, { type ReactNode, createContext, useState, useEffect } from 'react';
import { getUserBrands } from '../repositories/brands.service';
import { getBrand } from '../common/utils';
import { useGetCurrentUser } from '../hooks/api/user';

interface IBrand {
  focal_brand_id: string;
  id: number;
  name: string;
  takeaways: string[];
  user: number;
}
interface IBrandsData {
  userBrands: IBrand[];
  updateUserBrands: () => void;
  selectedBrand: IBrand;
  setSelectedBrand: (value: IBrand) => void;
  resetSelectedBrand: () => void;
}

interface IProps {
  children: ReactNode;
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const BrandsContext = createContext<IBrandsData>({} as IBrandsData);

export const BrandsProvider: React.FC<IProps> = ({ children }) => {
  const [userBrands, setUserBrands] = useState<IBrand[]>([]);

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const [selectedBrand, setSelectedBrand] = useState<IBrand>({} as IBrand);

  const [user] = useGetCurrentUser();

  useEffect(() => {
    if (user) {
      updateUserBrands();
    }
  }, [user]);

  useEffect(() => {
    const brandId = getBrand();

    if (brandId) {
      const brand = userBrands.find(brand => brand.id === brandId);

      if (brand) {
        setSelectedBrand(brand);
      }
    } else if (userBrands?.length) {
      setSelectedBrand(userBrands[0]);
    }
  }, [userBrands]);

  const resetSelectedBrand = () => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    setSelectedBrand({} as IBrand);
  };

  const updateUserBrands = async () => {
    const themes = await getUserBrands();
    setUserBrands(themes);
  };

  const contextValue: IBrandsData = {
    userBrands,
    updateUserBrands,
    selectedBrand,
    setSelectedBrand,
    resetSelectedBrand,
  };

  return <BrandsContext.Provider value={contextValue}>{children}</BrandsContext.Provider>;
};
