import React from 'react';

interface IProps {
  color?: string;
}

export const ArrowOutIcon = ({ color = '#28205B' }: IProps) => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_798_5152" maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="15">
      <rect y="0.5" width="14" height="14" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_798_5152)">
      <path
        d="M3.54355 10.9998L2.7998 10.2561L8.70605 4.3498H3.4998V3.2998H10.4998V10.2998H9.4498V5.09355L3.54355 10.9998Z"
        fill="white"
      />
    </g>
  </svg>
);
