import React from 'react';

export const CloseFill: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M10.0001 8.82141L14.1251 4.69641L15.3034 5.87474L11.1784 9.99974L15.3034 14.1247L14.1251 15.3031L10.0001 11.1781L5.87511 15.3031L4.69678 14.1247L8.82178 9.99974L4.69678 5.87474L5.87511 4.69641L10.0001 8.82141Z"
      fill="#42485D"
      fillOpacity="0.6"
    />
  </svg>
);
