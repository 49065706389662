import React from 'react';
interface IProps {
  active?: boolean;
}

export const CloseSquareIcon = ({ active }: IProps) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.8337 15.3417L24.6587 14.1667L20.0003 18.8251L15.342 14.1667L14.167 15.3417L18.8253 20.0001L14.167 24.6584L15.342 25.8334L20.0003 21.1751L24.6587 25.8334L25.8337 24.6584L21.1753 20.0001L25.8337 15.3417Z"
      fill={active ? '#FFF' : '#1B1B1E'}
    />
  </svg>
);
